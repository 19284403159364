import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DevicesService } from '../../../services/devices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorsService } from '@core/services/errors.service';
import * as moment from 'moment';
import * as fromModels from '../../../../../store/actions/models.action';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { CheckAccess, DateManipulations } from '@cloud/cloud.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ConfirmControlComponent } from '../confirm-control/confirm-control.component';
import { MY_FORMATS } from '@core/constants/consts';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateLanguageService } from '@core/services/translate-language.service';

moment.tz.setDefault('UTC');

type ControlReqT = {
    title: string;
    title_en: string;
    management: any;
};

@Component({
    selector: 'app-device-control',
    templateUrl: './device.component.html',
    styleUrls: ['./device.component.less'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    ]
})
export class DeviceComponent implements OnInit, OnDestroy {
    @Input() meteringDevice = null;
    currentDevice;
    previousLimitValue;
    isSpinnerVisible = true;
    controls: any;
    options: any;
    sendObject = <any>{
        management: {}
    };
    currentStatus;
    disableEdit = true;
    archivePowerProfileSendObject = {
        id: 0,
        management: {
            archive_profile: null
        }
    };
    powerSendObject = {
        id: 0,
        management: {
            power: 0
        }
    };
    powerLimitObject: any = {
        id: 0,
        management: {
            limit: 0
        }
    };
    setDateTimeSendObject = {
        id: 0,
        management: {
            date: null,
            time: null
        }
    };
    correctTimeSendObject = {
        id: 0,
        management: {
            time_correct: null
        }
    };
    correctTimeSendObject2 = {
        id: 0,
        management: {
            time_correct2: null
        }
    };
    correctSpecialDays = {
        id: 0,
        management: {
            set_special_day: {
                shedule: null,
                day_list: []
            }
        }
    };
    complexSetSendObject = {
        id: 0,
        management: {
            complex_set: 1,
            bytes: [false, false, false, false, false, false, false],
            m_msg1: 0,
            m_msg2: 0,
            m_msg3: 0,
            m_msg4: 0,
            m_msg5: 0,
            m_msg6: 0
        }
    };
    vegaSettings: { [key: string]: any } = {};
    setSettings: { [key: string]: any } = {};
    setSetpoints: { [key: string]: any } = {};
    vegaSettingsModel: { [key: string]: any } = {};
    setSettingsModel: { [key: string]: any } = {};
    setSetpointsModel: { [key: string]: any } = {};
    lartechSettings: { [key: string]: any } = {};
    lartechDelaySettings = [];
    lartechProfileSettings = [];
    lartechRefreshSettings = [];
    listTaskId = [];
    selectedTaskId: number;
    isNumberSelectedTaskId: boolean;
    regExp = /^[0-9ABCDEFabcdef]+$/;
    port: any;
    invisibleMode = false;
    public settingsTaskPlanerForm = new UntypedFormGroup({
        delay: new UntypedFormControl('', [Validators.required]),
        param1: new UntypedFormControl(0, [Validators.required]),
        param2: new UntypedFormControl(0, [Validators.required]),
        param3: new UntypedFormControl(0, [Validators.required]),
        param4: new UntypedFormControl(0, [Validators.required]),
        param5: new UntypedFormControl(0, [Validators.required]),
        param6: new UntypedFormControl(0, [Validators.required]),
        param7: new UntypedFormControl(0, [Validators.required]),
        param8: new UntypedFormControl(0, [Validators.required]),
        profile: new UntypedFormControl('', [Validators.required]),
        refresh: new UntypedFormControl('', [Validators.required])
    });
    public freeCommandForm = new UntypedFormGroup({
        payload: new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(444),
            Validators.pattern(this.regExp)
        ]),
        fport: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(255)]),
        class: new UntypedFormControl('', [Validators.required])
    });
    public classForCommandForLoRaWan = [
        {
            value: 'A',
            name: this.translateLanguageService.translateInstant('app_accounting_point.control.msg_60')
        },
        {
            value: 'C',
            name: this.translateLanguageService.translateInstant('app_accounting_point.control.msg_61')
        }
    ];
    public extraOptions = false;
    availableComplexSetMsgs = [
        { value: 0, title: 'Не выбрано' },
        { value: 600, title: 'Открытая Вариант 1 Основной' },
        { value: 601, title: 'Открытая Вариант 1 Дополнительный' },
        { value: 602, title: 'Открытая Вариант 2 Основной' },
        { value: 603, title: 'Открытая Вариант 2 Дополнительный' },
        { value: 604, title: 'Закрытая по подаче 1р Основной' },
        { value: 605, title: 'Закрытая по подаче 1р Дополнительный' },
        { value: 606, title: 'Закрытая по подаче 2р Основной' },
        { value: 607, title: 'Закрытая по подаче 2р Дополнительный' },
        { value: 608, title: 'ГВС открытая с циркуляцией Основной' },
        { value: 609, title: 'ГВС открытая с циркуляцией Дополнительный' },
        { value: 610, title: 'ГВС тупиковая Вариант 1 Основной' },
        { value: 611, title: 'ГВС тупиковая Вариант 1 Дополнительный' },
        { value: 612, title: 'ХВС Вариант 1 Основной' },
        { value: 613, title: 'Параметры ХИ Вариант 1 Основной' }
    ];
    reportPeriodObject = {
        id: 0,
        management: {
            report_period: 0,
            offset: 0
        }
    };
    frequency: any = 0;
    extraForm: UntypedFormGroup = new UntypedFormGroup({
        measurementMode: new UntypedFormControl(null, [Validators.required]),
        apv_pause: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(60)]),
        apv_timer: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(9)]),
        apv_count: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(9)])
    });
    // limit_mode
    listOfMeasurementMode = [
        {
            id: 0,
            name: this.translateLanguageService.translateInstant('app_accounting_point.control.msg_43')
        },
        {
            id: 1,
            name: this.translateLanguageService.translateInstant('app_accounting_point.control.msg_44')
        }
    ];
    modeInterval = true;
    lang: string;
    // TODO: move to service or const file
    readonly mg08Params = {
        muffing: {
            title: 'Приглушение',
            title_en: 'Muffing'
        },
        set_test: {
            title: 'Тестовый режим',
            title_en: 'Test mode'
        },
        closed_solenoid: {
            title: 'Электромагнитный клапан закрыт',
            title_en: 'Solenoid valve closed'
        },
        set_co_alarm: {
            title: 'Значение сигнала тревоги CO',
            title_en: 'CO alarm value'
        },
        set_co_exit_alarm: {
            title: 'Значение сигнала тревоги о выходе CO',
            title_en: 'CO CO exit alarm value'
        },
        set_gas_alarm: {
            title: 'Значение сигнала тревоги газа',
            title_en: 'GAS alarm value'
        },
        set_gas_exit_alarm: {
            title: 'Значение сигнала тревоги о выходе газа',
            title_en: 'GAS exit alarm value'
        },
        get_alarm_value: {
            title: 'Запрос значений сигнала тревоги CO+газ',
            title_en: 'Get CO+GAS alarm value'
        },
        get_exit_alarm_value: {
            title: 'Запрос значений сигнала тревоги о выходе CO+газ',
            title_en: 'Get CO+GAS exit alarm value'
        }
    };
    mg08Buttons = ['muffing', 'get_alarm_value', 'get_exit_alarm_value'];
    mg08Toggles = ['set_test', 'closed_solenoid'];
    mg08Inputs = ['set_co_alarm', 'set_co_exit_alarm', 'set_gas_alarm', 'set_gas_exit_alarm'];
    mg08Form = new UntypedFormGroup({
        set_test: new UntypedFormControl(false),
        closed_solenoid: new UntypedFormControl(false),
        set_co_alarm: new UntypedFormControl(),
        set_co_exit_alarm: new UntypedFormControl(),
        set_gas_alarm: new UntypedFormControl(),
        set_gas_exit_alarm: new UntypedFormControl()
    });
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        public dialogRef: MatDialogRef<DeviceComponent>,
        public _checkAccess: CheckAccess,
        public dateManipulations: DateManipulations,
        private route: ActivatedRoute,
        private router: Router,
        private _devicesService: DevicesService,
        private errors: ErrorsService,
        private store: Store<{}>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dateAdapter: DateAdapter<Date>,
        private translateLanguageService: TranslateLanguageService
    ) {}

    ngOnInit() {
        this.translateLanguageService.currentLanguage$.pipe(takeUntil(this.destroyed$)).subscribe((lang) => {
            this.lang = lang;
            this.dateAdapter.setLocale(lang);
        });

        if (this.meteringDevice) {
            this.data = {
                device_id: this.meteringDevice.id,
                model_id: this.meteringDevice.model_id
            };
        }
        if (_.isNull(this.data) || _.isUndefined(this.data)) {
            this.errors.showSnackbar('msg_79', true);
            this.dialogRef.close();
        }
        this.sendObject['id'] = _.get(this.data, 'device_id');
        this.powerSendObject.id = _.get(this.data, 'device_id');
        this.archivePowerProfileSendObject.id = _.get(this.data, 'device_id');
        this.powerLimitObject.id = _.get(this.data, 'device_id');
        this.setDateTimeSendObject.id = _.get(this.data, 'device_id');
        this.correctTimeSendObject.id = _.get(this.data, 'device_id');
        this.correctTimeSendObject2.id = _.get(this.data, 'device_id');
        this.correctSpecialDays.id = _.get(this.data, 'device_id');
        this.complexSetSendObject.id = _.get(this.data, 'device_id');
        this.reportPeriodObject.id = _.get(this.data, 'device_id');
        this._devicesService
            .getOneMeteringDevice(_.get(this.data, 'device_id'))
            .pipe(takeUntil(this.destroyed$))
            .subscribe((device: any) => {
                this.currentDevice = device;
                if (this.currentDevice?.properties?.hasOwnProperty('transparent_mport')) {
                    this.port = this.currentDevice?.properties?.transparent_mport;
                    this.invisibleMode = !!this.port;
                }
                if (!_.isNull(device.status)) {
                    this.currentStatus = device.status;
                    this.sendObject = device.status;
                    if (this.currentStatus.hasOwnProperty('additional_limit')) {
                        this.extraOptions = true;
                        this.extraForm.setValue({
                            measurementMode: this.currentStatus.additional_limit?.limit_mode || 0,
                            apv_pause: this.currentStatus.additional_limit?.apv_pause || 0,
                            apv_timer: this.currentStatus.additional_limit?.apv_timer || 0,
                            apv_count: this.currentStatus.additional_limit?.apv_count || 0
                        });
                        this.modeInterval = !this.currentStatus.additional_limit.limit_mode;
                    }
                    if (this.currentStatus?.last_special_shedule_day?.length) {
                        this.correctSpecialDays.management.set_special_day.day_list = [...this.currentStatus?.last_special_shedule_day];
                    }
                    if (this.currentStatus?.last_special_shedule_tariff) {
                        this.correctSpecialDays.management.set_special_day.shedule = { ...this.currentStatus?.last_special_shedule_tariff };
                    }
                    // дата/время на устройстве из status
                    // if (device.status.hasOwnProperty('last_get_datetime')) {
                    //     const date = moment(device.status.last_get_datetime, 'DD-MM-YYYY HH:mm:ss');
                    //     this.setDateTimeSendObject.management['date'] = date.format('YYYY-MM-DD');
                    //     this.setDateTimeSendObject.management['time'] = date.format('HH:mm');
                    // }
                }
                this.store
                    .pipe(
                        select(fromModels.getMeteringDeviceModelById, {
                            id: _.get(this.data, 'model_id')
                        })
                    )
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((model: any) => {
                        if (model.options && model.options.hasOwnProperty('control')) {
                            this.options = model.options;
                            this.controls = model.options.control;
                            if (this.controls?.vega_settings?.settings) {
                                this.vegaSettings = { ...this.controls.vega_settings.settings };
                                for (const key in this.controls.vega_settings.settings) {
                                    if (
                                        this.controls.vega_settings.settings.hasOwnProperty(key) &&
                                        device?.status.hasOwnProperty('vega_settings')
                                    ) {
                                        this.vegaSettingsModel[key] = device?.status?.vega_settings[key];
                                    }
                                }
                            }
                            if (this.controls?.set_settings?.settings) {
                                this.setSettings = { ...this.controls.set_settings.settings };
                                for (const key in this.controls.set_settings.settings) {
                                    if (
                                        this.controls.set_settings.settings.hasOwnProperty(key) &&
                                        device?.status.hasOwnProperty('set_settings')
                                    ) {
                                        this.setSettingsModel[key] = device?.status?.set_settings[key];
                                    }
                                }
                            }
                            if (this.controls?.set_setpoint?.settings) {
                                this.setSetpoints = { ...this.controls.set_setpoint.settings };
                                for (const key in this.controls.set_setpoint.settings) {
                                    if (
                                        this.controls.set_setpoint.settings.hasOwnProperty(key) &&
                                        device?.status.hasOwnProperty('set_setpoint')
                                    ) {
                                        this.setSetpointsModel[key] = device?.status?.set_setpoint[key];
                                    }
                                }
                            }
                            if (this.controls?.lartech_settings?.settings) {
                                if (this.controls.lartech_settings.settings.hasOwnProperty('task_id')) {
                                    this.lartechSettings = { ...this.controls.lartech_settings.settings };
                                    this.createListTaskIdForLartechSettings();
                                    this.getDelayForLartechSettings();
                                    this.getProfileForLartechSettings();
                                    this.getRefreshForLartechSettings();
                                } else {
                                    this.errors.showSnackbar('not_task_id', true);
                                    this.router.navigate(['/cloud/devices/']).then();
                                    return;
                                }
                            }
                            if (this.controls?.send_raw_payload) {
                                // TODO: is it need or not?
                                console.log('send raw payload');
                            }
                        }
                        if (this.currentDevice !== undefined) {
                            this.currentDevice['model'] = model;
                        }

                        if (_.isObject(device.status) && !_.isUndefined(device.status['power'])) {
                            this.powerSendObject['management']['power'] = Number(device.status['power']);
                        } else {
                            this.powerSendObject['management']['power'] = null;
                        }

                        if (_.isObject(device.status) && !_.isUndefined(device.status['last_set_limit'])) {
                            this.previousLimitValue = Number(device.status['last_set_limit']);
                            this.powerLimitObject.management['limit'] = Number(device.status['last_set_limit']);
                        } else {
                            this.powerLimitObject.management['limit'] = null;
                        }

                        // TODO: move to separate set form function
                        if (this.controls) {
                            [...this.mg08Inputs, ...this.mg08Toggles].forEach((key) => {
                                if (this.controls.hasOwnProperty(key)) {
                                    this.mg08Form.controls[key].setValue(this.controls[key]);
                                }
                            });
                        }

                        setTimeout(() => {
                            this.isSpinnerVisible = false;
                            this.disableEdit = false;
                        }, 100);
                    });
            });
    }

    public isControlActive(control) {
        return !!this.controls?.[control]?.['active'];
    }

    public getSettingName(control): string {
        return this.lang === 'en' && control?.value?.name_en ? control?.value?.name_en : control?.value?.name;
    }

    public updateTariffsForSpecialDays(data) {
        this.correctSpecialDays.management.set_special_day.shedule = data;
    }

    public mg08BtnClickHandler(key: string) {
        const body = {
            title: this.mg08Params?.[key].title,
            title_en: this.mg08Params?.[key].title_en,
            management: { [key]: true }
        };
        this.readControlStateCommandWithConfirmation(body);
    }

    public mg08ToggleHandler(key: string, event: any) {
        const previousValue = !event.checked;
        const body = {
            title: this.mg08Params?.[key].title,
            title_en: this.mg08Params?.[key].title_en,
            management: { [key]: event.checked }
        };
        const reqBody = this.getControlReqBody(body);
        this._devicesService.setDeviceControl(reqBody).subscribe(
            (res) => {
                this.errors.showSnackbar(res?.['data']?.msg);
            },
            (error) => {
                this.mg08Form.patchValue({ [key]: previousValue });
                throw error;
            }
        );
    }

    public mg08TextInputHandler(key: string) {
        const value = this.mg08Form.controls[key].value;
        const body = {
            title: this.mg08Params?.[key].title,
            title_en: this.mg08Params?.[key].title_en,
            management: { [key]: value }
        };
        const reqBody = this.getControlReqBody(body);
        this.readControlStateCommandWithConfirmation(reqBody);
    }

    postActiv(mode) {
        const body = {
            device_id: this.currentDevice.id,
            on: mode
        };
        this._devicesService.getPort(body).subscribe(
            (port: any) => {
                this.invisibleMode = !this.invisibleMode;
                this.port = port.data.port;
            },
            (error) => {}
        );
    }

    onSelectedTaskId() {
        let settingsSelectedTaskId;
        this.isNumberSelectedTaskId = typeof this.selectedTaskId === 'number';
        if (
            this.isNumberSelectedTaskId &&
            this.currentDevice &&
            this.currentDevice?.status &&
            this.currentDevice.status.hasOwnProperty('lartech_settings') &&
            this.currentDevice.status.lartech_settings.hasOwnProperty(this.selectedTaskId)
        ) {
            settingsSelectedTaskId = this.currentDevice.status.lartech_settings[this.selectedTaskId];
            this.settingsTaskPlanerForm.setValue({
                delay: settingsSelectedTaskId.delay,
                param1: settingsSelectedTaskId.param1,
                param2: settingsSelectedTaskId.param2,
                param3: settingsSelectedTaskId.param3,
                param4: settingsSelectedTaskId.param4,
                param5: settingsSelectedTaskId.param5,
                param6: settingsSelectedTaskId.param6,
                param7: settingsSelectedTaskId.param7,
                param8: settingsSelectedTaskId.param8,
                profile: settingsSelectedTaskId.profile,
                refresh: settingsSelectedTaskId.refresh
            });
        } else {
            this.settingsTaskPlanerForm.reset();
        }
    }

    setSchedulerSettings() {
        const isNullValueForm = Object.values(this.settingsTaskPlanerForm.value).some((el) => el === null);
        if (isNullValueForm) {
            this.errors.showSnackbar(this.translateLanguageService.translateInstant('snackBarMessages.msg_81'));
            return;
        } else {
            const body = {
                id: _.get(this.data, 'device_id'),
                management: { ...this.settingsTaskPlanerForm.value, task_id: this.selectedTaskId }
            };
            this._devicesService
                .setControl(body, `Задача планировщика - ${this.selectedTaskId}`, `Task scheduler - ${this.selectedTaskId}`)
                .then(
                    (response: any) => {
                        this.disableEdit = false;
                        this.errors.showSnackbar(response.data.msg);
                    },
                    (error) => {
                        this.disableEdit = false;
                        throw error;
                    }
                );
        }
    }

    // TODO: refactor code below

    getProfileForLartechSettings() {
        for (const [key, val] of Object.entries(this.controls.lartech_settings.settings.profile.properties)) {
            this.lartechProfileSettings.push({ key, val });
        }
    }

    getRefreshForLartechSettings() {
        for (const [key, val] of Object.entries(this.controls.lartech_settings.settings.refresh.properties)) {
            this.lartechRefreshSettings.push({ key, val });
        }
    }

    getDelayForLartechSettings() {
        for (const [key, val] of Object.entries(this.controls.lartech_settings.settings.delay.properties)) {
            this.lartechDelaySettings.push({ key, val });
        }
    }

    createListTaskIdForLartechSettings() {
        for (let i = this.lartechSettings.task_id.min; i <= this.lartechSettings.task_id.max; i++) {
            this.listTaskId.push(i);
        }
    }

    changePower(event) {
        this.disableEdit = true;
        const previousValue = Number(!event.checked);
        this.powerSendObject.management['power'] = Number(event.checked);
        const dialogRef = this.dialog.open(ConfirmControlComponent, {
            width: '25%'
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((result) => {
                if (result === true) {
                    this._devicesService
                        .setControl(
                            this.powerSendObject,
                            `Ручное ${event.checked ? 'включение' : 'отключение'} устройства`,
                            `Manual ${event.checked ? 'activation' : 'shutdown'} device`
                        )
                        .then(
                            (response: any) => {
                                this.disableEdit = false;
                                this.errors.showSnackbar(response.data.msg);
                            },
                            (error) => {
                                this.powerSendObject.management['power'] = Number(previousValue);
                                this.disableEdit = false;
                                throw error;
                            }
                        );
                } else {
                    this.powerSendObject.management['power'] = previousValue;
                    this.disableEdit = false;
                }
            });
    }

    onSetPlomb() {
        this._devicesService
            .setControl(
                {
                    management: { set_plomb: true },
                    id: _.get(this.data, 'device_id')
                },
                `Установка электронной пломбы`,
                `Installing an electronic seal`
            )
            .then((response: any) => {
                this.errors.showSnackbar(response.data.msg);
            });
    }

    reloadModel() {
        const dialogRef = this.dialog.open(ConfirmControlComponent, {
            width: '25%'
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((result) => {
                if (result) {
                    this._devicesService
                        .setControl(
                            {
                                id: _.get(this.data, 'device_id'),
                                management: { reset: true }
                            },
                            'Перезагрузка',
                            'Reboot'
                        )
                        .then((response: any) => {
                            this.errors.showSnackbar(response.data.msg);
                        });
                }
            });
    }

    readRelayStatus() {
        this._devicesService
            .setControl(
                {
                    id: _.get(this.data, 'device_id'),
                    management: { relay_state: true }
                },
                'Чтение состояния реле',
                'Reading relay status'
            )
            .then((response: any) => {
                this.errors.showSnackbar(response.data.msg);
            });
    }

    readPowerLimit() {
        this._devicesService
            .setControl(
                {
                    id: _.get(this.data, 'device_id'),
                    management: { limit_state: true }
                },
                'Чтение лимита мощности',
                'Reading power limit'
            )
            .then((response: any) => {
                this.errors.showSnackbar(response.data.msg);
            });
    }

    readDateAndTime() {
        this._devicesService
            .setControl(
                {
                    id: _.get(this.data, 'device_id'),
                    management: { get_datetime: true }
                },
                'Чтение даты и времени',
                'Reading date and time'
            )
            .then((response: any) => {
                this.errors.showSnackbar(response.data.msg);
            });
    }

    readTariffSchedule() {
        this._devicesService
            .setControl(
                {
                    id: _.get(this.data, 'device_id'),
                    management: { get_shedule_tariff: true }
                },
                'Чтение тарифного расписания',
                'Reading the tariff schedule'
            )
            .then((response: any) => {
                this.errors.showSnackbar(response.data.msg);
            });
    }

    setExtraPowerLimitValueToObject() {
        this.powerLimitObject.management.limit_mode = this.extraForm.get('measurementMode').value;
        this.powerLimitObject.management.apv_pause = this.extraForm.get('apv_pause').value;
        this.powerLimitObject.management.apv_timer = this.extraForm.get('apv_timer').value;
        this.powerLimitObject.management.apv_count = this.extraForm.get('apv_count').value;
    }

    deleteExtraPowerLimitValueToObject() {
        delete this.powerLimitObject.management.limit_mode;
        delete this.powerLimitObject.management.apv_pause;
        delete this.powerLimitObject.management.apv_timer;
        delete this.powerLimitObject.management.apv_count;
    }

    changeLimit() {
        if (!this.extraForm.valid && this.extraOptions) {
            return;
        }
        this.disableEdit = true;
        const dialogRef = this.dialog.open(ConfirmControlComponent, {
            width: '25%'
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((result) => {
                if (result === true) {
                    if (this.extraOptions) {
                        this.setExtraPowerLimitValueToObject();
                    } else {
                        this.deleteExtraPowerLimitValueToObject();
                    }
                    this._devicesService
                        .setControl(
                            this.powerLimitObject,
                            'Ручное изменение лимита мощности устройства',
                            'Manual change of device power limit'
                        )
                        .then(
                            (response: any) => {
                                this.disableEdit = false;
                                this.errors.showSnackbar(response.data.msg);
                            },
                            (error) => {
                                this.disableEdit = false;
                                this.powerLimitObject['management']['limit'] = this.previousLimitValue;
                                throw error;
                            }
                        );
                } else {
                    this.disableEdit = false;
                    this.powerLimitObject['management']['limit'] = this.previousLimitValue;
                }
            });
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    changeFrequency() {
        const body = {
            id: _.get(this.data, 'device_id'),
            management: { setFrequency: this.frequency }
        };
        this._devicesService.setControl(body, 'Ручное изменение частоты', 'Manual frequency change').then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }

    setDateTime() {
        this.disableEdit = true;
        const body = _.cloneDeep(this.setDateTimeSendObject);
        if (this.setDateTimeSendObject.management.hasOwnProperty('date') || this.setDateTimeSendObject.management.hasOwnProperty('time')) {
            body.management['time_set'] =
                moment(this.setDateTimeSendObject.management['date']).format('YYYY-MM-DD') +
                ' ' +
                (body.management['time'] || '00') +
                ':00';
            delete body.management['time'];
            delete body.management['date'];
        }
        this._devicesService.setControl(body, 'Ручное изменение даты и времени', 'Manual change of date and time').then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }

    correctTime() {
        this.disableEdit = true;
        this._devicesService.setControl(this.correctTimeSendObject, 'Ручная корректировка времени', 'Manual time adjustment').then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }

    correctTime2() {
        this.disableEdit = true;
        this._devicesService
            .setControl(
                this.correctTimeSendObject2,
                'Ручная корректировка времени с повышенной точностью',
                'Manual time adjustment with precision'
            )
            .then(
                (response: any) => {
                    this.disableEdit = false;
                    this.errors.showSnackbar(response.data.msg);
                },
                (error) => {
                    this.disableEdit = false;
                    throw error;
                }
            );
    }

    setSpecialDays() {
        this.disableEdit = true;
        this._devicesService.setControl(this.correctSpecialDays, 'Установка специальных дней', 'Set special days').then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }

    complexSet() {
        let arch_type = 0;
        const params = JSON.parse(JSON.stringify(this.complexSetSendObject));
        for (const [byte, value] of params.management.bytes.entries()) {
            if (value) {
                arch_type += Math.pow(2, byte);
            }
        }
        params.management['arch_type'] = arch_type;
        delete params.management.bytes;
        this._devicesService.setControl(params, 'Ручная установка параметров', 'Manual parameter setting').then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }

    setReportPeriod() {
        this.disableEdit = true;
        this._devicesService
            .setControl(this.reportPeriodObject, 'Ручная установка периода выхода на связь', 'Manual setting of the communication period')
            .then(
                (response: any) => {
                    this.disableEdit = false;
                    this.errors.showSnackbar(response.data.msg);
                },
                (error) => {
                    this.disableEdit = false;
                    throw error;
                }
            );
    }

    sendingCustomCommands() {
        const payload = this.freeCommandForm.get('payload').value;
        const fport = this.freeCommandForm.get('fport').value;
        const classCustomCommand = this.freeCommandForm.get('class').value;
        if (!payload || !fport || !classCustomCommand) {
            return this.errors.showSnackbar('msg_81', true);
        }
        if (payload.length % 2 !== 0) {
            return this.errors.showSnackbar('msg_145', true);
        }
        if (!payload.match(this.regExp)) {
            return this.errors.showSnackbar('msg_146', true);
        }
        this.disableEdit = true;
        this._devicesService
            .sendingCustomCommands(
                {
                    id: _.get(this.data, 'device_id'),
                    management: {
                        send_raw_payload: true,
                        payload: payload,
                        fport: fport,
                        class: classCustomCommand
                    }
                },
                'Произвольная команда',
                'Arbitrary command'
            )
            .then(
                (response: any) => {
                    this.disableEdit = false;
                    this.errors.showSnackbar(response.data.msg);
                },
                (error) => {
                    this.disableEdit = false;
                    throw error;
                }
            );
    }

    getSettings() {
        this.disableEdit = true;
        this._devicesService
            .setControl(
                {
                    id: _.get(this.data, 'device_id'),
                    management: { get_settings: true }
                },
                'Ручной запрос текущих настроек',
                'Manual request for current settings'
            )
            .then(
                (response: any) => {
                    this.disableEdit = false;
                    this.errors.showSnackbar(response.data.msg);
                },
                (error) => {
                    this.disableEdit = false;
                    throw error;
                }
            );
    }
    getSetPoints() {
        this.disableEdit = true;
        this._devicesService
            .setControl(
                {
                    id: _.get(this.data, 'device_id'),
                    management: { get_setpoints: true }
                },
                'Чтение уставок',
                'Reading setpoints'
            )
            .then(
                (response: any) => {
                    this.disableEdit = false;
                    this.errors.showSnackbar(response.data.msg);
                },
                (error) => {
                    this.disableEdit = false;
                    throw error;
                }
            );
    }

    getSpecialDays() {
        this.disableEdit = true;
        this._devicesService
            .setControl(
                {
                    id: _.get(this.data, 'device_id'),
                    management: { get_special_day: true }
                },
                'Чтение специальных дней',
                'Read special days'
            )
            .then(
                (response: any) => {
                    this.disableEdit = false;
                    this.errors.showSnackbar(response.data.msg);
                },
                (error) => {
                    this.disableEdit = false;
                    throw error;
                }
            );
    }

    unblock(): void {
        this.disableEdit = true;
        this._devicesService
            .setControl(
                {
                    id: _.get(this.data, 'device_id'),
                    management: { unblock: true }
                },
                'Разблокировка по команде',
                'Unlocking by command'
            )
            .then(
                (response: any) => {
                    this.disableEdit = false;
                    this.errors.showSnackbar(response.data.msg);
                },
                (error) => {
                    this.disableEdit = false;
                    throw error;
                }
            );
    }

    activateDataTransfer() {
        this.disableEdit = true;
        this._devicesService
            .setControl(
                {
                    id: _.get(this.data, 'device_id'),
                    management: { mts_activate: this.controls.mts_activate.active }
                },
                'Активация передачи данных',
                'Data transfer activation'
            )
            .then(
                (response: any) => {
                    this.disableEdit = false;
                    this.errors.showSnackbar(response.data.msg);
                },
                (error) => {
                    this.disableEdit = false;
                    throw error;
                }
            );
    }

    onChangeVegaSettings(key, value) {
        this.disableEdit = true;
        const body = {
            id: _.get(this.data, 'device_id'),
            management: { vega_settings: 1 }
        };
        body.management[key] = this.vegaSettingsModel[key];
        this._devicesService.setControl(body, value.name).then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }
    onChangeSetSettings() {
        this.disableEdit = true;
        const body = {
            id: _.get(this.data, 'device_id'),
            management: {}
        };
        body.management = {...this.setSettingsModel, sleep_time: Number(this.setSettingsModel.sleep_time) , range_connect: Number(this.setSettingsModel.range_connect), set_settings: true};
        this._devicesService.setControl(body, 'Запись настроек').then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }
    onChangeSetpoints() {
        this.disableEdit = true;
        const body = {
            id: _.get(this.data, 'device_id'),
            management: {}
        };
        body.management = {...this.setSetpointsModel, set_setpoint: true, skz_speed: Number(this.setSetpointsModel.skz_speed) , skz_boost: Number(this.setSetpointsModel.skz_boost), skz_move: Number(this.setSetpointsModel.skz_move)};
        this._devicesService.setControl(body, 'Запись уставок').then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }

    changeMode() {
        this.extraForm.get('apv_pause').setValue(this.extraForm.get('measurementMode').value ? 30 : 0);
        this.modeInterval = !this.extraForm.get('measurementMode').value;
    }

    // TODO: ConfirmActionComponent - create common component for overall project
    private readControlStateCommandWithConfirmation(body: ControlReqT) {
        this.disableEdit = true;

        const dialogRef = this.dialog.open(ConfirmControlComponent, { width: '25%' });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((ok) => {
                if (ok) {
                    this.readControlStateCommand(body);
                } else {
                    this.disableEdit = false;
                }
            });
    }

    private readControlStateCommand(body: ControlReqT) {
        const reqBody = this.getControlReqBody(body);
        this._devicesService.setDeviceControl(reqBody).subscribe(
            (res) => {
                this.errors.showSnackbar(res?.['data']?.msg);
                this.disableEdit = false;
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }

    private getControlReqBody(body: ControlReqT) {
        const { title, title_en, management } = body;
        return {
            command_type: 'control',
            device_ids: [_.get(this.data, 'device_id')],
            title_en: title_en || title,
            title,
            management
        };
    }
}
