<mat-card class="forget-card">
    <mat-card-header>
        <mat-card-title>{{ 'forgetActivateForm.h1' | translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'forgetActivateForm.desc' | translate }}</mat-card-subtitle>
    </mat-card-header>
    <form [formGroup]="forgetActivateForm" (submit)="submit()">
        <div class="forget-container">
            <div class="current_email">{{ 'forgetActivateForm.email' | translate }}: {{ email }}</div>
            <mat-form-field floatLabel="auto" class="forget-input">
                <input
                    matInput
                    type="password"
                    [placeholder]="'forgetActivateForm.inputs.password' | translate"
                    required
                    formControlName="password"
                    name="password"
                />
                <mat-error *ngIf="forgetActivateForm.controls['password'].hasError('minlength')">
                    {{ 'forgetActivateForm.error.fail_password' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="auto" class="forget-input">
                <input
                    matInput
                    type="password"
                    [placeholder]="'forgetActivateForm.inputs.password_confirmation' | translate"
                    required
                    formControlName="password_confirmation"
                    name="password_confirmation"
                />
                <mat-error *ngIf="forgetActivateForm.controls['password_confirmation'].hasError('minlength')">
                    {{ 'forgetActivateForm.error.fail_password' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="forget-button-container">
            <button id="forget-button" mat-button mat-flat-button color="primary">
                {{ 'forgetForm.button.submit' | translate }}
            </button>
        </div>
    </form>
</mat-card>
