<div class="nekta-search" [style.width.px]="getWidth()">
    <input class="nekta-search-input"
           matInput
           placeholder="{{ 'common.search' | translate }}"
           [(ngModel)]="searchString"
           (keyup.enter)="onClick()"
    />
    <div class="nekta-action-buttons">
        <i
            *ngIf="showExactSearch"
            [class]="'match-button--' + (!multiWord ? 'active' : 'disabled') + ' material-icons personal_design_icon'"
            [matTooltip]="'devices.listMeteringDevice.exact_search' | translate"
            (click)="toggleMultiWord()"
        >
            text_fields
        </i>

        <i *ngIf="searchString"
           class="close-button material-icons"
           [matTooltip]="'common.clear_field' | translate"
           (click)="onClear()"
        >
            close
        </i>
        <i
            class="search-button material-icons personal_design_icon"
            [matTooltip]="multiWord ? ('common.search_extended' | translate) : undefined"
            (click)="onClick()"
        >
            search
        </i>
    </div>
</div>
