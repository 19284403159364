<div class="h-100 flex-center-column">
    <mat-card class="text-center w-70">
        <h3 class="text-center">{{ 'cloud.no_access' | translate }}</h3>
        <div class="flex-center g-4">
            <button mat-raised-button color="primary" (click)="goBack()">{{ 'cloud.return'|translate }}</button>
            <button mat-raised-button color="primary" (click)="logout()">{{ 'cloud.exit'|translate }}</button>
        </div>
        <div>
            <img src="../../../../assets/img/support.png" alt="support" class="w-40"/>
        </div>
    </mat-card>
</div>
