import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DevicesApiService } from '@core/services/api/devices-api.service';
import { DialogService } from '@core/services/dialog.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export const DEVICE_ADMIN_POLL_KEYS = [
    'model',
    'serial_number',
    'datetime',
    'tariff1',
    'voltage1',
    'voltage2',
    'voltage3',
    'current1',
    'current2',
    'current3'
];

@Component({
    selector: 'app-device-poll-modal',
    templateUrl: './device-poll-modal.component.html',
    styleUrls: ['./device-poll-modal.component.less']
})
export class DevicePollModalComponent implements OnInit, OnDestroy {
    isSpinnerVisible = true;
    errorMsg: string | null = null;
    device = null;
    fieldTitles = [];
    pollResult = null;
    resultKeys = [];
    chart = null;

    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly data: any,
        private readonly devicesApiService: DevicesApiService,
        private readonly dialogService: DialogService,
        private readonly translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.device = this.data?.device || null;
        this.fieldTitles = this.data?.pollFieldTitles || [];
        if (this.device) {
            this.getPollData();
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    getPollData() {
        const body = {
            port: this.device?.broker_port,
            imei: this.device?.device_id
        };
        this.isSpinnerVisible = true;
        this.devicesApiService
            .getPollDataForAdmin(body)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                (res) => {
                    this.pollResult = res?.data?.answer || null;
                    this.resultKeys = DEVICE_ADMIN_POLL_KEYS.slice(0);
                    this.errorMsg = !!res?.data?.result ? null : res?.data?.error ?? this.translate.instant('admin.error');
                    this.isSpinnerVisible = false;
                },
                (error) => {
                    this.errorMsg = error?.errorMsg || this.translate.instant('admin.error');
                    this.isSpinnerVisible = false;
                }
            );
    }

    getFieldTitle(key) {
        return this.fieldTitles.find((w) => w?.id === key);
    }

    isNumber(key) {
        return typeof this.pollResult?.[key] === 'number';
    }

    isString(key) {
        return typeof this.pollResult?.[key] === 'string' && this.pollResult?.[key]?.trim() !== '';
    }

    closeModal() {
        this.dialogService.closeAll();
    }

    isAllPhasesReady() {
        return !!this.pollResult?.voltage1 && !!this.pollResult?.voltage2 && !!this.pollResult?.voltage3;
    }
}
