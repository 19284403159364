import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ReportBuilderActions = createActionGroup({
    source: 'Report Builder',
    events: {
        setAccountingPointType: props<{ accountingPointType: string[] }>(),
        setSelectedAccountingPoint: props<{ accountingPoints: any[] }>(),
        setSelectedFields: props<{ fields: any }>(),
        setReportDates: props<{ dateStart: any; dateEnd: any }>(),
        setDateStartReportDates: props<{ dateStart: any }>(),
        setDateEndReportDates: props<{ dateEnd: any }>(),
        setReportTitle: props<{ title: string }>(),
        setReportType: props<{ selectedReportType: string }>(),
        setReportTypeValue: props<{ reportTypeValue: string }>(),
        setMsgType: props<{ msgType: number }>(),
        saveCustomFields: props<{ customFields: any[] }>(),
        fetchAccountingPointFields: props<{ deviceGroupId: number }>(),
        fetchAccountingPointFieldsSuccess: props<{ fields: any }>(),
        fetchFieldsTitles: emptyProps(),
        fetchFieldsTitlesSuccess: props<{ fields: any }>(),
        fetchAvailableAttributes: emptyProps(),
        fetchAvailableAttributesSuccess: props<{ attributes: any }>(),
        loadReport: props<{ data: any }>(),
        clearReportStore: emptyProps(),
        fetchAvailableAccountingPoints: props<{ tied_device: boolean }>(),
        fetchAvailableAccountingPointsSuccess: props<{ availableAccountingPoints: any[] }>()
    }
});
