// Здесь собраны функции, не требующие интеграции в отдельный сервис

import { APP_DEMO_USERS } from '@core/constants/app.cloud.constants';
import { SortDirection } from '@angular/material/sort';
import { IdTitleWithEngSelector } from '@core/models/ui.models';

export function checkIsDemo(user: any) {
    return APP_DEMO_USERS.includes(user?.email);
}

export function sortByKey(a: any, b: any, key: string, sortDirection: SortDirection) {
    const isAsc = sortDirection === 'asc';
    if (a?.[key] === null || a?.[key] === undefined) {
        return isAsc ? 1 : -1;
    }
    if (b?.[key] === null || b?.[key] === undefined) {
        return !isAsc ? 1 : -1;
    }
    return (a?.[key] < b?.[key] ? -1 : 1) * (isAsc ? 1 : -1);
}

export function normalizeArrayById(data: any[]) {
    const result = {};
    data.forEach((item) => {
        result[item.id] = item;
    });
    return result;
}

export function getIdTitleEngItem(v: any): IdTitleWithEngSelector {
    return {
        id: v.id,
        title: v?.title ?? v.name,
        title_en: v?.title_en
    };
}

export function getAddressString(address: any, full = true) {
    const city = address?.city || null;
    if (full) {
        if (address?.street) {
            if (address?.house) {
                let fullAddress = `${address.street}, ${address.house}`;
                if (city) {
                    fullAddress = `${city}, ${fullAddress}`;
                }
                if (address?.innerObject) {
                    fullAddress = `${fullAddress}, ${address.innerObject}`;
                }
                return fullAddress.trim();
            } else {
                let fullAddress = `${address.street}`;
                if (city) {
                    fullAddress = `${city}, ${fullAddress}`;
                }
                if (address?.innerObject) {
                    fullAddress = `${fullAddress}, ${address.innerObject}`;
                }
                return fullAddress.trim();
            }
        } else {
            return '-';
        }
    } else {
        if (address?.street) {
            if (address?.house) {
                let fullAddress = `${address.street.length > 22 ? address.street.slice(0, 22) + '...' : address.street}, ${address.house}`;
                if (city) {
                    fullAddress = `${city}, ${fullAddress}`;
                }
                if (address?.innerObject) {
                    fullAddress = `${fullAddress}, ${address.innerObject}`;
                }

                return fullAddress.trim();
            } else {
                let fullAddress = `${address.street.length > 22 ? address.street.slice(0, 22) + '...' : address.street}`;
                if (city) {
                    fullAddress = `${city}, ${fullAddress}`;
                }
                if (address?.innerObject) {
                    fullAddress = `${fullAddress}, ${address.innerObject}`;
                }
                return fullAddress.trim();
            }
        } else {
            return '-';
        }
    }
}

export function checkIsStringArraysEqual(arr1: string[], arr2: string[]): boolean {
    const sortedArr1 = arr1.sort();
    const sortedArr2 = arr2.sort();
    return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
}
