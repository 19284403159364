import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html'
})
export class SnackbarComponent {
    html: SafeHtml;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        private sanitizer: DomSanitizer,
        ) {
        this.html = this.sanitizer.bypassSecurityTrustHtml(this.data.html);
    }
}
