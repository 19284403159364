import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ReportTemplatesActions = createActionGroup({
    source: 'Report Templates',
    events: {
        fetchBalanceTemplates: emptyProps(),
        fetchGroupTemplates: emptyProps(),
        fetchSingleTemplates: emptyProps(),
        fetchSystemTemplates: emptyProps(),
        loadBalanceTemplates: props<{ payload: any }>(),
        loadGroupTemplates: props<{ payload: any }>(),
        loadSingleTemplates: props<{ payload: any }>(),
        loadSystemTemplates: props<{ payload: any }>(),
        reset: emptyProps()
    }
});
