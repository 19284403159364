<h2 mat-dialog-title class="text-center">{{'get_event_log.query_event_logs' | translate}}</h2>
<div *ngIf="controls">
    <mat-form-field floatLabel="always" appearance="outline" class="w-70 mr-4">
        <mat-label>{{'get_event_log.type_of_magazine' | translate}}</mat-label>
        <mat-select [(ngModel)]="event_index" [disabled]="disableEdit">
            <mat-option *ngFor="let type of controls['event_log']['properties']; let i = index" [value]="i">
                {{ type }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button
        mat-raised-button
        color="primary"
        (click)="getEventLogs()"
        [disabled]="disableEdit"
    >
        {{'get_event_log.request' | translate}}
    </button>
</div>
