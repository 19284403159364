import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { ObjectsService } from '../../cloud/objects/objects.service';
import {
    fetchAccountingPointFields,
    fetchAccountingPointFieldsSuccess,
    fetchAvailableAccountingPoints,
    fetchAvailableAccountingPointsSuccess,
    fetchAvailableAttributes,
    fetchAvailableAttributesSuccess,
    fetchFieldsTitles,
    fetchFieldsTitlesSuccess
} from '../actions/report-generator.action';
import { DevicesService } from '../../cloud/devices/services/devices.service';

const addAdditionalFields = (fields) => {
    const resultFields = [];
    for (let field of fields) {
        if (field.includes('tariff') || field.includes('in1')) {
            resultFields.push(field);
            resultFields.push(`delta_${field}`);
            resultFields.push(`start_${field}`);
            resultFields.push(`end_${field}`);
        } else {
            resultFields.push(field);
        }
    }
    return resultFields;
};

@Injectable()
export class ReportGeneratorEffects {
    fetchAvailableAccountingPoints$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchAvailableAccountingPoints),
            map((action: any) => action),
            exhaustMap((params: any) =>
                this.objectsService.getAllAccountingPointList(null, params.tied_device).pipe(
                    map((points) => fetchAvailableAccountingPointsSuccess({ availableAccountingPoints: points })),
                    catchError((e) => {
                        throw e;
                    })
                )
            )
        )
    );

    fetchAccountingPointsFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchAccountingPointFields),
            map((action: any) => action.deviceGroupId),
            exhaustMap((deviceGroupId: string) =>
                this.objectsService.getTabsByDeviceGroupId(deviceGroupId).pipe(
                    map((fields) => {
                        const preparedFields = addAdditionalFields(fields.tariffs?.fields || []);
                        return fetchAccountingPointFieldsSuccess({ fields: preparedFields });
                    }),
                    catchError((e) => {
                        throw e;
                    })
                )
            )
        )
    );

    fetchFieldsTitles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchFieldsTitles),
            map((action: any) => action),
            exhaustMap(() =>
                this.devicesService.getDeviceFieldTitles().pipe(
                    map((fields) => fetchFieldsTitlesSuccess({ fields })),
                    catchError((e) => {
                        throw e;
                    })
                )
            )
        )
    );

    fetchAvailableAttributes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchAvailableAttributes),
            map((action: any) => action),
            exhaustMap(() =>
                this.devicesService.getAllAvailableAttributes().pipe(
                    map((attributes) => fetchAvailableAttributesSuccess({ attributes })),
                    catchError((e) => {
                        throw e;
                    })
                )
            )
        )
    );

    constructor(private actions$: Actions, private objectsService: ObjectsService, private devicesService: DevicesService) {}
}
