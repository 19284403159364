import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import {DevicesService} from '../../../../../../services/devices.service';
import {ErrorsService} from '@core/services/errors.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {takeUntil} from 'rxjs/operators';
import {TranslateLanguageService} from '../../../../../../../../core/services/translate-language.service';
import {Subject} from 'rxjs';
import {MY_FORMATS} from '@core/constants/consts';

@Component({
    selector: 'app-get-power-profile-modal',
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    ],
    template: `<h2 mat-dialog-title>{{'other.msg_1' | translate}}</h2>
        <div>
            <mat-form-field floatLabel="always" appearance="outline" style="margin-left: 15px; margin-right: 15px;">
                <mat-label>{{'other.msg_3' | translate}}</mat-label>
                <input
                    matInput
                    class="data-date"
                    (click)="archiveDate.open()"
                    (focus)="archiveDate.open()"
                    [matDatepicker]="archiveDate"
                    [(ngModel)]="archivePowerProfileSendObject.management['archive_profile']"
                    [disabled]="disableEdit"
                />
                <mat-datepicker-toggle matSuffix [for]="archiveDate"></mat-datepicker-toggle>
                <mat-datepicker #archiveDate></mat-datepicker>
            </mat-form-field>

            <button
                style="
            margin-left: 15px;
            height: 50px;
            margin-bottom: 15px;
            vertical-align: middle;
          "
                mat-raised-button
                color="primary"
                (click)="getPowerProfile()"
                [disabled]="disableEdit"
            >
                {{'other.msg_2' | translate}}
            </button>
        </div>`
})
export class GetPowerProfileModalComponent implements OnInit, OnDestroy {
    public disableEdit = false;
    public archivePowerProfileSendObject = {
        id: 0,
        management: {
            archive_profile: null
        }
    };
    private destroyed$: Subject<void> = new Subject<void>();

    constructor(
        private _devicesService: DevicesService,
        private errors: ErrorsService,
        public dialogRef: MatDialogRef<GetPowerProfileModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dateAdapter: DateAdapter<Date>,
        private translateLanguageService: TranslateLanguageService
    ) {}

    ngOnInit(): void {
        this.translateLanguageService.currentLanguage$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((lang) => {
                this.dateAdapter.setLocale(lang);
            });
        this.archivePowerProfileSendObject.id = this.data.currentDevice.id;
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    getPowerProfile() {
        this.disableEdit = true;
        const body = _.cloneDeep(this.archivePowerProfileSendObject);
        if (this.archivePowerProfileSendObject.management.hasOwnProperty('archive_profile')) {
            body.management['archive_profile'] =
                moment(this.archivePowerProfileSendObject.management['archive_profile']).format('YYYY-MM-DD') +
                ' 00:00:00';
        }
        this._devicesService.setControl(body, `Ручной сбор профиля мощности устройства`).then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
                this.dialogRef.close();
            },
            (error) => {
                this.disableEdit = false;
                this.dialogRef.close();
                throw error;
            }
        );
    }
}
