import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as echarts from 'echarts';

@Component({
    selector: 'app-vector-diagram',
    templateUrl: './vector-diagram.component.html',
    styleUrls: ['./vector-diagram.component.less']
})
export class VectorDiagramComponent implements OnInit, OnDestroy {
    @Input() device = null;
    @Input() message = null;

    chart = null;

    ngOnInit(): void {
        setTimeout(() => {
            this.setSelectedMessage(this.message);
        }, 100);
    }

    ngOnDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    setSelectedMessage(message: any) {
        if (!message) {
            return;
        }
        const dom = document.getElementById('deviceVectorChart');
        const series = [];
        const maxVoltage = Math.max(message?.voltage1, message?.voltage2, message?.voltage3);
        const maxCurrent = Math.max(message?.current1, message?.current2, message?.current3);
        series.push({
            coordinateSystem: 'polar',
            name: 'Ua',
            type: 'line',
            itemStyle: {
                color: '#FFD54F'
            },
            lineStyle: {
                width: 5
            },
            markPoint: {
                data: [
                    {
                        type: 'max'
                    }
                ],
                symbol: 'arrow',
                symbolSize: 20,
                symbolRotate: 0,
                symbolOffset: [0, 0],
                label: { show: true, color: '#263238', formatter: 'Ua' }
            },
            data: [
                [0, 0],
                [(message?.voltage1 / maxVoltage).toFixed(2), 0]
            ]
        });
        if (this.device.device_type_id === 3 || this.device.device_type_id === 29) {
            if (message?.cos1 && message?.current1) {
                const angle1 = (Math.acos(message?.cos1) * (180 / Math.PI)).toFixed(2);
                series.push({
                    coordinateSystem: 'polar',
                    name: 'Ia',
                    type: 'line',
                    itemStyle: {
                        color: '#FFD54F'
                    },
                    markPoint: {
                        data: [
                            {
                                type: 'max'
                            }
                        ],
                        symbol: 'arrow',
                        symbolSize: 20,
                        symbolRotate: parseFloat(angle1),
                        symbolOffset: [0, 0],
                        label: { show: true, color: '#263238', formatter: 'Ia' }
                    },
                    data: [
                        [0, 0],
                        [(message?.current1 / maxCurrent).toFixed(2), angle1]
                    ]
                });
            }
            if (message?.coruu1 && message?.voltage1) {
                series.push({
                    coordinateSystem: 'polar',
                    name: 'Ub',
                    type: 'line',
                    itemStyle: {
                        color: '#81C784'
                    },
                    lineStyle: {
                        width: 5
                    },
                    markPoint: {
                        data: [
                            {
                                type: 'max'
                            }
                        ],
                        symbol: 'arrow',
                        symbolSize: 20,
                        symbolRotate: message?.coruu1,
                        symbolOffset: [0, 0],
                        label: { show: true, color: '#263238', formatter: 'Ub' }
                    },
                    data: [
                        [0, 0],
                        [(message?.voltage1 / maxVoltage).toFixed(2), message?.coruu1]
                    ]
                });
                const angle2 = (Math.acos(message?.cos2) * (180 / Math.PI)).toFixed(2);
                series.push({
                    coordinateSystem: 'polar',
                    name: 'Ib',
                    type: 'line',
                    itemStyle: {
                        color: '#81C784'
                    },
                    markPoint: {
                        data: [
                            {
                                type: 'max'
                            }
                        ],
                        symbol: 'arrow',
                        symbolSize: 20,
                        symbolRotate: parseFloat(angle2) + parseFloat(message?.coruu1),
                        symbolOffset: [0, 0],
                        label: { show: true, color: '#263238', formatter: 'Ib' }
                    },
                    data: [
                        [0, 0],
                        [(message?.current2 / maxCurrent).toFixed(2), parseFloat(angle2) + parseFloat(message?.coruu1)]
                    ]
                });
            }
            if (message?.coruu2 && message?.voltage2) {
                series.push({
                    coordinateSystem: 'polar',
                    name: 'Uc',
                    type: 'line',
                    itemStyle: {
                        color: '#e57373'
                    },
                    lineStyle: {
                        width: 5
                    },
                    markPoint: {
                        data: [
                            {
                                type: 'max'
                            }
                        ],
                        symbol: 'arrow',
                        symbolSize: 20,
                        symbolRotate: message?.coruu2,
                        symbolOffset: [0, 0],
                        label: { show: true, color: '#263238', formatter: 'Uc' }
                    },
                    data: [
                        [0, 0],
                        [(message?.voltage2 / maxVoltage).toFixed(2), message?.coruu2]
                    ]
                });
                const angle3 = (Math.acos(message?.cos3) * (180 / Math.PI)).toFixed(2);
                series.push({
                    coordinateSystem: 'polar',
                    name: 'Ic',
                    itemStyle: {
                        color: '#e57373'
                    },
                    type: 'line',
                    markPoint: {
                        data: [
                            {
                                type: 'max'
                            }
                        ],
                        symbol: 'arrow',
                        symbolSize: 20,
                        symbolRotate: parseFloat(angle3) + parseFloat(message?.coruu2),
                        symbolOffset: [0, 0],
                        label: { show: true, color: '#263238', formatter: 'Ic' }
                    },
                    data: [
                        [0, 0],
                        [(message?.current3 / maxCurrent).toFixed(2), parseFloat(angle3) + parseFloat(message?.coruu2)]
                    ]
                });
            }
        } else {
            if (message?.cos1 && message?.current1) {
                const angle1 = (Math.acos(message?.cos1) * (180 / Math.PI)).toFixed(2);
                series.push({
                    coordinateSystem: 'polar',
                    name: 'Ia',
                    itemStyle: {
                        color: '#FFD54F'
                    },
                    type: 'line',
                    markPoint: {
                        data: [
                            {
                                type: 'max'
                            }
                        ],
                        symbol: 'arrow',
                        symbolSize: 20,
                        symbolRotate: parseFloat(angle1),
                        symbolOffset: [0, 0],
                        label: { show: true, color: '#263238', formatter: 'Ia' }
                    },
                    data: [
                        [0, 0],
                        [(message?.current1 / maxCurrent).toFixed(2), angle1]
                    ]
                });
            }
        }

        if (dom === null) {
            return;
        }
        const option = {
            legend: {
                show: false,
                data: ['Ua, Ub, Uc, Ia, Ib, Ic'],
                orient: 'vertical'
            },
            polar: {},
            tooltip: {
                show: false,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            angleAxis: {
                type: 'value',
                startAngle: 90,
                max: 360,
                clockwise: false
            },
            radiusAxis: {},
            series: series
        };
        this.chart = echarts.init(dom, {}, { renderer: 'canvas', height: 300, width: 300 });
        this.chart.setOption(option, true);
    }
}
