import { Injectable } from '@angular/core';
import { APP_DEMO_USERS } from '@core/constants/app.cloud.constants';

// TODO: add strict types

export type AccessType = 'create' | 'read' | 'update' | 'delete' | 'access_all';

/**
 * @description сервис проверки уровня доступа пользователя в системе
 */

@Injectable({
    providedIn: 'root'
})
export class CheckAccessService {
    private _access: any = null;

    get access() {
        return this._access;
    }

    set access(value: any) {
        this._access = value;
    }

    private _user: any = null;

    get user() {
        return this._user;
    }

    set user(value) {
        this._user = value;
    }

    checkIsModuleAllowed(slugFromGuard: string): boolean {
        if (this.access?.['is_admin'] === true) {
            return true;
        }
        if (this.access?.['access_group']?.['access_rules']?.['read']?.length > 0) {
            const currentModule = this.access?.['active_modules']?.find((el) => el.slug === slugFromGuard);
            const result = this.access?.['access_group']?.['access_rules']?.['read']?.includes(currentModule?.id);
            return !!result;
        }
        return false;
    }

    isAdmin(): boolean {
        return this.access?.['is_admin'] === true;
    }

    isDemoUser(): boolean {
        return APP_DEMO_USERS.includes(this.user?.email);
    }

    isNektaUser(): boolean {
        return this.access?.['current_company_id'] === 51;
    }

    canCreate(module_id): boolean {
        if (this.isAdmin()) {
            return true;
        }
        return this.getAccessStatus('create', module_id);
    }

    canRead(module_id): boolean {
        if (this.isAdmin()) {
            return true;
        }
        return this.getAccessStatus('read', module_id);
    }

    canUpdate(module_id): boolean {
        if (this.isAdmin()) {
            return true;
        }
        return this.getAccessStatus('update', module_id);
    }

    canDelete(module_id): boolean {
        if (this.isAdmin()) {
            return true;
        }
        return this.getAccessStatus('delete', module_id);
    }

    /**
     * @description если true то помимо доступа ко всем действиям дает также доступ ко всем объектам/устройствам/отчетам (зависит от модуля)
     */
    canAll(module_id): boolean {
        if (this.isAdmin()) {
            return true;
        }
        return this.getAccessStatus('access_all', module_id);
    }

    private getAccessStatus(rule: AccessType, module_id: any): boolean {
        return !!this.access?.access_group?.access_rules?.[rule]?.includes(module_id);
    }
}
