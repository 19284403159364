import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject, Injectable } from '@angular/core';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { ReportsApiService } from '@core/services/api/reports-api.service';
import { ReportTemplatesActions } from '@store/reportTemplates/reportTemplates.actions';

@Injectable()
export class ReportTemplatesEffects {
    private readonly actions$ = inject(Actions);
    private readonly reportsApiService = inject(ReportsApiService);

    fetchBalanceTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportTemplatesActions.fetchbalancetemplates),
            exhaustMap(() =>
                this.reportsApiService.getAvailableTemplates({ report_type: 'group', report_mode: 'balance' }).pipe(
                    map((res: any) => ReportTemplatesActions.loadbalancetemplates({ payload: res?.data?.report_templates || [] })),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    fetchGroupTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportTemplatesActions.fetchgrouptemplates),
            exhaustMap(() =>
                this.reportsApiService.getAvailableTemplates({ report_type: 'group' }).pipe(
                    map((res: any) => ReportTemplatesActions.loadgrouptemplates({ payload: res?.data?.report_templates || [] })),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    fetchSingleTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportTemplatesActions.fetchsingletemplates),
            exhaustMap(() =>
                this.reportsApiService.getAvailableTemplates({ report_type: 'single' }).pipe(
                    map((res: any) => ReportTemplatesActions.loadsingletemplates({ payload: res?.data?.report_templates || [] })),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    fetchSystemTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportTemplatesActions.fetchsystemtemplates),
            exhaustMap(() =>
                this.reportsApiService.getSystemTemplates().pipe(
                    map((res: any) => ReportTemplatesActions.loadsystemtemplates({ payload: res?.data || [] })),
                    catchError(() => EMPTY)
                )
            )
        )
    );
}
