import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CountryListConstants } from './constants/country-list.constants';
import { CountryInterface, PhoneNumber } from './country.interface';
import { ReplaySubject } from 'rxjs';
import Inputmask from 'inputmask';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-country-picker',
    templateUrl: './country-picker.component.html',
    styleUrls: ['./country-picker.component.less', './styles/flags/flags.css']
})
export class CountryPickerComponent implements OnInit, OnDestroy {
    @Input() fieldRequired = false;
    @Input() edit = false;
    @Input() title = null;
    @Output() numberChanged: EventEmitter<PhoneNumber> = new EventEmitter<PhoneNumber>();
    countryList: CountryInterface[] = [];
    dropdownVisible = false;
    selectedCountry = CountryListConstants.defaultCountryRu;
    phoneNumber = '+7';
    private dialCode: string;
    private lang: string;

    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(private eRef: ElementRef, private translateService: TranslateService) {}

    @Input('phoneNumber') set setCountryData(phone) {
        this.countryList = CountryListConstants.countries;
        if (phone) {
            this.phoneNumber = phone || null;
            this.onSearchCountry(null, this.phoneNumber);
        }
    }
    @Output() countryChanged = new EventEmitter<any>();

    @HostListener('document:click', ['$event'])
    clickOut(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.dropdownVisible = false;
        }
    }

    ngOnInit() {
        this.lang = this.translateService.currentLang;
        if (this.lang === 'en' && !this.edit) {
            this.selectedCountry = CountryListConstants.defaultCountryUS;
            this.phoneNumber = this.selectedCountry.dialCode;
        }
        this.countryList = CountryListConstants.countries;
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    getCountryName(country: any) {
        return this.lang === 'en' ? country?.name_en : country?.name;
    }

    onSearchCountry(e = null, recipient = null) {
        if (e || recipient) {
            const dialCode = e ? e.target.value.slice(0, 4) : recipient.slice(0, 4);
            let country = this.countryList.find((el) => el.dialCode === dialCode);
            if (!country && dialCode.length) {
                for (let i = 0; i < dialCode.length; i++) {
                    country = this.countryList.find((el) => el.dialCode === dialCode.slice(0, dialCode.length - i));
                    if (country) {
                        break;
                    }
                }
            }

            if (country) {
                this.dialCode = country.dialCode;
                this.selectedCountry = country;
            }
            this.numberChanged.emit({
                phoneNumber: e ? e.target.value : recipient ? recipient : null,
                dialCode: this.dialCode || null
            });
        } else {
            this.selectedCountry = CountryListConstants[this.lang === 'en' ? 'defaultCountryUS' : 'defaultCountryRu'];
        }
    }

    onSelectCountry(country: CountryInterface) {
        this.countryChanged.emit();
        this.selectedCountry = country;
        this.phoneNumber = country.dialCode;
        this.dropdownVisible = false;
    }

    setMask(selector, regex) {
        const input = document.getElementById(selector);
        Inputmask({ regex: regex, placeholder: '' }).mask(input);
    }

    toggleVisible() {
        this.dropdownVisible = !this.dropdownVisible;
    }
}
