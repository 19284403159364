import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { AuthRoutes } from '@auth/auth.routing';
import { LoginComponent } from '@auth/login/login.component';
import { RegistrationComponent } from '@auth/registration/registration.component';
import { ForgetComponent } from '@auth/forget/forget.component';
import { PopupComponent } from '@shared/popup/popup.component';
import { ForgetActivateComponent } from '@auth/forget/activate.component';
import { LogoutComponent } from '@auth/logout/logout.component';
import { WithoutAccessComponent } from '@auth/without-access/without-access.component';
import { ActivateUserComponent } from '@auth/activate/activate.component';

@NgModule({
    imports: [CommonModule, RouterModule.forChild(AuthRoutes), SharedModule],
    exports: [LoginComponent],
    declarations: [
        LoginComponent,
        RegistrationComponent,
        ForgetComponent,
        PopupComponent,
        ForgetActivateComponent,
        ActivateUserComponent,
        LogoutComponent,
        WithoutAccessComponent
    ]
})
export class AuthModule {}
