import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { ConsumersService, ConsumersStateService } from '../../services/consumers.service';
import { ConsumersActions } from '../actions/consumers.actions';
import { of } from 'rxjs';

@Injectable()
export class ConsumersEffects {
    fetchConsumers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConsumersActions.FetchConsumers),
            map((action: any) => action.payload),
            exhaustMap((params: any) =>
                this.consumersService.getConsumers(params.page, params.perPage, params.search_string).pipe(
                    map((consumers) => ({
                        type: ConsumersActions.FetchConsumersSuccess,
                        payload: consumers
                    })),
                    catchError((e) => {
                        throw e;
                    })
                )
            )
        )
    );

    addConsumers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConsumersActions.AddConsumer),
            map((action: any) => action.payload),
            exhaustMap((data: any) =>
                this.consumersService
                    .addConsumer({
                        name: data.name,
                        inn: data.inn,
                        number_of_contract: data.number_of_contract,
                        subject_type_id: data.subject_type_id,
                        address: data.address,
                        comment: data.comment,
                        registration_date:
                            data.subject_type_id == 2 && data.registration_date ? data.registration_date : undefined,
                        registration_number: data.subject_type_id == 2 ? data.registration_number : undefined,
                        phone_number: data.subject_type_id == 1 ? data.phone_number : undefined,
                        email: data.subject_type_id == 1 ? data.email : undefined,
                        account_access_enabled: data.account_access_enabled || false,
                        measurement_code_object: data?.measurement_code_object || null,
                    })
                    .pipe(
                        map((msg) => this.consumersStateService.addConsumerSuccess(data.page, data.perPage, msg)),
                        catchError((error) => of(this.consumersStateService.addConsumerFail(error)))
                    )
            )
        )
    );

    editConsumers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConsumersActions.EditConsumer),
            map((action: any) => action.payload),
            exhaustMap((data: any) =>
                this.consumersService
                    .editConsumer({
                        _id: data._id,
                        name: data.name,
                        inn: data.inn,
                        comment: data.comment,
                        address: data.address,
                        number_of_contract: data.number_of_contract,
                        subject_type_id: data.subject_type_id,
                        registration_date:
                            data.subject_type_id == 2 && data.registration_date ? data.registration_date : undefined,
                        registration_number: data.subject_type_id == 2 ? data.registration_number : undefined,
                        phone_number: data.subject_type_id == 1 ? data.phone_number : undefined,
                        email: data.subject_type_id == 1 ? data.email : undefined,
                        account_access_enabled: data.account_access_enabled || false,
                        measurement_code_object: data?.measurement_code_object || null,
                    })
                    .pipe(
                        map((msg) => this.consumersStateService.editConsumerSuccess(data.page, data.perPage, msg)),
                        catchError((error) => of(this.consumersStateService.editConsumerFail(error)))
                    )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private consumersService: ConsumersService,
        private consumersStateService: ConsumersStateService
    ) {}
}
