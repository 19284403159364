import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PassportService} from '../../../auth/passport.service';
import {CheckAccess} from '../../cloud.service';
import {ErrorsService} from '@core/services/errors.service';
import {environment} from '../../../../environments/environment';
import {Store} from '@ngrx/store';
import * as pollsGroupState from '../store/reducers/polls-group.reducer';
import {MatDialog} from '@angular/material/dialog';
import * as pollsGroupActions from '../store/actions/polls-group.actions';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

// TODO: move to new api services

@Injectable({
    providedIn: 'root'
})
export class PollsGroupService {
    _baseURL: string;
    public headers = new HttpHeaders();

    constructor(
        private httpClient: HttpClient,
        private auth: PassportService,
        private router: Router,
        public _checkAccess: CheckAccess
    ) {
        this._baseURL = environment.baseURL;
    }

    getGroups(pageIndex, pageSize) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = {
            page: pageIndex,
            per_page: pageSize,
            paginate: true
        };
        return this.httpClient
            .post(this._baseURL + 'device/opros/group/list', body, {
                headers: this.headers
            })
            .pipe(
                map((result: any) => {
                    return result.data.poll_groups;
                })
            );
    }

    getPollFields(params) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'device/model/poll/fields', params, {
                headers: this.headers
            })
            .pipe(
                map((result: any) => {
                    return result.data.model_poll_fields;
                })
            );
    }
    getBalanceGroup(id, date?: any) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + `balance/group/${id}/get_no_balance`, date, {
                headers: this.headers
            });
    }

    downloadExelNotBalance(id, date) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + `report/balance/group/${id}/create`, date, {
                headers: this.headers,
                responseType: 'blob'
            });
    }
    XMLImportingReadings(formData: any, fileVersion) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + `device/messages/import/` + fileVersion,  formData, {
                headers: this.headers
            });
    }

    getOnePoll(pollId) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .get(this._baseURL + `device/opros/group/${pollId}`, {
                headers: this.headers
            })
            .pipe(
                map((result: any) => {
                    return result.data.poll_group;
                })
            );
    }

    addPollGroup(data) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'device/opros/group/create', data, {
                headers: this.headers
            })
            .pipe(
                map((result: any) => {
                    return result.msg;
                })
            );
    }

    editPollGroup(data) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .put(this._baseURL + 'device/opros/group/update', data, {
                headers: this.headers
            })
            .pipe(
                map((result: any) => {
                    this.router.navigate(['cloud/devices/groups']).then();
                    return result.msg;
                })
            );
    }

    deletePoll(pollId) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + `device/opros/group/delete/${pollId}`, {}, {
                headers: this.headers
            })
            .pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    public onSwitchActive(id, isActive) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.put(`${this._baseURL}device/opros/group/switch`,
            {id, is_active: isActive},
            {headers: this.headers}
        );
    }

    public onPollCreate(requestData) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const requestBody = {
            command_type: 'poll',
            poll_group_id: requestData.id,
            title: `Ручной опрос группы`
        };
        return this.httpClient.post<any>(`${this._baseURL}task/device/create`,
            {...requestBody},
            {headers: this.headers}
        );
    }
}

@Injectable({
    providedIn: 'root'
})
export class PollsGroupStateService {
    pollsGroupPagination$ = new BehaviorSubject({perPage: 10, page: 1});
    constructor(
        private error: ErrorsService,
        private router: Router,
        private store: Store<pollsGroupState.PollsGroupState>,
        private dialog: MatDialog
    ) {
    }

    public addPollGroupSuccess(page, perPage, msg) {
        this.dialog.closeAll();
        this.error.showSnackbar(msg);
        return new pollsGroupActions.fetchPollGroups(this.pollsGroupPagination$.getValue());
    }

    public addPollGroupFail(error: any) {
        this.error.showSnackbar(error.error?.error?.data?.msg ? error.error.error.data.msg : error.error?.error?.msg);
        return new pollsGroupActions.addPollGroupsFail(error.error.error);
    }

    public editPollGroupSuccess(page, perPage, msg) {
        this.dialog.closeAll();
        this.error.showSnackbar(msg);
        return new pollsGroupActions.fetchPollGroups(this.pollsGroupPagination$.getValue());
    }

    public editPollGroupFail(error: any) {
        this.error.showSnackbar(error.error?.error?.data?.msg ? error.error.error.data.msg : error.error?.error?.msg);
        return new pollsGroupActions.addPollGroupsFail(error.error.error);
    }
}
