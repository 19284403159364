<mat-card class="page-header">
    <div class="data__header">
        <strong *ngIf="currentDevice !== undefined && currentDevice !== null">
            {{ 'devices.data.deviceData' | translate }}
            {{ currentDevice?.name ? '(' + currentDevice?.name + ')' : '' }}
        </strong>
        <strong *ngIf="currentAccountingPoint !== undefined && currentAccountingPoint !== null">
            {{ 'devices.data.accounting_point_data' | translate }}
            {{ currentAccountingPoint?.name ? '(' + currentAccountingPoint?.name + ')' : '' }}
        </strong>
    </div>
    <div class="buttons">
        <button class="data__button"
                color="primary"
                mat-button
                mat-flat-button
                (click)="onNavigateAccountingPoint()">
            {{ 'devices.data.accounting_point' | translate }}
        </button>
        <button class="data__button"
                color="primary"
                *ngIf="deviceId"
                mat-button
                mat-flat-button
                (click)="onNavigateTaskPoll()">
            {{ 'devices.data.task_poll' | translate }}
        </button>
        <app-get-current-values *ngIf="currentTab === 'instant_readings' &&
                                 controls !== null &&
                                 controls['now_value'] && controls['now_value'].active &&
                                 _checkAccess.listAccess('read', 4)"
                                [currentDevice]="currentDevice"
                                [currentAccountingPoint]="currentAccountingPoint"
        ></app-get-current-values>
        <app-get-power-profile *ngIf="currentTab === 'power_profiles' &&
                                controls !== null &&
                                controls['archive_profile'] && controls['archive_profile'].active &&
                                _checkAccess.listAccess('read', 4)"
                               [currentDevice]="currentDevice"
                               [currentAccountingPoint]="currentAccountingPoint">
        </app-get-power-profile>
        <button *ngIf="currentTab === 'archives' && controls !== null &&
                controls.archive  && controls.archive.active"
                mat-raised-button
                class="data__button"
                color="primary"
                (click)="getArchives()">
            {{ 'devices.data.buttons.request_archives' | translate }}
        </button>
        <button
            *ngIf="currentTab === 'journal_device' && controls !== null &&
             controls['event_log'] && controls['event_log'].active"
            mat-raised-button
            color="primary"
            class="data__button"
            (click)="getEventLogs()">
            {{ 'devices.data.buttons.request_journals' | translate }}
        </button>
        <button *ngIf="currentTab === 'tariffs' &&
             currentDevice?.device_group_id === 1 || currentDevice?.device_group_id === 2 ||
             currentDevice?.device_group_id === 3 || currentDevice?.device_group_id === 4"
                mat-raised-button
                class="data__button"
                (click)="onDisplayIndications()"
                color="primary">
            {{ 'output_indications.title' | translate }}
        </button>
        <button *ngIf="currentTab === 'tariffs' &&
             controls !== null &&
             controls['tariff']  && controls['tariff'].active"
                mat-raised-button
                class="data__button"
                color="primary"
                (click)="getTariff()">
            {{ 'devices.data.buttons.request_current_indications' | translate }}
        </button>
        <button *ngIf="currentTab === 'info'"
                mat-raised-button
                class="data__button"
                color="primary"
                (click)="onReferenceInformation()">
             {{ 'devices.data.buttons.reference_information' | translate }}
        </button>
        <button
            *ngIf="controls !== null && _checkAccess.listAccess('update', 4)"
            mat-raised-button
            color="primary"
            (click)="goToControls()">
            {{ 'devices.data.control' | translate }}
        </button>
    </div>
    <button mat-raised-button
            class="buttons-btn-menu"
            color="primary"
            [matMenuTriggerFor]="menuSecond">
        {{ 'reports.infrastructure.msg_33' | translate }}
    </button>
    <mat-menu class="buttons-mobile" #menuSecond="matMenu">
        <button mat-menu-item
                (click)="onNavigateAccountingPoint()">
            {{ 'devices.data.accounting_point' | translate }}
        </button>
        <button *ngIf="deviceId"
                mat-menu-item
                (click)="onNavigateTaskPoll()">
            {{ 'devices.data.task_poll' | translate }}
        </button>
        <app-get-current-values *ngIf="currentTab === 'instant_readings' &&
                                 controls !== null &&
                                 controls['now_value'] && controls['now_value'].active &&
                                 _checkAccess.listAccess('update', 4)"
                                [currentDevice]="currentDevice"
                                [currentAccountingPoint]="currentAccountingPoint"
        ></app-get-current-values>
        <app-get-power-profile *ngIf="currentTab === 'power_profiles' &&
                                controls !== null &&
                                controls['archive_profile'] && controls['archive_profile'].active &&
                                _checkAccess.listAccess('update', 4)"
                               [currentDevice]="currentDevice"
                               [currentAccountingPoint]="currentAccountingPoint">
        </app-get-power-profile>
        <button *ngIf="currentTab === 'archives' && controls !== null &&
                controls.archive  && controls.archive.active"
                mat-menu-item
                (click)="getArchives()">
            {{ 'devices.data.buttons.request_archives' | translate }}
        </button>
        <button *ngIf="currentTab === 'journal_device' && controls !== null &&
             controls['event_log'] && controls['event_log'].active"
                mat-menu-item
                (click)="getEventLogs()">
            {{ 'devices.data.buttons.request_journals' | translate }}
        </button>
        <button *ngIf="currentTab === 'tariffs' &&
             currentDevice?.device_group_id === 1 || currentDevice?.device_group_id === 2 ||
             currentDevice?.device_group_id === 3 || currentDevice?.device_group_id === 4"
                mat-menu-item
                (click)="onDisplayIndications()">
            {{ 'output_indications.title' | translate }}
        </button>
        <button *ngIf="currentTab === 'tariffs' &&
             controls !== null &&
             controls['tariff']  && controls['tariff'].active"
                mat-menu-item
                (click)="getTariff()">
            {{ 'devices.data.buttons.request_current_indications' | translate }}
        </button>
        <button *ngIf="currentTab === 'info'"
                mat-menu-item
                (click)="onReferenceInformation()">
            {{ 'devices.data.buttons.reference_information' | translate }}
        </button>
        <button
            *ngIf="controls !== null && _checkAccess.listAccess('update', 4)"
            mat-menu-item
            (click)="goToControls()">
            {{ 'devices.data.control' | translate }}
        </button>
    </mat-menu>
</mat-card>
<div>
    <app-select-device-data-date [tab]="currentTab"
                                 (msgTypeChange)="msgTypeChange($event)"
                                 (msgGroupChange)="msgGroupChange($event)"
                                 (dateChange)="dateChange($event)"
                                 (typeJournalChange)="typeJournalChange($event)"
                                 [dates]="selectedDate"
                                 [msgTypes]="msgTypes"
                                 [msgType]="msgType"
                                 [changeCurrentTabEvent]="changeCurrentTabEvent"
                                 [currentDevice]="currentDevice"
                                 (powerProfileTypeChange)="powerProfileTypeChange($event)">
    </app-select-device-data-date>
    <mat-tab-group animationDuration="0ms"
                   class="data-devices--tabs"
                   (selectedTabChange)="changeTab($event)">
        <mat-tab [disabled]="disablePagination"
                 class="content"
                 [attr.data-id]="'tariffs'"
                 *ngIf="
                (currentDevice?.hasOwnProperty('model') &&
                    (currentDevice?.tabs?.hasOwnProperty('tariffs') || currentDevice['tabs'] === null)) ||
                (currentAccountingPointTabs !== null && currentAccountingPointTabs?.hasOwnProperty('tariffs'))"
                 label="{{ 'devices.data.indications' | translate }}"
                 id="tariffs">
            <ng-template matTabContent>
                <metering-devices-data-show *ngIf="currentDevice?.hasOwnProperty('model') ||
                                            currentAccountingPointTabs !== null"
                                            [device]="currentDevice"
                                            [accountingPoint]="{
                                            id: accountingPointId,
                                            tabs: currentAccountingPointTabs,
                                            currentPoint: currentAccountingPoint}"
                                            [dateForm]="dateForm"
                                            [msgGroup]="msgGroup"
                                            [tab]="'tariffs'">
                </metering-devices-data-show>
            </ng-template>
        </mat-tab>
        <mat-tab [disabled]="disablePagination"
                 class="content"
                 [attr.data-id]="'instant_readings'"
                 *ngIf="
                (currentDevice?.hasOwnProperty('model') && currentDevice?.tabs?.hasOwnProperty('instant_readings')) ||
                (currentAccountingPointTabs !== null && currentAccountingPointTabs?.hasOwnProperty('instant_readings'))"
                 label="{{ 'devices.data.instantIndications' | translate }}">
            <ng-template matTabContent>
                <metering-devices-data-show *ngIf="currentDevice?.hasOwnProperty('model')
                                            || currentAccountingPointTabs !== null"
                                            [device]="currentDevice"
                                            [accountingPoint]="{
                                            id: accountingPointId,
                                            tabs: currentAccountingPointTabs,
                                            currentPoint: currentAccountingPoint}"
                                            [dateForm]="dateForm"
                                            [msgGroup]="msgGroup"
                                            [msgType]="msgType"
                                            [tab]="'instant_readings'">
                </metering-devices-data-show>
            </ng-template>
        </mat-tab>
        <mat-tab [disabled]="disablePagination"
                 class="content"
                 [attr.data-id]="'power_profiles'"
                 *ngIf="
                (currentDevice?.hasOwnProperty('model') && currentDevice?.tabs?.hasOwnProperty('power_profiles')) ||
                (currentAccountingPointTabs !== null && currentAccountingPointTabs?.hasOwnProperty('power_profiles'))"
                 label="{{ 'devices.data.powerProfile' | translate }}">
            <ng-template matTabContent>
                <metering-devices-data-power-profile *ngIf="currentDevice?.model || currentAccountingPointTabs !== null"
                                                     [device]="currentDevice"
                                                     [dateForm]="dateForm"
                                                     [msgGroup]="msgGroup"
                                                     [accountingPoint]="{
                                                     id: accountingPointId,
                                                     tabs: currentAccountingPointTabs,
                                                     currentPoint: currentAccountingPoint}"
                                                     [profileType]="profileType"
                                                     [msgType]="msgType">
                </metering-devices-data-power-profile>
            </ng-template>
        </mat-tab>
        <mat-tab [disabled]="disablePagination"
                 class="content"
                 [attr.data-id]="'energy_profiles'"
                 *ngIf="
                (currentDevice?.hasOwnProperty('model') && currentDevice?.tabs?.hasOwnProperty('energy_profiles')) ||
                (currentAccountingPointTabs !== null && currentAccountingPointTabs?.hasOwnProperty('energy_profiles'))"
                 label="{{ 'devices.data.energyProfiles' | translate }}">
            <ng-template matTabContent>
                <metering-devices-data-power-profile *ngIf="currentDevice?.hasOwnProperty('model')
                                                     || currentAccountingPointTabs !== null"
                                                     [device]="currentDevice"
                                                     [dateForm]="dateForm"
                                                     [isEnergy]="true"
                                                     [msgGroup]="msgGroup"
                                                     [accountingPoint]="{
                                                     id: accountingPointId,
                                                     tabs: currentAccountingPointTabs,
                                                     currentPoint: currentAccountingPoint}"
                                                     [profileType]="profileType"
                                                     [msgType]="msgType">
                </metering-devices-data-power-profile>
            </ng-template>
        </mat-tab>
        <mat-tab [disabled]="disablePagination"
                 class="content"
                 [attr.data-id]="'archives_system_1'"
                 *ngIf="currentDevice?.hasOwnProperty('model') && currentDevice?.tabs?.hasOwnProperty('archives_system_1')"
                 label="{{ 'devices.data.archives1' | translate }}">
            <ng-template matTabContent>
                <metering-devices-data-archives-system-1 *ngIf="currentDevice?.hasOwnProperty('model')"
                                                         [device]="currentDevice"
                                                         [dateForm]="dateForm"
                                                         [msgGroup]="msgGroup">
                </metering-devices-data-archives-system-1>
            </ng-template>
        </mat-tab>

        <mat-tab [disabled]="disablePagination"
                 class="content"
                 [attr.data-id]="'archives_system_2'"
                 *ngIf="currentDevice?.hasOwnProperty('model') && currentDevice?.tabs?.hasOwnProperty('archives_system_2')"
                 label="{{ 'devices.data.archives2' | translate }}">
            <ng-template matTabContent>
                <metering-devices-data-archives-system-2 *ngIf="currentDevice?.hasOwnProperty('model')"
                                                         [device]="currentDevice"
                                                         [dateForm]="dateForm"
                                                         [msgGroup]="msgGroup">
                </metering-devices-data-archives-system-2>
            </ng-template>
        </mat-tab>
        <mat-tab
            [disabled]="disablePagination"
            class="content"
            [attr.data-id]="'archives'"
            *ngIf="
                (currentDevice?.hasOwnProperty('model') && currentDevice?.tabs?.hasOwnProperty('archives')) ||
                (currentAccountingPointTabs !== null && currentAccountingPointTabs?.hasOwnProperty('archives'))
            "
            label="{{ 'devices.data.archives' | translate }}">
            <ng-template matTabContent>
                <metering-devices-data-show
                    *ngIf="currentDevice?.hasOwnProperty('model') || currentAccountingPointTabs !== null"
                    [device]="currentDevice"
                    [accountingPoint]="{
                        id: accountingPointId,
                        tabs: currentAccountingPointTabs,
                        currentPoint: currentAccountingPoint
                    }"
                    [dateForm]="dateForm"
                    [msgGroup]="msgGroup"
                    [msgType]="msgType"
                    [tab]="'archives'"
                >
                </metering-devices-data-show>
            </ng-template>
        </mat-tab>

        <mat-tab
            [disabled]="disablePagination"
            class="content"
            [attr.data-id]="'summary_readings'"
            *ngIf="
                (currentDevice?.hasOwnProperty('model') && currentDevice?.tabs?.hasOwnProperty('summary_readings')) ||
                (currentAccountingPointTabs !== null && currentAccountingPointTabs?.hasOwnProperty('summary_readings'))
            "
            label="{{ 'devices.data.finalIndications' | translate }}"
        >
            <ng-template matTabContent>
                <metering-devices-data-show
                    *ngIf="currentDevice?.hasOwnProperty('model') || currentAccountingPointTabs !== null"
                    [device]="currentDevice"
                    [accountingPoint]="{
                        id: accountingPointId,
                        tabs: currentAccountingPointTabs,
                        currentPoint: currentAccountingPoint
                    }"
                    [dateForm]="dateForm"
                    [msgGroup]="msgGroup"
                    [msgType]="msgType"
                    [tab]="'summary_readings'"
                >
                </metering-devices-data-show>
            </ng-template>
        </mat-tab>

        <mat-tab
            [disabled]="disablePagination"
            class="content"
            [attr.data-id]="'alarm'"
            *ngIf="
                (currentDevice?.hasOwnProperty('model') && currentDevice?.tabs?.hasOwnProperty('alarm')) ||
                (currentAccountingPointTabs !== null && currentAccountingPointTabs?.hasOwnProperty('alarm'))
            "
            label="{{ 'devices.data.alarms' | translate }}"
        >
            <ng-template matTabContent>
                <metering-devices-data-show
                    *ngIf="currentDevice?.hasOwnProperty('model') || currentAccountingPointTabs !== null"
                    [device]="currentDevice"
                    [accountingPoint]="{
                        id: accountingPointId,
                        tabs: currentAccountingPointTabs,
                        currentPoint: currentAccountingPoint
                    }"
                    [dateForm]="dateForm"
                    [msgGroup]="msgGroup"
                    [msgType]="msgType"
                    [tab]="'alarm'">
                </metering-devices-data-show>
            </ng-template>
        </mat-tab>

        <mat-tab
            [disabled]="disablePagination"
            class="content"
            [attr.data-id]="'journal'"
            *ngIf="
                (currentDevice?.hasOwnProperty('model') && currentDevice?.tabs?.hasOwnProperty('journal')) ||
                (currentAccountingPointTabs !== null && currentAccountingPointTabs?.hasOwnProperty('journal'))
            "
            label="{{ 'devices.data.journal' | translate }}"
        >
            <ng-template matTabContent>
                <metering-devices-data-show
                    *ngIf="currentDevice?.hasOwnProperty('model') || currentAccountingPointTabs !== null"
                    [device]="currentDevice"
                    [accountingPoint]="{
                        id: accountingPointId,
                        tabs: currentAccountingPointTabs,
                        currentPoint: currentAccountingPoint
                    }"
                    [dateForm]="dateForm"
                    [msgGroup]="msgGroup"
                    [msgType]="msgType"
                    [tab]="'journal'"
                >
                </metering-devices-data-show>
            </ng-template>
        </mat-tab>

        <mat-tab
            [disabled]="disablePagination"
            class="content"
            [attr.data-id]="'info'"
            *ngIf="
                (currentDevice?.hasOwnProperty('model') && currentDevice?.tabs?.hasOwnProperty('info')) ||
                (currentAccountingPointTabs !== null && currentAccountingPointTabs?.hasOwnProperty('info'))
            "
            label="{{ 'devices.data.info' | translate }}"
        >
            <ng-template matTabContent>
                <metering-devices-data-show
                    *ngIf="currentDevice?.hasOwnProperty('model') || currentAccountingPointTabs !== null"
                    [device]="currentDevice"
                    [accountingPoint]="{
                        id: accountingPointId,
                        tabs: currentAccountingPointTabs,
                        currentPoint: currentAccountingPoint
                    }"
                    [dateForm]="dateForm"
                    [msgGroup]="msgGroup"
                    [msgType]="msgType"
                    [tab]="'info'"
                >
                </metering-devices-data-show>
            </ng-template>
        </mat-tab>
        <mat-tab
            [disabled]="disablePagination"
            class="content"
            [attr.data-id]="'journal_device'"
            *ngIf="
                (currentDevice?.hasOwnProperty('model') && currentDevice?.tabs?.hasOwnProperty('journal_device')) ||
                (currentAccountingPointTabs !== null && currentAccountingPointTabs?.hasOwnProperty('journal_device'))
            "
            label="{{ 'devices.data.journal_device' | translate }}"
        >
            <ng-template matTabContent>
                <metering-devices-data-journal
                    *ngIf="currentDevice?.hasOwnProperty('model')"
                    [dateForm]="dateForm"
                    [typeJournal]="typeJournal"
                    [device]="currentDevice"
                >
                </metering-devices-data-journal>
            </ng-template>
        </mat-tab>
        <mat-tab
            [disabled]="disablePagination"
            class="content"
            [attr.data-id]="'service_information'"
            *ngIf="
                (currentDevice?.hasOwnProperty('model') &&
                    currentDevice?.tabs?.hasOwnProperty('service_information')) ||
                (currentAccountingPointTabs !== null &&
                    currentAccountingPointTabs?.hasOwnProperty('service_information'))
            "
            label="{{ 'devices.data.serviceInfo' | translate }}"
        >
            <ng-template matTabContent>
                <metering-devices-data-show
                    *ngIf="currentDevice?.hasOwnProperty('model') || currentAccountingPointTabs !== null"
                    [device]="currentDevice"
                    [accountingPoint]="{
                        id: accountingPointId,
                        tabs: currentAccountingPointTabs,
                        currentPoint: currentAccountingPoint
                    }"
                    [dateForm]="dateForm"
                    [msgGroup]="msgGroup"
                    [msgType]="msgType"
                    [tab]="'service_information'"
                >
                </metering-devices-data-show>
            </ng-template>
        </mat-tab>
        <mat-tab
            [disabled]="disablePagination"
            class="content"
            [attr.data-id]="'heat_calculating'"
            *ngIf="
                (currentDevice?.hasOwnProperty('model') &&
                    currentDevice?.tabs?.hasOwnProperty('heat_calculating')) ||
                (currentAccountingPointTabs !== null &&
                    currentAccountingPointTabs?.hasOwnProperty('heat_calculating'))
            "
            label="{{ 'devices.data.heatCalculating' | translate }}"
        >
            <ng-template matTabContent>
                <metering-devices-data-show
                    *ngIf="currentDevice?.hasOwnProperty('model') || currentAccountingPointTabs !== null"
                    [device]="currentDevice"
                    [accountingPoint]="{
                        id: accountingPointId,
                        tabs: currentAccountingPointTabs,
                        currentPoint: currentAccountingPoint
                    }"
                    [dateForm]="dateForm"
                    [msgGroup]="msgGroup"
                    [msgType]="msgType"
                    [tab]="'heat_calculating'"
                >
                </metering-devices-data-show>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
