<app-login
    *ngIf="!isAuth"
    [admin]="true"
    (adminEvent)="onLogin()"
></app-login>
<ng-container *ngIf="isAuth && isAllInitialDataLoaded">
    <h1 class="m-2 p-2">
        {{ 'admin.title' | translate }}
    </h1>

    <nekta-table
        [data]="devices"
        [displayedColumns]="displayedColumns"
        [isSpinnerVisible]="isSpinnerVisible"
        [onlyLocalData]="true"
        [pagination]="pagination"
        [filter]="filter"
        [sort]="sort"
        [tableColumns]="tableColumns"
        [trackByKey]="'device_id'"
        (clearFilters)="clearFilters()"
        (filterChange)="onChangeFilteringColumn($event)"
        (sortChange)="onSort($event)"
    >

        <div customOptions class="flex-between-center flex-wrap-mobile g-2">
            <nekta-search
                [searchString]="searchString"
                (searchStringChange)="onSearch($event)"
            ></nekta-search>
            <button
                mat-raised-button
                color="primary"
                (click)="getDevices()"
            >
                <mat-icon>refresh</mat-icon>
                {{ 'common.refresh' | translate }}
            </button>
        </div>

    </nekta-table>

</ng-container>

<mat-card *ngIf="isAllInitialDataLoaded && !isAuth" class="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</mat-card>

<ng-template #brokerPortTd let-device='item'>
    <span>
        {{ getPortName(device) }}
    </span>
</ng-template>

<ng-template #lastDateTd let-device='item'>
    <span>
        {{ device.last_date | date: 'medium' }}
    </span>
</ng-template>

<ng-template #statusTd let-device='item'>
    <mat-icon *ngIf="!!device?.connect_status" style="color: #4CAF50">check_circle_outline</mat-icon>
    <mat-icon *ngIf="!device?.connect_status" style="color: #CF5555">highlight_off</mat-icon>
</ng-template>

<ng-template #pollTd let-device='item'>
    <button
        mat-button
        color="primary"
        (click)="openPollModal(device)"
    >
        <mat-icon class="primary personal_design_icon">poll</mat-icon>
    </button>
</ng-template>
