import { createReducer, on } from '@ngrx/store';
import { ReportTemplatesActions } from '@store/reportTemplates/reportTemplates.actions';

export interface ReportTemplateState {
    balance: any[];
    group: any[];
    single: any[];
    system: any[];
}

const initialState: ReportTemplateState = {
    balance: [],
    group: [],
    single: [],
    system: []
};

export const reportTemplatesReducer = createReducer(
    initialState,
    on(ReportTemplatesActions.loadbalancetemplates, (state, { payload }) => {
        return {
            ...state,
            balance: payload
        };
    }),
    on(ReportTemplatesActions.loadgrouptemplates, (state, { payload }) => {
        return {
            ...state,
            group: payload
        };
    }),
    on(ReportTemplatesActions.loadsingletemplates, (state, { payload }) => {
        return {
            ...state,
            single: payload
        };
    }),
    on(ReportTemplatesActions.loadsystemtemplates, (state, { payload }) => {
        return {
            ...state,
            system: payload
        };
    }),
    on(ReportTemplatesActions.reset, (state) => {
        return { ...initialState };
    })
);
