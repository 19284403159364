import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDataWithInput } from '@core/services/dialog.service';
import { FormControl } from '@angular/forms';

/**
 * @description диалоговое алерт окно с текстовым инпутом, используется в связке с DialogService,
 * настраиваемые параметры передаются через MatDialogRef/MAT_DIALOG_DATA
 * при подтверждении эмитит значение в dialogRef, при отмене пустую строку
 * @param title заголовок окна, по умолчанию "Подтвердите действие"
 * @param content дополнительная инфо более мелким шрифтом
 * @param placeholder placeholder для инпута
 * @param confirmBtn текст на кнопке подтверждения, по умолчанию "Добавить"
 * @param cancelBtn текст на кнопке подтверждения, по умолчанию "Отмена"
 * @param isError красит текст content (дополнительная инфо) в красный цвет
 * @param validators добавляет функции-валидаторы для инпута
 */

@Component({
    selector: 'app-alert-with-input',
    templateUrl: './alert-with-input.component.html',
    styleUrls: ['./alert-with-input.component.less']
})
export class AlertWithInputComponent implements OnInit, OnDestroy {
    title = 'common.confirm_action';
    content = '';
    placeholder = '';
    confirmBtn = 'common.add';
    cancelBtn = 'common.cancel';
    isError = false;

    textInput = new FormControl('');

    constructor(
        private dialogRef: MatDialogRef<AlertWithInputComponent, string>,
        @Inject(MAT_DIALOG_DATA) private data: DialogDataWithInput
    ) {}

    ngOnInit() {
        if (this.data) {
            this.title = this.data?.title ?? 'common.confirm_action';
            this.content = this.data?.content ?? '';
            this.placeholder = this.data?.placeholder ?? '';
            this.confirmBtn = this.data?.confirmBtn ?? 'common.add';
            this.cancelBtn = this.data?.cancelBtn ?? 'common.cancel';
            this.isError = !!this.data?.isError;
        }
        if (this.data.validators) {
            this.textInput.addValidators(this.data.validators);
            this.textInput.updateValueAndValidity();
        }
    }

    ngOnDestroy() {
        this.textInput.setValue('');
    }

    onConfirm(): void {
        this.textInput.setValue(this.textInput.value.trim());
        if (this.textInput.valid) {
            if (!!this.data.callBackFn) {
                this.data.callBackFn(this.textInput.value);
            } else {
                this.dialogRef.close(this.textInput.value);
            }
        } else {
            this.textInput.markAsDirty();
        }
    }

    onClose(): void {
        this.dialogRef.close('');
    }
}
