import { createAction, props } from '@ngrx/store';

export const setAccountingPointType = createAction(
    '[ReportGenerator] Set accounting point type',
    props<{ accountingPointType: string[] }>()
);
export const setSelectedAccountingPoint = createAction(
    '[ReportGenerator] Set selected accounting points',
    props<{ accountingPoints: any[] }>()
);

export const setSelectedFields = createAction('[ReportGenerator] Set selected fields', props<{ fields: any }>());
export const setReportDates = createAction('[ReportGenerator] Set report dates', props<{ dateStart: any; dateEnd: any }>());
export const setDateStartReportDates = createAction('[ReportGenerator] Set report date start', props<{ dateStart: any; }>());
export const setDateEndReportDates = createAction('[ReportGenerator] Set report date end', props<{ dateEnd: any; }>());
export const setReportTitle = createAction('[ReportGenerator] Set report title', props<{ title: string }>());
export const setReportType = createAction('[ReportGenerator] Set report type', props<{ selectedReportType: string }>());
export const setReportTypeValue = createAction('[ReportGenerator] Set report type value', props<{ reportTypeValue: string }>());
export const setMsgType = createAction('[ReportGenerator] Set report message type value', props<{ msgType: number }>());


export const saveCustomFields = createAction('[ReportGenerator] Save custom fields', props<{ customFields: any[] }>());

export const fetchAccountingPointFields = createAction(
    '[ReportGenerator] Fetch accounting point fields',
    props<{ deviceGroupId: number }>()
);
export const fetchAccountingPointFieldsSuccess = createAction(
    '[ReportGenerator] Fetch accounting point fields success',
    props<{ fields: any }>()
);

export const fetchFieldsTitles = createAction('[ReportGenerator] Fetch fields titles');
export const fetchFieldsTitlesSuccess = createAction('[ReportGenerator] Fetch fields titles success', props<{ fields: any }>());

export const fetchAvailableAttributes= createAction('[ReportGenerator] Fetch available attributes');
export const fetchAvailableAttributesSuccess= createAction('[ReportGenerator] Fetch available attributes success', props<{ attributes: any }>());

export const loadReport= createAction('[ReportGenerator] Load report', props<{ data: any }>());
export const clearReportStore= createAction('[ReportGenerator] Clear report store');



export const fetchAvailableAccountingPoints = createAction(
    '[ReportGenerator] Load available accounting points',
    props<{ tied_device: boolean }>()
);
export const fetchAvailableAccountingPointsSuccess = createAction(
    '[ReportGenerator] Load available accounting points success',
    props<{ availableAccountingPoints: any[] }>()
);
