<h2 mat-dialog-title class="safe-text text-center">
    {{ title | translate }}
</h2>
<div mat-dialog-content *ngIf="content" class="safe-text">
    <p
        class="text-center"
        [class.mat-error]="isError"
    >
        {{ content | translate }}
    </p>
</div>
<div mat-dialog-content class="safe-text">
    <mat-form-field class="w-100" floatLabel="always" appearance="outline">
        <mat-label>{{ placeholder | translate }}</mat-label>
        <mat-select [formControl]="selectFC">
            <mat-option *ngIf="items?.length > 5">
                <ngx-mat-select-search
                    [formControl]="searchString"
                    [placeholderLabel]="'devices.add.device.input.search' | translate"
                    [noEntriesFoundLabel]="'devices.add.device.input.notFound' | translate"
                ></ngx-mat-select-search>
            </mat-option>
            <cdk-virtual-scroll-viewport itemSize="10" class="virtual-scroll">
                <mat-option *cdkVirtualFor="let item of filteredItems; templateCacheSize: 0;" [value]="item">
                    {{ item.title }}
                </mat-option>
            </cdk-virtual-scroll-viewport>
        </mat-select>
    </mat-form-field>
    <p *ngIf="!filteredItems.length" class="fail">
        {{'devices.listMeteringDevice.msg_11' | translate}}
    </p>
</div>
<div mat-dialog-actions class="flex-evenly">
    <button
        mat-button
        mat-flat-button
        class="alert-button safe-text"
        (click)="onClose()"
    >
        {{ cancelBtn | translate }}
    </button>
    <button
        mat-button
        mat-flat-button
        color="primary"
        class="alert-button safe-text"
        (click)="onConfirm()"
    >
        {{ confirmBtn | translate }}
    </button>
</div>
