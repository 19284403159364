import { LoginComponent } from '@auth/login/login.component';
import { RegistrationComponent } from '@auth/registration/registration.component';
import { ForgetComponent } from '@auth/forget/forget.component';
import { ForgetActivateComponent } from '@auth/forget/activate.component';
import { LogoutComponent } from '@auth/logout/logout.component';
import { Routes } from '@angular/router';
import { ActivateUserComponent } from '@auth/activate/activate.component';

export const AuthRoutes: Routes = [
    {
        path: 'auth',
        children: [
            { path: 'login', component: LoginComponent },
            { path: 'loginNew', component: LoginComponent },
            { path: 'registration', component: RegistrationComponent },
            { path: 'activate/:token', component: ActivateUserComponent },
            { path: 'forget', component: ForgetComponent },
            { path: 'forget/reset/:token', component: ForgetActivateComponent },
            { path: 'logout', component: LogoutComponent }
        ]
    }
];
