import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-special-days-selector',
    templateUrl: './special-days-selector.component.html',
    styleUrls: ['./special-days-selector.component.less']
})
export class SpecialDaysSelectorComponent implements OnInit {
    @Input() specialDays = [];
    @Output() specialDaysChange = new EventEmitter();

    readonly months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    days: number[] = [];

    selectedMonth = new UntypedFormControl(1);
    selectedDay = new UntypedFormControl(1);

    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    ngOnInit(): void {
        this.selectedMonth.valueChanges.pipe(startWith(1), takeUntil(this.destroyed$)).subscribe((month) => {
            if (month) {
                this.updateDaysQty(month);
                this.selectedDay.setValue(1);
            }
        });
    }

    addDay() {
        const newDay = this.addZeroToNumber(this.selectedMonth.value) + '-' + this.addZeroToNumber(this.selectedDay.value);
        if (this.specialDays.includes(newDay)) {
            return;
        }
        const result = [...this.specialDays, newDay];
        this.specialDaysChange.emit(result);
    }

    removeDay(i: number) {
        const result = this.specialDays.filter((w, index) => index !== i);
        this.specialDaysChange.emit(result);
    }

    private updateDaysQty(month: number) {
        let daysQty: number;
        if (month === 2) {
            daysQty = 29;
        } else if ([4, 6, 9, 11].includes(month)) {
            daysQty = 30;
        } else {
            daysQty = 31;
        }
        this.days = [];
        for (let i = 0; i < daysQty; i++) {
            this.days.push(i + 1);
        }
    }

    private addZeroToNumber(v: number): string {
        return v > 9 ? v.toString() : '0' + v;
    }
}
