import { Component, OnDestroy, OnInit } from '@angular/core';
import { PassportService } from 'src/app/auth/passport.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PopupComponent } from '@shared/popup/popup.component';
import { Router } from '@angular/router';
import { CompaniesService } from '@cloud/companies/companies.service';
import { environment } from '@environments/environment';
import { SnackbarService } from '@core/services/snackbar.service';

@Component({
    selector: 'app-root',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.less']
})
export class RegistrationComponent implements OnInit, OnDestroy {
    loading = false;
    companiesTypes: any;
    subscribers = [];
    useRandomPassword = true;
    registrationForm = new UntypedFormGroup({
        name: new UntypedFormControl(''),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        password: new UntypedFormControl(null, [
            Validators.required,
            Validators.pattern('^(?=.*[a-zа-я])(?=.*[A-ZА-Я])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$')
        ]),
        password_confirmation: new UntypedFormControl(null, [Validators.required, Validators.minLength(8)]),
        company_name: new UntypedFormControl('', [Validators.required]),
        inn: new UntypedFormControl('', [Validators.minLength(10), Validators.maxLength(10)]),
        phone_number: new UntypedFormControl('', [Validators.minLength(10), Validators.maxLength(15)])
    });

    constructor(
        private readonly auth: PassportService,
        private readonly companiesService: CompaniesService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly snackbar: SnackbarService,
        private readonly translate: TranslateService
    ) {
        this.subscribers[this.subscribers.length] = this.companiesService.getCompaniesTypes().subscribe((data: any) => {
            this.companiesTypes = data.data.company_types;
        });
    }

    ngOnInit() {
        this.auth.getSiteProperties().subscribe((res: any) => {
            this.useRandomPassword = res.data.use_random_password;
            environment.isKerberos = res.data.is_kerberos;
            if (this.useRandomPassword) {
                this.registrationForm.removeControl('password');
                this.registrationForm.removeControl('password_confirmation');
            }
        });
    }

    signup() {
        if (this.registrationForm?.controls?.['password_confirmation']?.value !== this.registrationForm?.controls?.['password']?.value) {
            this.snackbar.open('registrationForm.errors.password_not_equal', true);
            return this.registrationForm.controls['password_confirmation'].setErrors({ incorrect: true });
        } else if (this.registrationForm.status === 'VALID') {
            this.loading = true;
            this.subscribers[this.subscribers.length] = this.auth.signup(this.registrationForm, this.useRandomPassword).subscribe(
                (data: any) => {
                    this.dialog.open(PopupComponent, {
                        width: '400px',
                        height: '220px',
                        data: { name: this.translate.instant('registrationForm.messages.success'), message: data.msg }
                    });
                    this.router.navigate(['auth/login']).then();
                },
                (error) => {
                    this.loading = false;
                    throw error;
                }
            );
        }
    }

    onNumberChange(e) {
        this.registrationForm.controls['phone_number'].setValue(e.phoneNumber);
    }

    ngOnDestroy() {
        this.loading = false;
        this.subscribers.forEach((item) => {
            if (item !== undefined) {
                item.unsubscribe();
            }
        });
    }
}
