import { createReducer, on } from '@ngrx/store';
import _ from 'lodash';
import { ReportBuilderActions } from '@store/reportBuilder/report-builder.actions';

const initialState = {
    availableAccountingPoints: [],
    allAvailableAttributes: [],
    selectedAccountingPoints: [],
    reportTitle: '',
    reportType: 'group',
    reportTypeValue: 'horizontal',
    msgType: 0,
    selectedFields: [],
    customFields: [],
    accountingPointsFields: [],
    fieldTitles: [],
    accountingPointType: null,
    reportConfig: {
        dateStart: null,
        dateEnd: null
    },
    template: null
};
export const reportBuilderReducer = createReducer(
    initialState,
    on(ReportBuilderActions.setaccountingpointtype, (state, { accountingPointType }) => {
        return {
            ...state,
            accountingPointType: accountingPointType.map((i) => parseInt(i, 10))
        };
    }),
    on(ReportBuilderActions.fetchavailableaccountingpointssuccess, (state, { availableAccountingPoints }) => {
        return {
            ...state,
            availableAccountingPoints: availableAccountingPoints
        };
    }),
    on(ReportBuilderActions.setselectedaccountingpoint, (state, { accountingPoints }) => {
        return {
            ...state,
            selectedAccountingPoints: accountingPoints
        };
    }),
    on(ReportBuilderActions.setselectedfields, (state, { fields }) => {
        return {
            ...state,
            selectedFields: fields
        };
    }),
    on(ReportBuilderActions.fetchaccountingpointfieldssuccess, (state, { fields }) => {
        return {
            ...state,
            accountingPointsFields: _.uniq(fields)
        };
    }),
    on(ReportBuilderActions.fetchfieldstitlessuccess, (state, { fields }) => {
        return {
            ...state,
            fieldTitles: fields.data.fields
        };
    }),
    on(ReportBuilderActions.savecustomfields, (state, { customFields }) => {
        return {
            ...state,
            customFields: customFields
        };
    }),
    on(ReportBuilderActions.setreportdates, (state, { dateStart, dateEnd }) => {
        return {
            ...state,
            reportConfig: { dateStart, dateEnd }
        };
    }),
    on(ReportBuilderActions.setdatestartreportdates, (state, { dateStart }) => {
        return {
            ...state,
            reportConfig: { ...state.reportConfig, dateStart }
        };
    }),
    on(ReportBuilderActions.setdateendreportdates, (state, { dateEnd }) => {
        return {
            ...state,
            reportConfig: { ...state.reportConfig, dateEnd }
        };
    }),
    on(ReportBuilderActions.fetchavailableattributessuccess, (state, { attributes }) => {
        return {
            ...state,
            allAvailableAttributes: attributes
        };
    }),
    on(ReportBuilderActions.setreporttitle, (state, { title }) => {
        return {
            ...state,
            reportTitle: title
        };
    }),
    on(ReportBuilderActions.setreporttype, (state, { selectedReportType }) => {
        return {
            ...state,
            reportType: selectedReportType
        };
    }),
    on(ReportBuilderActions.setreporttypevalue, (state, { reportTypeValue }) => {
        return {
            ...state,
            reportTypeValue
        };
    }),
    on(ReportBuilderActions.setmsgtype, (state, { msgType }) => {
        return {
            ...state,
            msgType
        };
    }),
    on(ReportBuilderActions.loadreport, (state, { data }) => {
        return {
            ...state,
            ...data
        };
    }),
    on(ReportBuilderActions.clearreportstore, (state) => {
        return { ...initialState };
    })
);
