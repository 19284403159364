import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ButtonsService, TitleService } from '@cloud/cloud.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import { registerLocaleData } from '@angular/common';
import { environment } from '@environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from '@shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { CloudComponent } from '@cloud/cloud.component';
import { KerberosInterceptor } from '@core/interceptors/kerberos.interceptor';
import { NektaErrorHandler } from '@core/nekta-error.handler';
import { appEffects } from 'src/app/store/app.effects';
import { appReducer } from 'src/app/store/app.reducer';
import { AuthModule } from '@auth/auth.module';
import { AdminComponent } from './admin/admin.component';
import { NgxMaskModule } from 'ngx-mask';

registerLocaleData(localeRu, 'ru', localeRuExtra);

function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
    declarations: [CloudComponent, AppComponent, AdminComponent],
    imports: [
        AuthModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        LeafletModule,
        NgxMaskModule.forRoot(),
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        StoreModule.forRoot(appReducer),
        EffectsModule.forRoot(appEffects),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production // Restrict extension to log-only mode
        }),
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        MatSnackBar,
        // // DELETE - когда будут удалены все сущности этих сервисов
        TitleService,
        ButtonsService,
        { provide: LOCALE_ID, useValue: 'ru-RU' },
        { provide: ErrorHandler, useClass: NektaErrorHandler },
        // TODO: move to base-url-interceptor
        { provide: HTTP_INTERCEPTORS, useClass: KerberosInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
