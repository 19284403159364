import { createActionGroup, emptyProps, props } from '@ngrx/store';

// TODO: add fetch actions

export const DirectoriesActions = createActionGroup({
    source: 'Directories',
    events: {
        loadBrands: props<{payload: any}>(),
        loadDeviceGroups: props<{ payload: any }>(),
        loadInterfaces: props<{ payload: any }>(),
        loadMessageGroups: props<{ payload: any }>(),
        loadMessageTypes: props<{ payload: any }>(),
        reset: emptyProps()
    }
});
