import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { SuppliersService, SuppliersStateService } from '../../services/suppliers.service';
import { SuppliersActions } from '../actions/suppliers.actions';
import { of } from 'rxjs';

@Injectable()
export class SuppliersEffects {
    fetchSuppliers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SuppliersActions.FetchSuppliers),
            map((action: any) => action.payload),
            exhaustMap((params: any) =>
                this.suppliersService.getSuppliers(params.page, params.perPage, params.search_string).pipe(
                    map((consumers) => ({
                        type: SuppliersActions.FetchSuppliersSuccess,
                        payload: consumers
                    })),
                    catchError((e) => {
                        throw e;
                    })
                )
            )
        )
    );

    addSuppliers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SuppliersActions.AddSupplier),
            map((action: any) => action.payload),
            exhaustMap((data: any) =>
                this.suppliersService
                    .addSupplier({
                        name: data.name,
                        inn: data.inn,
                        number_of_contract: data.number_of_contract,
                        email: data.email,
                        report_date: data.report_date,
                    })
                    .pipe(
                        map((msg) => this.suppliersStateService.addSupplierSuccess(data.page, data.perPage, msg)),
                        catchError((error) => of(this.suppliersStateService.addSupplierFail(error)))
                    )
            )
        )
    );

    editSuppliers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SuppliersActions.EditSupplier),
            map((action: any) => action.payload),
            exhaustMap((data: any) =>
                this.suppliersService
                    .editSupplier({
                        _id: data._id,
                        name: data.name,
                        email: data.email,
                        inn: data.inn,
                        number_of_contract: data.number_of_contract,
                        report_date: data.report_date,
                    })
                    .pipe(
                        map((msg) => this.suppliersStateService.editSupplierSuccess(data.page, data.perPage, msg)),
                        catchError((error) => of(this.suppliersStateService.editSupplierFail(error)))
                    )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private suppliersService: SuppliersService,
        private suppliersStateService: SuppliersStateService
    ) {}
}
