export class CountryListConstants {
    static readonly countries = [
        { countryCode: 'af', name: 'Афганистан', dialCode: '+93', name_en: 'Afghanistan' },
        { countryCode: 'al', name: 'Албания', dialCode: '+355', name_en: 'Albania' },
        { countryCode: 'dz', name: 'Алжир', dialCode: '+21', name_en: 'Algeria' },
        { countryCode: 'as', name: 'Американское Самоа', dialCode: '+684', name_en: 'American Samoa' },
        { countryCode: 'ad', name: 'Андорра', dialCode: '+376', name_en: 'Andorra' },
        { countryCode: 'ao', name: 'Ангола', dialCode: '+244', name_en: 'Angola' },
        { countryCode: 'ai', name: 'Anguilla', dialCode: '+1-263', name_en: 'Anguilla' },
        { countryCode: 'ag', name: 'Антигуа и Барбуда', dialCode: '+1-267', name_en: 'Antigua' },
        { countryCode: 'ar', name: 'Аргентина', dialCode: '+54', name_en: 'Argentina' },
        { countryCode: 'am', name: 'Армения', dialCode: '+374', name_en: 'Armenia' },
        { countryCode: 'au', name: 'Австралия', dialCode: '+61', name_en: 'Australia' },
        { countryCode: 'at', name: 'Австрия', dialCode: '+43', name_en: 'Austria' },
        { countryCode: 'az', name: 'Азербайджан', dialCode: '+994', name_en: 'Azerbaijan' },
        { countryCode: 'bs', name: 'Багамы', dialCode: '+1-241', name_en: 'Bahamas' },
        { countryCode: 'bh', name: 'Бахрейн', dialCode: '+973', name_en: 'Bahrain' },
        { countryCode: 'bd', name: 'Бангладеш', dialCode: '+880', name_en: 'Bangladesh' },
        { countryCode: 'bb', name: 'Барбадос', dialCode: '+1-245', name_en: 'Barbados' },
        { countryCode: 'by', name: 'Белоруссия', dialCode: '+375', name_en: 'Belarus' },
        { countryCode: 'be', name: 'Бельгия', dialCode: '+32', name_en: 'Belgium' },
        { countryCode: 'bz', name: 'Белиз', dialCode: '+501', name_en: 'Belize' },
        { countryCode: 'bj', name: 'Бенин', dialCode: '+229', name_en: 'Benin' },
        { countryCode: 'bm', name: 'Бермудские острова', dialCode: '+1-440', name_en: 'Bermuda' },
        { countryCode: 'bo', name: 'Боливия', dialCode: '+591', name_en: 'Bolivia' },
        { countryCode: 'ba', name: 'Босния и Герцеговина', dialCode: '+387', name_en: 'Bosnia and Herzegovina' },
        { countryCode: 'bw', name: 'Ботсвана', dialCode: '+267', name_en: 'Botswana' },
        { countryCode: 'br', name: 'Бразилия', dialCode: '+55', name_en: 'Brazil' },
        { countryCode: 'vg', name: 'British Virgin Islands', dialCode: '+1-283', name_en: 'British Virgin Islands' },
        { countryCode: 'bn', name: 'Бруней Даруэсалаам', dialCode: '+673', name_en: 'Brunei Darusalaam' },
        { countryCode: 'bg', name: 'Болгария', dialCode: '+359', name_en: 'Bulgaria' },
        { countryCode: 'bf', name: 'Буркина Фасо', dialCode: '+226', name_en: 'Burkina Faso' },
        { countryCode: 'bi', name: 'Бурунди', dialCode: '+257', name_en: 'Burundi' },
        { countryCode: 'kh', name: 'Камбоджа', dialCode: '+855', name_en: 'Cambodia' },
        { countryCode: 'cm', name: 'Камерун', dialCode: '+237', name_en: 'Cameroon' },
        { countryCode: 'cv', name: 'Капе верде', dialCode: '+238', name_en: 'Cape Verde' },
        { countryCode: 'ky', name: 'Каймановы острова', dialCode: '+1-344', name_en: 'Cayman Islands' },
        {
            countryCode: 'cf',
            name: 'Центрально африканская республика',
            dialCode: '+236',
            name_en: 'Central African Republic'
        },
        { countryCode: 'td', name: 'Чад', dialCode: '+235', name_en: 'Chad' },
        { countryCode: 'cl', name: 'Чили', dialCode: '+56', name_en: 'Chile' },
        { countryCode: 'cn', name: 'Китай', dialCode: '+86', name_en: 'China' },
        { countryCode: 'co', name: 'Colombia', dialCode: '+57', name_en: 'Colombia' },
        {
            countryCode: 'km',
            name: 'Коморские и майотские острова',
            dialCode: '+269',
            name_en: 'Comoros and Mayotte Island'
        },
        { countryCode: 'ck', name: 'Острова Кука', dialCode: '+682', name_en: 'Cook Islands' },
        { countryCode: 'cr', name: 'Коста-Рика', dialCode: '+506', name_en: 'Costa Rica' },
        { countryCode: 'hr', name: 'Хорватия', dialCode: '+385', name_en: 'Croatia' },
        { countryCode: 'cu', name: 'Куба', dialCode: '+53', name_en: 'Cuba' },
        { countryCode: 'cy', name: 'Кипр', dialCode: '+357', name_en: 'Cyprus' },
        { countryCode: 'cz', name: 'Чешская республика', dialCode: '+420', name_en: 'Czech Republic' },
        { countryCode: 'dk', name: 'Дания', dialCode: '+45', name_en: 'Denmark' },
        { countryCode: 'dj', name: 'Джибути', dialCode: '+253', name_en: 'Djibouti' },
        { countryCode: 'dm', name: 'Доминика', dialCode: '+1-766', name_en: 'Dominica' },
        { countryCode: 'do', name: 'Доминиканская республика', dialCode: '+1-808', name_en: 'Dominican Republic' },
        { countryCode: 'ec', name: 'Эквадор', dialCode: '+593', name_en: 'Ecuador' },
        { countryCode: 'eg', name: 'Египет', dialCode: '+20', name_en: 'Egypt' },
        { countryCode: 'sv', name: 'Сальвадор', dialCode: '+503', name_en: 'El Salvador' },
        { countryCode: 'gq', name: 'Экваториальная Гвинея', dialCode: '+240', name_en: 'Equatorial Guinea' },
        { countryCode: 'ee', name: 'Эстония', dialCode: '+372', name_en: 'Estonia' },
        { countryCode: 'et', name: 'Эфиопия', dialCode: '+251', name_en: 'Ethiopia' },
        { countryCode: 'fk', name: 'Фолклендские острова', dialCode: '+500', name_en: 'Falkland Islands' },
        { countryCode: 'fj', name: 'Фиджи', dialCode: '+679', name_en: 'Fiji' },
        { countryCode: 'fi', name: 'Финляндия', dialCode: '+358', name_en: 'Finland' },
        { countryCode: 'fr', name: 'Франция', dialCode: '+33', name_en: 'France' },
        { countryCode: 'fr', name: 'Французские Антиллы', dialCode: '+590', name_en: 'French Antilles' },
        { countryCode: 'gf', name: 'Французская Гвиана', dialCode: '+594', name_en: 'French Guiana' },
        { countryCode: 'pf', name: 'Франзузская полинезия', dialCode: '+689', name_en: 'French Polynesia' },
        { countryCode: 'ga', name: 'Габон', dialCode: '+241', name_en: 'Gabon' },
        { countryCode: 'gm', name: 'Гамбия', dialCode: '+220', name_en: 'The Gambia' },
        { countryCode: 'ge', name: 'Грузия', dialCode: '+995', name_en: 'Georgia' },
        { countryCode: 'de', name: 'Германия', dialCode: '+49', name_en: 'Germany' },
        { countryCode: 'gh', name: 'Гана', dialCode: '+233', name_en: 'Ghana' },
        { countryCode: 'gi', name: 'Гибралтар', dialCode: '+350', name_en: 'Gibraltar' },
        { countryCode: 'gr', name: 'Греция', dialCode: '+30', name_en: 'Greece' },
        { countryCode: 'gl', name: 'Гренландия', dialCode: '+299', name_en: 'Greenland' },
        { countryCode: 'gd', name: 'Гренада', dialCode: '+1-472', name_en: 'Grenada' },
        { countryCode: 'gt', name: 'Гватемала', dialCode: '+502', name_en: 'Guatemala' },
        { countryCode: 'gn', name: 'Гвинея', dialCode: '+224', name_en: 'Guinea' },
        { countryCode: 'gw', name: 'Гвинея Биссау', dialCode: '+245', name_en: 'Guinea-Bissau' },
        { countryCode: 'gy', name: 'Гайана', dialCode: '+592', name_en: 'Guyana' },
        { countryCode: 'ht', name: 'Гаити', dialCode: '+509', name_en: 'Haiti' },
        { countryCode: 'hn', name: 'Гондурас', dialCode: '+504', name_en: 'Honduras' },
        { countryCode: 'hk', name: 'Гон Конг', dialCode: '+852', name_en: 'Hong Kong' },
        { countryCode: 'hu', name: 'Венгрия', dialCode: '+36', name_en: 'Hungary' },
        { countryCode: 'is', name: 'Исландия', dialCode: '+354', name_en: 'Iceland' },
        { countryCode: 'in', name: 'Индия', dialCode: '+91', name_en: 'India' },
        { countryCode: 'id', name: 'Индонезия', dialCode: '+62', name_en: 'Indonesia' },
        { countryCode: 'ir', name: 'Иран', dialCode: '+98', name_en: 'Iran' },
        { countryCode: 'iq', name: 'Ирак', dialCode: '+964', name_en: 'Iraq' },
        { countryCode: 'ie', name: 'Ирландская республика', dialCode: '+353', name_en: 'Ireland' },
        { countryCode: 'il', name: 'Израиль', dialCode: '+972', name_en: 'Israel' },
        { countryCode: 'it', name: 'Италия', dialCode: '+39', name_en: 'Italy' },
        { countryCode: 'jm', name: 'Джамайка', dialCode: '+1-875', name_en: 'Jamaica' },
        { countryCode: 'jp', name: 'Япония', dialCode: '+81', name_en: 'Japan' },
        { countryCode: 'jo', name: 'Иордания', dialCode: '+962', name_en: 'Jordan' },
        { countryCode: 'ru', name: 'Россия', dialCode: '+7', name_en: 'Russian Federation' },
        { countryCode: 'kz', name: 'Казахстан', dialCode: '+7', name_en: 'Kazakhstan' },
        { countryCode: 'ke', name: 'Кения', dialCode: '+254', name_en: 'Kenya' },
        { countryCode: 'ki', name: 'Кирибати', dialCode: '+686', name_en: 'Kiribati' },
        { countryCode: 'kw', name: 'Кувейт', dialCode: '+965', name_en: 'Kuwait' },
        { countryCode: 'kg', name: 'Кыргызстан', dialCode: '+996', name_en: 'Kyrgyzstan' },
        { countryCode: 'la', name: 'Лаос', dialCode: '+856', name_en: 'Laos' },
        { countryCode: 'lv', name: 'Латвия', dialCode: '+371', name_en: 'Latvia' },
        { countryCode: 'lb', name: 'Ливан', dialCode: '+961', name_en: 'Lebanon' },
        { countryCode: 'ls', name: 'Лессото', dialCode: '+266', name_en: 'Lesotho' },
        { countryCode: 'lr', name: 'Либерия', dialCode: '+231', name_en: 'Liberia' },
        { countryCode: 'ly', name: 'Ливия', dialCode: '+218', name_en: 'Libya' },
        { countryCode: 'li', name: 'Лихтенштейн', dialCode: '+423', name_en: 'Liechtenstein' },
        { countryCode: 'lt', name: 'Литва', dialCode: '+370', name_en: 'Lithuania' },
        { countryCode: 'lu', name: 'Люксембург', dialCode: '+352', name_en: 'Luxembourg' },
        { countryCode: 'mo', name: 'Макао', dialCode: '+853', name_en: 'Macau' },
        { countryCode: 'mk', name: 'Македония', dialCode: '+389', name_en: 'Macedonia' },
        { countryCode: 'mg', name: 'Мадагаскар', dialCode: '+261', name_en: 'Madagascar' },
        { countryCode: 'mw', name: 'Малави', dialCode: '+265', name_en: 'Malawi' },
        { countryCode: 'my', name: 'Малайзия', dialCode: '+60', name_en: 'Malaysia' },
        { countryCode: 'mv', name: 'Мальдивы', dialCode: '+960', name_en: 'Maldives' },
        { countryCode: 'ml', name: 'Мали', dialCode: '+223', name_en: 'Mali' },
        { countryCode: 'mt', name: 'Мальта', dialCode: '+356', name_en: 'Malta' },
        { countryCode: 'mh', name: 'Маршалловы острова', dialCode: '+692', name_en: 'Marshall Islands' },
        { countryCode: 'mq', name: 'Мартиника', dialCode: '+596', name_en: 'Martinique' },
        { countryCode: 'mr', name: 'Мавритания', dialCode: '+222', name_en: 'Mauritania' },
        { countryCode: 'mu', name: 'Маврикий', dialCode: '+230', name_en: 'Mauritius' },
        { countryCode: 'mx', name: 'Мексика', dialCode: '+52', name_en: 'Mexico' },
        { countryCode: 'fm', name: 'Микронезия', dialCode: '+691', name_en: 'Micronesia' },
        { countryCode: 'md', name: 'Молдова', dialCode: '+373', name_en: 'MOLDOVA' },
        { countryCode: 'mc', name: 'Монако', dialCode: '+377', name_en: 'Monaco' },
        { countryCode: 'mn', name: 'Монголия', dialCode: '+976', name_en: 'Mongolia' },
        { countryCode: 'ma', name: 'Мороко', dialCode: '+212', name_en: 'Morocco' },
        { countryCode: 'mz', name: 'Мозамбик', dialCode: '+258', name_en: 'Mozambique' },
        { countryCode: 'na', name: 'Намибия', dialCode: '+264', name_en: 'Namibia' },
        { countryCode: 'nr', name: 'Науру', dialCode: '+674', name_en: 'Nauru' },
        { countryCode: 'np', name: 'Непал', dialCode: '+977', name_en: 'Nepal' },
        { countryCode: 'nl', name: 'Нидерланды', dialCode: '+31', name_en: 'Netherlands' },
        { countryCode: 'nc', name: 'Новая каледония', dialCode: '+687', name_en: 'New Caledonia' },
        { countryCode: 'nz', name: 'Новая зеландия', dialCode: '+64', name_en: 'New Zealand' },
        { countryCode: 'ni', name: 'Никарагуа', dialCode: '+505', name_en: 'Nicaragua' },
        { countryCode: 'ne', name: 'Нигер', dialCode: '+227', name_en: 'Niger' },
        { countryCode: 'ng', name: 'Нигерия', dialCode: '+234', name_en: 'Nigeria' },
        { countryCode: 'nf', name: 'Норфолкские острова', dialCode: '+672', name_en: 'Norfolk Island' },
        { countryCode: 'kp', name: 'Северная Корея', dialCode: '+850', name_en: 'North Korea' },
        { countryCode: 'no', name: 'Норвегия', dialCode: '+47', name_en: 'Norway' },
        { countryCode: 'om', name: 'Оман', dialCode: '+968', name_en: 'Oman' },
        { countryCode: 'pk', name: 'Пакистан', dialCode: '+92', name_en: 'Pakistan' },
        { countryCode: 'pa', name: 'Панама', dialCode: '+507', name_en: 'Panama' },
        { countryCode: 'pg', name: 'Папуа Новая Гвинея', dialCode: '+675', name_en: 'Papua New Guinea' },
        { countryCode: 'py', name: 'Парагвай', dialCode: '+595', name_en: 'Paraguay' },
        { countryCode: 'pe', name: 'Перу', dialCode: '+51', name_en: 'Peru' },
        { countryCode: 'ph', name: 'Филипины', dialCode: '+63', name_en: 'Philippines' },
        { countryCode: 'pl', name: 'Польша', dialCode: '+48', name_en: 'Poland' },
        { countryCode: 'pt', name: 'Португалия', dialCode: '+351', name_en: 'Portugal' },
        { countryCode: 'pr', name: 'Пуэрто Рико', dialCode: '+1-786', name_en: 'Puerto Rico' },
        { countryCode: 'qa', name: 'Катар', dialCode: '+974', name_en: 'Qatar' },
        { countryCode: 'cg', name: 'Конго', dialCode: '+242', name_en: 'Congo' },
        { countryCode: 'ro', name: 'Румыния', dialCode: '+40', name_en: 'Romania' },
        { countryCode: 'rw', name: 'Республика Руанда', dialCode: '+250', name_en: 'Rwanda' },
        { countryCode: 'sa', name: 'Саудовская Аравия', dialCode: '+966', name_en: 'Saudi Arabia' },
        { countryCode: 'sn', name: 'Сенегал', dialCode: '+221', name_en: 'Senegal' },
        { countryCode: 'sg', name: 'Сингапур', dialCode: '+65', name_en: 'Singapore' },
        { countryCode: 'sk', name: 'Словакия', dialCode: '+421', name_en: 'Slovakia' },
        { countryCode: 'si', name: 'Словения', dialCode: '+386', name_en: 'Slovenia' },
        { countryCode: 'sb', name: 'Соломоновы острова', dialCode: '+677', name_en: 'Solomon Islands' },
        { countryCode: 'so', name: 'Сомали', dialCode: '+252', name_en: 'Somalia' },
        { countryCode: 'za', name: 'ЮАР', dialCode: '+27', name_en: 'South Africa' },
        { countryCode: 'kr', name: 'Южная Корея', dialCode: '+82', name_en: 'South Korea' },
        { countryCode: 'es', name: 'Испания', dialCode: '+34', name_en: 'Spain' },
        { countryCode: 'lk', name: 'Шри Ланка', dialCode: '+94', name_en: 'Sri Lanka' },
        { countryCode: 'sd', name: 'Судан', dialCode: '+249', name_en: 'Sudan' },
        { countryCode: 'sr', name: 'Суринам', dialCode: '+597', name_en: 'Suriname' },
        { countryCode: 'sz', name: 'Швейцария', dialCode: '+41', name_en: 'Swaziland' },
        { countryCode: 'se', name: 'Швеция', dialCode: '+46', name_en: 'Sweden' },
        { countryCode: 'sy', name: 'Сирия', dialCode: '+963', name_en: 'Syria' },
        { countryCode: 'tw', name: 'Тайвань', dialCode: '+886', name_en: 'Taiwan' },
        { countryCode: 'tj', name: 'Таджикистан', dialCode: '+992', name_en: 'Tajikistan' },
        { countryCode: 'tz', name: 'Танзания', dialCode: '+255', name_en: 'Tanzania' },
        { countryCode: 'th', name: 'Тайланд', dialCode: '+66', name_en: 'Thailand' },
        { countryCode: 'tg', name: 'Тонго', dialCode: '+228', name_en: 'Togo' },
        { countryCode: 'tn', name: 'Тунис', dialCode: '+216', name_en: 'Tunisia' },
        { countryCode: 'tr', name: 'Турция', dialCode: '+90', name_en: 'Turkey' },
        { countryCode: 'tm', name: 'Туркменистан', dialCode: '+993', name_en: 'Turkmenistan' },
        { countryCode: 'ug', name: 'Уганда', dialCode: '+256', name_en: 'Uganda' },
        { countryCode: 'ua', name: 'Украина', dialCode: '+380', name_en: 'Ukraine' },
        { countryCode: 'ae', name: 'О.А.Э.', dialCode: '+971', name_en: 'United Arab Emirates' },
        { countryCode: 'gb', name: 'Великобритания', dialCode: '+44', name_en: 'United Kingdom' },
        { countryCode: 'us', name: 'США', dialCode: '+1', name_en: 'United States' },
        { countryCode: 'ca', name: 'Канада', dialCode: '+1', name_en: 'Canada' },
        { countryCode: 'uy', name: 'Уругвай', dialCode: '+598', name_en: 'Uruguay' },
        { countryCode: 'uz', name: 'Узбекистан', dialCode: '+998', name_en: 'Uzbekistan' },
        { countryCode: 'va', name: 'Ватикан', dialCode: '+39', name_en: 'Vatican City' },
        { countryCode: 've', name: 'Венесуэла', dialCode: '+58', name_en: 'Venezuela' },
        { countryCode: 'vn', name: 'Вьетнам', dialCode: '+84', name_en: 'Vietnam' },
        { countryCode: 'zm', name: 'Замбия', dialCode: '+260', name_en: 'Zambia' },
        { countryCode: 'zw', name: 'Зимбабве', dialCode: '+263', name_en: 'Zimbabwe' }
    ];

    static readonly defaultCountryRu = {
        countryCode: 'ru',
        name: 'Россия',
        dialCode: '+7',
        name_en: 'Russian Federation'
    };
    static readonly defaultCountryUS = { countryCode: 'us', name: 'США', dialCode: '+1', name_en: 'United States' };
}
