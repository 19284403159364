import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { DevicesService } from 'src/app/cloud/devices/services/devices.service';
import { ErrorsService } from '@core/services/errors.service';
import { EventsService } from 'src/app/cloud/events/events.service';
import { ObjectsService } from 'src/app/cloud/objects/objects.service';
import { SuppliersService } from 'src/app/cloud/directories/services/suppliers.service';
import { ConsumersService } from 'src/app/cloud/directories/services/consumers.service';
import { PollsGroupService } from 'src/app/cloud/devices/services/polls-group.service';
import { TranslateService } from '@ngx-translate/core';

// TODO - create few common dialogs components

export interface DialogData {
    type: string;
    deleteType?: string;
    name: any;
    message: any;
    spinner: boolean;
    params: any;
}

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
    deleteIn: string;
    spinner;
    deleteId: number;
    deleteByConfirmForm: UntypedFormGroup;
    deleteMeteringPoints = false;
    subscribeNewsletter = true;

    constructor(
        public dialogRef: MatDialogRef<PopupComponent>,
        private errors: ErrorsService,
        public dialog: MatDialog,
        private devicesService: DevicesService,
        private _eventsService: EventsService,
        private objectsService: ObjectsService,
        private suppliersService: SuppliersService,
        private consumersService: ConsumersService,
        private pollsGroupService: PollsGroupService,
        private translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (this.data['spinner'] === true && this.data['spinner'] !== undefined) {
            dialogRef.disableClose = true;
        }
        if (this.data.hasOwnProperty('type')) {
            if (this.data.type.indexOf('delete') === 0) {
                this.deleteIn = 'delete';
            }
        }
        if (this.data.hasOwnProperty('deleteType') && this.data.deleteType === 'delete_by_confirm') {
            this.initForm();
        }
    }

    static getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    ngOnInit() {
        this.spinner = this.data['spinner'];
    }

    archiveBaseStation(baseStationId) {
        this.devicesService.deleteBaseStationOnTrash(baseStationId).then((result: any) => {
            this.dialogRef.close({ result: result });
        });
    }

    deleteBaseStation() {
        if (this.deleteId == this.data.params) {
            this.devicesService.deleteBaseStationFromBasket(this.data.params).subscribe(
                (data: any) => {
                    this.dialogRef.close({ result: true });
                    this.dialog.open(PopupComponent, {
                        width: '400px',
                        data: {
                            name: this.translateService.instant('app_popup.msg_28'),
                            message: data.msg
                        }
                    });
                },
                (error) => {
                    throw error;
                }
            );
        } else {
            this.errors.showSnackbar('msg_61', true);
        }
    }

    confirmDelete() {
        switch (this.data.type) {
            case 'archive_metering_devices':
                this.archiveMeteringDevices();
                break;
            case 'delete_metering_devices':
                this.deleteMeteringDevices();
                break;
            case 'archive_gateway':
                this.archiveGateway();
                break;
            case 'delete_gateway':
                this.deleteGateway();
                break;
            case 'delete_base_station':
                this.deleteBaseStation();
                break;
            case 'delete_event':
                this.deleteEvent();
                break;
            case 'delete_object_element':
                this.deleteElementObject();
                break;
            case 'delete_supplier_element':
                this.deleteSupplierElement(this.data?.params?._id);
                break;
            case 'delete_consumer_element':
                this.deleteConsumerElement(this.data?.params?._id);
                break;

            case 'delete_accounting_point_element':
                this.deleteAccountingPointElement(this.data?.params?._id);
                break;

            case 'delete_polls_group_element':
                this.deletePollsGroupElement(this.data?.params?.id);
                break;

            case 'delete_group_tasks_poll':
                this.deleteSurveyTasks({ device_task_ids: this.data.selectedDevices });
                break;

            case 'delete_all_survey_tasks_for_device':
                this.deleteSurveyTasks(this.data.device_id === null ? { delete_all: true } : { device_id: this.data.device_id });
                break;

            case 'delete_all_tasks_for_survey_group':
                this.deleteSurveyTasks({ poll_group_id: this.data.poll_group_id });
                break;

            case 'toggle_is_active_company':
                this.dialogRef.close({ result: true, isDevice: this.data.isDevice });
                break;

            case 'default':
                this.dialogRef.close({ result: true });
                break;
        }
    }

    deleteSurveyTasks(body) {
        if (this.deleteId == this.data.params.pin) {
            this.spinner = true;
            this.devicesService.cancelTask(body).subscribe(
                (result) => {
                    this.dialogRef.close({ result: true });
                    this.spinner = false;
                    this.dialog.open(PopupComponent, {
                        width: '400px',
                        data: {
                            name: this.translateService.instant('app_popup.msg_210')
                        }
                    });
                },
                (error) => {
                    throw error;
                }
            );
        } else {
            this.deleteId = null;
            this.errors.showSnackbar('msg_60', true);
        }
    }

    archiveMeteringDevices() {
        this.devicesService.archiveMeteringDevice(this.data.params).subscribe(
            (response: any) => {
                this.dialogRef.close({ result: true });
                this.dialog.open(PopupComponent, {
                    width: '400px',
                    data: {
                        name: this.translateService.instant('app_popup.msg_27'),
                        message: response.msg
                    }
                });
            },
            (error) => {
                throw error;
            }
        );
    }

    deleteMeteringDevices() {
        if (this.deleteId == this.data.params) {
            this.devicesService.deleteDeviceFromBasket(this.deleteId).subscribe(
                (response: any) => {
                    this.dialogRef.close({ result: true });
                    this.dialog.open(PopupComponent, {
                        width: '400px',
                        data: {
                            name: this.translateService.instant('app_popup.msg_26'),
                            message: response.msg
                        }
                    });
                },
                (error) => {
                    throw error;
                }
            );
        } else {
            this.errors.showSnackbar('msg_63', true);
        }
    }

    archiveGateway() {
        this.devicesService.deleteGateways(this.data.params).subscribe((response: any) => {
            this.dialogRef.close({ result: true });
            this.dialog.open(PopupComponent, {
                width: '400px',
                data: {
                    name: this.translateService.instant('app_popup.msg_25'),
                    message: response.msg
                }
            });
        });
    }

    deleteGateway() {
        if (this.deleteId == this.data.params) {
            this.devicesService.deleteGatewayFromBasket(this.data.params).subscribe(
                (response: any) => {
                    this.dialogRef.close({ result: true });
                    this.dialog.open(PopupComponent, {
                        width: '400px',
                        data: {
                            name: this.translateService.instant('app_popup.msg_24'),
                            message: response.msg
                        }
                    });
                },
                (error) => {
                    throw error;
                }
            );
        } else {
            this.errors.showSnackbar('msg_64', true);
        }
    }

    deleteEvent() {
        if (this.deleteId == this.data.params) {
            this._eventsService.deleteEvent(this.data.params).subscribe(
                (response: any) => {
                    this.dialogRef.close({ result: true });
                    this.dialog.open(PopupComponent, {
                        width: '400px',
                        data: {
                            name: this.translateService.instant('app_popup.msg_22'),
                            message: response.msg
                        }
                    });
                },
                (error) => {
                    throw error;
                }
            );
        } else {
            this.errors.showSnackbar('msg_66', true);
        }
    }

    deleteElementObject() {
        if (this.deleteId == this.data.params.pin) {
            this.objectsService.deleteObjectElement(this.data.params._id, this.deleteMeteringPoints).then(
                (result: any) => {
                    this.dialogRef.close({ result: true, response: result, node: this.data.params.node });
                    // this.errors.showSnackbar(result.data.msg);
                    /* this.dialog.open(PopupComponent, {
                    width: '400px',
                    data: {
                        name: 'Элемент успешно удалён',
                        message: result.msg
                    }
                }); */
                },
                (error) => {
                    throw error;
                }
            );
        } else {
            this.errors.showSnackbar('msg_68', true);
        }
    }

    closeConfirm() {
        this.dialogRef.close({ result: false });
    }

    close() {
        if (this.data.type === 'subscribe newsletter' && this.subscribeNewsletter) {
            this.dialogRef.close({ subscribeNewsletter: this.subscribeNewsletter });
        } else {
            this.dialogRef.close();
        }
    }

    typeof(value) {
        return typeof value;
    }

    onConfirmDelete() {
        if (this.deleteByConfirmForm.valid) {
            this.deleteId = +this.deleteByConfirmForm.get('deviceId').value;
            this.confirmDelete();
        }
    }

    private deleteSupplierElement(supplierId) {
        if (this.deleteId == this.data.params.pin) {
            this.suppliersService.deleteSupplier(supplierId).subscribe((result) => {
                this.errors.showSnackbar(result);
                this.dialogRef.close({ result: true });
            });
        } else {
            this.errors.showSnackbar('msg_60', true);
        }
    }

    private deleteConsumerElement(consumerId) {
        if (this.deleteId == this.data.params.pin) {
            this.consumersService.deleteConsumer(consumerId).subscribe((result) => {
                this.errors.showSnackbar(result);
                this.dialogRef.close({ result: true });
            });
        } else {
            this.errors.showSnackbar('msg_60', true);
        }
    }

    private deleteAccountingPointElement(_id: any) {
        if (this.deleteId == this.data.params.pin) {
            this.objectsService
                .deleteAccountingPoint(_id)
                .toPromise()
                .then((result) => {
                    this.errors.showSnackbar(result.msg);
                    this.dialogRef.close({ result: true });
                });
        } else {
            this.errors.showSnackbar('msg_60', true);
        }
    }

    private deletePollsGroupElement(id: any) {
        if (this.deleteId == this.data.params.pin) {
            this.pollsGroupService
                .deletePoll(id)
                .toPromise()
                .then((result) => {
                    this.errors.showSnackbar(result.msg);
                    this.dialogRef.close({ result: true });
                });
        } else {
            this.errors.showSnackbar('msg_60', true);
        }
    }

    private initForm() {
        this.deleteByConfirmForm = new UntypedFormGroup({
            deviceId: new UntypedFormControl(null, Validators.required)
        });
    }
}
