import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PassportService } from 'src/app/auth/passport.service';
import { Router } from '@angular/router';
import { PopupComponent } from '@shared/popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import { CloudService } from '@cloud/cloud.service';
import { TranslateLanguageService } from '@core/services/translate-language.service';
import { Subject } from 'rxjs';
import { APP_AUTH_LOGO_SRC } from '@core/constants/app.cloud.constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit, OnDestroy {
    @Input() admin = false;
    @Output() adminEvent = new EventEmitter();

    environment = environment;
    window = window;
    currentAuthForm = 'company';
    loginFormCompany = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        password: new UntypedFormControl(null, [Validators.required, Validators.minLength(1)]),
        captcha: new UntypedFormControl(null),
        captcha_key: new UntypedFormControl(null),
        one_t_p: new UntypedFormControl(null, [Validators.required])
    });
    personalData = false;
    personalCheck = false;
    withPersonalDesign = false;
    public language;
    captchaImg;
    one_t_p = false;

    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly auth: PassportService,
        private readonly _cloudService: CloudService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly translateLanguageService: TranslateLanguageService,
        private readonly translate: TranslateService
    ) {}

    ngOnInit() {
        this.withPersonalDesign = !!this._cloudService.personalDesign;
        this.auth.getSiteProperties().subscribe((res: any) => {
            environment.isKerberos = res.data.is_kerberos;
            if (
                (localStorage.getItem('access_token') !== 'null' &&
                    !_.isUndefined(localStorage.getItem('access_token')) &&
                    !_.isNull(localStorage.getItem('access_token'))) ||
                environment.isKerberos
            ) {
                if (this.admin) {
                    this.router.navigate(['admin']).catch((err) => {});
                } else {
                    this.dialog.open(PopupComponent, {
                        width: '300px',
                        data: { spinner: true }
                    });
                    this.router.navigate(['cloud']).catch((err) => {});
                }
            }
            this.language = this.translate.currentLang;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    demoLogin() {
        this.loginFormCompany.removeControl('captcha');
        this.loginFormCompany.removeControl('captcha_key');
        this.loginFormCompany.removeControl('one_t_p');
        if (this._cloudService.personalDesign?.demoUser && this._cloudService.personalDesign?.demoPassword) {
            this.loginFormCompany.setValue({
                email: this._cloudService.personalDesign?.demoUser,
                password: this._cloudService.personalDesign?.demoPassword
            });
        } else {
            this.loginFormCompany.setValue({
                email: 'demo@nekta.cloud',
                password: 'qwertyqwerty'
            });
        }
    }

    generateCaptcha() {
        this.auth.getCaptcha().subscribe((res: any) => {
            this.captchaImg = res.img;
            this.loginFormCompany.controls['captcha_key'].setValue(res.key);
        });
    }

    submit() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('app');
        localStorage.removeItem('phone');
        localStorage.removeItem('abonent_name');
        if (!this.admin) {
            this.dialog.open(PopupComponent, {
                width: '300px',
                data: { spinner: true }
            });
        }
        this.auth.login(this.loginFormCompany, this.personalCheck).then(
            (data: any) => {
                this._cloudService.loggedInSuccess$.next(true);
                this.auth.access_token = data.data.access_token;
                if (this.admin) {
                    this.adminEvent.emit();
                } else {
                    this.router.navigate(['cloud']).then();
                }
            },
            (error) => {
                const msg2FA = `Поле \'Одноразовый код 2FA\' обязательно для заполнения.`;
                const msgCaptcha = `Поле \'Идентификатор captcha\' обязательно для заполнения.`;
                setTimeout(() => {
                    this.dialog.closeAll();
                    if (error.error.error.hasOwnProperty('data')) {
                        if (error?.error?.error?.data?.attribute === 'one_t_p' && error?.error?.error?.data?.msg === msg2FA) {
                            this.one_t_p = true;
                            if (this.captchaImg) {
                                this.generateCaptcha();
                            }
                            return;
                        }
                        if (
                            error?.error?.error?.data?.attribute === 'captcha_key' ||
                            error?.error?.error?.data?.attribute === 'captcha' ||
                            this.captchaImg
                        ) {
                            this.generateCaptcha();
                            return;
                        }
                        this.dialog.open(PopupComponent, {
                            width: '400px',
                            data: {
                                type: 'error_popup',
                                name: this.translate.instant('loginFormCompany.messages.success'),
                                message: error.error.error.data.msg,
                                spinner: false
                            }
                        });
                        if (error.status === 449 && error.error.error.data.attribute === 'personal_data_access') {
                            this.personalData = true;
                        }
                    } else {
                        this.dialog.open(PopupComponent, {
                            width: '400px',
                            data: {
                                name: this.translate.instant('loginFormCompany.messages.success'),
                                message: error.error.error.msg,
                                spinner: false
                            }
                        });
                    }
                }, 500);
                if (
                    error?.error?.error?.data?.attribute !== 'captcha_key' &&
                    error?.error?.error?.data?.msg !== msgCaptcha &&
                    error?.error?.error?.data?.msg !== msg2FA
                ) {
                    throw error;
                }
            }
        );
    }

    switchLang(param) {
        this.translateLanguageService.updateCurrentLanguage(param, false);
        window.location.reload();
    }

    personalDataCheck(event) {
        this.personalCheck = !this.personalCheck;
    }

    getLogoSrc(): string {
        const design = this._cloudService.personalDesign;
        if (!design) {
            return APP_AUTH_LOGO_SRC;
        } else {
            return this.language === 'en'
                ? design?.logo_auth_en ?? design?.logo_auth ?? APP_AUTH_LOGO_SRC
                : design?.logo_auth ?? APP_AUTH_LOGO_SRC;
        }
    }
}
