import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ModelsActions = createActionGroup({
    source: 'DeviceModels',
    events: {
        loadCompanyMeteringDeviceModels: props<{ payload: any }>(),
        loadMeteringDeviceModels: props<{ payload: any }>(),
        loadGatewayModels: props<{ payload: any }>(),
        loadBaseStationModels: props<{ payload: any }>(),
        reset: emptyProps()
    }
});
