<div class="select-date">
    <form [formGroup]="dateForm" class="select-date__form">
        <mat-form-field class="select-date__form-field"
                        floatLabel="always"
                        appearance="outline">
            <mat-label>{{ 'devices.data.begin' | translate }}</mat-label>
            <input matInput
                   class="data-date"
                   [matDatepicker]="startDate"
                   [min]="getStartEarth()"
                   [max]="getPeriod('max')"
                   [formControlName]="'startDate'"
                   (dateChange)="dateChanged($event, 'startDate')"
                   placeholder="{{ 'devices.data.begin' | translate }}"/>
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="select-date__form-field"
                        floatLabel="always"
                        appearance="outline">
            <mat-label>{{ 'devices.data.end' | translate }}</mat-label>
            <input [max]="getPeriod('max')"
                   [min]="getPeriod('min')"
                   matInput
                   class="data-date"
                   [matDatepicker]="stopDate"
                   [formControlName]="'stopDate'"
                   (dateChange)="dateChanged($event, 'stopDate')"
                   placeholder="{{ 'devices.data.end' | translate }}"/>
            <mat-datepicker-toggle matSuffix [for]="stopDate"></mat-datepicker-toggle>
            <mat-datepicker #stopDate></mat-datepicker>
        </mat-form-field>
        <button class="select-date__form-button" id="button-date"
                mat-button
                mat-flat-button
                color="primary"
                (click)="changeDateMessages()">
            {{ 'devices.data.show' | translate }}
        </button>
    </form>
    <mat-form-field class="select-date__field"
                    floatLabel="always"
                    appearance="outline"
                    *ngIf="tab === 'power_profiles'">
        <mat-label>{{ 'devices.data.select_date.profile_period' | translate }}:</mat-label>
        <mat-select [(ngModel)]="profileType"
                    required
                    (selectionChange)="changePeriod(true)"
                    [(value)]="profileType">
            <mat-option value="30"> {{ 'devices.data.select_date.without_group' | translate }} </mat-option>
            <mat-option value="60"> {{ 'devices.data.select_date.hourly' | translate }} </mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="tab == 'journal_device'" style="display: flex; align-items: center">
        <mat-checkbox (change)="toggleSelectAll($event.checked)"
                      [checked]="journalDeviceAllSelect"
                      style="margin-top: -10px; margin-right: 5px;">
            <ng-container *ngIf="journalDeviceAllSelect; else allSelect">
                {{ 'devices.data.select_date.uncheck' | translate }}
            </ng-container>
            <ng-template #allSelect> {{ 'devices.data.select_date.check_all' | translate }}</ng-template>
        </mat-checkbox>
        <mat-form-field class="select-date__field"
                        floatLabel="always"
                        appearance="outline">

            <mat-label>{{ 'devices.data.select_date.journal_types' | translate }}:</mat-label>
            <mat-select panelClass="type-journal-panel"
                        multiple="true"
                        [(ngModel)]="typeJournal"
                        required
                        (selectionChange)="changeTypeJournal()"
                        [(value)]="typeJournal">
                <mat-option style="height: 3.5rem; line-height: 16px; white-space: unset;"
                            *ngFor="let journalType of getJournalMessagesTypes" [value]="journalType.id">
                    {{ (currentLanguage === 'en' && journalType.title_en) ? journalType.title_en: journalType.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-form-field class="select-date__field"
                    floatLabel="always"
                    appearance="outline"
                    *ngIf="msgTypes.length > 1 && tab == 'archives'">
        <mat-label>{{ 'devices.data.select_date.archive_type' | translate }}:</mat-label>
        <mat-select [(ngModel)]="msgType"
                    required
                    (selectionChange)="changePeriod(true)"
                    [(value)]="msgType">
            <mat-option *ngFor="let msgT of msgTypes" [value]="msgT">
                {{ 'map_msg_types.msg_'+ msgT | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="select-date__field"
                    floatLabel="always"
                    appearance="outline"
                    *ngIf=" tab !== 'power_profiles' &&
                tab !== 'archives' &&
                tab !== 'energy_profiles' &&
                tab !== 'power_profile' &&
                tab !== 'archives_system_1' &&
                tab !== 'archives_system_2' &&
                tab !== 'journal_device' &&
                messageGroups.length > 0">
        <mat-label>{{ 'devices.data.select_date.grouping' | translate }}</mat-label>
        <mat-select [(ngModel)]="msgGroup"
                    required
                    disableOptionCentering
                    (selectionChange)="changePeriod(true)"
                    [(value)]="msgGroup">
            <mat-option *ngFor="let groups of messageGroups" [value]="groups.id">
                <ng-container *ngIf="currentLanguage === 'en'; else titleRu">
                    {{ (groups.title_en) | titlecase}}
                </ng-container>
                <ng-template #titleRu>
                    {{ groups.title }}
                </ng-template>

            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
