import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PassportService implements OnDestroy {
    _baseURL: string;
    _baseURL_old: string;
    public headers = new HttpHeaders();
    token = null;
    fromAdmin = false;
    public twoFactorAuthentication = false;

    constructor(private _http: HttpClient) {
        this._baseURL = environment.baseURL;
        this._baseURL_old = environment.baseURL_old;
        if (this.token === null && localStorage.getItem('access_token') != null) {
            this.token = localStorage.getItem('access_token');
        }
    }

    get access_token() {
        return this.token;
    }

    set access_token(token) {
        this.token = token;
        localStorage.setItem('access_token', token);
    }

    /**
     * Авторизация пользователя
     * @param {FormGroup} user
     * @returns {Observable<Object>}
     */
    login(user: UntypedFormGroup, personalData) {
        const body = {
            email: user.controls['email'].value,
            password: user.controls['password'].value
        };
        if (personalData !== false) {
            body['personal_data_access'] = true;
        }
        if (user.controls?.['captcha']?.value) {
            body['captcha'] = user.controls['captcha'].value;
            body['captcha_key'] = user.controls['captcha_key'].value;
        }
        if (user.controls?.['one_t_p']?.value) {
            body['one_t_p'] = user.controls['one_t_p'].value;
        }
        return this._http.post(this._baseURL + 'auth/login', body).toPromise();
    }

    logout() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.access_token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this._http.get(this._baseURL + 'auth/logout', { headers: this.headers }).toPromise();
    }

    /**
     * Регистрация пользователя
     * @param {FormGroup} user
     * @returns {Observable<Object>}
     */
    signup(user: UntypedFormGroup, useRandomPassword) {
        const body = {
            name: user.controls['name'].value,
            email: user.controls['email'].value,
            company_type_id: 1,
            company_name: user.controls['company_name'].value,
            inn: user.controls['inn'].value,
            phone_number: user.value.phone_number,
            user_time_zone: (new Date().getTimezoneOffset() / 60) * -1
        };
        if (!useRandomPassword) {
            body['password'] = user.controls['password'].value;
            body['password_confirmation'] = user.controls['password_confirmation'].value;
        }
        return this._http.post(this._baseURL + 'auth/signup', body);
    }

    /**
     * Активация пользователя
     * @param {string} token
     * @returns {Observable<Object>}
     */
    activateUser(token: string) {
        return this._http.get(this._baseURL + 'auth/signup/activate/' + token);
    }

    /**
     * Форма забытого пароля
     * @param {FormGroup} user
     * @returns {Observable<Object>}
     */
    forget(user: UntypedFormGroup) {
        const body = {
            email: user.controls['email'].value
        };
        return this._http.post(this._baseURL + 'auth/password/create', body);
    }

    /**
     * Запрос e-mail по токену восстановлению
     * @param token
     * @returns {Observable<Object>}
     */
    checkForgetPass(token: string) {
        return this._http.get(this._baseURL + 'auth/password/find/' + token);
    }

    /**
     * Установка нового пароля при сбросе
     * @param {FormGroup} forgetForm
     * @param {string} email
     * @param {string} token
     * @returns {Observable<Object>}
     */
    setNewForgetPassword(forgetForm: UntypedFormGroup, email: string, token: string) {
        const body = {
            email: email,
            token: token,
            password: forgetForm.controls['password'].value,
            password_confirmation: forgetForm.controls['password_confirmation'].value
        };
        return this._http.post(this._baseURL + 'auth/password/reset', body);
    }

    getLicenseInfo() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this._http
            .get(this._baseURL + 'admin/license/info', {
                headers: this.headers
            })
            .pipe(
                map((data: any) => {
                    return data.data;
                })
            );
    }

    ngOnDestroy() {
        this.token = null;
        this.headers = null;
    }

    getSiteProperties() {
        return this._http.get(this._baseURL + 'site_properties');
    }

    getCaptcha() {
        return this._http.get(this._baseURL + 'captcha');
    }

    getData2FA() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this._http.get(this._baseURL + 'user/2FA/init', { headers: this.headers });
    }

    enable2FA(one_t_p, current_password) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this._http.post(this._baseURL + 'user/2FA/enable', { one_t_p, current_password }, { headers: this.headers });
    }

    disable2FA(one_t_p, current_password) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this._http.delete(this._baseURL + 'user/2FA/disable', {
            headers: this.headers,
            body: { one_t_p, current_password }
        });
    }
}
