import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CloudService, DateManipulations } from '@cloud/cloud.service';
import { ProfileService } from '@cloud/profile/profile.service';
import { PassportService } from '@auth/passport.service';
import { Store } from '@ngrx/store';
import { AccessActions } from '@store/access/access.actions';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { SnackbarService } from '@core/services/snackbar.service';
import { UsersApiService } from '@core/services/api/users-api.service';
import { CheckAccessService } from '@core/services/check-access.service';

// TODO: разобраться че тут происходит, раскидать по другим сервисам и компонентам логику

@Injectable({
    providedIn: 'root'
})
export class CloudGuard implements CanActivate {
    constructor(
        private usersApiService: UsersApiService,
        private _cloudService: CloudService,
        private _dateManipulations: DateManipulations,
        private _profileService: ProfileService,
        private checkAccessService: CheckAccessService,
        private router: Router,
        private auth: PassportService,
        private store: Store,
        private translate: TranslateService,
        private dialog: MatDialog,
        private snackbar: SnackbarService
    ) {}

    canActivate(): Promise<boolean> {
        return new Promise((resolve) => {
            if (location.hash.indexOf('token') !== -1) {
                let access_token, access_token_old;
                if (location.hash.indexOf('old_token') !== -1) {
                    const splitLocation = location.hash.split(',');
                    access_token = splitLocation[1].replace('token=', '');
                    access_token_old = splitLocation[2].replace('old_token', '');
                    this.auth.access_token = access_token;
                    localStorage.setItem('access_token', access_token);
                    localStorage.setItem('access_token_old', access_token_old);
                    this.auth.fromAdmin = true;
                    this._cloudService.enterFromAdminB2B = true;
                } else {
                    const splitLocation = location.hash.split('?');
                    access_token = splitLocation[1].replace('token=', '');
                    this.auth.access_token = access_token;
                    localStorage.setItem('access_token', access_token);
                    this.auth.fromAdmin = true;
                }
            }
            if ((localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'null') || environment.isKerberos) {
                const result = { access: [], user: [], current_company: [] };
                this.usersApiService.getAccess().subscribe(
                    (accessData) => {
                        result.access = accessData;
                        this.store.dispatch(AccessActions.load({ payload: accessData }));
                        this.checkAccessService.access = accessData;
                        const user = this.usersApiService.getUserInfo_users();
                        const current_company = this.usersApiService.getUserCompanyInfo();
                        forkJoin([user, current_company]).subscribe(
                            ([userData, companyData]) => {
                                if (this.auth.access_token === null && localStorage.getItem('access_token') != null) {
                                    this.auth.access_token = localStorage.getItem('access_token');
                                }
                                result.user = userData;
                                this.auth.twoFactorAuthentication = userData['2fa_enabled'];
                                result.current_company = companyData;
                                if (companyData.options?.only_api !== null && companyData.options?.only_api) {
                                    return this.removeAccessData(this.translate.instant('guards.msg_1'));
                                }
                                result['currentTimeZone'] = userData.current_time_zone;

                                this.checkAccessService.user = userData;
                                this._cloudService.settings = userData.settings ?? {};
                                this._cloudService.app = result;
                                // TODO: delete when services will be cleaned
                                this._profileService.app = result;
                                this._dateManipulations.app = result;

                                if (
                                    result?.['access']?.['access_group']?.['access_rules']?.['read']?.length ||
                                    (result['access']['access_group'] === null && result['access']['is_admin'] === true)
                                ) {
                                    resolve(true);
                                } else {
                                    this.router.navigate(['withoutAccess']);
                                    resolve(false);
                                }
                            },
                            () => {
                                this.router.navigate(['auth/logout']);
                                resolve(false);
                            }
                        );
                    },
                    (error) => {
                        resolve(false);
                        this.removeAccessData(error?.error?.error?.data?.msg);
                    }
                );
            } else {
                resolve(false);
                this.router.navigate(['auth/login']);
            }
        });
    }

    removeAccessData(msg?: string) {
        localStorage.removeItem('access_token');
        this.auth.access_token = null;
        this.router.navigate(['auth/login']);
        this.snackbar.open(msg ?? 'snackBarMessages.msg_52', !msg);
        this.dialog.closeAll();
    }
}
