import { Component } from '@angular/core';
import { PassportService } from '../passport.service';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-forget',
    templateUrl: './forget.component.html',
    styleUrls: ['./forget.component.css']
})
export class ForgetComponent {
    loading = false;
    forgetForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators.email])
    });

    constructor(
        private readonly auth: PassportService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly translate: TranslateService
    ) {}

    submit() {
        this.loading = true;
        this.auth.forget(this.forgetForm).subscribe(
            (data: any) => {
                this.dialog.open(PopupComponent, {
                    width: '400px',
                    data: { name: this.translate.instant('forgetForm.messages.success'), message: data.msg }
                });
                this.router.navigate(['auth/login']).then();
                this.loading = false;
            },
            (error) => {
                this.loading = false;
                if (error.status === 400 && error.error.error.data) {
                    throw error;
                } else {
                    throw error;
                }
            }
        );
    }
}
