import { EnvironmentConfig } from 'src/environments/env.model';

export const environment: EnvironmentConfig = {
    production: true,
    baseURL: 'https://core.nekta.cloud/api/',
    baseURL_old: 'https://preview.nekta.cloud/',
    microAbonentURL: 'https://core.nekta.cloud:3000',
    microAdminURL: 'https://nekta.cloud:3001',
    socketUrl: 'https://nekta.cloud:3003',
    b2bUrl: 'http://b2b.nekta.cloud/',
    yaMetrika: 57622081,
    reportGeneratorApi: 'https://core.nekta.cloud:3000/',
    buildType: 'cloud',
    errorLogger: true,
    isKerberos: false
};
