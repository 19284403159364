import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {SharedActions} from '../actions/shared.actions';
import {ObjectsService} from '../../cloud/objects/objects.service';

@Injectable()
export class SharedEffects {
    fetchAccountingPoints$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedActions.FetchAccountingPoints),
            exhaustMap((action: any) =>
                this.objectsService.getAllAccountingPointList(null,
                    null,
                    null,
                    null,
                    null,
                    false,
                    null,
                    null,
                    null,
                    null,
                    action?.payload).pipe(
                    map((points) => ({
                        type: SharedActions.FetchAccountingPointsSuccess,
                        payload: points
                    })),
                    catchError((e) => {
                        throw e;
                    })
                )
            )
        )
    );
    fetchStructureTreeObjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedActions.FetchStructureTreeObjects),
            exhaustMap(() =>
                this.objectsService.getBuildStructureTree().pipe(
                    map((objects) => ({
                        type: SharedActions.FetchStructureTreeObjectsSuccess,
                        payload: objects
                    })),
                    catchError((e) => {
                        this.objectsService.treeSpinner = false;
                        throw e;
                    })
                )
            )
        )
    );
    fetchStructureTreeBalanceGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedActions.FetchStructureTreeBalanceGroups),
            exhaustMap(() =>
                this.objectsService.getBuildStructureTree().pipe(
                    map((objects) => ({
                        type: SharedActions.FetchStructureTreeBalanceGroupsSuccess,
                        payload: objects
                    })),
                    catchError((e) => {
                        this.objectsService.treeSpinner = false;
                        this.objectsService.firstLoadingTreeSpinner = false;
                        throw e;
                    })
                )
            )
        )
    );
    fetchStructureTreeInfrastructureReports$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedActions.FetchStructureTreeInfrastructureReports),
            exhaustMap(() =>
                this.objectsService.getBuildStructureTree().pipe(
                    map((objects) => ({
                        type: SharedActions.FetchStructureTreeInfrastructureReportsSuccess,
                        payload: objects
                    })),
                    catchError((e) => {
                        this.objectsService.treeSpinner = false;
                        this.objectsService.firstLoadingTreeSpinner = false;
                        throw e;
                    })
                )
            )
        )
    );
    fetchStructureTreeNewReportAccountingPoints = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedActions.FetchStructureTreeNewReportAccountingPoints),
            exhaustMap(() => {
                    return this.objectsService.getBuildStructureTree(null, false).pipe(
                        map((objects) => ({
                            type: SharedActions.FetchStructureTreeNewReportAccountingPointsSuccess,
                            payload: objects
                        })),
                        catchError((e) => {
                            this.objectsService.treeSpinner = false;
                            this.objectsService.firstLoadingTreeSpinner = false;
                            throw e;
                        })
                    );
                }
            )
        )
    );

    constructor(private actions$: Actions, private objectsService: ObjectsService) {
    }
}
