import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

// TODO: перенести на верхний уровень в AppComponent при необходимости в конце рефакторинга

/**
 * @description сервис расширяет встроенный сервис Angular Material: MatPaginatorIntl.
 * добавляет перевод для компонента пагинатор в таблицах
 * добавлять в провайдер модуля
 */

@Injectable()
export class MatPaginatorIntlService extends MatPaginatorIntl {
    public constructor(private translateService: TranslateService) {
        super();

        this.translateService.onLangChange.subscribe(() => {
            this.getAndInitTranslations();
        });

        this.getAndInitTranslations();
    }

    public getRangeLabel = (page: number, pageSize: number, length: number) => {
        const of = this.translateService ? this.translateService.instant('paginator.of') : 'of';
        if (page * pageSize + pageSize > length) {
            return page * pageSize + 1 + ' - ' + length + ` ${of} ` + length;
        } else {
            return page * pageSize + 1 + ' - ' + (page * pageSize + pageSize) + ` ${of} ` + length;
        }
    }

    public getAndInitTranslations(): void {
        this.translateService
            .get([
                'paginator.of',
                'paginator.items_per_page',
                'paginator.next_page',
                'paginator.previous_page',
                'paginator.first_page',
                'paginator.last_page'
            ])
            .subscribe((data: any) => {
                this.itemsPerPageLabel = data['paginator.items_per_page'];
                this.nextPageLabel = data['paginator.next_page'];
                this.previousPageLabel = data['paginator.previous_page'];
                this.firstPageLabel = data['paginator.first_page'];
                this.lastPageLabel = data['paginator.last_page'];

                this.changes.next();
            });
    }
}
