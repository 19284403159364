import {SharedActions, SharedUnion} from '../actions/shared.actions';
import {cloneDeep} from 'lodash';

export interface SharedState {
    accountingPoints: any;
    structureTreeMap: any;
    structureTreeObjects: any;
    structureTreeBalanceGroups: any;
    structureTreeInfrastructureReports: any;
    structureTreeNewReportAccountingPoints: any;
}

export const initialState: SharedState = {
    accountingPoints: [],
    structureTreeMap: null,
    structureTreeObjects: null,
    structureTreeBalanceGroups: null,
    structureTreeInfrastructureReports: null,
    structureTreeNewReportAccountingPoints: null,
};
const addChildsCount: (state, element, changeParent?) => any = (state, element, changeParent = null) => {
    const index = element.parentId ? element.parentId : '0';
    const newState = {
        ...state,
        structureTreeObjects: {
            ...state.structureTreeObjects,
            [index]: state.structureTreeObjects[index].map((el) => el._id === element.node.parentId ?
                {
                    ...el,
                    childs_count: el.childs_count === 0 || el.childs_count === undefined ? 1 : el.childs_count + 1
                } : el),
        }
    };
    return changeParent ? reduceChildsCount(newState, element, true) : newState;
};
const reduceChildsCount: (state, element, changeParent?) => any = (state, element, changeParent = false) => {
    const index = element.parentId ? element.parentId : '0';
    return {
        ...state,
        structureTreeObjects: {
            ...state.structureTreeObjects,
            [index]: state.structureTreeObjects[index].map((el) => el._id === (changeParent ? element.key : element.node.parentId) ?
                {...el, childs_count: el.childs_count === 0 ? 0 : el.childs_count - 1} : el)
        }
    };
};

const modifyTree: (state, element) => any = (state, element) => {
    const index = element.node.parentId ? element.node.parentId : '0';
    const newState = {
        ...state,
        structureTreeObjects: {
            ...state.structureTreeObjects,
            [index]: [...(state.structureTreeObjects[index] || []), element.node]
        }
    };
    return addChildsCount(newState, element);
};
const cacheTree: (state, data, tree) => any = (state, data, tree) => {
    const index = data.item_id ? data.item_id : '0';
    return {
        ...state,
        [tree]: {
            ...state[tree],
            [index]: data.res
        }
    };
};
const editElementInObjectsTree: (state, data) => any = (state, data) => {
    const index = data.node.parentId && !state.structureTreeObjects?.[0]?.find(el => el._id === data.node._id) ? data.node.parentId : '0';
    const element = state.structureTreeObjects[data.key].find((el) => el._id === data.node._id);
    const newState = {
        ...state,
        structureTreeObjects: {
            ...state.structureTreeObjects,
            [data.key]: state.structureTreeObjects[data.key].filter((el) => el._id !== data.node._id),
            [index]: [...(state.structureTreeObjects[index] || []), {
                ...data.node,
                devices_info: element?.devices_info,
                childs_devices_info: element?.childs_devices_info,
                childs_count: element.childs_count
            }]
        }
    };
    return index === data.key ? {
        ...state,
        structureTreeObjects: {
            ...state.structureTreeObjects,
            [index]: state.structureTreeObjects[index].map((el) => el._id === data.node._id ? {
                ...data.node,
                devices_info: el?.devices_info,
                childs_devices_info: el?.childs_devices_info,
                childs_count: el.childs_count
            } : el)
        }
    } : addChildsCount(newState, data, true);
};
const deleteElementInObjectsTree: (state, data) => any = (state, data) => {
    const index = data.node.parentId ? data.node.parentId : '0';
    const newState = {
        ...state,
        structureTreeObjects: {
            ...state.structureTreeObjects,
            [index]: state.structureTreeObjects[index].filter((el) => el._id !== data.node._id)
        }
    };
    return reduceChildsCount(newState, data);
};
const sortStructureTreeObjects: (state) => any = (state) => {
    const newState = cloneDeep(state);
    const sortOrder = state.sortOrder || 'asc';
    for (const [key, value] of Object.entries(newState.structureTreeObjects)) {
        if (Array.isArray(value)) {
            newState.structureTreeObjects[key] = value.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (sortOrder === 'asc') {
                    return nameA.localeCompare(nameB);
                } else {
                    return nameB.localeCompare(nameA);
                }
            });
        }
    }
    newState.sortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    return newState;
};

export function reducer(state = initialState, action: SharedUnion): SharedState {
    switch (action.type) {
        case SharedActions.FetchAccountingPointsSuccess: {
            return {
                ...state,
                accountingPoints: action.payload
            };
        }
        case SharedActions.FetchStructureTreeObjectsSuccess: {
            return {
                ...state,
                structureTreeObjects: state.structureTreeObjects === null ? {0: action.payload} : {...state.structureTreeObjects}
            };
        }
        case SharedActions.FetchStructureTreeBalanceGroupsSuccess: {
            return {
                ...state,
                structureTreeBalanceGroups: state.structureTreeBalanceGroups === null ? {0: action.payload} : {...state.structureTreeBalanceGroups}
            };
        }
        case SharedActions.FetchStructureTreeInfrastructureReportsSuccess: {
            return {
                ...state,
                structureTreeInfrastructureReports: state.structureTreeInfrastructureReports === null ? {0: action.payload} : {...state.structureTreeInfrastructureReports}
            };
        }
        case SharedActions.FetchStructureTreeNewReportAccountingPointsSuccess: {
            return {
                ...state,
                structureTreeNewReportAccountingPoints: state.structureTreeNewReportAccountingPoints === null ? {0: action.payload} : {...state.structureTreeNewReportAccountingPoints}
            };
        }
        case SharedActions.CleanStructureTree: {
            return {
                ...state,
                structureTreeObjects: null,
                structureTreeBalanceGroups: null,
                structureTreeInfrastructureReports: null
            };
        }
        case SharedActions.CleanStructureTreeNewReportAccountingPoints: {
            return {
                ...state,
                structureTreeNewReportAccountingPoints: null
            };
        }
        case SharedActions.CleanStructureTreeBalanceGroups: {
            return {
                ...state,
                structureTreeBalanceGroups: null
            };
        }
        case SharedActions.AddElementToStructureTree: {
            return modifyTree(state, action.payload);
        }
        case SharedActions.CacheElementToTreeObjects: {
            return cacheTree(state, action.payload, 'structureTreeObjects');
        }
        case SharedActions.CacheElementToTreeBalanceGroups: {
            return cacheTree(state, action.payload, 'structureTreeBalanceGroups');
        }
        case SharedActions.CacheElementToTreeInfrastructureReports: {
            return cacheTree(state, action.payload, 'structureTreeInfrastructureReports');
        }
        case SharedActions.CacheElementToTreeNewReportAccountingPoints: {
            return cacheTree(state, action.payload, 'structureTreeNewReportAccountingPoints');
        }
        case SharedActions.EditElementInTreeObjects: {
            return editElementInObjectsTree(state, action.payload);
        }
        case SharedActions.DeleteElementInTreeObjects: {
            return deleteElementInObjectsTree(state, action.payload);
        }
        case SharedActions.SortStructureTreeObjects: {
            return sortStructureTreeObjects(state);
        }
        default: {
            return state;
        }
    }
}
