import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CloudComponent} from 'src/app/cloud/cloud.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import { fromEvent, Observable, ReplaySubject, Subject } from 'rxjs';
import {DevicesService} from '../../../../services/devices.service';
import * as moment from 'moment';
import 'moment-timezone';
import * as echarts from 'echarts';
import {CheckAccess, CloudService, DateManipulations} from 'src/app/cloud/cloud.service';
import {MeteringDevicesDataComponent} from '../data.component';
import 'zrender/lib/svg/svg';
import * as _ from 'lodash';
import {ErrorsService} from '@core/services/errors.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {MatCheckboxChange} from '@angular/material/checkbox';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import {ConfirmPopupComponent} from '../../../../components/confirm-popup/confirm-popup.component';
import {MatDialog} from '@angular/material/dialog';
import {
    PowerProfileMinMaxModalComponent
} from '../../../../components/power-profile-min-max-modal/power-profile-min-max-modal.component';
import {CompanySettingsService} from '../../../../../company-settings/company-settings.service';
import {
    MaximumPowerRedundancyComponent
} from '../../../../components/maximum-power-redundancy/maximum-power-redundancy.component';
import {TranslateLanguageService} from '../../../../../../core/services/translate-language.service';
import {select, Store} from '@ngrx/store';
import {selectDeviceFieldTitlesState} from '../../../../../../store/selectors/selectors';

@Component({
    selector: 'metering-devices-data-power-profile',
    templateUrl: './power_profile.component.html',
    styleUrls: ['./power_profile.component.less']
})
export class MeteringDevicesPowerProfileComponent implements OnInit, OnDestroy, OnChanges {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    withKt: boolean = false;
    withLoss: boolean = false;
    RAMode: boolean = false;
    peakHours: boolean = false;
    app;
    max_ra = null;
    loadApp = this._cloudService.appConst.pipe(takeUntil(this.destroyed$)).subscribe((app: any) => {
        this.app = app;
    });

    private mapDeviceGroup = {
        1: 1,
        2: 1,
        3: 4,
        4: 3,
        5: 2
    };

    @Input() device: any;
    @Input() dateForm: any;
    @Input() isEnergy = false;
    @Input() msgGroup: any;
    @Input() profileType: any;
    @Input() msgType?: number;
    @Input() accountingPoint: any;
    isSpinnerVisible = true;
    chart;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource = new MatTableDataSource();

    displayedColumns: string[];
    availableColumns: string[];
    peakHoursList;
    fieldsTitles: any[] = [];
    settings;
    messages;
    showChart = true;
    public dataType = 'device';
    energy: boolean = false;
    lang: string;
    resizeObservable$: Observable<Event>;

    constructor(
        private store: Store<{}>,
        private translate: TranslateService,
        public cloud: CloudComponent,
        private companySettingsService: CompanySettingsService,
        public dialog: MatDialog,
        private devicesService: DevicesService,
        private _cloudService: CloudService,
        private _dataComponent: MeteringDevicesDataComponent,
        public _checkAccess: CheckAccess,
        private errors: ErrorsService,
        private translateLanguageService: TranslateLanguageService,
        public dateManipulations: DateManipulations
    ) {
        CloudService.setLanguage(translate);
    }

    ngOnInit() {
        this._cloudService.settings.subscribe((settings) => (this.settings = settings));
        this.devicesService.refreshData.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.refreshData();
        });

        this.companySettingsService.getDefaultPeakHours()
            .subscribe((data) => {
                const appCompany = JSON.parse(localStorage.getItem('app'));
                const peakHoursZone = appCompany?.current_company?.options?.peak_hours_zone;
                const peakHours = appCompany?.current_company?.options?.peak_hours;
                if (peakHoursZone && (peakHoursZone === 'price_0' || peakHoursZone === 'price_1')) {
                    this.peakHoursList = data[appCompany.current_company.options.peak_hours_zone];
                    return;
                }
                if (peakHours && peakHoursZone === 'manual') {
                    this.peakHoursList = peakHours;
                }
            });

        this.translateLanguageService.currentLanguage$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((lang) => {
                this.lang = lang;
            });
        this.resizeObservable$ = fromEvent(window, 'resize');

        this.resizeObservable$.pipe(takeUntil(this.destroyed$), debounceTime(500))
            .subscribe(() => this.refreshData());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (+changes?.profileType?.currentValue === 30) {
            this.profileType = 30;
        } else if (+changes?.profileType?.currentValue === 60) {
            this.profileType = 60;
        }

    }

    refreshData() {
        if (this._dataComponent.currentTab == 'power_profiles' || this._dataComponent.currentTab == 'energy_profiles') {
            this.isSpinnerVisible = true;
            if (this.device !== undefined && this.device !== null) {
                this.dataType = 'device';
                setTimeout((_) => {
                    this.store
                        .pipe(select(selectDeviceFieldTitlesState)).subscribe((res) => {
                        res.filter((el: any) => el.id === this.device.device_fields_titles.find((elem) => elem.id === el.id)?.id).
                        forEach((field: any) => this.fieldsTitles[field.name] = field); });
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                    this.getMessages(this.withKt, 'device', this.withLoss)
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe(() => {
                            setTimeout(() => {
                                this.isSpinnerVisible = false;
                            }, 500);
                        });
                }, 500);
            } else if (this.accountingPoint?.tabs !== undefined && this.accountingPoint?.tabs !== null) {
                this.dataType = 'accounting_point';
                this.withKt = true;
                this.withLoss = true;
                this.devicesService.getDeviceFieldTitles().subscribe((result: any) => {
                    const filteredFields = result.data.fields.filter(
                        (field) =>
                            (field.device_group_id ===
                                this.mapDeviceGroup[this.accountingPoint.currentPoint?.resource_type_id] ||
                                field.device_group_id === null) &&
                            field.protocol_id === null &&
                            field.device_type_id === null &&
                            field.device_id === null
                    );
                    filteredFields.forEach((field) => {
                        this.fieldsTitles[field.name] = field;
                    });
                    this.dataSource = new MatTableDataSource();
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                    this.getMessages(this.withKt, 'accounting_point', this.withLoss)
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe(() => {
                            setTimeout(() => {
                                this.isSpinnerVisible = false;
                            }, 500);
                        });
                });
            }
        }
    }

    getMessages(withKkt, type, withLoss) {
        this.showChart = true;
        return new Observable<any>((observer) => {
            if (this.chart !== undefined) {
                this.chart.dispose();
            }
            let id;
            if (type === 'device') {
                id = this.device.id;
            } else if (type === 'accounting_point') {
                id = this.accountingPoint.id;
            }
            //is_energy

            let apiCall$ = this.isEnergy
                ?
                this.devicesService
                    .getEnergyMessages(
                        id,
                        10,
                        this.devicesService.dateForm.startDate,
                        this.devicesService.dateForm.stopDate,
                        0,
                        10000,
                        'energy_profiles',
                        this.energy,
                        Number(this.profileType),
                        type,
                        withKkt,
                        withLoss,
                    )
                : this.devicesService
                    .getMessages(
                        id,
                        5,
                        this.devicesService.dateForm.startDate,
                        this.devicesService.dateForm.stopDate,
                        0,
                        10000,
                        'power_profiles',
                        this.energy,
                        Number(this.profileType),
                        type,
                        withKkt,
                        withLoss,
                    )

                apiCall$
                .pipe(takeUntil(this.destroyed$))
                .subscribe(
                    (messages) => {
                        if (messages.data.length == 0 || messages.fields_headers.length == 0) {
                            this.dataSource.data = [];
                            setTimeout(() => {
                                this.isSpinnerVisible = false;
                            }, 500);
                            return;
                        }
                        this.dataSource.data = messages.data;
                        if (messages.hasOwnProperty('max_r/a') && messages['max_r/a'] !== null) {
                            switch (messages['max_r/a']) {
                                case 1:
                                    this.max_ra = 50;
                                    break;
                                case 4:
                                    this.max_ra = 35;
                                    break;
                                default:
                                    this.max_ra = 40;
                                    break;
                            }
                        }
                        this.availableColumns = this.sortFields(messages.fields_headers);
                        let filteredColumns = this.availableColumns.filter((column) => {
                            return (
                                column.search(/start_/i) === -1 &&
                                column.search(/end_/i) === -1 &&
                                column.search(/transformation_ratio/i) === -1 &&
                                column.search(/realdatetime/i) === -1
                            );
                        });
                        if (type === 'device') {
                            this.displayedColumns = this.sortFields(
                                _.get(
                                    this.settings,
                                    'deviceFields.model_id_' + this.device?.model?.id + '.power_profile',
                                    _.clone(filteredColumns)
                                ),
                                this.availableColumns
                            );
                            this.displayedColumns = this.displayedColumns.filter(column => {
                                return column.indexOf('delete_message') === -1;
                            });
                            this.displayedColumns.push('delete_message');
                        } else if (type === 'accounting_point') {
                            this.displayedColumns = this.sortFields(_.clone(filteredColumns), this.availableColumns);
                        }

                        _.pull(this.displayedColumns, 'transformation_ratio');
                        _.pull(this.availableColumns, 'transformation_ratio');
                        if (
                            this.device?.model?.tabs !== null &&
                            !_.isUndefined(this.device?.model?.tabs['power_profiles']) &&
                            !_.isUndefined(this.device?.model?.tabs['power_profiles']['chart_fields']) &&
                            this.device?.model?.tabs['power_profiles']['chart_fields'][0] !== null
                        ) {
                            this.messages = messages;
                            this.prepareMessagesForChart(
                                messages.fields_headers,
                                messages.data,
                                this.device?.model?.tabs['power_profiles']['chart_fields']
                            ).then((chartData: any) => {
                                setTimeout((_) => {
                                    if (chartData.inputs.length > 0 && chartData.labels.length) {
                                        this.renderChart(chartData, 'deviceChartPowerProfile');
                                    } else {
                                        this.showChart = false;
                                    }
                                }, 1000);
                            });
                        } else if (
                            this.accountingPoint !== undefined &&
                            this.accountingPoint?.tabs !== null &&
                            !_.isUndefined(this.accountingPoint?.tabs['power_profiles']) &&
                            !_.isUndefined(this.accountingPoint?.tabs['power_profiles']['chart_fields']) &&
                            this.accountingPoint?.tabs['power_profiles']['chart_fields'][0] !== null
                        ) {
                            this.messages = messages;
                            this.prepareMessagesForChart(
                                messages.fields_headers,
                                messages.data,
                                this.accountingPoint?.tabs['power_profiles']['chart_fields']
                            ).then((chartData: any) => {
                                setTimeout((_) => {
                                    if (chartData.inputs.length > 0 && chartData.labels.length) {
                                        this.renderChart(chartData, 'deviceChartPowerProfile');
                                    } else {
                                        this.showChart = false;
                                    }
                                }, 1000);
                            });
                        } else {
                            this.showChart = false;
                        }
                        setTimeout((_) => {
                            observer.next(null);
                            observer.complete();
                        }, 500);
                    },
                    (error) => {
                        throw error;
                    }
                );
        });
    }

    changeFieldVisible(field) {
        if (this.displayedColumns.indexOf(field) === -1) {
            this.displayedColumns.push(field);
        } else {
            _.pull(this.displayedColumns, field);
        }
        this.displayedColumns = this.sortFields(this.displayedColumns, this.availableColumns);
        _.set(
            this.settings,
            'deviceFields.model_id_' + this.device?.model?.id + '.power_profile',
            this.displayedColumns
        );
        this._cloudService.settings = this.settings;
    }

    sortFields(columns, mainArr = null) {
        if (_.isNull(mainArr)) {
            mainArr = [
                'datetime',
                'in1',
                'start_in1',
                'end_in1',
                'delta_in1',
                'in2',
                'start_in2',
                'end_in2',
                'delta_in2',
                'in3',
                'start_in3',
                'end_in3',
                'delta_in3',
                'in4',
                'start_in4',
                'end_in4',
                'delta_in4',
                'tariff1',
                'start_tariff1',
                'end_tariff1',
                'delta_tariff1',
                'tariff2',
                'start_tariff2',
                'end_tariff2',
                'delta_tariff2',
                'tariff3',
                'start_tariff3',
                'end_tariff3',
                'delta_tariff3',
                'tariff4',
                'start_tariff4',
                'end_tariff4',
                'delta_tariff4',
                'tariff5',
                'start_tariff5',
                'end_tariff5',
                'delta_tariff5'
            ];
        }
        const result = mainArr.filter(function (field) {
            return columns.indexOf(field) !== -1;
        });
        return result.concat(columns.filter((el) => !result.includes(el)));
    }

    getFormatedDate(value) {
        return moment.unix(this.dateManipulations.dateLocal(value)).utc().format('DD.MM.YYYY HH:mm');
    }

    typeof(value) {
        return typeof value;
    }

    prepareMessagesForChart(inputs, messages, chartFields?): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const data = {series: [], labels: [], inputs: []};
            let chartType = _.get(this.settings, 'chartType.model_id_' + this.device?.model?.id, 'line');
            if (this.RAMode && !this.isEnergy) {
                inputs = ['r/a+', 'r/a-'];
                chartFields = [...chartFields, ...inputs];
            }
            for (const input of inputs) {
                let index;
                const field = this.fieldsTitles[input] ? this.fieldsTitles[input] : input;
                let name = (this.lang === 'en' && field.title_en) ? field.title_en : field.title ? field.title : field;
                if (this.lang === 'en') {
                    name += !_.isNull(_.get(this.fieldsTitles[input], 'unit.title_en', null))
                        ? ' ,' + this.fieldsTitles[input].unit.title_en
                        : '';
                } else {
                    name += !_.isNull(_.get(this.fieldsTitles[input], 'unit.title', null))
                        ? ' ,' + this.fieldsTitles[input].unit.title
                        : '';
                }

                if (chartFields !== undefined &&
                    _.isArray(chartFields) &&
                    chartFields.length > 0 &&
                    chartFields.indexOf(input) !== -1 &&
                    data.inputs.indexOf(input) === -1
                ) {
                    data.inputs.push(name);
                    index = data.series.push({
                        name: name,
                        data: [],
                        step: 'start',
                        type: chartType,
                        markPoint: {
                            data: [
                                {type: 'max', name: this.translate.instant('devices.data_show.max')},
                                {type: 'min', name: this.translate.instant('devices.data_show.min')}
                            ],
                            symbolSize: [100, 20],
                            symbol: 'rect',
                            symbolRotate: 'arrow'
                        },
                        markLine: this.RAMode && this.max_ra ? {
                            silent: true,
                            lineStyle: {
                                color: '#333'
                            },
                            data: [
                                {
                                    yAxis: this.max_ra
                                },
                            ]
                        } : null
                    });
                    for (const message of messages) {
                        if (input.search(/date/i) === -1) {
                            data.series[index - 1].data.push(parseFloat(message[input]).toFixed(3));
                        }
                    }
                }
            }
            this.generateLabels(messages).then(
                (labels: any) => {
                    data.labels = this.unique(labels);
                    if (this.peakHours) {
                        data.labels = data.labels.filter((el) => {
                            let duration;
                            if (this.profileType === 30) {
                                duration = moment.duration({hours: 0, minutes: 30});
                            } else {
                                duration = moment.duration({hours: 0, minutes: 0});
                            }
                            const dateItemStr = moment(el, 'DD.MM.YYYY HH:mm:ss').subtract(duration).format('DD.MM.YYYY HH:mm:ss');
                            const dateItemObj = moment(dateItemStr, 'DD.MM.YYYY HH:mm:ss');
                            const itemMonth = dateItemObj.month();
                            const itemHour = dateItemObj.hour();
                            const day = dateItemObj.day();
                            const isWeekend = day === 0 || day === 6;
                            const firstInterval = this.peakHoursList[itemMonth][0];
                            const secondInterval = this.peakHoursList[itemMonth][1];

                            if (this.profileType === 30) {
                                if (!isWeekend && firstInterval.length && secondInterval.length) {
                                    if (itemHour + 1 >= firstInterval[0] && itemHour < firstInterval[1] ||
                                        itemHour + 1 >= secondInterval[0] && itemHour < secondInterval[1]) {
                                        return el;
                                    }
                                } else {
                                    if (!isWeekend && itemHour + 1 >= firstInterval[0] && itemHour < firstInterval[1]) {
                                        return el;
                                    }
                                }
                            } else {
                                if (!isWeekend && firstInterval.length && secondInterval.length) {
                                    if ((itemHour >= firstInterval[0] && itemHour <= firstInterval[1]) ||
                                        (itemHour >= secondInterval[0] && itemHour <= secondInterval[1])) {
                                        return el;
                                    }
                                } else {
                                    if (!isWeekend && itemHour >= firstInterval[0] && itemHour <= firstInterval[1]) {
                                        return el;
                                    }
                                }
                            }
                        });
                        this.dataSource.data = this.dataSource.data.filter(el => {
                            const date = moment
                                .unix(this.dateManipulations.dateLocal(el['datetime']))
                                .utc()
                                .format('DD.MM.YYYY HH:mm:ss');
                            if (data.labels.includes(date)) {
                                return el;
                            }

                        });
                    }
                    resolve(data);
                },
                (error) => {
                    reject(error);
                    throw error;
                }
            );
        });
    }

    async generateLabels(messages) {
        return new Promise((resolve) => {
            const labels = [];
            for (const message of messages) {
                labels.push(
                    moment
                        .unix(this.dateManipulations.dateLocal(message['datetime']))
                        .utc()
                        .format('DD.MM.YYYY HH:mm:ss')
                );
            }
            resolve(labels);
        });
    }

    unique(arr) {
        const obj = {};
        for (let i = 0; i < arr.length; i++) {
            const str = arr[i];
            obj[str] = true;
        }
        return Object.keys(obj);
    }

    renderChart(chartData, id) {
        const dom = document.getElementById(id);
        this.chart = echarts.init(dom, {}, {renderer: 'canvas', height: 400, width: window.innerWidth - 50});
        let selected = _.get(this.settings, 'chartSelectedSeries.model_id_' + this.device?.model?.id, null);
        let end = 100;
        if (chartData.labels > 5000) {
            end = 30;
        } else {
            end = 100;
        }
        const option = {
            toolbox: {
                top: '10%',
                right: '2%',
                show: true,
                feature: {
                    magicType: {
                        type: ['line', 'bar'],
                        title: {
                            line: this.translate.instant('devices.data_show.msg_9'),
                            bar: this.translate.instant('devices.data_show.msg_10')
                        }
                    },
                    saveAsImage: {
                        type: 'jpeg',
                        title: this.translate.instant('devices.data_show.msg_8'),
                        show: true,
                        name: this.getDeviceName()
                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                confine: true
            },
            legend: {
                data: chartData.inputs,
                type: 'scroll',
                selected: _.isNull(selected) ? chartData.inputs : selected
            },
            grid: {
                left: '2%',
                right: '5%',
                bottom: '12%',
                containLabel: true
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: end
                },
                {
                    start: 0,
                    end: 100,
                    handleIcon:
                        'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                }
            ],
            xAxis: {
                type: 'category',
                data: chartData.labels
            },
            yAxis: {
                type: 'value'
            },
            series: chartData.series
        };
        this.chart.setOption(option, true);
        if (this.dataType === 'device') {
            this.chart.on('legendselectchanged', (params) => {
                _.set(this.settings, 'chartSelectedSeries.model_id_' + this.device.model.id, params.selected);
                this._cloudService.settings = this.settings;
            });
            this.chart.on('magictypechanged', (params) => {
                _.set(this.settings, 'chartType.model_id_' + this.device.model.id, params.currentType);
                this._cloudService.settings = this.settings;
            });
        }
    }

    getDeviceName() {
        const msg = (this.lang === 'en' ? 'for device ' : 'по устройству ');
        const deviceName = this.device !== undefined && this.device !== null ? msg + this.device.name : '';
        return this.translate.instant('devices.data_show.msg_7', {
            deviceName
        });
    }

    changeWithKktOrLoss($event: MatCheckboxChange, type) {
        this.isSpinnerVisible = true;
        if (type === 'loss') {
            this.withLoss = $event.checked;
        } else if (type === 'kt') {
            this.withKt = $event.checked;
        }
        this.getMessages(this.withKt, this.dataType, this.withLoss)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                setTimeout(() => {
                    this.isSpinnerVisible = false;
                }, 500);
            });
    }

    changeRAMode(event) {
        this.isSpinnerVisible = true;
        this.RAMode = event.checked;

        if (
            this.device?.model?.tabs !== null &&
            !_.isUndefined(this.device?.model?.tabs['power_profiles']) &&
            !_.isUndefined(this.device?.model?.tabs['power_profiles']['chart_fields']) &&
            this.device?.model?.tabs['power_profiles']['chart_fields'][0] !== null
        ) {
            this.prepareMessagesForChart(
                this.messages.fields_headers,
                this.messages.data,
                this.device?.model?.tabs['power_profiles']['chart_fields']
            ).then((chartData: any) => {
                setTimeout((_) => {
                    if (chartData.inputs.length > 0 && chartData.labels.length) {
                        this.renderChart(chartData, 'deviceChartPowerProfile');
                        this.isSpinnerVisible = false;
                    } else {
                        this.showChart = false;
                    }
                }, 1000);
            });
        } else if (
            this.accountingPoint !== undefined &&
            this.accountingPoint?.tabs !== null &&
            !_.isUndefined(this.accountingPoint?.tabs['power_profiles']) &&
            !_.isUndefined(this.accountingPoint?.tabs['power_profiles']['chart_fields']) &&
            this.accountingPoint?.tabs['power_profiles']['chart_fields'][0] !== null
        ) {

            this.prepareMessagesForChart(
                this.messages.fields_headers,
                this.messages.data,
                this.accountingPoint?.tabs['power_profiles']['chart_fields']
            ).then((chartData: any) => {
                setTimeout((_) => {
                    if (chartData.inputs.length > 0 && chartData.labels.length) {
                        this.renderChart(chartData, 'deviceChartPowerProfile');
                        this.isSpinnerVisible = false;
                    } else {
                        this.showChart = false;
                    }
                }, 1000);
            });
        } else {
            this.showChart = false;
        }

    }

    onPopupToggle(sec: string) {
        if (sec === 'max') {
            this.dialog.open(MaximumPowerRedundancyComponent, {
                maxWidth: '618px',
                minWidth: '618px',
                data: {
                    id: this.device.id
                }
            });

        } else {
            this.dialog.open(PowerProfileMinMaxModalComponent, {
                maxWidth: '618px',
                minWidth: '618px',
                data: {
                    messages: this.dataSource.data
                }
            });
        }

    }


    changeEnergy($event) {
        this.energy = $event.checked;
        this.refreshData();
    }

    onTogglePeakHours(event) {
        if (this.peakHours) {
            this.isSpinnerVisible = true;
            this.prepareMessagesForChart(
                this.messages.fields_headers,
                this.messages.data,
                this.device?.model?.tabs['power_profiles']['chart_fields']
            ).then((chartData: any) => {
                setTimeout((_) => {
                    if (chartData.inputs.length && chartData.labels.length) {
                        this.renderChart(chartData, 'deviceChartPowerProfile');
                        this.isSpinnerVisible = false;
                    } else {
                        this.showChart = false;
                        this.isSpinnerVisible = false;
                    }
                }, 1000);
            });
        } else {
            this.refreshData();
        }
    }

    downloadExcel() {
        const sortConfig = this.dataSource.sort;
        let messages = _.orderBy(
            _.cloneDeep(this.dataSource.data),
            [sortConfig.active],
            [sortConfig.direction || 'desc']
        );
        let displayedFields = _.cloneDeep(this.displayedColumns);
        displayedFields = this.sortFields(displayedFields, this.availableColumns).filter(
            (c) => c != 'delete_message' && c !== 'reason' && c !== 'message_api' && c !== 'method'
        );
        let workbook = new Excel.Workbook();
        const invalidCharacters = /[*?:\/[\]]/g;
        const correctedName = this.device.name.replace(invalidCharacters, '_');
        let worksheet = workbook.addWorksheet(correctedName, {
            pageSetup: {fitToPage: true, fitToHeight: 5, fitToWidth: 7}
        });
        let headers = [];
        _.each(displayedFields, (column) => {
            headers.push(this.fieldsTitles[column]?.title || column);
        });

        let headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFDDDDDD'},
                bgColor: {argb: 'FFFFFFFF'}
            };
            cell.border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'}
            };
            cell.alignment = {wrapText: true, indent: 1};
        });
        _.each(messages, (message: any) => {
            let rowArr = [];
            for (let key of displayedFields) {
                if (key === 'datetime' || key === 'realdatetime' || key === 'created_at') {
                    rowArr.push(this.getFormatedDate(message[key]));
                } else {
                    rowArr.push(message[key] !== undefined && message[key] !== null ? message[key] : '-');
                }
            }
            let newRow = worksheet.addRow(rowArr);
            newRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'FFFFFFFF'},
                    bgColor: {argb: 'FFFFFFFF'}
                };
                cell.border = {
                    top: {style: 'thin'},
                    left: {style: 'thin'},
                    bottom: {style: 'thin'},
                    right: {style: 'thin'}
                };
                cell.alignment = {wrapText: true, indent: 1};
                //cell.alignment = { indent: 1 };
            });
        });
        _.each(displayedFields, function (column, index) {
            worksheet.getColumn(index + 1).width = 25;
        });
        const fileName = this.translate.instant('devices.data_show.msg_6', {deviceName: this.device.name});
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            fs.saveAs(
                blob,
                `${fileName} ${moment
                    .unix(this.dateManipulations.dateLocal(this.devicesService.dateForm.startDate))
                    .utc()
                    .format('DD.MM.YYYY')}
                     ${this.translate.instant('devices.data.msg_4')}
                     ${moment
                    .unix(this.dateManipulations.dateLocal(this.devicesService.dateForm.stopDate))
                    .utc()
                    .format('DD.MM.YYYY')}.xlsx`
            );
        });
    }

    refreshDataHandler() {
        this.isSpinnerVisible = true;
        this.refreshData();
    }

    onDeleteMessage(message) {
        const reqData = {
            datetime: message.datetime,
            msgType: this.msgType,
            device_id: this.device.id
        };
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
            width: '400px',
            data: {
                type: 'delete-message',
                reqData
            }
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((data) => {
                if (data && data.result === 'success') {
                    this.dataSource.data = this.dataSource.data.filter((el: any) => el.datetime !== message.datetime);
                }
            });
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
        this.destroyed$.complete();
        if (this.chart !== undefined) {
            this.chart.dispose();
        }
    }


}
