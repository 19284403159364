import { createSelector } from '@ngrx/store';

// DELETE all file when all selectors will be replaced
//
// export const loadMeteringDeviceModels = createAction(
//     '[MeteringDeviceModels Store] Load',
//     props<{ meteringDevicesModels: any }>()
// );
// export const loadGatewayModels = createAction('[GatewayModels Store] Load', props<{ gatewayModels: any }>());
// export const loadBaseStationModels = createAction(
//     '[BaseStationModels Store] Load',
//     props<{ baseStationModels: any }>()
// );

export const getMeteringDeviceModel = (state: any) => state.deviceModels.meteringDevicesModels;
export const getGatewayModels = (state: any) => state.deviceModels.gatewayModels;
export const getBaseStationModels = (state: any) => state.deviceModels.baseStationModels;

export const getMeteringDeviceModelById = createSelector(
    getMeteringDeviceModel,
    (state: any, props) => state[props.id]
);
export const getGatewayModelById = createSelector(getGatewayModels, (state: any, props) => state[props.id]);
export const getBaseStationModelById = createSelector(getBaseStationModels, (state: any, props) => state[props.id]);
