<div class="table-data">
    <div class="row-data">
        <div class="title"></div>
        <div class="title">A+</div>
        <div class="title">A-</div>
        <div class="title">R+</div>
        <div class="title">R-</div>
        <div class="title">S</div>
    </div>
    <div class="row-data">
        <div class="title">{{'devices.data_show.min'|translate}}</div>
        <div>{{power_a_plus_min}} </div>
        <div>{{power_a_minus_min}}</div>
        <div>{{power_r_plus_min}}</div>
        <div>{{power_r_minus_min}}</div>
        <div>{{power_s_min}}</div>
    </div>
    <div class="row-data">
        <div class="title">{{'devices.data_show.max'|translate}}</div>
        <div>{{power_a_plus_max}}</div>
        <div>{{power_a_minus_max}}</div>
        <div>{{power_r_plus_max}}</div>
        <div>{{power_r_minus_max}}</div>
        <div>{{power_s_max}}</div>
    </div>
    <div class="row-data">
        <div class="title">{{'devices.data_show.average'|translate}}</div>
        <div>{{power_a_plus_average}}</div>
        <div>{{power_a_minus_average}}</div>
        <div>{{power_r_plus_average}}</div>
        <div>{{power_r_minus_average}}</div>
        <div>{{power_s_average}}</div>
    </div>
</div>
