import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-control',
    templateUrl: './confirm-control.component.html',
    styleUrls: ['./confirm-control.component.less']
})
export class ConfirmControlComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ConfirmControlComponent>) {}

    ngOnInit(): void {}

    no() {
        this.dialogRef.close(false);
    }

    yes() {
        this.dialogRef.close(true);
    }
}
