import { Pipe, PipeTransform } from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'getTimeFromMin'
})
export class GetTimeFromMinPipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
    }

    transform(min: number): string {
        const hours = Math.trunc(min / 60);
        const minutes = min % 60;
        return this.translateService.instant('devices.listMeteringDevice.expanded_data.time', { hours, minutes });
    }
}
