<div class="auth_form">
    <div class="logo">
        <img routerLink="/cloud"
             class="logo_size"
             alt="logo"
             [src]="getLogoSrc()"
        />
    </div>
    <mat-card class="login-card">
        <mat-card-header>
            <mat-card-title>{{ 'loginFormCompany.title' | translate }}</mat-card-title>
        </mat-card-header>
        <form *ngIf="currentAuthForm === 'company'" [formGroup]="loginFormCompany" (submit)="submit()">
            <div class="login-container">
                <mat-form-field hideRequiredMarker="true" class="login-input">
                    <input
                        matInput
                        type="email"
                        placeholder="{{ 'loginFormCompany.inputs.email' | translate }}"
                        required
                        formControlName="email"
                        name="email"
                    />
                </mat-form-field>
                <mat-form-field hideRequiredMarker="true" class="login-input">
                    <input
                        matInput
                        type="password"
                        placeholder="{{ 'loginFormCompany.inputs.password' | translate }}"
                        required
                        formControlName="password"
                        name="password"
                    />
                </mat-form-field>
                <div class="captcha" *ngIf="captchaImg">
                    <img (click)="generateCaptcha()" [src]="captchaImg"/>
                    <mat-form-field hideRequiredMarker="true" class="login-input-captcha">
                        <input
                            matInput
                            placeholder="{{ 'loginFormCompany.inputs.captcha' | translate }}"
                            required
                            formControlName="captcha"
                            name="captcha"
                        />
                    </mat-form-field>
                </div>
                <div *ngIf="one_t_p">
                    <mat-form-field hideRequiredMarker="true" class="login-input">
                        <input
                            matInput
                            placeholder="{{ 'company_settings.poll_settings.msg_6' | translate }}"
                            required
                            formControlName="one_t_p"
                            name="captcha"
                        />
                    </mat-form-field>
                </div>
                <div style="padding-bottom: 2%; font-size: 14px" *ngIf="personalData">
                    <mat-checkbox (change)="personalDataCheck($event.checked)">
                        {{ 'accept' | translate }}<br/>
                        <a href="https://nekta.tech/privacy-policy/"
                           target="_blank">{{ 'confidentiality_policy' | translate }}</a>
                    </mat-checkbox>
                </div>
            </div>
            <div class="login-button-container">
                <button id="login-button"
                        mat-button
                        mat-flat-button
                        color="primary">
                    {{ 'loginFormCompany.button.enter' | translate }}
                </button>
                <button
                    *ngIf="environment.buildType !== 'server'"
                    routerLink="/auth/registration"
                    id="register-button"
                    mat-button
                    mat-flat-button
                    color="accent">
                    {{ 'loginFormCompany.button.registration' | translate }}
                </button>
                <button mat-button routerLink="/auth/forget" id="forget-button">
                    {{ 'loginFormCompany.button.forget_password' | translate }}
                </button>
            </div>
            <div class="login-button-container demoBlock" *ngIf="environment.buildType !== 'server'">
                            <span class="demo-subtitle">{{ 'loginFormCompany.demo_subtitle' | translate }}</span
                            ><br/>
                <!--                       will be feature...     -->
                <button id="demoLogin" mat-button mat-flat-button class="demo" (click)="demoLogin()">
                    {{ 'loginFormCompany.button.demo' | translate }}
                </button>
            </div>
            <div class="login-button-container languageButton">
                <span>{{ 'loginFormCompany.language' | translate }}: </span>
                <a (click)="switchLang('ru')"><img src="/assets/img/Russia.png" alt="ru" class="lang"/></a>
                <a (click)="switchLang('en')"><img src="/assets/img/United-Kingdom.png" alt="en" class="lang"/></a>
            </div>
        </form>
    </mat-card>
    <div class="mobile_app" *ngIf="!withPersonalDesign">
        <a target="_blank" href="https://play.google.com/store/apps/details?id=io.nekta.cloud">
            <img style="height:40px" src="/assets/img/google-play-badge.png" alt="google play"/>
        </a>
    </div>
</div>
