// TODO: move needed to other file and services
//  Тут много таких констант которые отвечают за переводы,
//  надо просто пробежаться где они используются и сделать там нормальные переводы, без этих костылей.

export const PERIOD_UPDATE = [
    { value: 60, viewValue: '1 минута', viewValueEn: '1 minute' },
    { value: 300, viewValue: '5 минут', viewValueEn: '5 minutes' },
    { value: 900, viewValue: '15 минут', viewValueEn: '15 minutes' },
    { value: 1800, viewValue: '30 минут', viewValueEn: '30 minutes' },
    { value: 3600, viewValue: '1 час', viewValueEn: '1 hour' },
    { value: 21600, viewValue: '6 часов', viewValueEn: '6 hours' },
    { value: 43200, viewValue: '12 часов', viewValueEn: '12 hours' },
    { value: 86400, viewValue: '24 часа', viewValueEn: '24 hours' },
    { value: 172800, viewValue: '48 часов', viewValueEn: '48 hours' },
    { value: 259200, viewValue: '72 часа', viewValueEn: '72 hours' },
    { value: 604800, viewValue: '1 неделя', viewValueEn: '1 week' },
    { value: 1209600, viewValue: '2 недели', viewValueEn: '2 weeks' },
    { value: 2592000, viewValue: '1 месяц', viewValueEn: '1 month' }
];
export const MONTH_DATE_FORMAT = {
    parse: {
        dateInput: 'MM.YYYY'
    },
    display: {
        dateInput: 'MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};
export const LIST_OF_DEVICE_RU = {
    1: 'Вода',
    2: 'Газ',
    3: 'Тепло',
    4: 'Электричество',
    5: 'Вещество',
    6: 'Датчик',
    7: 'Прочее'
};
export const LIST_OF_DEVICE_EN = {
    1: 'Water',
    2: 'Gas',
    3: 'Heat',
    4: 'Electricity',
    5: 'Substance',
    6: 'Sensor',
    7: 'Other'
};
export const LIST_OF_GROUP = [
    {
        id: 0,
        name: 'Счётчик воды',
        slug: 'water_meter_cold'
    },
    {
        id: 1,
        name: 'Счётчик воды',
        slug: 'water_meter_hot'
    },
    {
        id: 5,
        name: 'Счётчик газа',
        slug: 'gas_meter'
    },
    {
        id: 4,
        name: 'Счётчик тепла',
        slug: 'heat_meter'
    },
    {
        id: 3,
        name: 'Счётчик электричества',
        slug: 'electricity_meter'
    },
    {
        id: 6,
        name: 'Счетчик вещества',
        slug: 'substance_meter'
    },
    {
        id: 8,
        name: 'Водоотведение',
        slug: 'drainage'
    },
    {
        id: 9,
        name: 'Питьевая вода',
        slug: 'drinking_water'
    },
    {
        id: 7,
        name: 'Прочее',
        slug: 'other'
    }
];
export const MAP_TABS = {
    tariffs: 'Показания',
    power_profiles: 'Профиль мощности',
    alarm: 'Тревоги',
    journal_device: 'Журнал событий',
    journal: 'Журнал опроса',
    service_information: 'Служебная информация',
    instant_readings: 'Мгновенные показания',
    archives_system_1: 'Архивы - Система 1',
    archives_system_2: 'Архивы - Система 2',
    summary_readings: 'Итоговые показания',
    info: 'Справочная информация',
    archives: 'Архивы',
    heat_calculating: 'Рассчитанные показания',
    energy_profiles: 'Профиль энергии'
};
export const MAP_TABS_EN = {
    tariffs: 'Indications',
    power_profiles: 'Power Profile',
    alarm: 'Alarm',
    journal_device: 'Event log',
    journal: 'Poll Log',
    service_information: 'Service information',
    instant_readings: 'Instant Readings',
    archives_system_1: 'Archives - System 1',
    archives_system_2: 'Archives - System 2',
    summary_readings: 'Final indications',
    info: 'Reference Information',
    archives: 'Archives',
    heat_calculating: 'Calculated Readings',
    energy_profiles: 'Energy profile'
};
export const TARIFF = {
    in1: 'Вход 1',
    tariff0: 'Сумма распределителей',
    tariff1: 'Тариф 1',
    tariff2: 'Тариф 2',
    tariff3: 'Тариф 3',
    tariff4: 'Тариф 4',
    tariff5: 'Тариф 5',
    tariff6: 'Тариф 6'
};
export const TARIFF_EN = {
    in1: 'Input 1',
    tariff0: 'Sum of distributors',
    tariff1: 'Tariff 1',
    tariff2: 'Tariff 2',
    tariff3: 'Tariff 3',
    tariff4: 'Tariff 4',
    tariff5: 'Tariff 5',
    tariff6: 'Tariff 6'
};
export const TYPE_WATER_METER = [
    { value: 'hot', viewValue: 'Горячая' },
    { value: 'cold', viewValue: 'Холодная' }
];
export const MY_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY'
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

export const VOLTAGE_LEVEL = [
    { id: 1, title: 'ВН (110 кВ и выше)', translateKey: 'voltage_level_1' },
    { id: 2, title: 'СН1 (35 кВ)', translateKey: 'voltage_level_2' },
    { id: 3, title: 'СН2 (20 - 1 кВ)', translateKey: 'voltage_level_3' },
    { id: 4, title: 'НН (ниже 1 кВ)', translateKey: 'voltage_level_4' }
];
export const PARAM_MVITU = [
  't_forward',
  't_revers',
  'p_forward',
  'p_revers',
  'q_forward',
  'q_revers',
];
export const PARAM_LERS = [
    'None',
    'T_in',
    'T_out',
    'T_cw',
    'T_outdoor',
    'M_in',
    'M_out',
    'V_in',
    'V_out',
    'Q_in',
    'Q_out',
    'Q_delta',
    'P_in',
    'P_out',
    'P_cw',
    'NormalOperationDuration',
    'T',
    'P',
    'V',
    'V_std',
    'Ap1',
    'An1',
    'Rp1',
    'Rn1',
    'Ap2',
    'An2',
    'Rp2',
    'Rn2',
    'Ap3',
    'An3',
    'Rp3',
    'Rn3',
    'Ap4',
    'An4',
    'Rp4',
    'Rn4',
    'Ap',
    'An',
    'Rp',
    'Rn',
    'StopDuration',
    'FlowUnderrunDuration',
    'FlowOverrunDuration',
    'TempDiffLowDuration',
    'NoPowerDuration',
    'IncidentDuration',
    'SaturatedSteamDuration',
    'T_delta',
    'P_delta',
    'V_delta',
    'M_delta',
    'M',
    'T_indoor',
    'PowerProfile_Pp',
    'PowerProfile_Pn',
    'PowerProfile_Qp',
    'PowerProfile_Qn',
    'S',
    'SA',
    'SB',
    'SC',
    'ElectricCurrent_P',
    'Pp',
    'Pn',
    'PA',
    'PB',
    'PC',
    'Q',
    'Qp',
    'Qn',
    'QA',
    'QB',
    'QC',
    'U',
    'UA',
    'UB',
    'UC',
    'I',
    'IA',
    'IB',
    'IC',
    'PhiUAB',
    'PhiUAC',
    'PhiUBC',
    'PhiUIA',
    'PhiUIB',
    'PhiUIC',
    'Frequency',
    'CosPhi',
    'CosPhiA',
    'CosPhiB',
    'CosPhiC',
    'M_balance',
    'H',
    'T_internal',
    'T_control',
    'T_base',
    'ValvePercent',
    'CorrectionCoeff',
    'CalorificValue',
    'Humidity',
    'AtmospherePressure',
    'CarbonDioxideContent',
    'NitrogenContent',
    'V_NoCount',
    'DensityStd'
];

export const PERIOD_POWER_PROFILE = [
    { value: 30, viewValue: '30 минут', viewValueEn: '30 minutes' },
    { value: 60, viewValue: '60 минут', viewValueEn: '60 minutes' }
];
