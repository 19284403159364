import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject, Injectable } from '@angular/core';
import { TreeActions } from '@store/tree/tree.actions';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { ObjectsApiService } from '@core/services/api/objects-api.service';
import { EMPTY } from 'rxjs';
import { ObjectsTreeService } from '@core/services/objects-tree.service';

@Injectable()
export class TreeEffects {
    private readonly actions$ = inject(Actions);
    private readonly objectsApiService = inject(ObjectsApiService);
    private readonly objectsTreeService = inject(ObjectsTreeService);

    fetchObjectsTree$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TreeActions.fetchobjectstree),
            tap(() => this.objectsTreeService.loading = true),
            exhaustMap(() =>
                this.objectsApiService.getBuildStructureTree().pipe(
                    tap(() => this.objectsTreeService.loading = false),
                    map((result: any) => TreeActions.loadobjectstree({ payload: result?.data || null })),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    fetchObjectsTreeWithStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TreeActions.fetchobjectstreewithstats),
            tap(() => this.objectsTreeService.loading = true),
            exhaustMap(() =>
                this.objectsApiService.getBuildStructureTree({ with_stats: true }).pipe(
                    tap(() => this.objectsTreeService.loading = false),
                    map((result: any) => TreeActions.loadobjectstree({ payload: result?.data || null })),
                    catchError(() => EMPTY)
                )
            )
        )
    );
}
