import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DevicesService} from '../../services/devices.service';

// DELETE when all versions will be deleted

/**
 * @deprecated will be deleted
 */
@Component({
    selector: 'app-confirm-popup',
    templateUrl: './confirm-popup.component.html',
    styleUrls: ['./confirm-popup.component.less']
})
export class ConfirmPopupComponent implements OnInit {
    spinner;

    constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>,
                private devicesService: DevicesService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
    }

    onDeleteMessage() {
        this.devicesService.deleteMessage(this.data.reqData)
            .subscribe((res) => {
                this.dialogRef.close({result: 'success'});
        });
    }

    close() {
        this.dialogRef.close();
    }

}
