<div class="controls-wrapper">

    <!--Перезагрузка-->
    <mat-card *ngIf="currentControl?.reset?.active" class="setout-control">
        <button style="height: 50px"
                mat-raised-button
                color="primary"
                class="send_but"
                (click)="reloadModel()">
            {{ 'app_accounting_point.control.msg_37' | translate }}
        </button>
    </mat-card>
    <!--/Перезагрузка-->
    <mat-card *ngIf="currentControl?.setout?.active" class="setout-control">
        <h4>{{ 'gateway.msg_1'| translate }}</h4>
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{ 'gateway.msg_2'| translate }}</mat-label>
            <mat-select [(ngModel)]="bodyParams.setOut">
                <mat-option [value]="1"> {{ 'gateway.msg_3'| translate }}</mat-option>
                <mat-option [value]="2"> {{ 'gateway.msg_4'| translate }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{ 'import_objects.msg_9'|translate }}</mat-label>
            <mat-select [(ngModel)]="bodyParams.out">
                <mat-option [value]="1"> {{ 'gateway.msg_5'| translate }}</mat-option>
                <mat-option [value]="2"> {{ 'gateway.msg_6'| translate }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{ 'gateway.msg_8'| translate }}</mat-label>
            <input
                matInput
                [(ngModel)]="bodyParams.duration"
                type="number"
                [min]="0"
                [max]="255"
                placeholder="{{'gateway.msg_11'| translate}}"
            />
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="sendSetOutControl()">{{ 'gateway.msg_10'| translate }}
        </button>
    </mat-card>

    <!--Корректировка даты/времени на устройстве-->
    <div
        class="control-block"
        *ngIf="currentControl?.hasOwnProperty('time_correct') && currentControl['time_correct']['active'] === true"
    >
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{ 'app_accounting_point.control.msg_18' | translate }}</mat-card-title>
            </mat-card-header>
            <div>
                <div>
                    <mat-form-field floatLabel="always" appearance="outline"
                                    style="margin-left: 15px; margin-right: 15px">
                        <mat-label>{{ 'app_accounting_point.control.msg_19' | translate }}</mat-label>
                        <input
                            matInput
                            class="correction-seconds"
                            type="number"
                            max="30"
                            min="-30"
                            [disabled]="disableEdit"
                            [(ngModel)]="bodyParams.time_correct"
                        />
                    </mat-form-field>

                    <button
                        style="margin-left: 15px; height: 50px; margin-bottom: 15px; vertical-align: middle"
                        mat-raised-button
                        color="primary"
                        (click)="correctTime()"
                        [disabled]="disableEdit"
                    >
                        {{ 'app_accounting_point.control.msg_20' | translate }}
                    </button>
                </div>
            </div>
        </mat-card>
    </div>
    <!--/Корректировка даты/времени на устройстве-->

    <!--Прочитать дату/время-->
    <div class="control-block"
         *ngIf="isControlActive('get_datetime')"
    >
        <mat-card class="control-block__card">
            <mat-card-header>
                <mat-card-title>{{ 'app_accounting_point.control.msg_53' | translate }}</mat-card-title>
            </mat-card-header>
            <button mat-raised-button
                    color="primary"
                    class="send_but"
                    (click)="readDateAndTime()">
                {{ 'app_accounting_point.control.msg_51' | translate }}
            </button>
        </mat-card>
    </div>
    <!--/Прочитать дату/время-->

    <!--Установка даты/времени на устройстве-->
    <div
        class="control-block control-archive"
        *ngIf="isControlActive('time_set')"
    >
        <mat-card >
            <mat-card-header>
                <mat-card-title>{{ 'app_accounting_point.control.msg_11' | translate }}</mat-card-title>
            </mat-card-header>
            <div>
                <div>
                    <mat-form-field floatLabel="always" appearance="outline"
                                    style="margin-left: 15px; margin-right: 15px">
                        <mat-label>{{ 'app_accounting_point.control.msg_12' | translate }}</mat-label>
                        <input
                            matInput
                            class="data-date"
                            (click)="setDeviceDate.open()"
                            (focus)="setDeviceDate.open()"
                            [matDatepicker]="setDeviceDate"
                            [(ngModel)]="setDateTimeSendObject.management['date']"
                            [disabled]="disableEdit"
                        />
                        <mat-datepicker-toggle matSuffix [for]="setDeviceDate"></mat-datepicker-toggle>
                        <mat-datepicker #setDeviceDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" appearance="outline" class="time">
                        <mat-label>{{ 'app_accounting_point.control.msg_13' | translate }}</mat-label>
                        <input
                            matInput
                            aria-label="24hr format"
                            [ngxTimepicker]="fullTime"
                            [format]="24"
                            readonly
                            placeholder="{{ 'app_accounting_point.control.msg_14' | translate }}"
                            [(ngModel)]="setDateTimeSendObject.management['time']"
                            [disabled]="disableEdit"
                        />
                        <ngx-material-timepicker #fullTime [cancelBtnTmpl]="cancelBtn"
                                                 [confirmBtnTmpl]="confirmBtn">
                        </ngx-material-timepicker>
                        <ng-template #cancelBtn>
                            <button class="pickerBtn cancelBtn" mat-raised-button style="margin-right: 15px"
                                    type="button">
                                {{ 'app_accounting_point.control.msg_15' | translate }}
                            </button>
                        </ng-template>
                        <ng-template #confirmBtn>
                            <button class="pickerBtn" mat-raised-button color="primary"
                                    type="button">{{ 'app_accounting_point.control.msg_16' | translate }}
                            </button>
                        </ng-template>
                    </mat-form-field>
                    <button
                        style="margin-left: 15px; height: 50px; margin-bottom: 15px; vertical-align: middle"
                        mat-raised-button
                        color="primary"
                        (click)="setDateTime()"
                        [disabled]="disableEdit"
                    >
                        {{ 'app_accounting_point.control.msg_48' | translate }}
                    </button>
                </div>
            </div>
        </mat-card>
    </div>
    <!--/Установка даты/времени на устройстве-->

    <mat-card class="setout-control__vis" *ngIf="currentModel?.options?.virtual_bridge">
        <h1>{{ 'gateway.msg_17'| translate }}</h1>
        <div>
            <span>{{ 'gateway.msg_18'| translate }}:</span>
            <span *ngIf="invisableMode">{{ 'gateway.msg_12'| translate }}</span>
            <span *ngIf="!invisableMode">{{ 'gateway.msg_13'| translate }}</span>
        </div>
        <button class="setout-control__button"
                *ngIf="!invisableMode" mat-raised-button
                color="primary"
                (click)="postActiv(true)">
            {{ 'gateway.msg_14'| translate }}
        </button>
        <button class="setout-control__button"
                *ngIf="invisableMode" mat-raised-button
                color="primary"
                (click)="postActiv(false)">
            {{ 'gateway.msg_15'| translate }}
        </button>
        <div *ngIf="port">
            <span>{{ 'gateway.msg_16'| translate }}: </span>
            <span>{{ port }}</span>
        </div>
    </mat-card>
    <mat-card class="setout-control__cib" *ngIf="currentModel?.id === 2 && currentControl">
        <div *ngFor="let param of currentControl | keyvalue">
            <ng-container *ngIf="param.value.active">
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{ 'gateway.' + param.key | translate }}</mat-label>
                    <ng-container *ngIf="param.key === 'set_url'; else inpTypeNumber">
                        <input
                            matInput
                            [(ngModel)]="bodyParams[param.key]"
                            type="text"
                            placeholder="{{'gateway.'+ param.key | translate}}"
                        />
                    </ng-container>
                    <ng-template #inpTypeNumber>
                        <input
                            matInput
                            [(ngModel)]="bodyParams[param.key]"
                            type="number"
                            placeholder="{{'gateway.'+ param.key | translate}}"
                        />
                    </ng-template>
                </mat-form-field>
                <button mat-raised-button color="primary"
                        (click)="sendSetParamOutControl(param)">{{ 'send_report.msg_32'| translate }}
                </button>
            </ng-container>
        </div>
    </mat-card>

    <!--Отправка произвольных команд на устройства LoRaWan-->
    <div
        class="custom-command"
        *ngIf="currentModel?.options?.control.hasOwnProperty('send_raw_payload') && currentModel?.options?.control['send_raw_payload']['active'] === true"
    >
        <mat-card style="padding: 25px; height: 70%">
            <mat-card-header>
                <mat-card-title>{{ 'app_accounting_point.control.msg_58' | translate }}</mat-card-title>
            </mat-card-header>
            <form [formGroup]="freeCommandForm">
                <mat-form-field floatLabel="always"
                                appearance="outline">
                    <mat-label>{{ 'app_accounting_point.control.msg_62' | translate }}</mat-label>
                    <input matInput type="text" formControlName="payload">
                </mat-form-field>

                <mat-form-field floatLabel="always"
                                appearance="outline">
                    <mat-label>{{ 'app_accounting_point.control.msg_63' | translate }}</mat-label>
                    <input matInput type="number" formControlName="fport" min="0" max="255">
                </mat-form-field>

                <mat-form-field floatLabel="always"
                                appearance="outline">
                    <mat-label>{{ 'app_accounting_point.control.msg_64' | translate }}</mat-label>
                    <mat-select formControlName="class">
                        <mat-option *ngFor="let val of classForCommandForLoRaWan"
                                    [value]="val.value">
                            {{ val.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </form>
            <div style="text-align: center">
                <button
                    class="send_but"
                    mat-raised-button
                    color="primary"
                    (click)="sendingCustomCommands()"
                >
                    {{ 'app_accounting_point.control.msg_10' | translate }}
                </button>
            </div>
        </mat-card>
    </div>
    <!--/Отправка произвольных команд на устройства LoRaWan-->

    <!-- Активация передачи данных -->
    <mat-card *ngIf="isMTCDataSendActive()" class="setout-control fit-width">
        <mat-card-header>
            <mat-card-title>
                {{ 'gateway.mtc_activate_data_title' | translate }}
            </mat-card-title>
        </mat-card-header>
        <button
                mat-raised-button
                color="primary"
                class="send_but"
                (click)="sendMTCDataActivate()">
            {{ 'gateway.mtc_activate_data_btn' | translate }}
        </button>
    </mat-card>
    <!/-- / Активация передачи данных -->

    <button mat-stroked-button color="warn" class="fit-width"
            (click)="dialogRef.close()">
        {{ 'gateway.msg_9'| translate }}
    </button>
</div>
