import { Component, OnInit } from '@angular/core';
import { CloudService } from '@cloud/cloud.service';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { TranslateLanguageService } from '@core/services/translate-language.service';
import { APP_FAVICON_SRC, APP_TITLE } from '@core/constants/app.cloud.constants';
import { PersonalDesignConfig } from '@core/models/app.core.models';
import { PERSONAL_DESIGN_CONFIGS } from '@core/personal-design-configs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
    window = window;
    state = 0; // TODO: delete if no usage
    public customCss: SafeResourceUrl | null = null;
    public customJs: SafeResourceUrl | null = null;
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');

    constructor(
        private cloudService: CloudService,
        private sanitizer: DomSanitizer,
        private titleService: Title,
        private translateLanguageService: TranslateLanguageService,
        private translateService: TranslateService,
    ) {
        this.translateLanguageService.languageSet();
    }

    ngOnInit() {
        this.setPersonalDesign();
    }

    private setPersonalDesign() {
        const personalDesign: PersonalDesignConfig | null = PERSONAL_DESIGN_CONFIGS?.[window.location.hostname] ?? null;
        this.cloudService.setPersonalDesign(personalDesign) ;
        const pageTitle = this.getPageTitle(personalDesign);
        this.titleService.setTitle(pageTitle);
        this.customCss = personalDesign?.custom_css ? this.sanitizer.bypassSecurityTrustResourceUrl(personalDesign?.custom_css) : null;
        this.customJs = personalDesign?.custom_js ? this.sanitizer.bypassSecurityTrustResourceUrl(personalDesign?.custom_js) : null;
        this.favIcon.href = personalDesign?.favicon ?? APP_FAVICON_SRC;
    }

    private getPageTitle(w: PersonalDesignConfig | null): string {
        const lang = this.translateService.currentLang;
        if (!w) {
            return APP_TITLE;
        } else {
            return lang === 'en'
                ? w?.title_en ?? w?.title ?? APP_TITLE
                : w?.title ?? APP_TITLE;
        }
    }
}
