import { ConsumersActions, ConsumersUnion } from '../actions/consumers.actions';

export interface ConsumersState {
    data: any;
    currentPage: number;
    lastPage: number;
    perPage: number;
    total: number;
    error: any;
}

export const initialState: ConsumersState = {
    data: [],
    currentPage: null,
    lastPage: null,
    perPage: null,
    total: null,
    error: null
};

/**
 * @deprecated will be deleted
 */

export function reducer(state = initialState, action: ConsumersUnion): ConsumersState {
    switch (action.type) {
        case ConsumersActions.FetchConsumersSuccess: {
            if (
                action.payload?.current_page &&
                action.payload?.last_page &&
                action.payload?.per_page &&
                action.payload?.total
            ) {
                return {
                    data: action.payload.data,
                    currentPage: action.payload.current_page,
                    lastPage: action.payload.last_page,
                    perPage: action.payload.per_page,
                    total: action.payload.total,
                    error: null
                };
            } else {
                return {
                    data: action.payload,
                    currentPage: null,
                    lastPage: null,
                    perPage: null,
                    total: null,
                    error: null
                };
            }
        }
        case ConsumersActions.AddConsumerFail: {
            return {
                ...state,
                error: action.payload
            };
        }
        default: {
            return state;
        }
    }
}
