<mat-card class="forget-card">
    <mat-card-header>
        <mat-card-title>{{ 'forgetForm.h1' | translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'forgetForm.desc' | translate }}</mat-card-subtitle>
    </mat-card-header>
    <form [formGroup]="forgetForm" (submit)="submit()">
        <div class="forget-container">
            <mat-form-field floatLabel="auto" class="forget-input">
                <input
                    matInput
                    type="email"
                    [placeholder]="'forgetForm.email' | translate"
                    required
                    formControlName="email"
                    name="email"
                />
                <mat-error *ngIf="forgetForm.controls['email'].hasError('email')">
                    {{ 'forgetForm.error.fail_email' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="forget-button-container">
            <button id="forget-button" mat-button mat-flat-button color="primary">
                {{ 'forgetForm.button.submit' | translate }}
            </button>
        </div>
    </form>
</mat-card>
