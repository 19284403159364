<div class="country-list-container">
    <div class="country-picker-container">
        <div class="countries-input">
            <div class="code-flag" (click)="toggleVisible()">
                <div class="selected-country-icon flex">
                    <span [class]="'flag-icon flag-icon-' + selectedCountry.countryCode"></span>
                </div>
            </div>
        </div>
        <mat-form-field floatLabel="always" appearance="outline" class="template">
            <mat-label>{{ (title || 'devices.inputs.number') | translate }}</mat-label>
            <input name="search-code" id="phone_inp" (keyup)="onSearchCountry($event)" [(ngModel)]="phoneNumber"
                   (focus)="setMask('phone_inp', '^((\\+)([0-9]){14})$')"
                   matInput [required]="fieldRequired"/>
        </mat-form-field>
    </div>
    <div class="flaga-dropdown-container" [class.visible]="dropdownVisible">
        <div class="country-flag-item" *ngFor="let country of countryList">
            <div (click)="onSelectCountry(country)" class="country-flag-item-wrapper">
                <span [class]="'dropdown-flag flag-icon flag-icon-' + country.countryCode"></span>
                <span class="dial-code"><b>{{ country.dialCode }}</b></span>
                <span class="country-name ml-2">{{ getCountryName(country) }}</span>
            </div>
        </div>
    </div>
</div>
