import { Component } from '@angular/core';
import { PassportService } from '../passport.service';
import { Router } from '@angular/router';

@Component({
    template: ''
})
export class LogoutComponent {
    constructor(private readonly router: Router, private readonly auth: PassportService) {
        this.auth.logout().then(
            (data: any) => {
                let lang = null;
                if (localStorage.getItem('language') !== null) {
                    lang = localStorage.getItem('language');
                }
                localStorage.removeItem('access_token');
                localStorage.removeItem('app');
                localStorage.removeItem('phone');
                localStorage.removeItem('abonent_name');
                if (lang !== null) {
                    localStorage.setItem('language', lang);
                }
                this.auth.access_token = null;
                this.router.navigate(['auth/login']);
                this.auth.fromAdmin = false;
            },
            (error) => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('app');
                localStorage.removeItem('phone');
                localStorage.removeItem('abonent_name');
                this.auth.access_token = null;
                this.router.navigate(['auth/login']);
                this.auth.fromAdmin = false;
            }
        );
    }
}
