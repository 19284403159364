import { ActionReducerMap } from '@ngrx/store';
import { deviceModelsReducer, DeviceModelsState } from '@store/models/models.reducer';
import { reducer as deviceTitlesReducer } from 'src/app/store/reducers/titles.reducer';
import { directoriesReducer, DirectoriesState } from '@store/directories/directories.reducer';
import { accessReducer, AccessState } from '@store/access/access.reducer';
import { reducer as consumersReducer } from 'src/app/cloud/directories/store/reducers/consumers.reducer';
import { reducer as suppliersReducer } from 'src/app/cloud/directories/store/reducers/suppliers.reducer';
import { reducer as sharedReducer } from 'src/app/store/reducers/shared.reducer';
import { reducer as pollsGroupReducer } from 'src/app/cloud/devices/store/reducers/polls-group.reducer';
import { reducer as reportGeneratorReducer } from 'src/app/store/reducers/report-generator.reducer';
import { treeReducer, TreeState } from '@store/tree/tree.reducer';
import { reportTemplatesReducer, ReportTemplateState } from '@store/reportTemplates/reportTemplates.reducer';
import { reportBuilderReducer } from '@store/reportBuilder/report-builder.reducer';

// TODO: reorganize imports
// TODO: delete unused reducers

export const enum StoreSelectors {
    Access = 'access',
    Consumers = 'consumers',
    DeviceModels = 'deviceModels',
    DeviceTitles = 'deviceFieldsTitles',
    Directories = 'directories',
    PollsGroup = 'pollsGroup',
    ReportBuilder = 'reportBuilder',
    ReportGenerator = 'reportGenerator',
    ReportTemplates = 'reportTemplates',
    Shared = 'shared',
    Suppliers = 'suppliers',
    Tree = 'tree'
}

// TODO: add types for all reducers
export interface AppStateModel {
    [StoreSelectors.Access]: AccessState;
    [StoreSelectors.Consumers]: any;
    [StoreSelectors.DeviceModels]: DeviceModelsState;
    [StoreSelectors.DeviceTitles]: any;
    [StoreSelectors.Directories]: DirectoriesState;
    [StoreSelectors.PollsGroup]: any;
    [StoreSelectors.ReportBuilder]: any;
    [StoreSelectors.ReportGenerator]: any;
    [StoreSelectors.ReportTemplates]: ReportTemplateState;
    [StoreSelectors.Shared]: any;
    [StoreSelectors.Suppliers]: any;
    [StoreSelectors.Tree]: TreeState;
}

export const appReducer: ActionReducerMap<AppStateModel> = {
    [StoreSelectors.Access]: accessReducer,
    [StoreSelectors.Consumers]: consumersReducer,
    [StoreSelectors.DeviceModels]: deviceModelsReducer,
    [StoreSelectors.DeviceTitles]: deviceTitlesReducer,
    [StoreSelectors.Directories]: directoriesReducer,
    [StoreSelectors.PollsGroup]: pollsGroupReducer,
    [StoreSelectors.ReportBuilder]: reportBuilderReducer,
    [StoreSelectors.ReportGenerator]: reportGeneratorReducer,
    [StoreSelectors.ReportTemplates]: reportTemplatesReducer,
    [StoreSelectors.Shared]: sharedReducer,
    [StoreSelectors.Suppliers]: suppliersReducer,
    [StoreSelectors.Tree]: treeReducer
};
