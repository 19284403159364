export enum DeviceModelInterfacesEnum {
    LoRaWAN = 1,
    Impulse = 2,
    RS_232 = 3,
    RS_485 = 4,
    WM_BUS = 5,
    M_BUS = 6,
    Ethernet = 7,
    Zigbee = 8,
    PLC = 9,
    GSM_GPRS = 10,
    CAN = 11,
    NB_Fi = 12,
    Current_loop = 13,
    RS_422 = 14,
    USB = 15,
    Wi_Fi = 16,
    NB_IoT = 17,
    _6LoWPAN = 18,
    _3G = 19,
    Actility = 20,
    lers_api = 21,
    kt_iot = 22,
    virtual = 23,
    temperature = 24,
    net868 = 25,
    ftp = 26,
    Lartech = 27,
    CSD = 28,
    one_wire = 29,
    single_wire = 30,
    discrete = 31,
    analog = 32,
    ERTH_IoT_Server = 33,
    vega = 34,
    LorawanThings_stack = 35,
    LorawanChirpStack = 36,
    open_out = 37,
    RF433 = 38,
    AirBit = 39,
    Smartgrid = 40,
    NIDD = 41,
    ethernet_uspd = 42,
}
