import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {GetPowerProfileModalComponent} from './get-power-profile-modal';

@Component({
    selector: 'app-get-power-profile',
    templateUrl: './get-power-profile.component.html',
    styleUrls: ['./get-power-profile.component.less']
})
export class GetPowerProfileComponent implements OnInit {
    @Input() currentDevice;
    @Input() currentAccountingPoint;

    constructor(public dialog: MatDialog) {}

    ngOnInit(): void {}

    openPowerProfileModal() {
        this.dialog.open(GetPowerProfileModalComponent, {
            data: {
                currentDevice: this.currentDevice,
                currentAccountingPoint: this.currentAccountingPoint
            }
        });
    }
}
