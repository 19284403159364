import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { ObjectsApiService } from '@core/services/api/objects-api.service';
import { DevicesApiService } from '@core/services/api/devices-api.service';
import { ReportBuilderActions } from '@store/reportBuilder/report-builder.actions';

function addAdditionalFields(fields) {
    const resultFields = [];
    for (const field of fields) {
        if (field.includes('tariff') || field.includes('in1')) {
            resultFields.push(field);
            resultFields.push(`delta_${field}`);
            resultFields.push(`start_${field}`);
            resultFields.push(`end_${field}`);
        } else {
            resultFields.push(field);
        }
    }
    return resultFields;
}

@Injectable()
export class ReportBuilderEffects {
    private readonly actions$ = inject(Actions);
    private readonly devicesApiService = inject(DevicesApiService);
    private readonly objectsApiService = inject(ObjectsApiService);

    fetchAccountingPointsFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportBuilderActions.fetchaccountingpointfields),
            map((action: any) => action.deviceGroupId),
            exhaustMap((deviceGroupId: string) =>
                this.objectsApiService.getTabsByDeviceGroupId({ device_group_id: deviceGroupId }).pipe(
                    map((fields) => {
                        const preparedFields = addAdditionalFields(fields.tariffs?.fields || []);
                        return ReportBuilderActions.fetchaccountingpointfieldssuccess({ fields: preparedFields });
                    }),
                    catchError((e) => {
                        throw e;
                    })
                )
            )
        )
    );

    fetchAvailableAccountingPoints$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportBuilderActions.fetchavailableaccountingpoints),
            map((action: any) => action),
            exhaustMap((params: any) =>
                this.objectsApiService
                    .getAllAccountingPointList({
                        tied_object: null,
                        tied_device: params.tied_device
                    })
                    .pipe(
                        map((points) => ReportBuilderActions.fetchavailableaccountingpointssuccess({ availableAccountingPoints: points })),
                        catchError((e) => {
                            throw e;
                        })
                    )
            )
        )
    );

    fetchAvailableAttributes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportBuilderActions.fetchavailableattributes),
            map((action: any) => action),
            exhaustMap(() =>
                this.devicesApiService.getAllAvailableAttributes().pipe(
                    map((attributes) => ReportBuilderActions.fetchavailableattributessuccess({ attributes })),
                    catchError((e) => {
                        throw e;
                    })
                )
            )
        )
    );

    fetchFieldsTitles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportBuilderActions.fetchfieldstitles),
            map((action: any) => action),
            exhaustMap(() =>
                this.devicesApiService.getDeviceFieldTitles().pipe(
                    map((fields) => ReportBuilderActions.fetchfieldstitlessuccess({ fields })),
                    catchError((e) => {
                        throw e;
                    })
                )
            )
        )
    );
}
