import { createReducer, on } from '@ngrx/store';
import { ModelsActions } from '@store/models/models.actions';
import { normalizeArrayById } from '@core/helpers';

export interface DeviceModelsState {
    companyMeteringDevices: any[];
    meteringDevices: any[];
    gateways: any[];
    baseStations: any[];
    companyMeteringDevicesModels: any;
    meteringDevicesModels: any;
    gatewayModels: any;
    baseStationModels: any;
}

const initialState: DeviceModelsState = {
    companyMeteringDevices: [],
    meteringDevices: [],
    gateways: [],
    baseStations: [],
    companyMeteringDevicesModels: {},
    meteringDevicesModels: {},
    gatewayModels: {},
    baseStationModels: {}
};
export const deviceModelsReducer = createReducer(
    initialState,
    on(ModelsActions.loadcompanymeteringdevicemodels, (state, { payload }) => {
        return {
            ...state,
            companyMeteringDevicesModels: normalizeArrayById(payload),
            companyMeteringDevices: payload
        };
    }),
    on(ModelsActions.loadmeteringdevicemodels, (state, { payload }) => {
        return {
            ...state,
            meteringDevicesModels: normalizeArrayById(payload),
            meteringDevices: payload
        };
    }),
    on(ModelsActions.loadgatewaymodels, (state, { payload }) => {
        return {
            ...state,
            gatewayModels: normalizeArrayById(payload),
            gateways: payload
        };
    }),
    on(ModelsActions.loadbasestationmodels, (state, { payload }) => {
        return {
            ...state,
            baseStationModels: normalizeArrayById(payload),
            baseStations: payload
        };
    }),
    on(ModelsActions.reset, (state) => {
        return { ...initialState };
    })
);
