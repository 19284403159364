import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * @description поиск с фиксированным стилем
 * @param multiWord если включен то показывает иконку с предупреждением что можно вводить слова с разделителем,
 * логика разбиения строки реализуется в компоненте-родителе
 * @param size фиксированный размер, ограничен в мобильном режиме
 * @param searchString введенная строка поиска
 * @param searchStringChange эмиттер строки поиска
 */

@Component({
    selector: 'nekta-search',
    templateUrl: './nekta-search.component.html',
    styleUrls: ['./nekta-search.component.less']
})
export class NektaSearchComponent {
    @Input() showExactSearch = false;
    @Input() multiWord = false;
    @Input() searchString: string | null;
    @Input() size: 's' | 'm' | 'l' | 'full' = 'm';

    @Output() searchStringChange: EventEmitter<string | null> = new EventEmitter<string | null>();
    @Output() exactSearchChange: EventEmitter<boolean | null> = new EventEmitter<boolean | null>();

    getWidth() {
        if (this.size === 's') {
            return 240;
        }
        if (this.size === 'm') {
            return 360;
        }
        if (this.size === 'l') {
            return 500;
        }
        return undefined;
    }

    onClear() {
        this.searchString = '';
        this.onClick();
    }

    onClick() {
        this.searchStringChange.emit(this.searchString);
    }

    toggleMultiWord() {
        this.multiWord = !!this.multiWord;
        this.exactSearchChange.emit(this.multiWord);
    }
}
