import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PassportService} from '../../auth/passport.service';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EventsService {
    state: number = 0;
    _baseURL: string;
    public headers = new HttpHeaders();
    token: string;

    constructor(private httpClient: HttpClient, private auth: PassportService) {
        this._baseURL = environment.baseURL;
    }

    addEvent(body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'event/create', body, { headers: this.headers });
    }

    updateEvent(body) {
        if (body.active === true) {
            body.active = 1;
        } else if (body.active === false) {
            body.active = 0;
        } else {
            body.active = 1;
        }
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'event/update', body, { headers: this.headers });
    }

    getEventList() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'event/list', {}, { headers: this.headers }).pipe(
            map((response: any) => {
                return response.data.events;
            })
        );
    }

    getDeviceEventList(device_id, model_class_id = null) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = {
            device_id,
        };
        if (model_class_id) {
            body['model_class_id'] = model_class_id;
        }
        return this.httpClient.post(this._baseURL + 'event/list', {...body}, { headers: this.headers }).pipe(
            map((response: any) => {
                return response.data.events;
            })
        );
    }

    getEventListWithParams(device_ids = [], status_ids = [], sort = 'desc', sort_field = 'created_at') {
        const body = {
            device_ids: device_ids,
            status_ids: status_ids,
            sort: sort,
            sort_field: sort_field,
            per_page: 50
        };
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'event/occurred/list', body, { headers: this.headers }).pipe(
            map((response: any) => {
                return response.data.occurred_events;
            })
        );
    }

    deleteEvent(id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.delete(this._baseURL + 'event/remove/' + id, { headers: this.headers });
    }

    duplicateEvent(id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.put(this._baseURL + 'event/copy/' + id, {id}, { headers: this.headers });
    }

    getOccurredEventsList(
        paginate,
        per_page?,
        ids?,
        prevPage?,
        pageIndex?,
        totalSize?,
        fieldSort?,
        directionSort?,
        searchString?,
        device_id?
    ) {
        const body = {
            paginate: paginate
        };
        if (device_id) {
            body['device_ids'] = device_id;
        }
        if (paginate === false) {
            if (per_page !== null) {
                body['per_page'] = per_page;
            }
            if (ids !== null) {
                body['ids'] = ids;
            }
            body['sort'] = directionSort;
            body['sort_field'] = fieldSort;
        } else {
            // body['prevPage'] = prevPage;
            body['page'] = pageIndex;
            body['per_page'] = totalSize;
            body['sort_field'] = fieldSort;
            body['sort'] = directionSort;
            body['search_string'] = searchString;
        }

        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'event/occurred/list', body, { headers: this.headers }).pipe(
            map((response: any) => {
                return response.data.occurred_events;
            })
        );
    }
    getEvent(id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'event/occurred/list', {device_ids: [id]}, { headers: this.headers }).pipe(
            map((response: any) => {
                return response.data.occurred_events;
            })
        );
    }
    setOccurredEventsRead() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(this._baseURL + 'event/occurred/set_all_read', { headers: this.headers }).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    setOneOccurredEventsRead(id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .put(this._baseURL + 'event/occurred/status/read/' + id, {}, { headers: this.headers })
            .pipe(
                map((response: any) => {
                    return response.data.occurred_event;
                })
            );
    }

    toggleEventStatus(body, id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'event/toggle_status/' + id, body, { headers: this.headers })
            .toPromise();
    }

    getEventById(event_id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'event/get_by_id', { id: event_id }, { headers: this.headers });
    }

    getTranslateFields(id) {
        const body = {
            model_ids: id,
            append_attributes: ['device_fields_titles']
        };
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'device/model/metering_devices', body, { headers: this.headers })
            .pipe(
                map((response: any) => {
                    return response.data.metering_device_models[0];
                })
            );
    }
}
