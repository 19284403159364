import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PassportService } from 'src/app/auth/passport.service';

@Component({
    selector: 'app-without-access',
    templateUrl: './without-access.component.html',
    styleUrls: ['./without-access.component.less']
})
export class WithoutAccessComponent implements OnInit {
    constructor(private readonly auth: PassportService, private readonly dialog: MatDialog, private readonly router: Router) {}

    ngOnInit() {
        this.dialog.closeAll();
    }

    goBack() {
        this.router.navigate(['cloud']).then();
    }

    logout() {
        this.auth.access_token = null;
        this.router.navigate(['auth/login']).then();
    }
}
