import { Action } from '@ngrx/store';

export enum ConsumersActions {
    FetchConsumers = '[Consumers] Fetch consumers',
    FetchConsumersSuccess = '[Consumers] Fetch consumers success',
    AddConsumer = '[Consumers] Add consumer',
    AddConsumerSuccess = '[Consumers] Add consumer success',
    AddConsumerFail = '[Consumers] Add consumer fail',
    EditConsumer = '[Consumers] Edit consumer',
    EditConsumerSuccess = '[Consumers] Edit consumer success',
    EditConsumerFail = '[Consumers] Edit consumer fail'
}

export class fetchConsumers implements Action {
    readonly type = '[Consumers] Fetch consumers';

    constructor(public payload: { page: number | null; perPage: number | null, search_string?: string }) {}
}

export class fetchConsumersSuccess implements Action {
    readonly type = '[Consumers] Fetch consumers success';

    constructor(public payload: any) {}
}

export class addConsumers implements Action {
    readonly type = '[Consumers] Add consumer';

    constructor(public payload: any) {}
}

export class addConsumersSuccess implements Action {
    readonly type = '[Consumers] Add consumer success';

    constructor() {}
}

export class addConsumersFail implements Action {
    readonly type = '[Consumers] Add consumer fail';

    constructor(public payload: any) {}
}

export class editConsumers implements Action {
    readonly type = '[Consumers] Edit consumer';

    constructor(public payload: any) {}
}

export class editConsumersSuccess implements Action {
    readonly type = '[Consumers] Edit consumer success';

    constructor() {}
}

export class editConsumersFail implements Action {
    readonly type = '[Consumers] Edit consumer fail';

    constructor(public payload: any) {}
}

export type ConsumersUnion =
    | fetchConsumers
    | fetchConsumersSuccess
    | addConsumers
    | addConsumersSuccess
    | addConsumersFail
    | editConsumers
    | editConsumersSuccess
    | editConsumersFail;
