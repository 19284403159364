import { createReducer, on } from '@ngrx/store';
import { AccessActions } from '@store/access/access.actions';

export interface AccessState {
    access_group: null | any;
    is_admin: boolean;
    active_modules: null | any[];
    rules_belong_to_company_id: null | any;
    current_company_id: null | any;
}

export const initialState: AccessState = {
    access_group: null,
    is_admin: false,
    active_modules: null,
    rules_belong_to_company_id: null,
    current_company_id: null
};

export const accessReducer = createReducer(
    initialState,
    on(AccessActions.load, (state, { payload }) => {
        return {
            ...state,
            ...payload
        };
    }),
    on(AccessActions.reset, (state) => {
        return { ...initialState };
    })
);
