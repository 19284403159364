import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { APP_SNACKBAR_DURATION } from '@core/constants/app.cloud.constants';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    constructor(public matSnackBar: MatSnackBar, private translate: TranslateService) {}

    open(errorMsg: string, withTranslate = false, panelClass?: string) {
        const action = this.translate.instant('snackBarMessages.msg_1');
        const message = withTranslate ? this.translate.instant(errorMsg) : errorMsg;

        this.matSnackBar.open(message, action, {
            panelClass,
            duration: APP_SNACKBAR_DURATION
        });
    }

    openInnerHtml(innerHtml: string) {
        this.matSnackBar.openFromComponent(SnackbarComponent, {
            data: {
                html: innerHtml
            },
            duration: APP_SNACKBAR_DURATION
        });
    }
}
