import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PassportService } from 'src/app/auth/passport.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DevicesApiService {
    private _baseURL: string;

    constructor(private _http: HttpClient, private auth: PassportService) {
        this._baseURL = environment.baseURL;
    }

    getListOfGroup(body) {
        return this._http.post(this._baseURL + 'device/opros/group/list', body, { headers: this.getHeaders() });
    }

    getPort(body) {
        return this._http.post(this._baseURL + 'device/transparent_listener_switch', body, { headers: this.getHeaders() });
    }

    getBrands() {
        return this._http.post(this._baseURL + 'device/model/brands', '', { headers: this.getHeaders() });
    }

    getReservedMaxPower(id: string, body) {
        return this._http.post(this._baseURL + `device/messages/reserved_max_power/${id}`, body, { headers: this.getHeaders() });
    }

    setTariff(body) {
        return this._http.post(this._baseURL + 'device/save_schedule_electro', body, { headers: this.getHeaders() });
    }

    getOneMeteringDevice(id) {
        return this._http
            .get(this._baseURL + 'device/metering_device/' + id, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.metering_device));
    }

    getOneGateway(id) {
        return this._http
            .get(this._baseURL + 'device/gateway/' + id, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.gateway));
    }

    getOneBaseStation(id) {
        return this._http
            .get(this._baseURL + 'device/base_station/' + id, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.base_station));
    }

    getMeteringDevices(body) {
        return this._http
            .post(this._baseURL + 'device/metering_devices', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.metering_devices));
    }

    // TODO: check need or not
    getMeteringDevicesFullList(body) {
        return this._http.post(this._baseURL + 'device/metering_devices', body, { headers: this.getHeaders() });
    }

    // TODO: check need or not
    // getMeteringDeviceByGroups(deviceGroups) {
    //     return this._http
    //         .post(
    //             this._baseURL + 'device/metering_devices',
    //             {
    //                 paginate: false,
    //                 device_group_id: deviceGroups
    //             },
    //             { headers: this.getHeaders() }
    //         )
    //         .pipe(map((res: any) => res.data.metering_devices));
    // }

    getDevicesGroups() {
        return this._http.post(this._baseURL + 'device/model/metering_device/groups', '', { headers: this.getHeaders() });
    }

    // TODO: check need or not
    devices_groups() {
        return this._http.post(this._baseURL + 'device/model/metering_device/groups', '', { headers: this.getHeaders() });
    }

    gateway_create(body) {
        return this._http.post(this._baseURL + 'device/gateway/create', body, { headers: this.getHeaders() });
    }

    baseStation_create(body) {
        return this._http.post(this._baseURL + 'device/base_station/create', body, { headers: this.getHeaders() });
    }

    device_create(body) {
        return this._http.post(this._baseURL + 'device/metering_device/create', body, { headers: this.getHeaders() });
    }

    getMeteringDeviceModels(body: any = {}) {
        return this._http.post<any>(this._baseURL + 'device/model/metering_devices', body, { headers: this.getHeaders() });
    }

    getGateways(body) {
        return this._http.post(this._baseURL + 'device/gateways', body, { headers: this.getHeaders() });
    }

    // TODO: check need or not
    getGatewaysFullList(body) {
        return this._http.post(this._baseURL + 'device/gateways', body, { headers: this.getHeaders() });
    }

    // TODO: check need or not
    getGatewaysByIds(body) {
        return this._http
            .post(this._baseURL + 'device/gateways', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.gateways));
    }

    getGatewaysModels(body: any = {}) {
        return this._http.post(this._baseURL + 'device/model/gateways', body, { headers: this.getHeaders() });
    }

    getBaseStationDevices(body) {
        return this._http.post(this._baseURL + 'device/base_stations', body, { headers: this.getHeaders() });
    }

    getBaseStationModels(body: any = {}) {
        return this._http.post(this._baseURL + 'device/model/base_stations', body, { headers: this.getHeaders() });
    }

    getMessages(body) {
        return this._http.post<any>(this._baseURL + 'device/messages', body, { headers: this.getHeaders() });
    }

    // TODO: delete unused functions (method was copied form "getMessages")
    getEnergyMessages(body) {
        return this._http
            .post(this._baseURL + 'device/energy_profile', body, { headers: this.getHeaders() });
    }

    getPowerProfilesByIds(body) {
        return this._http
            .post(this._baseURL + 'device/messages', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.messages));
    }

    getBaseStationMessages(body) {
        return this._http
            .post(this._baseURL + 'base_station/messages', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.messages));
    }

    getPowerProfile(body) {
        return this._http
            .post(this._baseURL + 'device/power_profile', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.messages));
    }

    getLastMessages(id, msgType) {
        // TODO: wtf body in headers???
        const body = new HttpParams().set('msgType', msgType);
        return this._http
            .post(this._baseURL + 'messages/last/' + id, body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.message));
    }

    archiveMeteringDevice(id) {
        return this._http.delete(this._baseURL + 'device/metering_device/remove_in_trash/' + id, { headers: this.getHeaders() });
    }

    deleteMeteringDevice(id) {
        return this._http.delete(this._baseURL + 'device/metering_device/remove_in_trash/' + id, { headers: this.getHeaders() });
    }

    getDeletedMeteringDevices(body) {
        return this._http.post(this._baseURL + 'device/metering_devices', body, { headers: this.getHeaders() });
    }

    getDeletedGateways(body) {
        return this._http.post(this._baseURL + 'device/gateways', body, { headers: this.getHeaders() });
    }

    // TODO: check need or not
    getDeletedBaseStationDevices() {
        return this._http.post(this._baseURL + 'device/base_stations', { is_deleted: true }, { headers: this.getHeaders() });
    }

    archiveGateway(id) {
        return this._http.delete(this._baseURL + 'device/gateway/remove_in_trash/' + id, { headers: this.getHeaders() });
    }

    updateDeviceStatus(body: { device_ids: number[], active: boolean }): Observable<any> {
        return this._http.put(this._baseURL + 'device/metering_device/switch', body, { headers: this.getHeaders() });
    }


    deleteGatewayFromBasket(id) {
        return this._http.delete(this._baseURL + 'device/gateway/remove_from_trash/' + id, { headers: this.getHeaders() });
    }

    restoreGatewayFromBasket(id) {
        return this._http.delete(this._baseURL + 'device/gateway/restore_from_trash/' + id, { headers: this.getHeaders() });
    }

    restoreDeviceFromBasket(id) {
        return this._http.delete(this._baseURL + 'device/metering_device/restore_from_trash/' + id, { headers: this.getHeaders() });
    }

    deleteDeviceFromBasket(id) {
        return this._http.delete(this._baseURL + 'device/metering_device/remove_from_trash/' + id, { headers: this.getHeaders() });
    }

    getGroupsMessage() {
        return this._http
            .get(this._baseURL + 'device/messages/groups', { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.device_messages_groups));
    }

    gateways_compatible(body) {
        return this._http.post(this._baseURL + 'device/gateways/compatible', body, { headers: this.getHeaders() });
    }

    // TODO: need to check
    getTaskDeviceId() {
        return this._http.post(this._baseURL + 'device/get_task_by_device_id', { device_id: 1 }, { headers: this.getHeaders() });
    }

    // TODO: need to check
    getDevice(id) {
        return this._http
            .get(this._baseURL + 'device/metering_device/' + id, {
                headers: this.getHeaders()
            })
            .pipe(
                map((response: any) => {
                    return response.data.metering_device;
                })
            );
    }

    // TODO: need to check
    allInterfaces() {
        return this._http.post(this._baseURL + 'device/model/interfaces', '', { headers: this.getHeaders() });
    }

    getInterfaces(body) {
        return this._http.post(this._baseURL + 'device/model/interfaces', body, { headers: this.getHeaders() });
    }

    getMeteringDevicesTypes() {
        return this._http
            .post(this._baseURL + 'device/model/metering_device/types', '', { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.metering_device_types));
    }

    getAttributesList() {
        return this._http.get<any>(this._baseURL + 'device/attributes/list', { headers: this.getHeaders() });
    }

    getAttributes(body) {
        return this._http.post(this._baseURL + 'device/attributes', body, { headers: this.getHeaders() });
    }

    addAttributes(body) {
        return this._http.post(this._baseURL + 'device/attribute/add', body, { headers: this.getHeaders() });
    }

    deleteAttribute(body) {
        return this._http.post(this._baseURL + 'device/attribute/delete', body, { headers: this.getHeaders() });
    }

    saveChangeAttribute(body) {
        return this._http.put(this._baseURL + 'device/attribute/update', body, { headers: this.getHeaders() });
    }

    getDeviceAddress(meteringPoint_device_id) {
        return this._http.post(this._baseURL + 'device/address/get', { meteringPoint_device_id }, { headers: this.getHeaders() });
    }

    getCollectionCardIndications(body): Observable<any> {
        return this._http.post(this._baseURL + 'device/collection_card_indications', body, { headers: this.getHeaders() });
    }

    getCollectionCardPower(body): Observable<any> {
        return this._http.post(this._baseURL + 'device/collection_card_power', body, { headers: this.getHeaders() });
    }

    getCollectionCardArchive(body): Observable<any> {
        return this._http.post(this._baseURL + 'device/collection_card_archive', body, { headers: this.getHeaders() });
    }

    getCollectionCard(body) {
        return this._http.post(this._baseURL + 'device/collection_card', body, { headers: this.getHeaders() });
    }

    getCollectionCardTimeShifts(body) {
        return this._http.post<any>(this._baseURL + 'device/collection_card_time', body, { headers: this.getHeaders() });
    }

    // TODO: refactor all 'setControl' methods

    sendingCustomCommands(body) {
        return this._http.post(this._baseURL + 'task/device/create', body, { headers: this.getHeaders() });
    }

    // TODO: refactor all 'setControl' methods

    // setControl(body) {
    //     if (this._checkAccess.listAccess('read', 4) || this._checkAccess.listAccess('update', 4)) {
    //         return this._http
    //             .post(
    //                 this._baseURL + 'task/device/create',
    //                 {
    //                     command_type: 'control',
    //                     title: title,
    //                     title_en: title_en || title,
    //                     device_ids: [body.id],
    //                     management: body.management
    //                 },
    //                 {
    //                     headers: this.getHeaders()
    //                 }
    //             )
    //             ;
    //     } else {
    //         this.errors.showSnackbar('msg_39', true);
    //     }
    // }

    // TODO: refactor all 'setControl' methods to this
    setDeviceControl(body) {
        return this._http.post(this._baseURL + 'task/device/create', body, { headers: this.getHeaders() });
    }

    getControl(body) {
        return this._http.post(this._baseURL + 'device/control', body, { headers: this.getHeaders() });
    }

    addOnDashboard(body) {
        return this._http.post(this._baseURL + 'dashboard/add_device', body, { headers: this.getHeaders() });
    }

    deleteOnDashboard(body) {
        return this._http.post(this._baseURL + 'dashboard/delete_device', body, { headers: this.getHeaders() });
    }

    editDevice(body) {
        return this._http.post(this._baseURL + 'device/metering_device/edit', body, { headers: this.getHeaders() });
    }

    saveDeviceSwitch(body, id) {
        return this._http.put(this._baseURL + 'device/metering_device/switch/' + id, body, { headers: this.getHeaders() });
    }

    deleteBaseStationOnTrash(id) {
        return this._http.delete(this._baseURL + 'device/base_stations/remove_in_trash/' + id, { headers: this.getHeaders() });
    }

    restoreBaseStationFromBasket(id) {
        return this._http.delete(this._baseURL + 'device/base_stations/restore_from_trash/' + id, { headers: this.getHeaders() });
    }

    deleteBaseStationFromBasket(id) {
        return this._http.delete(this._baseURL + 'device/base_stations/remove_from_trash/' + id, { headers: this.getHeaders() });
    }

    tieUserDevice(body) {
        return this._http.post(this._baseURL + 'device/tie_user', body, { headers: this.getHeaders() });
    }

    getSchedule(device_id) {
        return this._http.post(this._baseURL + 'device/get_schedule', { device_id }, { headers: this.getHeaders() });
    }

    deleteSchedule(device_id) {
        return this._http.post(this._baseURL + 'device/delete_schedule', { device_id }, { headers: this.getHeaders() });
    }

    saveSchedule(params) {
        return this._http.post(this._baseURL + 'device/save_schedule', params, { headers: this.getHeaders() });
    }

    getTiesUsers(device_id) {
        return this._http
            .post(this._baseURL + 'device/get_ties_user', { device_id }, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.tie_users));
    }

    // TODO: Посмотреть где используется и выпилить

    getMeteringDevicesByIds(body) {
        return this._http.post(this._baseURL + 'device/metering_devices', body, { headers: this.getHeaders() });
    }

    getDeviceFieldTitles() {
        return this._http.get(this._baseURL + 'device/get_field_titles', { headers: this.getHeaders() });
    }

    getAllAvailableAttributes() {
        return this._http
            .get(this._baseURL + 'device/model/company/attributes', { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.device_available_attributes));
    }

    addBalanceGroup(body) {
        return this._http.post(this._baseURL + 'balance/group/create', body, { headers: this.getHeaders() });
    }

    updateBalanceGroup(id, body) {
        return this._http.put(this._baseURL + 'balance/group/' + id, body, { headers: this.getHeaders() });
    }

    getBalanceGroups(body) {
        return this._http.post(this._baseURL + 'balance/groups', body, { headers: this.getHeaders() });
    }

    deleteBalanceGroup(group_id) {
        return this._http.delete(this._baseURL + 'balance/group/' + group_id, { headers: this.getHeaders() });
    }

    getOneBalanceGroup(group_id) {
        return this._http.get(this._baseURL + 'balance/group/' + group_id, { headers: this.getHeaders() });
    }

    getAllMsgTypes() {
        return this._http.get(this._baseURL + 'device/messages/types', { headers: this.getHeaders() });
    }

    saveUpdateBaseStation(body) {
        return this._http
            .put(this._baseURL + 'device/base_station/edit', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data));
    }

    saveUpdateGateway(body) {
        return this._http
            .put(this._baseURL + 'device/gateway/edit', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data));
    }

    getDeviceLogsById(device_id) {
        return this._http
            .post(this._baseURL + 'device/get_log', { device_id }, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data));
    }

    changeGatewayInDevice(body) {
        return this._http
            .post(this._baseURL + 'device/gateway/replace', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data));
    }

    getOpros(device_id) {
        return this._http
            .post(this._baseURL + 'device/opros', { device_id }, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.device_polling));
    }

    clearTrash() {
        return this._http.delete(this._baseURL + 'device/metering_device/clear_trash', { headers: this.getHeaders() });
    }

    togglePollingActive(body) {
        return this._http.put(this._baseURL + 'device/opros/switch', body, { headers: this.getHeaders() });
    }

    getCompanyRejectedData(body) {
        return this._http.post(this._baseURL + 'objects/accounting_point/company/rejected_data', body, { headers: this.getHeaders() });
    }

    getCompanyValidatedPowerProfile(body) {
        return this._http.post(this._baseURL + 'objects/accounting_point/company/validated/power_profile', body, {
            headers: this.getHeaders()
        });
    }

    getJournalMessagesTypes() {
        return this._http
            .post(this._baseURL + 'device/messages/types', { type_group_id: undefined }, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.device_messages_types));
    }

    getMessagesByTypes(body) {
        return this._http.post<any>(this._baseURL + 'device/system/messages/', body, { headers: this.getHeaders() });
    }

    getSerialNumbersByDeviceIds(body) {
        return this._http
            .post(this._baseURL + 'devices/attribute/values', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.attribute_values));
    }

    onPollCreate(body) {
        return this._http.post<any>(this._baseURL + 'task/device/create', body, { headers: this.getHeaders() });
    }

    getTaskPoll(body) {
        return this._http.post<any>(`${this._baseURL}task/device/list`, body, { headers: this.getHeaders() });
    }

    cancelTask(body) {
        return this._http.put<any>(`${this._baseURL}task/device/cancel`, body, { headers: this.getHeaders() });
    }

    settingsClear(id) {
        return this._http.post(`${this._baseURL}device/settings_clear/${id}`, id, { headers: this.getHeaders() });
    }

    getStatusList() {
        return this._http.get<any>(`${this._baseURL}task/device/status/list`, { headers: this.getHeaders() });
    }

    getTaskDeviceLogs(body) {
        return this._http.post<any>(`${this._baseURL}task/device/logs`, body, { headers: this.getHeaders() });
    }

    getLogTypeList() {
        return this._http.get<any>(`${this._baseURL}task/device/log/types/list`, { headers: this.getHeaders() });
    }

    getLogResultCodeList() {
        return this._http.get<any>(`${this._baseURL}task/device/log/result_code/list`, { headers: this.getHeaders() });
    }

    deleteMessage(body) {
        return this._http.delete(`${this._baseURL}device/metering_device/message`, { headers: this.getHeaders(), body });
    }

    onGetMeteringDevicesByIds(body) {
        return this._http.post<any>(`${this._baseURL}device/metering_devices`, body, { headers: this.getHeaders() });
    }

    getAccountingPoint(id) {
        return this._http.get(`${this._baseURL}objects/accounting_point/${id}`, { headers: this.getHeaders() });
    }

    getTranslateFields(body) {
        return this._http
            .post(this._baseURL + 'device/model/metering_devices', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.metering_device_models[0]));
    }

    getLightListMeteringDevices(body: any) {
        return this._http.post(`${this._baseURL}device/metering_devices`, body, { headers: this.getHeaders() });
    }

    generateUuid1() {
        return this._http.post(this._baseURL + 'generate_uuid1', '', { headers: this.getHeaders() });
    }

    messageDirtyOne(body) {
        return this._http.put(this._baseURL + 'device/messages/dirty_one', body, { headers: this.getHeaders() });
    }

    deleteDeviceAllMessages(body) {
        return this._http.delete(this._baseURL + 'device/metering_device/message/all',  { headers: this.getHeaders(), body });
    }

    deleteDeviceMessage(body) {
        return this._http.delete(this._baseURL + 'device/metering_device/message',  { headers: this.getHeaders(), body });
    }

    gatewayMassReplace(body) {
        return this._http.post(this._baseURL + 'device/gateway/mass_replace/', body, { headers: this.getHeaders() });
    }

    setConnectionParams(body: any, id) {
        return this._http.patch(this._baseURL + `device/metering_devices/set_connection_params/${id}`, body, {
            headers: this.getHeaders()
        });
    }

    onSwitchPollGroupActive(body) {
        return this._http.put(this._baseURL + 'device/opros/group/switch', body, { headers: this.getHeaders() });
    }

    deletePollGroup(id) {
        return this._http.post(`${this._baseURL}device/opros/group/delete/${id}`, id, { headers: this.getHeaders() });
    }

    importXMLForReading(formData: FormData, fileVersion: 'virtual' | 'xml80020') {
        return this._http.post(this._baseURL + `device/messages/import/` + fileVersion, formData, { headers: this.getHeaders() });
    }

    importGeneralFiles(formData: FormData, params = {}) {
        return this._http.post<any>(this._baseURL + `objects/smart_import`, formData, {
            headers: this.getHeaders(),
            params
        });
    }

    replacePollGroup(body) {
        return this._http.post(`${this._baseURL}device/opros/group/replace`, body, { headers: this.getHeaders() });
    }

    // методы для страницы admin

    getDevicesPortsForAdmin() {
        return this._http.get(this._baseURL + `admin/broker/list/ports`, { headers: this.getHeaders() });
    }

    getConnectedDevicesForAdmin(body: { filter_imei?: string }) {
        return this._http.post<any>(this._baseURL + `admin/broker/list/connected_gateways`, body, { headers: this.getHeaders() });
    }

    getPollDataForAdmin(body = {}) {
        return this._http.post<any>(`${this._baseURL}admin/broker/request_data`, body, { headers: this.getHeaders() });
    }

    private getHeaders() {
        return new HttpHeaders().append('Access-Control-Allow-Origin', '*').append('Authorization', 'Bearer ' + this.auth.access_token);
    }
}
