import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { DevicesService } from 'src/app/cloud/devices/services/devices.service';
import { CloudService, DateManipulations } from 'src/app/cloud/cloud.service';
import * as _ from 'lodash';
import * as moment from 'moment/moment';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';

const DEFAULT_FIELDS_TITLES = [
    'datetime',
    'in1',
    'start_in1',
    'end_in1',
    'delta_in1',
    'in2',
    'start_in2',
    'end_in2',
    'delta_in2',
    'in3',
    'start_in3',
    'end_in3',
    'delta_in3',
    'in4',
    'start_in4',
    'end_in4',
    'delta_in4',
    'tariff1',
    'start_tariff1',
    'end_tariff1',
    'delta_tariff1',
    'tariff2',
    'start_tariff2',
    'end_tariff2',
    'delta_tariff2',
    'tariff3',
    'start_tariff3',
    'end_tariff3',
    'delta_tariff3',
    'tariff4',
    'start_tariff4',
    'end_tariff4',
    'delta_tariff4',
    'tariff5',
    'start_tariff5',
    'end_tariff5',
    'delta_tariff5'
];

@Component({
    selector: 'app-gateways-data-journal',
    templateUrl: './gateways-data-journal.component.html',
    styleUrls: ['./gateways-data-journal.component.less']
})
export class GatewaysDataJournalComponent implements OnInit, OnDestroy {
    @Input() device: any;
    @Input() dateForm: any;
    @Input() msgGroup: any;
    @Input() msgType: number[];
    @Input() typeJournal: any;

    @ViewChild(MatSort) matSort: MatSort;
    @ViewChild('matPaginator', { read: MatPaginator }) paginator: MatPaginator;
    dataSource = new MatTableDataSource();

    displayedColumns: string[];
    availableColumns: string[];
    fieldsTitles: any = {
        datetime: this.translate.instant('devices.data.msg_1'),
        event_name: this.translate.instant('devices.data.msg_2')
    };
    pageSizeOptions: number[] = [10, 25, 100];
    previousPageIndex = 0;
    pageIndex = 0;
    pageSize = 10;
    itemsLength = 0;

    userSettings: any = null;

    isLoadingResults = false;
    isSpinnerVisible = true;

    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        private translate: TranslateService,
        private devicesService: DevicesService,
        private _cloudService: CloudService,
        public dateManipulations: DateManipulations
    ) {}

    ngOnInit() {
        this._cloudService.settings.pipe(takeUntil(this.destroyed$)).subscribe((settings) => (this.userSettings = settings));
        this.isSpinnerVisible = true;
        if (!this.typeJournal || this.typeJournal.length === 0) {
            this.dataSource.data = [];
            this.isSpinnerVisible = false;
        }
        this.dataSource.sort = this.matSort;

        this.devicesService.refreshData.subscribe(() => {
            this.devicesService
                .getMessagesByTypes(
                    this.device.id,
                    this.dateForm.startDate,
                    this.dateForm.stopDate,
                    this.typeJournal,
                    this.previousPageIndex,
                    this.pageIndex + 1,
                    this.pageSize
                )
                .pipe(takeUntil(this.destroyed$))
                .subscribe((res: any) => {
                    if (res.data.messages.data.length === 0) {
                        this.dataSource.data = [];
                        setTimeout(() => {
                            this.isSpinnerVisible = false;
                        }, 500);
                        return;
                    }
                    this.pageIndex = res.data.messages.current_page - 1;
                    this.pageSize = res.data.messages.per_page;
                    this.itemsLength = res.data.messages.total;
                    this.dataSource.data = res.data.messages.data;
                    this.availableColumns = ['datetime', 'event_name'];
                    this.displayedColumns = this.sortFields(
                        this.userSettings?.['gatewayFields.model_id_' + this.device?.model?.id + '.journal_device'] ??
                            this.availableColumns,
                        this.availableColumns
                    );
                    this.isSpinnerVisible = false;
                });
        });
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    changeFieldVisible(field) {
        const newDisplayedFields = this.displayedColumns.includes(field)
            ? this.displayedColumns.filter((w) => w !== field)
            : [...this.displayedColumns, field];
        this.displayedColumns = this.sortFields(newDisplayedFields, this.availableColumns);
        this.userSettings['gatewayFields.model_id_' + this.device?.model?.id + '.journal_device'] = this.displayedColumns;
        this._cloudService.settings = this.userSettings;
    }

    sortFields(columns, mainArr = null) {
        if (!mainArr) {
            mainArr = DEFAULT_FIELDS_TITLES;
        }
        const result = mainArr.filter((field) => columns.includes(field));
        return result.concat(columns.filter((el) => !result.includes(el)));
    }

    getFormattedDate(value) {
        return moment.unix(this.dateManipulations.dateLocal(value)).utc().format('DD.MM.YYYY HH:mm');
    }

    paginatorChanges(event: PageEvent) {
        this.isLoadingResults = true;
        this.previousPageIndex = event.previousPageIndex;
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.devicesService
            .getMessagesByTypes(
                this.device.id,
                this.dateForm.startDate,
                this.dateForm.stopDate,
                this.typeJournal,
                event.previousPageIndex,
                event.pageIndex + 1,
                event.pageSize
            )
            .pipe(takeUntil(this.destroyed$))
            .subscribe((res: any) => {
                if (res.data.messages.data.length === 0) {
                    this.dataSource.data = [];
                    setTimeout(() => {
                        this.isLoadingResults = false;
                    }, 500);
                    return;
                }
                if (res.data.messages) {
                    this.pageIndex = res.data.messages.current_page - 1;
                    this.pageSize = res.data.messages.per_page;
                    this.itemsLength = res.data.messages.total;
                    this.dataSource.data = res.data.messages.data;

                    setTimeout(() => {
                        this.isLoadingResults = false;
                    }, 200);
                }
            });
    }

    getHeaderByField(field): string {
        return (
            (this.fieldsTitles[field] ? this.fieldsTitles[field]?.title : field) +
            (this.fieldsTitles[field]?.unit ? ', ' + this.fieldsTitles[field]?.unit.title : '')
        );
    }

    getCellType(message, field) {
        return ['datetime', 'created_at', 'realdatetime'].includes(field) ? 'date' : typeof message?.[field];
    }

    downloadExcel() {
        const sortConfig = this.dataSource.sort;
        const messages = _.orderBy(_.cloneDeep(this.dataSource.data), [sortConfig?.active], [sortConfig?.direction || 'desc']);
        let displayedFields = _.cloneDeep(this.displayedColumns).filter(
            (c) => c !== 'delete_message' && c !== 'reason' && c !== 'message_api' && c !== 'method'
        );
        displayedFields = this.sortFields(displayedFields, this.availableColumns);
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet(this.device.name, {
            pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 }
        });
        const headers = [];
        _.each(displayedFields, (column) => {
            headers.push(this.fieldsTitles[column].title || column);
        });

        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFDDDDDD' },
                bgColor: { argb: 'FFFFFFFF' }
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            cell.alignment = { wrapText: true, indent: 1 };
        });
        _.each(messages, (message: any) => {
            const rowArr = [];
            for (const key of displayedFields) {
                if (key === 'datetime' || key === 'realdatetime' || key === 'created_at') {
                    rowArr.push(this.getFormattedDate(message[key]));
                } else {
                    rowArr.push(message[key] !== undefined && message[key] !== null ? message[key] : '-');
                }
            }
            const newRow = worksheet.addRow(rowArr);
            newRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' },
                    bgColor: { argb: 'FFFFFFFF' }
                };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
                cell.alignment = { wrapText: true, indent: 1 };
            });
        });
        _.each(displayedFields, function (column, index) {
            worksheet.getColumn(index + 1).width = 25;
        });
        const downloadMsg = this.translate.instant('devices.data.msg_3', { deviceName: this.device.name });
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            fs.saveAs(
                blob,
                `${downloadMsg} ${moment
                    .unix(this.dateManipulations.dateLocal(this.dateForm.startDate))
                    .utc()
                    .format('DD.MM.YYYY')}
                     ${this.translate.instant('devices.data.msg_4')}
                     ${moment
                         .unix(this.dateManipulations.dateLocal(this.dateForm.stopDate))
                         .utc()
                         .format('DD.MM.YYYY')}.xlsx`
            );
        });
    }
}
