import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PassportService } from '../../auth/passport.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { PARAM_LERS } from '@core/constants/consts';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class CompanySettingsService {
    _baseURL: string;
    public headers = new HttpHeaders();
    token: string;
    filteredModels;
    allMsgTypes;
    allFields;
    saveSetting$: Subject<any> = new Subject<any>();

    constructor(private httpClient: HttpClient, private auth: PassportService) {
        this._baseURL = environment.baseURL;
    }

    getCompanyLogs(body) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.access_token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'company/log',body,{headers: this.headers})
            .pipe(map((data: any) => data.data.log));
    }

    getServicesLogs(pageIndex, pageSize, startDate, stopDate) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.access_token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(
                this._baseURL + 'service/company/log',
                { start_date: startDate, stop_date: stopDate, page: pageIndex, per_page: pageSize },
                {
                    headers: this.headers
                }
            )
            .pipe(
                map((data: any) => {
                    return data.data.service_logs;
                })
            );
    }

    getAvailableService() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.access_token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(this._baseURL + 'service/company/list', { headers: this.headers }).pipe(
            map((data: any) => {
                return data.data;
            })
        );
    }

    getServiceGroups() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.access_token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(this._baseURL + 'service/groups', { headers: this.headers }).pipe(
            map((data: any) => {
                return data.data.service_groups;
            })
        );
    }
    setServiceSettings(settings) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.access_token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + 'service/company/set', settings, { headers: this.headers }).pipe(
            map((data: any) => {
                return data.data;
            })
        );
    }

    onDeleteService(id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.access_token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.delete(`${this._baseURL}service/company/${id}`, { headers: this.headers });
    }

    getDefaultPeakHours() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.access_token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(this._baseURL + 'default_peak_hours', { headers: this.headers }).pipe(
            map((data: any) => {
                return data.data.peak_hours_each_month_by_regions_id;
            })
        );
    }

    peakHoursSet(body, id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.access_token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.post(this._baseURL + `company/${id}/peak_hours`, body, { headers: this.headers });
    }

    getTransparentList(
        previousPage,
        pageIndex,
        pageSize,
        fieldSort,
        directionSort,
        search,
        modelId?,
        modelClassId?,
        active?,
    ) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = {
            page: pageIndex,
            last_page: previousPage,
            per_page: pageSize,
            sort_field: fieldSort,
            sort: directionSort,
            search_string: search,
            paginate: true
        };
        if (!_.isUndefined(modelId) && !_.isNull(modelId)) {
            body['model_id'] = modelId;
        }
        if (!_.isUndefined(modelClassId) && !_.isNull(modelClassId)) {
            body['model_class_id'] = modelClassId;
        }
        if (!_.isUndefined(active) && !_.isNull(active)) {
            body['is_active'] = active;
        }

        return this.httpClient.post<any>(this._baseURL + 'devices/transparent_list', body, {headers: this.headers})
          .pipe(map((res) => res.data));
    }


    exportIntoAlmatySU(formData) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .post(this._baseURL + 'service/almaty_su/export', formData, {
                headers: this.headers
            });
    }
}
