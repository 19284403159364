import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
import { PassportService } from '@auth/passport.service';

@Component({
    template: ``
})
export class ActivateUserComponent {
    token: string;

    constructor(
        private readonly auth: PassportService,
        private readonly dialog: MatDialog,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly translate: TranslateService
    ) {
        this.token = this.route.snapshot.paramMap.get('token');
        this.auth.activateUser(this.token).subscribe(
            (ok) => {
                this.dialog.open(PopupComponent, {
                    width: '400px',
                    data: {
                        name: this.translate.instant('userActivateForm.messages.success.h1'),
                        message: this.translate.instant('userActivateForm.messages.success.desc')
                    }
                });
                this.router.navigate(['auth/login']).then();
            },
            (error) => {
                this.dialog.open(PopupComponent, {
                    width: '400px',
                    data: {
                        name: this.translate.instant('userActivateForm.messages.fail.h1'),
                        message: error.error.error.data.msg
                    }
                });
                this.router.navigate(['auth/login']).then();
                throw error;
            }
        );
    }
}
