import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDataWithSelect } from '@core/services/dialog.service';
import { IdTitleSelector } from '@core/models/ui.models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * @description диалоговое алерт окно с инпутом в виде селектора, используется в связке с DialogService,
 * настраиваемые параметры передаются через MatDialogRef/MAT_DIALOG_DATA
 * при подтверждении эмитит значение селектора в dialogRef, при отмене null
 * @param title заголовок окна, по умолчанию "Подтвердите действие"
 * @param content дополнительная инфо более мелким шрифтом
 * @param placeholder placeholder для инпута
 * @param items массив данных для селектора
 * @param confirmBtn текст на кнопке подтверждения, по умолчанию "Добавить"
 * @param cancelBtn текст на кнопке подтверждения, по умолчанию "Отмена"
 * @param isError красит текст content (дополнительная инфо) в красный цвет
 * @param validators добавляет функции-валидаторы для инпута
 */

@Component({
    selector: 'app-alert-with-select',
    templateUrl: './alert-with-select.component.html',
    styleUrls: ['./alert-with-select.component.less']
})
export class AlertWithSelectComponent implements OnInit, OnDestroy {
    title = 'common.confirm_action';
    content = '';
    placeholder = '';
    confirmBtn = 'common.add';
    cancelBtn = 'common.cancel';
    isError = false;
    items: IdTitleSelector[] = [];
    filteredItems: IdTitleSelector[] = [];
    itemSize = 24;

    searchString = new UntypedFormControl('', []);
    selectFC = new UntypedFormControl(null, []);

    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        private dialogRef: MatDialogRef<AlertWithSelectComponent, string>,
        @Inject(MAT_DIALOG_DATA) private data: DialogDataWithSelect
    ) {}

    ngOnInit() {
        if (this.data) {
            this.title = this.data?.title ?? 'common.confirm_action';
            this.content = this.data?.content ?? '';
            this.placeholder = this.data?.placeholder ?? '';
            this.confirmBtn = this.data?.confirmBtn ?? 'common.add';
            this.cancelBtn = this.data?.cancelBtn ?? 'common.cancel';
            this.isError = !!this.data?.isError;
            this.items = this.data?.items ?? [];
            this.filteredItems = this.items.slice(0);
        }
        if (this.data.validators) {
            this.selectFC.setValidators(this.data.validators);
        }
        this.searchString.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => this.searchItems());
    }

    ngOnDestroy() {
        this.selectFC.setValue(null);
    }

    onConfirm(): void {
        if (this.selectFC.valid) {
            this.dialogRef.close(this.selectFC.value);
        } else {
            this.selectFC.markAsDirty();
        }
    }

    onClose(): void {
        this.dialogRef.close(null);
    }

    private searchItems() {
        if (!this.items.length) {
            return;
        }
        const search = this.searchString.value?.toLowerCase();
        if (!search) {
            this.filteredItems = this.items.slice(0);
        } else {
            this.filteredItems = this.items.filter((item) => item?.title?.toLowerCase()?.includes(search));
        }
    }
}
