import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorsService } from '@core/services/errors.service';
import { DevicesService } from '../../../services/devices.service';
import { ConfirmControlComponent } from '../confirm-control/confirm-control.component';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateLanguageService } from '@core/services/translate-language.service';
import * as moment from 'moment/moment';
import { DateAdapter } from '@angular/material/core';

@Component({
    selector: 'app-control-gateway',
    templateUrl: './gateway.component.html',
    styleUrls: ['./gateway.component.less']
})
export class GatewayControlComponent implements OnInit {
    public currentDevice;
    public currentModel;
    public currentControl;
    public bodyParams: any = {};
    public invisableMode = false;
    public statusMode: any;
    public port: any;
    regExp = /^[0-9ABCDEFabcdef]+$/;

    public freeCommandForm = new UntypedFormGroup({
        payload: new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(444),
            Validators.pattern(this.regExp)
        ]),
        fport: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(255)]),
        class: new UntypedFormControl('', [Validators.required])
    });

    public classForCommandForLoRaWan = [
        {
            value: 'A',
            name: this.translateLanguageService.translateInstant('app_accounting_point.control.msg_60')
        },
        {
            value: 'C',
            name: this.translateLanguageService.translateInstant('app_accounting_point.control.msg_61')
        }
    ];

    setDateTimeSendObject = {
        management: {
            date: null,
            time: null
        }
    };
    disableEdit = false;
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<GatewayControlComponent>,
        private errors: ErrorsService,
        public dialog: MatDialog,
        private deviceService: DevicesService,
        private translateLanguageService: TranslateLanguageService,
        private _devicesService: DevicesService,
        private dateAdapter: DateAdapter<Date>
    ) {
        this.dateAdapter.getFirstDayOfWeek = () => 1;
    }

    ngOnInit(): void {
        this.currentControl = this.data.control;
        this.currentModel = this.data.model;
        this.currentDevice = this.data.device;
        this.invisableMode = this.currentDevice.properties.transparent_mport;
        this.port = this.currentDevice.properties?.transparent_mport;
    }

    public isControlActive(control) {
        return this.currentControl?.[control]?.['active'];
    }

    readDateAndTime() {
        this._devicesService
            .setControl(
                {
                    id: this.currentDevice.id,
                    management: { get_datetime: true }
                },
                'Чтение даты и времени',
                'Reading date and time'
            )
            .then((response: any) => {
                this.errors.showSnackbar(response.data.msg);
            });
    }

    setDateTime() {
        this.disableEdit = true;
        const body = {
            id: this.currentDevice.id,
            management: {
                time_set:
                    moment(this.setDateTimeSendObject.management['date']).add(1, 'day').format('YYYY-MM-DD') +
                    ' ' +
                    (this.setDateTimeSendObject.management['time'] || '00') +
                    ':00'
            }
        };
        this._devicesService.setControl(body, 'Ручное изменение даты и времени', 'Manual change of date and time').then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }

    sendingCustomCommands() {
        const payload = this.freeCommandForm.get('payload').value;
        const fport = this.freeCommandForm.get('fport').value;
        const classCustomCommand = this.freeCommandForm.get('class').value;
        if (!payload || !fport || !classCustomCommand) {
            return this.errors.showSnackbar('msg_81', true);
        }
        if (payload.length % 2 !== 0) {
            return this.errors.showSnackbar('msg_145', true);
        }
        if (!payload.match(this.regExp)) {
            return this.errors.showSnackbar('msg_146', true);
        }
        this._devicesService
            .sendingCustomCommands(
                {
                    id: this.currentDevice.id,
                    management: {
                        send_raw_payload: true,
                        payload: payload,
                        fport: fport,
                        class: classCustomCommand
                    }
                },
                'Произвольная команда',
                'Arbitrary command'
            )
            .then(
                (response: any) => {
                    this.errors.showSnackbar(response.data.msg);
                },
                (error) => {
                    throw error;
                }
            );
    }

    postActiv(mode) {
        const body = {
            device_id: this.currentDevice.id,
            on: mode
        };
        this.deviceService.getPort(body).subscribe(
            (port: any) => {
                this.invisableMode = !this.invisableMode;
                this.port = port.data.port;
            },
            (error) => {}
        );
    }

    sendSetOutControl() {
        if (!this.bodyParams?.setOut || !this.bodyParams?.out || this.bodyParams?.duration < 0 || this.bodyParams?.duration > 255) {
            this.errors.showSnackbar('msg_80', true);
            return;
        }
        this.deviceService
            .setControl(
                {
                    id: this.currentDevice.id,
                    management: {
                        setout: this.bodyParams.setOut,
                        out: this.bodyParams.out,
                        duration: this.bodyParams.duration
                    }
                },
                `Ручное управление шлюзом`
            )
            .then((response: any) => {
                this.errors.showSnackbar(response.data.msg);
                this.dialogRef.close();
            });
    }

    correctTime() {
        this.disableEdit = true;
        const reqData = {
            id: this.currentDevice.id,
            management: {
                time_correct: this.bodyParams.time_correct
            }
        };
        this.deviceService.setControl(reqData, 'Ручная корректировка времени', 'Manual time adjustment').then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
                this.dialogRef.close();
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }

    reloadModel() {
        const dialogRef = this.dialog.open(ConfirmControlComponent, {
            width: '25%'
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((result) => {
                if (result) {
                    this.deviceService
                        .setControl(
                            {
                                id: this.currentDevice.id,
                                management: { reset: true }
                            },
                            'Перезагрузка'
                        )
                        .then((response: any) => {
                            this.errors.showSnackbar(response.data.msg);
                        });
                }
            });
    }

    sendSetParamOutControl(param) {
        const reqData = {
            id: this.currentDevice.id,
            management: {}
        };
        reqData.management[param.key] = this.bodyParams[param.key];
        this.deviceService.setControl(reqData, `Изменение настроек`).then((response: any) => {
            this.errors.showSnackbar(response.data.msg);
            this.dialogRef.close();
        });
    }

    isMTCDataSendActive() {
        return !!this.currentControl?.mts_activate?.active;
    }

    sendMTCDataActivate() {
        const dialogRef = this.dialog.open(ConfirmControlComponent, {
            width: '25%'
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((result) => {
                if (result) {
                    this.deviceService
                        .setControl(
                            {
                                id: this.currentDevice.id,
                                management: { mts_activate: true }
                            },
                            'Активация передачи данных'
                        )
                        .then((response: any) => {
                            this.errors.showSnackbar(response.data.msg);
                        });
                }
            });
    }
}
