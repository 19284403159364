import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CheckAccessService } from '@core/services/check-access.service';

@Injectable({
    providedIn: 'root'
})
export class AdministrationGuard implements CanActivate {
    constructor(private checkAccessService: CheckAccessService, private router: Router) {}

    canActivate(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const isAllowed = this.checkAccessService.isNektaUser();
            if (!isAllowed) {
                this.router.navigate(['withoutAccess']).then();
            }
            resolve(isAllowed);
        });
    }
}
