<h2 mat-dialog-title>{{'output_indications.title' | translate}}</h2>
<ng-container *ngIf="data.resourceType === 4">
    <form [formGroup]="electricityMetersForm">
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{'output_indications.tariff1' | translate}}</mat-label>
            <input matInput
                   type="number"
                   required
                   formControlName="tariff1"/>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{'output_indications.tariff2' | translate}}</mat-label>
            <input matInput
                   type="number"
                   formControlName="tariff2"/>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{'output_indications.tariff3' | translate}}</mat-label>
            <input matInput
                   type="number"
                   formControlName="tariff3"/>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{'output_indications.tariff4' | translate}}</mat-label>
            <input matInput
                   type="number"
                   formControlName="tariff4"/>
        </mat-form-field>
        <mat-form-field
            floatLabel="always"
            appearance="outline"
        >
            <mat-label>{{'output_indications.indication_date' | translate}}</mat-label>
            <input matInput
                   required
                   class="data-date"
                   formControlName="indicationDate"
                   (click)="archiveDate.open()"
                   (focus)="archiveDate.open()"
                   [matDatepicker]="archiveDate"/>
            <mat-datepicker-toggle matSuffix [for]="archiveDate"></mat-datepicker-toggle>
            <mat-datepicker style="margin-left: -33px!important;" #archiveDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="time">
            <mat-label>{{ 'output_indications.indication_time' | translate }}</mat-label>
            <input
                matInput
                required
                aria-label="24hr format"
                [ngxTimepicker]="fullTime"
                [format]="24"
                readonly
                placeholder="{{ 'app_accounting_point.control.msg_14' | translate }}"
                formControlName="indicationTime"
            />
            <ngx-material-timepicker #fullTime [cancelBtnTmpl]="cancelBtn"
                                     [confirmBtnTmpl]="confirmBtn">
            </ngx-material-timepicker>
            <ng-template #cancelBtn>
                <button class="pickerBtn cancelBtn" mat-raised-button style="margin-right: 15px"
                        type="button">
                    {{ 'app_accounting_point.control.msg_15' | translate }}
                </button>
            </ng-template>
            <ng-template #confirmBtn>
                <button class="pickerBtn" mat-raised-button color="primary"
                        type="button">{{ 'app_accounting_point.control.msg_16' | translate }}</button>
            </ng-template>
        </mat-form-field>

        <ngx-material-timepicker #fullTime [cancelBtnTmpl]="cancelBtn"
                                 [confirmBtnTmpl]="confirmBtn">
        </ngx-material-timepicker>

        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{'output_indications.calculation_basics' | translate}}</mat-label>
            <mat-select formControlName="calculationBasics">
                <mat-option *ngFor="let basicValue of calculationBasicsValues" [value]="basicValue.value">
                    {{ basicValue | getTitlePipe: 'value': 'title_en' }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{'output_indications.calculation_method' | translate}}</mat-label>
            <mat-select formControlName="calculationMethod">
                <mat-option *ngFor="let methodValue of calculationMethodValues" [value]="methodValue.value">
                    {{ methodValue | getTitlePipe: 'value': 'title_en' }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
    <div style="display: flex; justify-content: flex-end">
        <button mat-raised-button
                [disabled]="electricityMetersForm.invalid"
                type="button"
                (click)="onSubmit()"
                color="primary">
            {{'output_indications.send'| translate}}
        </button>
        <button style="margin-left: 10px" (click)="dialogRef.close()" mat-button mat-flat-button>
            {{ 'devices.edit.properties.cancel' | translate }}
        </button>
    </div>
</ng-container>

<ng-container *ngIf="data.resourceType === 3">
    <form [formGroup]="heatMetersForm">
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{'output_indications.indication' | translate}}</mat-label>
            <input matInput
                   type="number"
                   required
                   formControlName="tariff1"/>
        </mat-form-field>
        <mat-form-field
            floatLabel="always"
            appearance="outline"
        >
            <mat-label>{{'output_indications.indication_date' | translate}}</mat-label>
            <input matInput
                   required
                   class="data-date"
                   formControlName="indicationDate"
                   (click)="archiveDate.open()"
                   (focus)="archiveDate.open()"
                   [matDatepicker]="archiveDate"/>
            <mat-datepicker-toggle matSuffix [for]="archiveDate"></mat-datepicker-toggle>
            <mat-datepicker style="margin-left: -33px!important;" #archiveDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="time">
            <mat-label>{{ 'output_indications.indication_time' | translate }}</mat-label>
            <input
                matInput
                required
                aria-label="24hr format"
                [ngxTimepicker]="fullTime"
                [format]="24"
                readonly
                placeholder="{{ 'app_accounting_point.control.msg_14' | translate }}"
                formControlName="indicationTime"
            />
            <ngx-material-timepicker #fullTime [cancelBtnTmpl]="cancelBtn"
                                     [confirmBtnTmpl]="confirmBtn">
            </ngx-material-timepicker>
            <ng-template #cancelBtn>
                <button class="pickerBtn cancelBtn" mat-raised-button style="margin-right: 15px"
                        type="button">
                    {{ 'app_accounting_point.control.msg_15' | translate }}
                </button>
            </ng-template>
            <ng-template #confirmBtn>
                <button class="pickerBtn" mat-raised-button color="primary"
                        type="button">{{ 'app_accounting_point.control.msg_16' | translate }}</button>
            </ng-template>
        </mat-form-field>
    </form>
    <div style="display: flex; justify-content: flex-end">
        <button mat-raised-button
                [disabled]="heatMetersForm.invalid"
                type="button"
                (click)="onSubmit()"
                color="primary">
            {{'output_indications.send'| translate}}
        </button>
        <button style="margin-left: 10px" (click)="dialogRef.close()" mat-button mat-flat-button>
            {{ 'devices.edit.properties.cancel' | translate }}
        </button>
    </div>
</ng-container>

<ng-container *ngIf="data.resourceType === 1 || data.resourceType === 2">
    <form [formGroup]="waterGasMetersForm">
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{'output_indications.indication' | translate}}</mat-label>
            <input matInput
                   type="number"
                   required
                   formControlName="in1"/>
        </mat-form-field>
        <mat-form-field
            floatLabel="always"
            appearance="outline"
        >
            <mat-label>{{'output_indications.indication_date' | translate}}</mat-label>
            <input matInput
                   required
                   class="data-date"
                   formControlName="indicationDate"
                   (click)="archiveDate.open()"
                   (focus)="archiveDate.open()"
                   [matDatepicker]="archiveDate"/>
            <mat-datepicker-toggle matSuffix [for]="archiveDate"></mat-datepicker-toggle>
            <mat-datepicker style="margin-left: -33px!important;" #archiveDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="time">
            <mat-label>{{ 'output_indications.indication_time' | translate }}</mat-label>
            <input
                matInput
                required
                aria-label="24hr format"
                [ngxTimepicker]="fullTime"
                [format]="24"
                readonly
                placeholder="{{ 'app_accounting_point.control.msg_14' | translate }}"
                formControlName="indicationTime"
            />
            <ngx-material-timepicker #fullTime [cancelBtnTmpl]="cancelBtn"
                                     [confirmBtnTmpl]="confirmBtn">
            </ngx-material-timepicker>
            <ng-template #cancelBtn>
                <button class="pickerBtn cancelBtn" mat-raised-button style="margin-right: 15px"
                        type="button">
                    {{ 'app_accounting_point.control.msg_15' | translate }}
                </button>
            </ng-template>
            <ng-template #confirmBtn>
                <button class="pickerBtn" mat-raised-button color="primary"
                        type="button">{{ 'app_accounting_point.control.msg_16' | translate }}</button>
            </ng-template>
        </mat-form-field>
    </form>
    <div style="display: flex; justify-content: flex-end">
        <button mat-raised-button
                [disabled]="waterGasMetersForm.invalid"
                type="button"
                (click)="onSubmit()"
                color="primary">
            {{'output_indications.send'| translate}}
        </button>
        <button style="margin-left: 10px" (click)="dialogRef.close()" mat-button mat-flat-button>
            {{ 'devices.edit.properties.cancel' | translate }}
        </button>
    </div>
</ng-container>

