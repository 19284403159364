import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PassportService } from '@auth/passport.service';
import { environment } from '@environments/environment';

// DELETE когда будут убраны все зависимости

/**
 @deprecated будет удалено (не используется)
  для API запросов использовать companies-api.service.ts или users-api.service.ts
  для appVar cloud.service.ts
 */
@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    _baseURL: string;
    public headers = new HttpHeaders();
    appSubject = new Subject();
    appVar;

    constructor(private httpClient: HttpClient, private auth: PassportService) {
        this._baseURL = environment.baseURL;
    }

    set app(app) {
        this.appVar = app;
        this.appSubject.next(app);
    }

    get appConst(): Observable<any> {
        return new Observable((observer) => {
            observer.next(this.appVar);
            observer.complete();
        });
    }

    getUserCompanyInfo() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(this._baseURL + 'user/company', { headers: this.headers }).pipe(
            map((response: any) => {
                return response.data.company;
            })
        );
    }

    saveCompanyInfo(data) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.put(this._baseURL + 'company', data, { headers: this.headers }).toPromise();
    }
}
