import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-power-profile-min-max-modal',
    templateUrl: './power-profile-min-max-modal.component.html',
    styleUrls: ['./power-profile-min-max-modal.component.less']
})
export class PowerProfileMinMaxModalComponent implements OnInit {
    // A+
    power_a_plus_max = '-';
    power_a_plus_min = '-';
    power_a_plus_average = '-';
    // A-
    power_a_minus_max = '-';
    power_a_minus_min = '-';
    power_a_minus_average = '-';
    // R+
    power_r_plus_max = '-';
    power_r_plus_min = '-';
    power_r_plus_average = '-';
    // R-
    power_r_minus_max = '-';
    power_r_minus_min = '-';
    power_r_minus_average = '-';
    // S
    power_s_max = '-';
    power_s_min = '-';
    power_s_average = '-';

    constructor(public dialogRef: MatDialogRef<PowerProfileMinMaxModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        const msgData = {
            power_a_plus: [],
            power_a_minus: [],
            power_r_plus: [],
            power_r_minus: [],
            power_s: []
        };
        let calculatePowerS = true;
        this.data.messages.forEach(el => {
            if (!el.hasOwnProperty('power_a_plus') || !el.hasOwnProperty('power_r_plus') ) {
                calculatePowerS = false;
            }

            if (el.hasOwnProperty('power_a_plus')) {
                msgData.power_a_plus.push(el.power_a_plus);
            }
            if (el.hasOwnProperty('power_a_minus')) {
                msgData.power_a_minus.push(el.power_a_minus);
            }
            if (!el.hasOwnProperty('power_a_minus') && el.hasOwnProperty('power_a_plus')) {
                msgData.power_a_minus.push(0);
            }
            if (el.hasOwnProperty('power_r_plus')) {
                msgData.power_r_plus.push(el.power_r_plus);
            }
            if (el.hasOwnProperty('power_r_minus')) {
                msgData.power_r_minus.push(el.power_r_minus);
            }
            if (!el.hasOwnProperty('power_r_minus') && el.hasOwnProperty('power_r_plus')) {
                msgData.power_r_minus.push(0);
            }

            let powerS;
            if (calculatePowerS) {
                const a_minus = el.power_a_minus ? el.power_a_minus : 0;
                const r_minus = el.power_r_minus ? el.power_r_minus : 0;
                powerS = (Math.sqrt((el.power_a_plus) - a_minus)) +
                    (Math.sqrt((el.power_r_plus) - r_minus));
                if (isNaN(powerS)) {
                    calculatePowerS = false;
                }
                msgData.power_s.push(powerS);
            }
        });
        if (msgData.power_a_plus && msgData.power_a_plus.length) {
            this.power_a_plus_max = this.getMaxValue(msgData.power_a_plus);
            this.power_a_plus_min = this.getMinValue(msgData.power_a_plus);
            this.power_a_plus_average = this.getAverage(msgData.power_a_plus);
        }
        if (msgData.power_a_minus && msgData.power_a_minus.length) {
            this.power_a_minus_max = this.getMaxValue(msgData.power_a_minus);
            this.power_a_minus_min = this.getMinValue(msgData.power_a_minus);
            this.power_a_minus_average = this.getAverage(msgData.power_a_minus);
        }
        if (msgData.power_r_plus && msgData.power_r_plus.length) {
            this.power_r_plus_max = this.getMaxValue(msgData.power_r_plus);
            this.power_r_plus_min = this.getMinValue(msgData.power_r_plus);
            this.power_r_plus_average = this.getAverage(msgData.power_r_plus);
        }
        if (msgData.power_r_minus && msgData.power_r_minus.length) {
            this.power_r_minus_max = this.getMaxValue(msgData.power_r_minus);
            this.power_r_minus_min = this.getMinValue(msgData.power_r_minus);
            this.power_r_minus_average = this.getAverage(msgData.power_r_minus);
        }

        if (calculatePowerS) {
            this.power_s_max = this.getMaxValue(msgData.power_s);
            this.power_s_min = this.getMinValue(msgData.power_s);
            this.power_s_average = this.getAverage(msgData.power_s);
        }
    }

    getMaxValue(data) {
        return Math.max(...data).toFixed(4);
    }

    getMinValue(data) {
        return Math.min(...data).toFixed(4);
    }

    getAverage(data) {
        return ((data.reduce((a, b) => a + b, 0)) / (data.length)).toFixed(4);
    }
}
