import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';

import { DevicesService } from '../../../../../../services/devices.service';
import { ErrorsService } from '@core/services/errors.service';
import { MY_FORMATS} from '@core/constants/consts';
import {TranslateLanguageService} from '@core/services/translate-language.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-display-indications',
    templateUrl: './display-indications.component.html',
    styleUrls: ['./display-indications.component.less'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    ],
})
export class DisplayIndicationsComponent implements OnInit {
    electricityMetersForm: UntypedFormGroup;
    heatMetersForm: UntypedFormGroup;
    waterGasMetersForm: UntypedFormGroup;

    calculationBasicsValues = [
        {value: 'Непредоставление показаний прибора учета', title_en: 'Failure to provide meter readings'},
        {value: 'Недопуск к прибору учета', title_en: 'Access to the meter'},
        {value: 'Неисправность прибора учета', title_en: 'Malfunction of the meter'},
        {value: 'Утрата прибора учета', title_en: 'Loss of a meter'},
        {value: 'Отсутствие прибора учета', title_en: 'Lack of a meter'},
        {value: 'Истечение срока эксплуатации прибора учета', title_en: 'Expiration of the service life of the meter'},
        {value: 'Истечение срока межповерочного интервала прибора учета', title_en: 'Expiration of the calibration interval of the meter'},
        {value: 'Выявление факта бездоговорного потребления', title_en: 'Identification of the fact of non-contractual consumption'},
        {value: 'Выявление факта безучетного потребления', title_en: 'Identification of the fact of unaccounted consumption'},
    ];
    calculationMethodValues = [
        {value: 'Использование показаний контрольного прибора учета', title_en: 'Using the readings of the control meter'},
        {value: 'Использование замещающей информации', title_en: 'Use of replacement information'},
        {value: 'Расчет по максимальной мощности', title_en: 'Maximum power calculation'},
        {value: 'Расчет по токовой нагрузке и напряжению', title_en: 'Calculation by current load and voltage'},
        {value: 'Расчет по среднемесячному потреблению', title_en: 'Calculation based on average monthly consumption'},
        {value: 'Расчет по нормативам', title_en: 'Calculation according to standards'},
    ];
    constructor(
        private _devicesService: DevicesService,
        private errors: ErrorsService,
        private translateLanguageService: TranslateLanguageService,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<DisplayIndicationsComponent>,
        private readonly fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: { device_id: number, model_id: number, resourceType: number }
    ) {}

    ngOnInit(): void {
        if (this.data.resourceType === 4) {
            this.initElectricityMetersForm();
        } else if (this.data.resourceType === 3) {
            this.initHeatMetersForm();
        } else if (this.data.resourceType === 1 || this.data.resourceType === 2) {
            this.initWaterGasMetersForm();
        }
    }

    initElectricityMetersForm() {
        this.electricityMetersForm = this.fb.group({
            tariff1: [null, [Validators.required]],
            tariff2: [null],
            tariff3: [null],
            tariff4: [null],
            indicationDate: [null, [Validators.required]],
            indicationTime: [null, [Validators.required]],
            calculationBasics: [null],
            calculationMethod: [null]
        });
    }

    initHeatMetersForm() {
        this.heatMetersForm = this.fb.group({
            tariff1: [null, [Validators.required]],
            indicationDate: [null, [Validators.required]],
            indicationTime: [null, [Validators.required]],
        });
    }

    initWaterGasMetersForm() {
        this.waterGasMetersForm = this.fb.group({
            in1: [null, [Validators.required]],
            indicationDate: [null, [Validators.required]],
            indicationTime: [null, [Validators.required]],
        });
    }

    onSubmit() {
        const reqData = {
            device_id: this.data.device_id,
            msgType: 1,
        };
        if (this.data.resourceType === 4) {
            const date = moment(new Date(this.electricityMetersForm.get('indicationDate')?.value)).format('DD/MM/YYYY');
            const time = this.electricityMetersForm.get('indicationTime')?.value + ':00';
            const dateTime = moment.utc(date + ' ' + time, 'DD/MM/YYYY HH:mm:ss').unix();
            const tariff2 = +this.electricityMetersForm.get('tariff1')?.value || null;
            const tariff3 = +this.electricityMetersForm.get('tariff2')?.value || null;
            const tariff4 = +this.electricityMetersForm.get('tariff3')?.value || null;
            const tariff5 = +this.electricityMetersForm.get('tariff4')?.value || null;

            const close = this.translateLanguageService.translateInstant('snackBarMessages.msg_1');
            if (+this.electricityMetersForm.get('tariff4')?.value) {
                if (!tariff4) {
                    const message = this.translateLanguageService.translateInstant('output_indications.tariff3_validation');
                    this.snackBar.open(message, close, { duration: 2000 });
                    return;
                } else if (!tariff3) {
                    const message = this.translateLanguageService.translateInstant('output_indications.tariff2_validation');
                    this.snackBar.open(message, close, { duration: 2000 });
                    return;
                }
            } else if (+this.electricityMetersForm.get('tariff3')?.value) {
                if (!tariff3 ) {
                    const message = this.translateLanguageService.translateInstant('output_indications.tariff2_validation');
                    this.snackBar.open(message, close, { duration: 2000 });
                    return;
                }
            }
            let tariff1 = 0;
            if (tariff2) { tariff1 += tariff2; }
            if (tariff3) { tariff1 += tariff3; }
            if (tariff4) { tariff1 += tariff4; }
            if (tariff5) { tariff1 += tariff5; }
            reqData['message'] = {
                datetime: dateTime,
                realdatetime: Math.floor(new Date().getTime() / 1000),
                tariff1,
                tariff2,
                tariff3,
                tariff4,
                tariff5,
                message_api: true,
                reason: this.electricityMetersForm.get('calculationBasics')?.value || null,
                method: this.electricityMetersForm.get('calculationMethod')?.value || null
            };
        } else if (this.data.resourceType === 3) {
            const date = moment(new Date(this.heatMetersForm.get('indicationDate')?.value)).format('DD/MM/YYYY');
            const time = this.heatMetersForm.get('indicationTime')?.value + ':00';
            const dateTime = moment.utc(date + ' ' + time, 'DD/MM/YYYY HH:mm:ss').unix();
            const tariff1 = +this.heatMetersForm.get('tariff1')?.value || null;

            reqData['message'] = {
                datetime: dateTime,
                realdatetime: Math.floor(new Date().getTime() / 1000),
                tariff1,
                message_api: true
            };
        } else if (this.data.resourceType === 1 || this.data.resourceType === 2) {
            const date = moment(new Date(this.waterGasMetersForm.get('indicationDate')?.value)).format('DD/MM/YYYY');
            const time = this.waterGasMetersForm.get('indicationTime')?.value + ':00';
            const dateTime = moment.utc(date + ' ' + time, 'DD/MM/YYYY HH:mm:ss').unix();
            const in1 = +this.waterGasMetersForm.get('in1')?.value || null;

            reqData['message'] = {
                datetime: dateTime,
                realdatetime: Math.floor(new Date().getTime() / 1000),
                in1,
                message_api: true
            };
        }
        this._devicesService.messageDirtyOne(reqData).subscribe((data) => {
            this.dialogRef.close();
        });
    }
}
