import {Injectable, EventEmitter} from '@angular/core';
import _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class InfrastructureReportTreeService {
    public checkedItems = [];
    public checkedItemsBools = {};
    public intermediateCheckedItems = {};
    public objectParents = {};
    public selectedConsumer = null;
    public selectedSupplier = null;
    public selectedPriceCategory = null;
    public checkEventHandler = new EventEmitter<any>();

    private allData = {};
    public checkedObjects = {};
    public indeterminateObjects = {};
    public parentsChilds = null;
    public tiedObjects = [];
    public selectedAccountingPointsForCompareConsumptions = [];
    private branchForObjectCache = {};
    private selectedAccountingPoints = [];
    public firstLoading = null;

    setInitialData(allData: any = {}, checkedItems: any[] = []) {
        this.checkedItems = checkedItems;
        this.allData = allData;
        for (const o of _.flatten(Object.values(this.allData)) as any) {
            this.objectParents[o._id] = o.parentId;
        }
        for (let item of this.checkedItems) {
            this.checkedItemsBools[item._id] = true;
            this.intermediateCheckedItems[item.parentId] = true;
            this.rebuildIsIndeterminateChecked(item._id);
        }
    }

    setCheckedItems(checkedItems: any[], editUser = false) {
        if (editUser) {
            checkedItems.forEach((el) => {
                this.checkedObjects[el._id] = el;
            });
        }
        this.checkedItems = checkedItems;
        this.checkedItemsBools = {};
        this.intermediateCheckedItems = {};
        for (let item of this.checkedItems) {
            this.checkedItemsBools[item._id] = true;
            this.intermediateCheckedItems[item.parentId] = true;
            this.rebuildIsIndeterminateChecked(item._id);
        }
    }

    destroyData() {
        this.allData = {};
        this.checkedItems = [];
        this.checkedItemsBools = {};
        this.intermediateCheckedItems = {};
        this.objectParents = {};
        this.allData = {};
        this.branchForObjectCache = {};
    }

    checkItemHandle(value: any, checked: boolean) {
        const fullBranch = this.buildFullBranchForObject(value);
        if (checked) {
            this.checkedItems = [...this.checkedItems, ...fullBranch];
        } else {
            const fullBranchIds = new Set(fullBranch.map((o) => o._id));
            this.checkedItems = this.checkedItems.filter((o) => !fullBranchIds.has(o._id));
        }
        this.checkedItemsBools = {};
        this.intermediateCheckedItems = {};
        for (let item of this.checkedItems) {
            this.checkedItemsBools[item._id] = true;
            this.intermediateCheckedItems[item.parentId] = true;
            this.rebuildIsIndeterminateChecked(item._id);
        }
        this.markCheckedObjectsByAccountingPoints(this.selectedAccountingPoints);
        this.checkEventHandler.emit({value: value, checked: checked});
    }

    rebuildIsIndeterminateChecked(objectId: any) {
        let parent = this.objectParents[objectId];
        while (parent) {
            this.intermediateCheckedItems[parent] = true;
            parent = this.objectParents[parent];
        }
    }

    loopForParents(parentId) {
        this.intermediateCheckedItems[parentId] = true;
        if (this.objectParents[parentId]) {
            return this.loopForParents(this.objectParents[parentId]);
        }
        return parentId;
    }

    recursiveFindBranch(object, result = []) {
        result.push(object);
        result = [...result, ...(this.allData[object._id] || []).map((o) => ({_id: o._id, parentId: o.parentId}))];
        if ((this.allData[object._id] || []).length > 0) {
            for (let item of this.allData[object._id] || []) {
                if ((this.allData[item._id] || []).length > 0) {
                    result = [...result, ...this.recursiveFindBranch({_id: item._id, parentId: item.parentId}, [])];
                }
            }
        }
        return result;
    }

    buildFullBranchForObject(object) {
        const res: any = [{_id: object._id, parentId: object.parentId, type: object.type}];
            this.parentsChilds?.[object._id]?.all_child_ids.forEach((el) => {
                res.push({_id: el, parentId: object._id});
            });
        return res;
    }

    markCheckedObjectsByAccountingPoints(points) {
        this.selectedAccountingPoints = points;
        this.intermediateCheckedItems = {};
        for (let item of this.checkedItems) {
            this.checkedItemsBools[item._id] = true;
            this.intermediateCheckedItems[item.parentId] = true;
            this.rebuildIsIndeterminateChecked(item._id);
        }
        const objectIds: any = new Set(points.map((p: any) => p.parent_id));
        for (let item of objectIds) {
            this.intermediateCheckedItems[item] = true;
            this.rebuildIsIndeterminateChecked(item);
        }
    }
}
