import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateLanguageService } from '../services/translate-language.service';

@Directive({
    selector: '[appInputMinMaxValidate]'
})
export class InputMinMaxValidateDirective {
    @Input('appInputMinMaxValidate')
    data: { max: number, min: number };

    @HostListener('change')
    onChange() {
        const close = this.translateLanguageService.translateInstant('snackBarMessages.msg_1');
        const msgMax = `${this.translateLanguageService.translateInstant('other.msg_23')} ${this.data.max}`;
        const msgMin = `${this.translateLanguageService.translateInstant('other.msg_24')} ${this.data.min}`;
        const value = +(this.elRef.nativeElement as HTMLInputElement).value.trim();
        if (value > this.data.max) {
            (this.elRef.nativeElement as HTMLInputElement).value = `${this.data.max}`;
            this.snackBar.open(msgMax, close, { duration: 2000 });
        } else if (value < this.data.min) {
            (this.elRef.nativeElement as HTMLInputElement).value = `${this.data.min}`;
            this.snackBar.open(msgMin, close, { duration: 2000 });
        }
    }

    constructor(private elRef: ElementRef,
                private snackBar: MatSnackBar,
                private translateLanguageService: TranslateLanguageService) {
    }
}
