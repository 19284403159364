import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { PassportService } from '@auth/passport.service';
import { CheckAccess } from '../cloud.service';

/**
 * @deprecated будет удалено в финале рефакторинга, использовать companiesApiService
 */

// DELETE when all will be ready

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {
    _baseURL: string;
    public headers = new HttpHeaders();
    token: string;

    constructor(private httpClient: HttpClient, private auth: PassportService, public _checkAccess: CheckAccess) {
        this._baseURL = environment.baseURL;
    }

    listUsers(sort = 'asc', sort_field = 'id', search_string = null) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        const body = {
            sort: sort,
            sort_field: sort_field,
            search_string: search_string
        };
        return this.httpClient.post(this._baseURL + 'company/users', body, { headers: this.headers });
    }

    getCompaniesTypes() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get(this._baseURL + 'company/types');
    }
}
