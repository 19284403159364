import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';

/**
 @deprecated - будет удален в конце рефакторинга,
  вместо него использовать core/services/snackbar.service.ts
  рекомендуемое обозначение нового сервиса: snackbar
 */

@Injectable({
    providedIn: 'root'
})
export class ErrorsService {
    constructor(public snackBar: MatSnackBar, private translate: TranslateService) {}

    showSnackbar(error, flag = false, extraClasses = null, innerHtml = null) {
        const action = this.translate.instant('snackBarMessages.msg_1');
        if (flag) {
            const message = this.translate.instant(`snackBarMessages.${error}`);
            if (extraClasses) {
                this.snackBar.open(message, action, {
                    panelClass: extraClasses,
                    duration: 5000
                });
            } else {
                this.snackBar.open(message, action, {
                    duration: 5000
                });
            }
        } else if (innerHtml && typeof innerHtml === 'string' && innerHtml?.length > 0) {
            let replacedHtml = innerHtml.replace(`[red]`, `&nbsp;<div style="color: #CF5555"> `);
            replacedHtml = replacedHtml.replace(`[/red]`, ` </div>&nbsp;`);
            this.snackBar.openFromComponent(SnackbarComponent, {
                data: {
                    html: replacedHtml
                },
                duration: 5000
            });
        } else {
            if (extraClasses) {
                this.snackBar.open(error, action, {
                    panelClass: extraClasses,
                    duration: 5000
                });
            } else {
                this.snackBar.open(error, action, {
                    duration: 5000
                });
            }
        }
    }
}
