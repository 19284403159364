<mat-card class="page-header">
    <strong>{{ 'devices.data.deviceData'| translate }} {{ currentDevice?.name ? '(' + currentDevice?.name + ')' : '' }}</strong>
    <div class="flex-between g-4">
        <button
            *ngIf="(currentControl && _checkAccess.listAccess('update', 4))"
            mat-raised-button
            color="primary"
            (click)="goToControls()">
            {{ 'devices.data.control' | translate }}
        </button>

        <button *ngIf="currentTab === 'journal_device' && currentControl?.['event_log'].active"
                mat-raised-button
                color="primary"
                (click)="getEventLogs()">
            {{ 'devices.data.buttons.request_journals' | translate }}
        </button>
    </div>
</mat-card>

<div>
    <app-select-device-data-date [tab]="currentTab"
                                 [deviceType]="'gateway'"
                                 (msgTypeChange)="msgTypeChange($event)"
                                 (msgGroupChange)="msgGroupChange($event)"
                                 (dateChange)="dateChange($event)"
                                 (typeJournalChange)="typeJournalChange($event)"
                                 [dates]="selectedDate"
                                 [msgTypes]="msgTypes"
                                 [msgType]="msgType"
                                 [changeCurrentTabEvent]="changeCurrentTabEvent"
                                 [currentDevice]="currentDevice"
                                 (powerProfileTypeChange)="powerProfileTypeChange($event)">
    </app-select-device-data-date>
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="changeTab($event)">
        <mat-tab
            *ngIf="currentDevice?.model_id"
            [attr.data-id]="'tariffs'"
            class="content"
            label="{{ 'devices.data.gatewayData' | translate }}"
        >
            <ng-template matTabContent>
                <gateways-data
                    *ngIf="currentDevice?.hasOwnProperty('model_id')"
                    [device]="currentDevice"
                    [dateForm]="dateForm"
                    [msgGroup]="msgGroup"
                >
                </gateways-data>
            </ng-template>
        </mat-tab>
        <mat-tab
            *ngIf="currentDevice?.model?.tabs?.journal_device"
            [attr.data-id]="'journal_device'"
            class="content mat-card"
            label="{{ 'devices.data.journal_device' | translate }}"
        >
            <ng-template matTabContent>
                <app-gateways-data-journal
                    [dateForm]="dateForm"
                    [typeJournal]="typeJournal"
                    [device]="currentDevice"
                >
                </app-gateways-data-journal>
            </ng-template>
        </mat-tab>
        <mat-tab
            *ngIf="currentDevice?.model?.tabs?.journal"
            [attr.data-id]="'journal'"
            class="content mat-card"
            label="{{ 'devices.data.journal' | translate }}"
        >
            <ng-template matTabContent>
                <metering-devices-data-show
                    [device]="currentDevice"
                    [dateForm]="dateForm"
                    [msgGroup]="msgGroup"
                    [msgType]="msgType"
                    [tab]="'journal'"
                    [deviceType]="'gateway'"
                >
                </metering-devices-data-show>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
