import { Component, Input, OnInit } from '@angular/core';
import { DevicesService } from '@cloud/devices/services/devices.service';
import { SnackbarService } from '@core/services/snackbar.service';

@Component({
    selector: 'app-get-current-values',
    templateUrl: './get-current-values.component.html',
    styleUrls: ['./get-current-values.component.less']
})
export class GetCurrentValuesComponent implements OnInit {
    @Input() currentDevice;
    @Input() currentAccountingPoint;

    public disableEdit = false;

    constructor(private _devicesService: DevicesService, private snackbar: SnackbarService) {}

    ngOnInit(): void {}

    getTariff() {
        this.disableEdit = true;
        const body = {
            id: this.currentDevice.id,
            management: {
                now_value: 1
            }
        };
        this._devicesService.setControl(body, 'Ручной запрос мгновенных').then(
            (response: any) => {
                this.disableEdit = false;
                if (response?.data?.control_set?.result || response?.data?.msg) {
                    this.snackbar.open(response?.data?.msg);
                } else {
                    this.snackbar.open(response.data?.control_set?.error);
                }
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }
}
