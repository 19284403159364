export const selectConsumersState = (state: any) => state.consumers;
export const selectSuppliersState = (state: any) => state.suppliers;
export const selectSystemReportsState = (state: any) => state.systemReports;
export const selectSharedState = (state: any) => state.shared;
export const selectReportGeneratorState = (state: any) => state.reportGenerator;
export const selectDeviceFieldTitlesState = (state: any) => state.deviceFieldsTitles;
export const selectPollGroupsState = (state: any) => state.pollsGroup;

// DELETE code below when all instances will be removed

/**
 * @deprecated использовать селектор из @store/directories/
 */
export const selectDeviceGroupsState = (state: any) => state.directories.deviceGroups;
/**
 * @deprecated использовать селектор из @store/directories/
 */
export const selectDeviceInterfacesState = (state: any) => state.directories.deviceInterfaces;
/**
 * @deprecated использовать селектор из @store/directories/
 */
export const selectMessageGroupsState = (state: any) => state.directories.messageGroups;

/**
 * @deprecated использовать селектор из @store/models/
 */
export const selectDeviceModelsState = (state: any) => state.deviceModels;
/**
 * @deprecated использовать селектор из @store/models/
 */
export const selectMeteringDeviceModelsState = (state: any) => state.deviceModels.meteringDevicesModels;
/**
 * @deprecated использовать селектор из @store/models/
 */
export const selectGatewaysDeviceModelsState = (state: any) => state.deviceModels.gatewayModels;
/**
 * @deprecated использовать селектор из @store/models/
 */
export const selectBSDeviceModelsState = (state: any) => state.deviceModels.baseStationModels;
