import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PassportService } from 'src/app/auth/passport.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UsersApiService {
    private _baseURL: string;

    constructor(private _http: HttpClient, private auth: PassportService) {
        this._baseURL = environment.baseURL;
    }

    addNewUser(body) {
        return this._http.post(this._baseURL + 'user/create', body, { headers: this.getHeaders() });
    }

    deleteUser(id) {
        return this._http.delete(this._baseURL + 'user/' + id, { headers: this.getHeaders() });
    }

    getAccess() {
        return this._http.get(this._baseURL + 'user/access', { headers: this.getHeaders() }).pipe(map((data: any) => data.data));
    }

    getUserInfo_users() {
        return this._http.get(this._baseURL + 'user', { headers: this.getHeaders() }).pipe(map((data: any) => data.data.user));
    }

    getUserInfo_company(id) {
        return this._http
            .get(this._baseURL + 'company/user/' + id, { headers: this.getHeaders() })
            .pipe(map((data: any) => data.data.user));
    }

    getUserCompanyInfo() {
        return this._http.get(this._baseURL + 'user/company', { headers: this.getHeaders() }).pipe(map((data: any) => data.data.company));
    }

    getUserSystemLanguage(param) {
        return this._http.put(this._baseURL + 'user/locale/' + param, '', { headers: this.getHeaders() });
    }

    editUser(body: any, id) {
        return this._http.post(this._baseURL + 'user/edit/' + id, body, { headers: this.getHeaders() });
    }

    saveUserInfo(body) {
        return this._http.put(this._baseURL + 'user', body, { headers: this.getHeaders() });
    }

    saveUserInfoCompany(body, id) {
        return this._http.put(this._baseURL + 'company/user/' + id, body, { headers: this.getHeaders() });
    }

    getAllGroups() {
        return this._http.get(this._baseURL + 'access/groups', { headers: this.getHeaders() });
    }

    getTieUserObjects(body, id) {
        return this._http.post(this._baseURL + `objects/tie_user/${id}`, body, { headers: this.getHeaders() });
    }

    untieUserObjects(body, id) {
        return this._http.post(this._baseURL + `objects/untie_user/${id}`, body, { headers: this.getHeaders() });
    }

    getUserTieObjects(user_id) {
        return this._http.post(this._baseURL + `objects/build_tree`, {user_id, with_stats: false}, { headers: this.getHeaders() }).pipe(
            map((res: any) =>  res.data)
        );
    }

    saveSwitch(body, id) {
        return this._http.put(this._baseURL + 'company/user/switch/' + id, body, { headers: this.getHeaders() });
    }

    updateUserSettings(body) {
        return this._http.put(this._baseURL + 'user/settings', body, { headers: this.getHeaders() });
    }

    changePassword(body, id) {
        return this._http.put(this._baseURL + `user/${id}/change_password`, body, { headers: this.getHeaders() });
    }

    private getHeaders() {
        return new HttpHeaders().append('Access-Control-Allow-Origin', '*').append('Authorization', 'Bearer ' + this.auth.access_token);
    }
}
