import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PassportService } from 'src/app/auth/passport.service';
import { environment } from 'src/environments/environment';
import { AddReportRequestModel, GenerateReportResI } from '@cloud/reports/pages/report-generator/report-generator-types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReportsApiService {
    private _baseURL: string;

    constructor(private _http: HttpClient, private auth: PassportService) {
        this._baseURL = environment.baseURL;
    }

    getAvailableTemplates(body) {
        return this._http.post<any>(this._baseURL + 'report/templates/available', body, { headers: this.getHeaders() });
    }

    createStructureReport(body: { device_ids: Array<number>; startDate: number; stopDate: number }) {
        return this._http.post(this._baseURL + 'report/create/structure', body, {
            headers: this.getHeaders(),
            responseType: 'blob'
        });
    }

    createSystemReport(body) {
        return this._http.post(this._baseURL + 'report/system/create', body, { headers: this.getHeaders() });
    }

    createSingleReport(body) {
        return this._http.post(this._baseURL + 'report/create/single', body, { headers: this.getHeaders() });
    }

    createGroupByDevicesReport(body) {
        return this._http.post(this._baseURL + 'report/create/group', body, { headers: this.getHeaders() });
    }

    createInfrastructureReport(body) {
        return this._http.post<any>(this._baseURL + 'report/infrastructure/create', body, { headers: this.getHeaders() });
    }

    getReports(body) {
        return this._http.post(this._baseURL + 'report/list', body, { headers: this.getHeaders() });
    }

    getSystemReports(body) {
        return this._http.post(this._baseURL + 'report/system/list', body, { headers: this.getHeaders() });
    }

    getSingleSystemReport(id) {
        return this._http.get<any>(this._baseURL + 'report/system/' + id, { headers: this.getHeaders() });
    }

    getReportStatuses() {
        return this._http.get(this._baseURL + 'report/statuses', { headers: this.getHeaders() });
    }

    downloadReport(body) {
        return this._http.post(this._baseURL + 'report/file', body, {
            headers: this.getHeaders(),
            responseType: 'blob'
        });
    }

    downloadSystemReport(body) {
        return this._http.post(this._baseURL + 'report/system/file', body, {
            headers: this.getHeaders(),
            responseType: 'blob'
        });
    }

    deleteReport(body) {
        return this._http.post(this._baseURL + 'report/data/delete', body, { headers: this.getHeaders() });
    }

    deleteSystemReport(reportId) {
        return this._http.delete(this._baseURL + `report/system/${reportId}`, { headers: this.getHeaders() });
    }

    reCreate(body) {
        return this._http.post(this._baseURL + 'report/recreate', body, { headers: this.getHeaders() });
    }

    reCreateSystem(body) {
        return this._http.post(this._baseURL + 'report/system/recreate', body, { headers: this.getHeaders() });
    }

    createNewsletter(body) {
        return this._http.post<any>(this._baseURL + 'task/report/create', body, { headers: this.getHeaders() });
    }

    editNewsletter(body, id) {
        return this._http.put<any>(this._baseURL + 'task/report/update/' + id, body, { headers: this.getHeaders() });
    }

    getSingleReportInfo(body) {
        return this._http.post<any>(this._baseURL + 'report/data', body, { headers: this.getHeaders() });
    }

    getSingleReportPreview(id) {
        return this._http.post<any>(this._baseURL + `report/data/preview/${id}`, {}, { headers: this.getHeaders() });
    }

    updateReports(body) {
        return this._http.post(this._baseURL + 'report/update', body, { headers: this.getHeaders() });
    }

    getSystemTemplates() {
        return this._http.get<any>(this._baseURL + 'report/system/available', { headers: this.getHeaders() });
    }

    getNewsLetters() {
        return this._http.get<any>(this._baseURL + 'task/report/list', { headers: this.getHeaders() });
    }

    deleteNewsLetter(id) {
        return this._http.delete(this._baseURL + 'task/report/remove/' + id, { headers: this.getHeaders() });
    }

    getTemplatePreview(body) {
        return this._http.post(this._baseURL + 'report/template/preview', body, { headers: this.getHeaders() });
    }

    getSystemReportPreview(id) {
        return this._http.post<any>(this._baseURL + `report/system/preview/${id}`, {}, { headers: this.getHeaders() });
    }

    updateSystemReport(body) {
        return this._http.post(this._baseURL + `report/system/update`, body, { headers: this.getHeaders() });
    }

    sendCryptoReport(id, body) {
        return this._http
            .post(this._baseURL + `report/${id}/cryptopro_callback`, body, { headers: this.getHeaders() })
            .pipe(map((result: any) => result?.msg));
    }

    // EXPLAIN: reportGeneratorApi ???
    // createReportFromConstructor(data) {
    //     return this._http
    //         .post(this.reportGeneratorApi + 'report/create', data, {
    //             headers: this.headers
    //         })
    // }

    // saveReportTemplateConstructor(data) {
    //     return this._http.post(this.reportGeneratorApi + 'report/save', data, { headers: this.headers });
    // }

    getAveragingList() {
        return this._http.get(this._baseURL + 'report/system/reference/averaging', { headers: this.getHeaders() });
    }

    getDataTypeList() {
        return this._http.get(this._baseURL + 'report/system/reference/data_type', { headers: this.getHeaders() });
    }

    getMeasuringChannelList() {
        return this._http.get(this._baseURL + 'report/system/reference/measuring_channel', { headers: this.getHeaders() });
    }

    // EXPLAIN: reportGeneratorApi ???
    // getListConstructorTemplates() {
    //     return this._http
    //         .get(this.reportGeneratorApi + 'report/get_list', {headers: this.headers})
    // }

    createGroupStructure(body) {
        return this._http.post(this._baseURL + 'report/create/group/structure', body, { headers: this.getHeaders() });
    }

    updateGroupStructure(body) {
        return this._http.post(this._baseURL + 'report/update', body, { headers: this.getHeaders() });
    }

    createBalanceReport(params) {
        return this._http.post(this._baseURL + 'report/create/group/balance', params, { headers: this.getHeaders() });
    }

    onToggleNewsLetter(body) {
        return this._http.put(this._baseURL + 'task/switch', body, { headers: this.getHeaders() });
    }

    templateUpload(body) {
        return this._http.post(this._baseURL + 'report/templates/upload', body, { headers: this.getHeaders() });
    }

    deleteTemplate(id) {
        return this._http.delete(this._baseURL + `report/templates/upload/${id}`, { headers: this.getHeaders() });
    }

    getTemplateAvailableVariables(body) {
        return this._http.post<any>(this._baseURL + 'report/templates/available_variables', body, { headers: this.getHeaders() });
    }

    // методы для конструктора отчетов

    getReportBuilderList(body) {
        return this._http
            .post<any>(this._baseURL + 'report/designer/list', body, { headers: this.getHeaders() })
            .pipe(map((response: any) => response.data.designer_reports));
    }

    deleteReportBuilder(reportId) {
        return this._http.delete(this._baseURL + `report/designer/${reportId}`, { headers: this.getHeaders() });
    }

    getReportById(reportId) {
        return this._http
            .get<any>(this._baseURL + `report/designer/${reportId}`, { headers: this.getHeaders() })
            .pipe(map((response: any) => response.data.designer_report));
    }

    createTemplate(body) {
        return this._http.post(this._baseURL + 'report/designer/create_template', body, { headers: this.getHeaders() });
    }

    getTemplateList(body) {
        return this._http
            .post(this._baseURL + `report/designer/list_templates`, body, { headers: this.getHeaders() })
            .pipe(map((response: any) => response.data.designer_templates));
    }

    deleteBuilderTemplate(templateId) {
        return this._http.delete(this._baseURL + `report/designer/template/${templateId}`, { headers: this.getHeaders() });
    }

    getTemplateById(templateId) {
        return this._http
            .get(this._baseURL + `report/designer/template/${templateId}`, { headers: this.getHeaders() })
            .pipe(map((res: any) => res?.data?.designer_template));
    }

    updateTemplate(body) {
        return this._http
            .put<any>(this._baseURL + 'report/designer/update_template', body, { headers: this.getHeaders() })
            .pipe(map((response: any) => response.data.system_report));
    }

    saveReport(body: AddReportRequestModel) {
        return this._http.post<any>(this._baseURL + 'report/designer/create', body, { headers: this.getHeaders() });
    }

    updateReport(body: AddReportRequestModel, reportId) {
        return this._http.put(
            this._baseURL + 'report/designer/update',
            {
                ...body,
                _id: reportId
            },
            { headers: this.getHeaders() }
        );
    }

    reportDesignerGenerate(body): Observable<GenerateReportResI> {
        return this._http.post<GenerateReportResI>(this._baseURL + 'report/designer/generate', body, { headers: this.getHeaders() });
    }

    private getHeaders() {
        return new HttpHeaders().append('Access-Control-Allow-Origin', '*').append('Authorization', 'Bearer ' + this.auth.access_token);
    }
}
