<mat-card class="tariff-setting">
    <mat-card-header *ngIf="!isSpecialDays">
        <mat-card-title>{{ ('app_accounting_point.control.msg_55') | translate }}</mat-card-title>
    </mat-card-header>
    <div class="tariff-setting__step-one">
        <span class="tariff-setting__title">{{ 'tariffSetting.title' | translate }}</span>
        <input [(ngModel)]="tariff" class="tariff-setting__count"
               (change)="changeTime()"
               type="number"
               min="1"
               max="4">
        <div *ngIf="tariff>1" class="tariff-setting__step-two__information">
            <span class="tariff-setting__second">{{ 'tariffSetting.answer.sub_msg' | translate }}</span>
            <span class="tariff-setting__second-warning">{{ 'tariffSetting.answer.sub_warning_msg' | translate }}</span>
        </div>
    </div>
    <div class="tariff-setting__container">
        <span *ngIf="tariff === 1"
              class="tariff-setting__tariff-one">
            {{ 'tariffSetting.answer.tariff_one' | translate }}
        </span>
        <div *ngIf="tariff>1"
             class="tariff-setting__options">
            <div class="tariff-setting__options-container">
                <div class="tariff-setting__options-subtitle">
                    <span
                        class="tariff-setting__title-under">{{ 'tariffSetting.answer.tariff_one_info.title' | translate }}</span>
                    <span *ngIf="tariff===2"
                          class="tariff-setting__options-title">{{ 'tariffSetting.answer.tariff_one_info.day' | translate }}</span>
                    <span *ngIf="tariff===3"
                          class="tariff-setting__options-title">{{ 'tariffSetting.answer.tariff_one_info.pic' | translate }}</span>
                </div>
                <div class="tariff-setting__options-setting">
                    <div class="tariff-setting__zone">
                        <span>{{ 'tariffSetting.time_first' | translate }}</span>
                        <input [(ngModel)]="tariff1"
                               class="tariff-setting__options-clock"
                               (change)="updateAtAnyChanges()"
                               [class._noTitle]="tariff===4" type="time">
                    </div>
                    <div *ngIf="tariff === 3"
                         class="tariff-setting__zone">
                        <span>{{ 'tariffSetting.time_second' | translate }}</span>
                        <input class="tariff-setting__options-clock"
                               [(ngModel)]="tariff1S"
                               (change)="updateAtAnyChanges()"
                               [class._noTitle]="tariff===4" type="time">
                    </div>

                </div>
            </div>
            <div class="tariff-setting__options-container">
                <div class="tariff-setting__options-subtitle">
                    <span
                        class="tariff-setting__title-under">{{ 'tariffSetting.answer.tariff_two_info.title' | translate }}</span>
                    <span *ngIf="tariff===2 || tariff===3"
                          class="tariff-setting__options-title">{{ 'tariffSetting.answer.tariff_two_info.night' | translate }}</span>
                </div>
                <div class="tariff-setting__options-setting">
                    <div class="tariff-setting__zone">
                        <span>{{ 'tariffSetting.time_first' | translate }}</span>
                        <input [(ngModel)]="tariff2"
                               class="tariff-setting__options-clock"
                               (change)="updateAtAnyChanges()"
                               [class._noTitle]="tariff===4" type="time">
                    </div>
                </div>
            </div>
            <div *ngIf="tariff === 3 || tariff === 4"
                 class="tariff-setting__options-container">
                <div class="tariff-setting__options-subtitle">
                    <span
                        class="tariff-setting__title-under">{{ 'tariffSetting.answer.tariff_three_info.title' | translate }}</span>
                    <span *ngIf="tariff===3"
                          class="tariff-setting__options-title">{{ 'tariffSetting.answer.tariff_three_info.night' | translate }}</span>
                </div>
                <div class="tariff-setting__options-setting">
                    <div class="tariff-setting__zone">
                        <span>{{ 'tariffSetting.time_first' | translate }}</span>
                        <input class="tariff-setting__options-clock"
                               [(ngModel)]="tariff3"
                               (change)="updateAtAnyChanges()"
                               [class._noTitle]="tariff===4" type="time">
                    </div>
                    <div *ngIf="tariff === 3"
                         class="tariff-setting__zone">
                        <span>{{ 'tariffSetting.time_second' | translate }}</span>
                        <input class="tariff-setting__options-clock"
                               [(ngModel)]="tariff3S"
                               (change)="updateAtAnyChanges()"
                               [class._noTitle]="tariff===4" type="time">
                    </div>
                </div>
            </div>
            <div *ngIf="tariff === 4"
                 class="tariff-setting__options-container">
                <div class="tariff-setting__options-subtitle">
                    <span
                        class="tariff-setting__title-under">{{ 'tariffSetting.answer.tariff_four_info.title' | translate }}</span>
                </div>
                <div class="tariff-setting__options-setting">
                    <div class="tariff-setting__zone">
                        <span>{{ 'tariffSetting.time_first' | translate }}</span>
                        <input [(ngModel)]="tariff4"
                               class="tariff-setting__options-clock"
                               (change)="updateAtAnyChanges()"
                               [class._noTitle]="tariff===4" type="time">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tariff-setting__button" *ngIf="!isSpecialDays">
        <button mat-button
                (click)="sendTariff()"
                mat-flat-button
                color="primary"
                [disabled]="tariff === 0">
            {{ 'tariffSetting.button' | translate }}
        </button>
    </div>
</mat-card>
