import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@core/services/dialog.service';

/**
 * @description диалоговое алерт окно для подтверждения или отмены, используется в связке с DialogService,
 * настраиваемые параметры передаются через MatDialogRef/MAT_DIALOG_DATA
 * при подтверждении эмитит true в dialogRef
 * @param title заголовок окна, по умолчанию "Подтвердите действие"
 * @param content дополнительная инфо более мелким шрифтом
 * @param confirmBtn текст на кнопке подтверждения, по умолчанию "Да"
 * @param cancelBtn текст на кнопке отмены, по умолчанию "Нет"
 * @param isError красит текст content (дополнительная инфо) в красный цвет
 */

@Component({
    selector: 'app-confirm-action',
    templateUrl: './confirm-action.component.html',
    styleUrls: ['./confirm-action.component.less']
})
export class ConfirmActionComponent implements OnInit {
    title = 'common.confirm_action';
    content = '';
    confirmBtn = 'common.yes';
    cancelBtn = 'common.no';
    isError = false;

    constructor(
        private dialogRef: MatDialogRef<ConfirmActionComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) private data: DialogData
    ) {}

    ngOnInit() {
        if (this.data) {
            this.title = this.data?.title ?? 'common.confirm_action';
            this.content = this.data?.content ?? '';
            this.confirmBtn = this.data?.confirmBtn ?? 'common.yes';
            this.cancelBtn = this.data?.cancelBtn ?? 'common.no';
            this.isError = !!this.data?.isError;
        }
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onClose(): void {
        this.dialogRef.close(false);
    }
}
