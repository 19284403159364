import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const TreeActions = createActionGroup({
    source: 'Tree',
    events: {
        addElement: props<{ payload: any }>(),
        cacheElement: props<{ payload: {id: any, items: any} }>(),
        fetchObjectsTree: emptyProps(),
        fetchObjectsTreeWithStats: emptyProps(),
        loadObjectsTree: props<{ payload: any }>(),
        sortObjectsTree: emptyProps(),
        resetObjectsTree: emptyProps(),
        resetAll: emptyProps()
    }
});
