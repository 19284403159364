import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class NektaErrorHandler implements ErrorHandler {
    constructor(
        private translate: TranslateService,
        private zone: NgZone,
        public snackBar: MatSnackBar,
    ) {
    }

    handleError(err: any): void {
        err = _.get(err, 'rejection', err); //
        const navigatorObject: any = navigator;
        const connection = navigatorObject?.connection || navigatorObject?.mozConnection || navigatorObject?.webkitConnection;
        const connectionType = connection?.effectiveType;
        const errorParams = {
            userAgent: navigatorObject?.userAgent || null,
            connectionType: connectionType || null,
            platform: navigatorObject?.platform || null,
            cookieEnabled: navigatorObject?.cookieEnabled || null,
            deviceMemory: navigatorObject?.deviceMemory || null,
            host: window?.location?.hostname || null,
            currentUrl: window?.location?.href || null,
            currentApp: null,
            clientInternetInfo: null,
            stackTrace: err?.stack ? err.stack.toString() : null,
            requestError: null
        };
        try {
            errorParams['currentApp'] = JSON.parse(localStorage.getItem('app'));
        } catch {
        }
        if (err.name === 'HttpErrorResponse') {
            errorParams['requestError'] = {
                url: err.url,
                message: err.message,
                headers: err.headers,
                statusText: err.statusText,
                target: err.error.target,
                error: err.error.error
            };
            this.zone.run(() => {
                const defaultValue = this.translate.instant('snackBarMessages.msg_134');
                const close = this.translate.instant('snackBarMessages.msg_1');
                this.snackBar.open(_.get(err, 'error.error.data.msg', defaultValue), close, {
                    duration: 5000
                });
            });
        } else {
            console.error(err);
        }
    }
}
