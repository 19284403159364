import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DevicesService} from '../../services/devices.service';
import {MatTableDataSource} from '@angular/material/table';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {UntypedFormControl} from '@angular/forms';
import moment from 'moment';
import {TranslateLanguageService} from '../../../../core/services/translate-language.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY',
    },
    display: {
        dateInput: 'YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};
@Component({
    selector: 'app-maximum-power-redundancy',
    templateUrl: './maximum-power-redundancy.component.html',
    styleUrls: ['./maximum-power-redundancy.component.less'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    ]
})
export class MaximumPowerRedundancyComponent implements OnInit, OnDestroy {
    public date = new UntypedFormControl(moment());
    public tableInfo: { current_powers: Array<null | number>, max_power: string };

    public dataSource = new MatTableDataSource();

    public displayedColumns: string[] = ['month', 'max_power', 'actual_power', 'reserved_power'];
    public lang;
    private destroy$: Subject<void> = new Subject<void>();

    constructor(public dialogRef: MatDialogRef<MaximumPowerRedundancyComponent>,
                private dateAdapter: DateAdapter<Date>,
                private translateLanguageService: TranslateLanguageService,
                @Inject(MAT_DIALOG_DATA) public data: { id: string },
                private devicesService: DevicesService) {
    }

    ngOnInit(): void {
        this.devicesService.getReservedMaxPower(this.data.id, {year: moment().year().toString()})
            .subscribe((next: { current_powers: Array<null | number>, max_power: string }) => {
                this.tableInfo = next;
                this.renderTable();
            });

        this.translateLanguageService.currentLanguage$
            .pipe(takeUntil(this.destroy$))
            .subscribe((lang) => {
                this.dateAdapter.setLocale(lang);
                this.lang = lang;
            });
    }

    renderTable() {
        this.dataSource.data = this.tableInfo.current_powers.reduce((endData: any, item: any, index: number) => {
            endData.push({
                month: this.translateLanguageService.translateInstant(`month.month_${index}`),
                max_power: this.tableInfo.max_power,
                actual_power: item || '-',
                reserved_power: item ? (+this.tableInfo.max_power - +item) : '-'
            });
            return endData;
        }, []);
    }

    setYear($event: any, dp: any) {
        const ctrlValue = this.date.value;
        ctrlValue.year($event.year());
        this.date.setValue(ctrlValue);
        dp.close();
        this.devicesService.getReservedMaxPower(this.data.id, {year: this.date.value.year().toString()})
            .subscribe((next: { current_powers: Array<null | number>, max_power: string }) => {
                this.tableInfo = next;
                this.renderTable();
            });
    }

    returnType(elementElement: any): any {
        return typeof  elementElement;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
