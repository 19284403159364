import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

// TODO: почистить закомментированный код - скопирован с оригинала сервиса InfrastructureReportTreeService

@Injectable({
    providedIn: 'root'
})
export class ObjectsTreeService {
    // public checkEventHandler = new EventEmitter<any>(); TODO: find how to change
    public checkedObjects = {};
    public expandedObjects = {};
    public indeterminateObjects = {};
    public parentChilds = null;
    public tiedObjects = [];
    public selectedAccountingPointsForConsumptionCompare = [];
    public isFirstLoad = null;
    public loading = false;
    readonly expandedObjects$ = new BehaviorSubject<any>(this.expandedObjects);
    readonly selectedNode$ = new BehaviorSubject<any>(null);
    readonly selectedNodeChilds$ = new BehaviorSubject<any>(null);
    private selectedNode = null; // может быть или null (ничего не выбрано) или 'company' (выбрана вся компания) или объект
    private selectedNodeChilds = null;
    private lastCheckedItem$ = new BehaviorSubject<any>(null); // checkEventHandler
    private removeCheckedItemFromTree$ = new BehaviorSubject<any>(null); // removeCheckEventHandler

    private checkedItems = [];
    private checkedItemsStatuses = {};
    private intermediateCheckedItems = {};
    private objectParents = {};
    private selectedConsumer = null;
    private selectedSupplier = null;
    private selectedPriceCategory = null;
    private allTreeData = {};
    private branchForObjectCache = {};
    private selectedAccountingPoints = [];

    setInitialData(allData: any = {}, checkedItems: any[] = []) {
        this.checkedItems = checkedItems;
        this.allTreeData = allData;
        for (const o of _.flatten(Object.values(this.allTreeData)) as any) {
            this.objectParents[o?._id || '-'] = o?.parentId;
        }
        this.updateCheckedItemsParams();
    }

    getCheckedItems() {
        return this.checkedItems;
    }

    getLastCheckedItem$() {
        return this.lastCheckedItem$.asObservable();
    }

    removeCheckedItem$() {
        return this.removeCheckedItemFromTree$.asObservable();
    }

    setToRemoveCheckedItem(item) {
        this.removeCheckedItemFromTree$.next(item);
    }

    setLastCheckedItem(item) {
        this.lastCheckedItem$.next(item);
    }

    setCheckedItems(checkedItems: any[]) {
        this.checkedItems = checkedItems;
        this.checkedItemsStatuses = {};
        this.intermediateCheckedItems = {};
        this.updateCheckedItemsParams();
    }

    resetAllData() {
        this.checkedObjects = {};
        this.expandedObjects = {};
        this.indeterminateObjects = {};
        this.parentChilds = null;
        this.tiedObjects = [];
        this.selectedAccountingPointsForConsumptionCompare = [];
        this.isFirstLoad = null;
        this.loading = false;
        this.selectedNode = null;
        this.selectedNodeChilds = null;
        this.checkedItems = [];
        this.checkedItemsStatuses = {};
        this.intermediateCheckedItems = {};
        this.objectParents = {};
        this.selectedConsumer = null;
        this.selectedSupplier = null;
        this.selectedPriceCategory = null;
        this.allTreeData = {};
        this.branchForObjectCache = {};
        this.selectedAccountingPoints = [];

        this.expandedObjects$.next({});
        this.selectedNode$.next(null);
        this.selectedNodeChilds$.next(null);
        this.lastCheckedItem$.next(null);
    }

    handleItemChecking(value: any, checked: boolean) {
        // checkItemHandle
        const fullBranch = this.buildFullBranchForObject(value);
        if (checked) {
            this.checkedItems = [...this.checkedItems, ...fullBranch];
        } else {
            const fullBranchIds = new Set(fullBranch.map((o) => o?._id));
            this.checkedItems = this.checkedItems.filter((o) => !fullBranchIds.has(o?._id));
        }
        this.checkedItemsStatuses = {};
        this.intermediateCheckedItems = {};
        this.updateCheckedItemsParams();
        this.markCheckedObjectsByAccountingPoints(this.selectedAccountingPoints);
        this.setLastCheckedItem({ value, checked });
    }

    rebuildIndeterminateCheck(objectId: any) {
        let parent = this.objectParents?.[objectId];
        while (parent) {
            this.intermediateCheckedItems[parent] = true;
            parent = this.objectParents?.[parent];
        }
    }

    closeAllExpandedObjects() {
        this.updateExpandedObjects({});
    }

    updateExpandedObjects(v) {
        this.expandedObjects = v;
        this.expandedObjects$.next(this.expandedObjects);
    }

    updateSelectedNode(v) {
        this.selectedNode = v;
        this.selectedNode$.next(this.selectedNode);
    }

    updateSelectedNodeChilds(v) {
        this.selectedNodeChilds = v;
        this.selectedNodeChilds$.next(this.selectedNodeChilds);
    }

    // loopForParents(parentId) {
    //     this.intermediateCheckedItems[parentId] = true;
    //     if (this.objectParents[parentId]) {
    //         return this.loopForParents(this.objectParents[parentId]);
    //     }
    //     return parentId;
    // }
    //
    // recursiveFindBranch(object, result = []) {
    //     result.push(object);
    //     result = [...result, ...(this.allTreeData[object._id] || []).map((o) => ({ _id: o._id, parentId: o.parentId }))];
    //     const isObjectWithChildes = !!this.allTreeData?.[object?._id || '--']?.length;
    //     if (isObjectWithChildes) {
    //         for (const item of this.allTreeData[object._id] || []) {
    //             const isItemWithChildes = !!this.allTreeData?.[item?._id || '--']?.length;
    //             if (isItemWithChildes) {
    //                 result = [...result, ...this.recursiveFindBranch({ _id: item._id, parentId: item.parentId }, [])];
    //             }
    //         }
    //     }
    //     return result;
    // }

    buildFullBranchForObject(object) {
        const res: any = [{ _id: object._id, parentId: object.parentId, type: object.type }];
        if (Array.isArray(this.parentChilds?.[object._id]?.all_child_ids)) {
            this.parentChilds?.[object._id]?.all_child_ids.forEach((el) => {
                res.push({ _id: el, parentId: object._id });
            });
        }
        return res;
    }

    markCheckedObjectsByAccountingPoints(points) {
        this.selectedAccountingPoints = points;
        this.intermediateCheckedItems = {};
        this.updateCheckedItemsParams();
        const objectIds: any = new Set(points.map((p: any) => p.parent_id));
        for (const item of objectIds) {
            this.intermediateCheckedItems[item] = true;
            this.rebuildIndeterminateCheck(item);
        }
    }

    private updateCheckedItemsParams() {
        if (Array.isArray(this.checkedItems)) {
            this.checkedItems?.forEach((item) => {
                this.checkedItemsStatuses[item?._id || '-'] = true;
                this.intermediateCheckedItems[item?.parentId || '-'] = true;
                this.rebuildIndeterminateCheck(item?._id);
            });
        }
    }
}
