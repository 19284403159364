import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {DevicesService} from '../../../../../../services/devices.service';
import {ErrorsService} from '@core/services/errors.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import * as fromModels from '../../../../../../../../store/actions/models.action';
import {ReplaySubject} from 'rxjs';

@Component({
    selector: 'app-get-event-logs',
    templateUrl: './get-event-logs-modal.component.html',
    styleUrls: ['./get-event-logs-modal.component.less']
})
export class GetEventLogsModalComponent implements OnInit, OnDestroy {
    disableEdit = false;
    controls: any;
    eventLogsSendObject = {
        id: 0,
        management: {
            event_log: true,
            event_index: 0
        }
    };
    currentDevice;
    currentStatus;
    sendObject = <any>{
        management: {}
    };
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(private _devicesService: DevicesService,
                private errors: ErrorsService,
                private store: Store<{}>,
                public dialogRef: MatDialogRef<GetEventLogsModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.sendObject['id'] = _.get(this.data, 'device_id');
        this.eventLogsSendObject.id = _.get(this.data, 'device_id');

        this._devicesService
            .getOneMeteringDevice(_.get(this.data, 'device_id'))
            .pipe(takeUntil(this.destroyed$))
            .subscribe((device: any) => {
                this.currentDevice = device;
                if (!_.isNull(device.status)) {
                    this.currentStatus = device.status;
                    this.sendObject = device.status;
                }
                this.store
                    .pipe(
                        select(fromModels.getMeteringDeviceModelById, {
                            id: _.get(this.data, 'model_id')
                        })
                    )
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((model: any) => {
                        if (model.options.hasOwnProperty('control')) {
                            this.controls = model.options.control;
                        }
                    });
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    getEventLogs() {
        this.disableEdit = true;
        this._devicesService.setControl(this.eventLogsSendObject, `Ручной сбор журнала событий`).then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }
}
