import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { DevicesService } from 'src/app/cloud/devices/services/devices.service';
import { ErrorsService } from '@core/services/errors.service';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import * as fromModels from 'src/app/store/actions/models.action';

@Component({
    selector: 'app-event-logs-modal',
    templateUrl: './event-logs-modal.component.html',
    styleUrls: ['./event-logs-modal.component.less']
})
export class EventLogsModalComponent implements OnInit, OnDestroy {
    disableEdit = false;
    controls: any;
    event_index;
    currentDevice;
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        private _devicesService: DevicesService,
        private errors: ErrorsService,
        private store: Store<{}>,
        public dialogRef: MatDialogRef<EventLogsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this._devicesService
            .getGatewaysByIds([this.data?.['device_id']])
            .pipe(takeUntil(this.destroyed$))
            .subscribe((device: any) => {
                this.currentDevice = device[0];
            });

        this.store
            .pipe(select(fromModels.getGatewayModelById, { id: this.data?.['model_id'] }))
            .pipe(takeUntil(this.destroyed$))
            .subscribe((model: any) => {
                if (model.options?.['control']) {
                    this.controls = model.options.control;
                }
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    getEventLogs() {
        this.disableEdit = true;
        const body = {
            id: this.currentDevice?.id,
            management: {
                event_log: true,
                event_index: this.event_index
            }
        };
        this._devicesService.setControl(body, `Ручной сбор журнала событий`).then(
            (res: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(res.data.msg);
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            },
        );
    }
}
