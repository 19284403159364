<div>
    <mat-form-field style="left: 25%;" appearance="fill">
        <mat-label>{{'max_power.msg_1' | translate}}</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp
                        startView="multi-year"
                        (yearSelected)="setYear($event, dp)"
                        panelClass="example-month-picker">
        </mat-datepicker>
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container *ngFor="let title of displayedColumns">
            <ng-container [matColumnDef]="title">
                <th mat-header-cell *matHeaderCellDef> {{'other.msg_'+ title | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    {{returnType(element[title]) === 'number' ? (element[title] | number: '1.0-4') : element[title] }}
                </td>
            </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>
