import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateService } from '@core/services/date.service';

/**
 * @description простые часы без стилевого оформления
 */

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.less']
})
export class ClockComponent implements OnInit, OnDestroy {
    time = '';
    private timeInterval;

    constructor(private dateService: DateService) {}

    ngOnInit() {
        this.timeInterval = setInterval(() => {
            this.time = this.dateService.getOnlyTime(Date.now());
        }, 1000);
    }

    ngOnDestroy() {
        clearInterval(this.timeInterval);
    }
}
