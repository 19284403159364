import {PollGroupsActions, PollGroupsUnion} from '../actions/polls-group.actions';

export interface PollsGroupState {
    data: any;
    currentPage: number;
    lastPage: number;
    perPage: number;
    total: number;
    error: any;
}

export const initialState: PollsGroupState = {
    data: [],
    currentPage: null,
    lastPage: null,
    perPage: null,
    total: null,
    error: null
};

// DELETE: when all instances will be eliminated

/**
 * @deprecated will be deleted
 */

export function reducer(state = initialState, action: PollGroupsUnion): PollsGroupState {
    switch (action.type) {
        case PollGroupsActions.FetchPollGroupsSuccess: {
            return {
                data: action.payload.data,
                currentPage: action.payload.current_page,
                lastPage: action.payload.last_page,
                perPage: action.payload.per_page,
                total: action.payload.total,
                error: null
            };
        }
        case PollGroupsActions.AddPollGroupFail: {
            return {
                ...state,
                error: action.payload
            };
        }
        default: {
            return state;
        }
    }
}
