import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {PollGroupsActions} from '../actions/polls-group.actions';
import {PollsGroupService, PollsGroupStateService} from '../../services/polls-group.service';

@Injectable()
export class PollGroupsEffects {
    fetchPollGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PollGroupsActions.FetchPollGroups),
            map((action: any) => action.payload),
            exhaustMap((params: any) =>
                this.pollsGroupService.getGroups(params.page, params.perPage).pipe(
                    map((consumers) => ({
                        type: PollGroupsActions.FetchPollGroupsSuccess,
                        payload: consumers
                    })),
                    catchError((e) => {
                        throw e;
                    })
                )
            )
        )
    );

    addPollGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PollGroupsActions.AddPollGroup),
            map((action: any) => action.payload),
            exhaustMap((data: any) =>
                this.pollsGroupService
                    .addPollGroup({
                        name: data.name,
                        model_id: data.model_id,
                        connection_type: data.connection_type,
                        device_interface_id: data.device_interface_id,
                        poll_params: data.poll_params,
                        device_ids: data.device_ids,
                        autoload_params: data.autoload_params,
                        service_params: data.service_params,
                        selected_data: data.selected_data,
                        poll_one: data.poll_one,
                    })
                    .pipe(
                        map((msg) => this.pollsGroupStateService.addPollGroupSuccess(data.page, data.perPage, msg)),
                        catchError((error) => of(this.pollsGroupStateService.addPollGroupFail(error)))
                    )
            )
        )
    );

    editPollGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PollGroupsActions.EditPollGroup),
            map((action: any) => action.payload),
            exhaustMap((data: any) => {
                const body = {
                    id: data.id,
                    name: data.name,
                    model_id: data.model_id,
                    connection_type: data.connection_type,
                    device_interface_id: data.device_interface_id,
                    poll_params: data.poll_params,
                    device_ids: data.device_ids,
                    is_active: data.is_active,
                    autoload_params: data.autoload_params,
                    service_params: data.service_params,
                    selected_data: data.selected_data,
                    poll_one: data.poll_one,
                    report_period_update: data.report_period_update,
                    warning_report_period: data.warning_report_period
                };
                if (data?.averaging_pp) {
                    body['averaging_pp'] = data['averaging_pp'];
                }
                return this.pollsGroupService
                    .editPollGroup(body)
                    .pipe(
                        map((msg) => this.pollsGroupStateService.editPollGroupSuccess(data.page, data.perPage, msg)),
                        catchError((error) => of(this.pollsGroupStateService.editPollGroupFail(error)))
                    )
} )
        )
    );

    constructor(
        private actions$: Actions,
        private pollsGroupService: PollsGroupService,
        private pollsGroupStateService: PollsGroupStateService
    ) {
    }
}
