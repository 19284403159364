<mat-card class="innerContent" [style.display]="!isSpinnerVisible ? 'none' : 'block'">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</mat-card>
<mat-card class="innerContent" *ngIf="!isSpinnerVisible && dataSource?.data?.length === 0">
    <div style="text-align: center">{{ 'devices.data.nothing' | translate }}</div>
</mat-card>

<mat-card
    [style.display]="isSpinnerVisible ? 'none' : 'block'"
    *ngIf="showChart && dataSource?.data?.length != 0"
    class="innerContent device-chart-content">

    <mat-card-title>
        <span style="vertical-align: middle">{{'devices.data_show.chart'|translate}}</span>
    </mat-card-title>
    <ng-container *ngIf="!isEnergy">
        <mat-checkbox class="kkt-toggle"
                      [checked]="RAMode"
                      (change)="changeRAMode($event)">
            R/A
        </mat-checkbox>
        <mat-checkbox *ngIf="profileType === 30" class="kkt-toggle"
                      [checked]="energy"
                      (change)="changeEnergy($event)">
            {{'devices.data_show.energy' | translate}}
        </mat-checkbox>
    </ng-container>
    <mat-checkbox class="kkt-toggle"
                  *ngIf="peakHoursList"
                  [(ngModel)]="peakHours"
                  (change)="onTogglePeakHours($event)">
        {{'devices.data_show.peak_hours' | translate}}
    </mat-checkbox>
    <ng-container *ngIf="!isEnergy">
        <button mat-button mat-flat-button color="primary"
                (click)="onPopupToggle('som')"
                style="position: absolute;right: 17px;top: 30px;">
            {{'devices.data_show.min_max' | translate}}
        </button>
        <button mat-button mat-flat-button color="primary"
                (click)="onPopupToggle('max')"
                style="position: absolute;right: 150px; top: 30px;">
            {{'devices.data_show.msg_5' | translate}}
        </button>
    </ng-container>
    <div id="deviceChartPowerProfile" style="overflow: hidden"  class="w-100"></div>
</mat-card>


<mat-card
    [style.visibility]="isSpinnerVisible == true || dataSource.data.length == 0 ? 'hidden' : 'visible'"
    class="innerContent"
>
    <mat-card-title>
        <span style="vertical-align: middle">{{'devices.data_show.data'| translate}}</span>
        <div class="fields-setting-menu">
            <mat-checkbox class="kkt-toggle" [checked]="withLoss" (change)="changeWithKktOrLoss($event, 'loss')"
            >{{'devices.data_show.with_losses'| translate}}</mat-checkbox
            >
            <mat-checkbox class="kkt-toggle" [checked]="withKt" (change)="changeWithKktOrLoss($event, 'kt')"
            >{{'devices.data_show.with_ct'| translate}}</mat-checkbox
            >
            <button mat-icon-button (click)="refreshDataHandler()">
                <mat-icon>refresh</mat-icon>
            </button>
            <button mat-icon-button (click)="downloadExcel()">
                <mat-icon class="personal_design_icon">cloud_download</mat-icon>
            </button>
            <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="dataType === 'device'">
                <mat-icon>settings</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <mat-list>
                    <mat-list-item *ngFor="let field of availableColumns">
                        <mat-checkbox
                            [checked]="displayedColumns?.indexOf(field) !== -1"
                            (click)="$event.stopPropagation()"
                            (change)="changeFieldVisible(field)"
                        >
                            <ng-container *ngIf="lang === 'en'; else langRu">
                                {{ fieldsTitles[field]?.title_en ? fieldsTitles[field]?.title_en : field}}
                                {{ fieldsTitles[field]?.unit ? ', ' + fieldsTitles[field]?.unit?.title_en : '' }}
                            </ng-container>
                            <ng-template #langRu>
                                {{ fieldsTitles[field] ? fieldsTitles[field]?.title : field}}
                                {{ fieldsTitles[field]?.unit ? ', ' + fieldsTitles[field]?.unit.title : '' }}
                            </ng-template>
                        </mat-checkbox>
                    </mat-list-item>
                </mat-list>
            </mat-menu>
        </div>
    </mat-card-title>
    <div class="data-table">
        <table
            mat-table
            matSort
            [dataSource]="dataSource"
            class="mat-elevation-z8 device-data"
            matSortActive="datetime"
            matSortDisableClear
            matSortDirection="desc"
        >
            <ng-container *ngFor="let field of displayedColumns; let i = index" matColumnDef="{{ field }}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <ng-container *ngIf="field !== 'delete_message'; else emptyTh">
                        <ng-container *ngIf="lang === 'en'; else langRu">
                            {{ fieldsTitles[field]?.title_en ? fieldsTitles[field].title_en : field}}
                            {{ fieldsTitles[field]?.unit ? ', ' + fieldsTitles[field]?.unit.title_en : '' }}
                        </ng-container>
                        <ng-template #langRu>
                            {{ fieldsTitles[field] ? fieldsTitles[field]?.title : field
                            }}{{ fieldsTitles[field]?.unit ? ', ' + fieldsTitles[field]?.unit.title : '' }}
                        </ng-template>
                    </ng-container>
                    <ng-template #emptyTh></ng-template>
                </th>
                <td mat-cell *matCellDef="let message" disableClear="true">
                    <ng-container *ngIf="field !== 'delete_message'; else emptyTd">
                        <p *ngIf="field === 'datetime' || field === 'created_at' || field === 'realdatetime'">
                            {{ getFormatedDate(message[field]) }}
                        </p>
                        <p
                            *ngIf="
                            field !== 'datetime' &&
                            field !== 'created_at' &&
                            field !== 'realdatetime' &&
                            typeof(message[field]) === 'number'
                        "
                        >
                            {{ message[field] | number: '1.0-4' }}
                        </p>
                        <p
                            *ngIf="
                            field !== 'datetime' &&
                            field !== 'created_at' &&
                            field !== 'realdatetime' &&
                            typeof(message[field]) !== 'number'
                        "
                        >
                            {{ message[field] }}
                        </p>
                    </ng-container>
                    <ng-template #emptyTd>
                        <div class="delete-message" (click)="onDeleteMessage(message)">
                            <span class="material-icons"> cancel </span>
                        </div>
                    </ng-template>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>

    <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</mat-card>

<!--
    <mat-card class="innerContent"
              *ngIf="!isSpinnerVisible && dataSource?.data?.length === 0">
        <div style="text-align:center;">{{'devices.data.nothing' | translate}}</div>
    </mat-card>
    <mat-card
              *ngIf="!isSpinnerVisible || dataSource?.data?.length != 0"
              class="innerContent">
        <mat-card-title>
            <span style="vertical-align: middle;">График</span>
        </mat-card-title>
        <div id="deviceChart" style="overflow: hidden"></div>
    </mat-card>
    <mat-card *ngIf="!isSpinnerVisible || dataSource?.data?.length != 0"
              class="innerContent">
        <mat-card-title>
            <span style="vertical-align: middle;">Данные</span>
        </mat-card-title>
        <div class="data-table">
            <table mat-table matSort [dataSource]="dataSource"
                   class="mat-elevation-z8 device-data" matSortActive="datetime" matSortDisableClear
                   matSortDirection="desc">

                <ng-container *ngFor="let field of tableFields; let i = index" matColumnDef="{{field.name}}">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{field.title}}</th>
                    <td mat-cell *matCellDef="let message" disableClear="true">{{value(message, field)}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </mat-card> -->
