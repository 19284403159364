import {Action, createReducer, on} from '@ngrx/store';
import {loadDeviceFieldsTitles} from '../actions/titles.action';

const initialState = [];
const deviceTitlesReducer = createReducer(
    initialState,
    on(loadDeviceFieldsTitles, (state, { deviceFieldsTitles }) => {
            return deviceFieldsTitles;
        }
    )
)
export function reducer(state: any | undefined, action: Action) {
    return deviceTitlesReducer(state, action);
}
