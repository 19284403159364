<mat-card class="innerContent" [style.display]="!isSpinnerVisible ? 'none' : 'block'">
    <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</mat-card>
<mat-card class="innerContent" *ngIf="!isSpinnerVisible && dataSource?.data?.length === 0">
    <div style="text-align: center">{{ 'devices.data.nothing' | translate }}</div>
</mat-card>

<mat-card
    [style.display]="isSpinnerVisible ? 'none' : 'block'"
    *ngIf="showChart && dataSource?.data?.length != 0"
    class="innerContent device-chart-content"
>
    <mat-card-title>
        <span style="vertical-align: middle">{{'devices.data_show.chart'|translate}}</span>
    </mat-card-title>
    <div id="deviceChart" style="overflow: hidden"></div>
</mat-card>

<mat-card
    [style.visibility]="isSpinnerVisible == true || dataSource.data.length == 0 ? 'hidden' : 'visible'"
    class="innerContent"
>
    <mat-card-title>
        <span style="vertical-align: middle">{{ 'devices.data.bsData' | translate }}</span>
        <div class="fields-setting-menu">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>settings</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <mat-list>
                    <mat-list-item *ngFor="let field of availableColumns">
                        <mat-checkbox
                            [checked]="displayedColumns.indexOf(field) !== -1"
                            (click)="$event.stopPropagation()"
                            (change)="changeFieldVisible(field)"
                        >
                            {{ fieldsTitles[field] ? fieldsTitles[field]?.title : field
                            }}{{ fieldsTitles[field]?.unit ? ', ' + fieldsTitles[field]?.unit.title : '' }}
                        </mat-checkbox>
                    </mat-list-item>
                </mat-list>
            </mat-menu>
        </div>
    </mat-card-title>
    <div class="data-table">
        <table
            mat-table
            matSort
            [dataSource]="dataSource"
            class="mat-elevation-z8 device-data"
            matSortActive="datetime"
            matSortDisableClear
            matSortDirection="desc"
        >
            <ng-container *ngFor="let field of displayedColumns; let i = index" matColumnDef="{{ field }}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ fieldsTitles[field] ? fieldsTitles[field]?.title : field }}
                </th>
                <td mat-cell *matCellDef="let message" disableClear="true">
                    <p *ngIf="field === 'datetime' || field === 'created_at' || field === 'realdatetime'">
                        {{ getFormatedDate(message[field]) }}
                    </p>
                    <p
                        *ngIf="
                            field !== 'datetime' &&
                            field !== 'created_at' &&
                            field !== 'realdatetime' &&
                            typeof(message[field]) === 'number'
                        "
                    >
                        {{ message[field] | number: '1.0-4' }}
                    </p>
                    <p
                        *ngIf="
                            field !== 'datetime' &&
                            field !== 'created_at' &&
                            field !== 'realdatetime' &&
                            typeof(message[field]) !== 'number'
                        "
                    >
                        {{ message[field] }}
                    </p>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>

    <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</mat-card>

<!--
    <mat-card class="innerContent"
              *ngIf="!isSpinnerVisible && dataSource?.data?.length === 0">
        <div style="text-align:center;">{{'devices.data.nothing' | translate}}</div>
    </mat-card>
    <mat-card
              *ngIf="!isSpinnerVisible || dataSource?.data?.length != 0"
              class="innerContent">
        <mat-card-title>
            <span style="vertical-align: middle;">График</span>
        </mat-card-title>
        <div id="deviceChart" style="overflow: hidden"></div>
    </mat-card>
    <mat-card *ngIf="!isSpinnerVisible || dataSource?.data?.length != 0"
              class="innerContent">
        <mat-card-title>
            <span style="vertical-align: middle;">Данные</span>
        </mat-card-title>
        <div class="data-table">
            <table mat-table matSort [dataSource]="dataSource"
                   class="mat-elevation-z8 device-data" matSortActive="datetime" matSortDisableClear
                   matSortDirection="desc">

                <ng-container *ngFor="let field of tableFields; let i = index" matColumnDef="{{field.name}}">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{field.title}}</th>
                    <td mat-cell *matCellDef="let message" disableClear="true">{{value(message, field)}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </mat-card> -->
