<h2 mat-dialog-title>{{'get_archive_tariff.msg_1'| translate}}</h2>
<div>
    <div></div>
    <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>{{'get_archive_tariff.msg_2'| translate}}</mat-label>
        <mat-select [(ngModel)]="archiveSendObject.management['type']"
                    (selectionChange)="changeType()"
                    [disabled]="disableEdit">
            <mat-option *ngFor="let type of archiveType"
                        [value]="type.title">
                {{ type.title | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field
        floatLabel="always"
        appearance="outline"
        style="display:block; max-width: 200px"
        *ngIf="archiveSendObject.management['type'] !== null"
    >
        <mat-label>{{'get_archive_tariff.msg_3'| translate}}</mat-label>
        <input matInput
               class="data-date"
               (click)="archiveDate.open()"
               (focus)="archiveDate.open()"
               [matDatepicker]="archiveDate"
               [(ngModel)]="archiveSendObject.management['archive']"
               [disabled]="disableEdit"/>
        <mat-datepicker-toggle matSuffix [for]="archiveDate"></mat-datepicker-toggle>
        <mat-datepicker #archiveDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field
        floatLabel="always"
        appearance="outline"
        class="time"
        *ngIf="archiveSendObject.management['type'] !== 1 &&
                        archiveSendObject.management['type'] !== 2 &&
                        archiveSendObject.management['type'] !== null">
        <mat-label>{{'get_archive_tariff.msg_4'| translate}}</mat-label>
        <input matInput
               aria-label="24hr format"
               [ngxTimepicker]="fullTime"
               [format]="24"
               readonly
               placeholder="{{'get_archive_tariff.msg_5'| translate}}"
               [(ngModel)]="archiveSendObject.management['archiveTime']"
               [disabled]="disableEdit"/>
        <ngx-material-timepicker #fullTime [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
        </ngx-material-timepicker>
        <ng-template #cancelBtn>
            <button class="pickerBtn cancelBtn"
                    mat-raised-button
                    style="margin-right: 15px"
                    type="button">
                {{'get_archive_tariff.msg_8'| translate}}
            </button>
        </ng-template>
        <ng-template #confirmBtn>
            <button class="pickerBtn"
                    mat-raised-button
                    color="primary"
                    type="button">
                {{'get_archive_tariff.msg_6'| translate}}
            </button>
        </ng-template>
    </mat-form-field>
    <button style="margin-left: 15px; height: 50px; margin-bottom: 15px; vertical-align: middle"
            mat-raised-button
            color="primary"
            (click)="getArchive()"
            [disabled]="disableEdit">
        {{'get_archive_tariff.msg_7'| translate}}
    </button>
</div>


