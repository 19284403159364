import { Action } from '@ngrx/store';

export enum SuppliersActions {
    FetchSuppliers = '[Suppliers] Fetch suppliers',
    FetchSuppliersSuccess = '[Suppliers] Fetch suppliers success',
    AddSupplier = '[Suppliers] Add supplier',
    AddSupplierSuccess = '[Suppliers] Add supplier success',
    AddSupplierFail = '[Suppliers] Add supplier fail',
    EditSupplier = '[Suppliers] Edit supplier',
    EditSupplierSuccess = '[Suppliers] Edit supplier success',
    EditSupplierFail = '[Suppliers] Edit supplier fail'
}

export class fetchSuppliers implements Action {
    readonly type = '[Suppliers] Fetch suppliers';

    constructor(public payload: { page: number; perPage: number, search_string?: string }) {}
}

export class fetchSuppliersSuccess implements Action {
    readonly type = '[Suppliers] Fetch suppliers success';

    constructor(public payload: any) {}
}

export class addSuppliers implements Action {
    readonly type = '[Suppliers] Add supplier';

    constructor(public payload: any) {}
}

export class addSuppliersSuccess implements Action {
    readonly type = '[Suppliers] Add supplier success';

    constructor() {}
}

export class addSuppliersFail implements Action {
    readonly type = '[Suppliers] Add supplier fail';

    constructor(public payload: any) {}
}

export class editSuppliers implements Action {
    readonly type = '[Suppliers] Edit supplier';

    constructor(public payload: any) {}
}

export class editSuppliersSuccess implements Action {
    readonly type = '[Suppliers] Edit supplier success';

    constructor() {}
}

export class editSuppliersFail implements Action {
    readonly type = '[Suppliers] Edit supplier fail';

    constructor(public payload: any) {}
}

export type SuppliersUnion =
    | fetchSuppliers
    | fetchSuppliersSuccess
    | addSuppliers
    | addSuppliersSuccess
    | addSuppliersFail
    | editSuppliers
    | editSuppliersSuccess
    | editSuppliersFail;
