import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PassportService } from 'src/app/auth/passport.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EventsApiService {
    private _baseURL: string;

    constructor(private _http: HttpClient, private auth: PassportService) {
        this._baseURL = environment.baseURL;
    }

    addEvent(body) {
        return this._http.post(this._baseURL + 'event/create', body, { headers: this.getHeaders() });
    }

    updateEvent(body) {
        return this._http.post(this._baseURL + 'event/update', body, { headers: this.getHeaders() });
    }

    getEventsList(body = {}) {
        return this._http.post(this._baseURL + 'event/list', body, { headers: this.getHeaders() });
    }

    getEventListWithParams(body) {
        return this._http
            .post(this._baseURL + 'event/occurred/list', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.occurred_events));
    }

    deleteEvent(id) {
        return this._http.delete(this._baseURL + 'event/remove/' + id, { headers: this.getHeaders() });
    }

    duplicateEvent(id) {
        return this._http.put(this._baseURL + 'event/copy/' + id, { id }, { headers: this.getHeaders() });
    }

    getOccurredEventsList(body) {
        return this._http
            .post(this._baseURL + 'event/occurred/list', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.occurred_events));
    }

    getEvent(id) {
        return this._http
            .post(this._baseURL + 'event/occurred/list', { device_ids: [id] }, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.occurred_events));
    }

    setOccurredEventsRead() {
        return this._http
            .get(this._baseURL + 'event/occurred/set_all_read', { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data));
    }

    setOneOccurredEventsRead(id) {
        return this._http
            .put(this._baseURL + 'event/occurred/status/read/' + id, {}, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.occurred_event));
    }

    toggleEventStatus(body, id) {
        return this._http.post(this._baseURL + 'event/toggle_status/' + id, body, { headers: this.getHeaders() });
    }

    getEventById(id) {
        return this._http.post(this._baseURL + 'event/get_by_id', { id }, { headers: this.getHeaders() });
    }

    getTranslateFields(body) {
        return this._http
            .post(this._baseURL + 'device/model/metering_devices', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.metering_device_models[0]));
    }

    private getHeaders() {
        return new HttpHeaders().append('Access-Control-Allow-Origin', '*').append('Authorization', 'Bearer ' + this.auth.access_token);
    }
}
