<mat-card class="m-0">
    <div class="p-2">
        <p *ngIf="!specialDays.length; else chipList;" class="mat-error">
            {{ 'app_accounting_point.control.msg_70' | translate }}
        </p>
        <ng-template #chipList>
            <p>
                {{ 'app_accounting_point.control.msg_71' | translate }}
            </p>
            <mat-chip-list>
                <mat-chip *ngFor="let specialDay of specialDays; index as i;"
                          (removed)="removeDay(i)">
                    {{ specialDay }}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
            </mat-chip-list>
        </ng-template>
    </div>
    <div class="p-2">
        <mat-form-field appearance="outline" class="mr-4">
            <mat-label>{{ 'app_accounting_point.control.msg_72'  | translate }}</mat-label>
            <mat-select [formControl]="selectedMonth">
                <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mr-4">
            <mat-label>{{ 'app_accounting_point.control.msg_73'  | translate }}</mat-label>
            <mat-select [formControl]="selectedDay">
                <mat-option *ngFor="let day of days" [value]="day">{{ day }}</mat-option>
            </mat-select>
        </mat-form-field>
        <button
            mat-raised-button
            color="primary"
            (click)="addDay()"
        >
            {{ 'app_accounting_point.control.msg_74' | translate }}
        </button>
    </div>
</mat-card>
