import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PassportService } from '../passport.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-forget-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['./activate.component.css']
})
export class ForgetActivateComponent implements OnInit {
    token: string;
    email: string;
    forgetActivateForm = new UntypedFormGroup({
        password: new UntypedFormControl(null, [Validators.required, Validators.minLength(8)]),
        password_confirmation: new UntypedFormControl(null, [Validators.required, Validators.minLength(8)])
    });

    constructor(
        private readonly auth: PassportService,
        private readonly dialog: MatDialog,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly translate: TranslateService
    ) {
        this.token = this.route.snapshot.paramMap.get('token');
    }

    ngOnInit() {
        this.auth.checkForgetPass(this.token).subscribe(
            (email: any) => {
                this.email = email.data.email;
            },
            (error) => {
                this.router.navigate(['auth/login']);
                throw error;
            }
        );
    }

    submit() {
        this.auth.setNewForgetPassword(this.forgetActivateForm, this.email, this.token).subscribe(
            (response: any) => {
                this.dialog.open(PopupComponent, {
                    width: '400px',
                    data: { name: this.translate.instant('forgetActivateForm.messages.success'), message: response.msg }
                });
                this.router.navigate(['auth/login']);
            },
            (error) => {
                throw error;
            }
        );
    }
}
