<div *ngIf="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>

<ng-container *ngIf="data.type === 'delete-message'">
    <h2 mat-dialog-title>{{'app_popup.msg_19'|translate}}</h2>
    <mat-dialog-actions>
        <button mat-button mat-flat-button color="primary"
                (click)="onDeleteMessage()">{{'app_popup.msg_8'|translate}}</button>

        <button mat-button mat-flat-button color="primary" (click)="close()">{{'app_popup.msg_3'|translate}}</button>
    </mat-dialog-actions>
</ng-container>
