<mat-card class="innerContent">
    <mat-progress-spinner
        *ngIf="isSpinnerVisible"
        color="primary" mode="indeterminate" class="m-center">
    </mat-progress-spinner>
    <p *ngIf="!isSpinnerVisible && !dataSource?.data?.length"
       class="text-center">
        {{ 'devices.data.nothing' | translate }}
    </p>
    <ng-container *ngIf="!isSpinnerVisible && dataSource?.data?.length">
        <mat-card-title>
            <span>{{ 'devices.data.bsData' | translate }}</span>
            <div class="fields-setting-menu">
                <button mat-icon-button (click)="downloadExcel()">
                    <mat-icon class="personal_design_icon">cloud_download</mat-icon>
                </button>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>settings</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <mat-list>
                        <mat-list-item *ngFor="let field of availableColumns">
                            <mat-checkbox
                                [checked]="displayedColumns?.includes(field)"
                                (click)="$event.stopPropagation()"
                                (change)="changeFieldVisible(field)"
                            >
                                {{ getHeaderByField(field) }}
                            </mat-checkbox>
                        </mat-list-item>
                    </mat-list>
                </mat-menu>
            </div>
        </mat-card-title>
        <div class="data-table">
            <mat-progress-spinner *ngIf="isLoadingResults"
                                  color="primary" mode="indeterminate" class="m-center"></mat-progress-spinner>
            <table
                class="mat-elevation-z8 device-data"
                mat-table
                matSort
                matSortActive="datetime"
                matSortDirection="desc"
                matSortDisableClear
                [dataSource]="dataSource"
            >
                <ng-container *ngFor="let field of displayedColumns" [matColumnDef]="field">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">
                        {{ fieldsTitles[field] ?? field }}
                    </th>
                    <td mat-cell *matCellDef="let message">
                        <p [ngSwitch]="getCellType(message, field)">
                            <span *ngSwitchCase="'date'">{{ getFormattedDate(message[field]) }}</span>
                            <span *ngSwitchCase="'number'">{{ message[field] | number: '1.0-4' }}</span>
                            <span *ngSwitchDefault>{{ message[field] }}</span>
                        </p>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>

        <mat-paginator
            #matPaginator
            [pageSizeOptions]="pageSizeOptions"
            [pageSize]="pageSize"
            [length]="itemsLength"
            [pageIndex]="pageIndex"
            (page)="paginatorChanges($event)"
            showFirstLastButtons
        >
        </mat-paginator>
    </ng-container>
</mat-card>
