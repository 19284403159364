import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CheckAccess } from '../../cloud.service';
import { ErrorsService } from '@core/services/errors.service';
import { PassportService } from '../../../auth/passport.service';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as suppliersState from '../store/reducers/suppliers.reducer';
import * as suppliersActions from '../store/actions/suppliers.actions';
import { MatDialog } from '@angular/material/dialog';

/**
 * @deprecated will be deleted
 */

// DELETE when all module will be ready

@Injectable({
    providedIn: 'root'
})
export class SuppliersService {
    _baseURL: string;
    public headers = new HttpHeaders();
    token: string;

    constructor(
        private httpClient: HttpClient,
        private auth: PassportService,
        public _checkAccess: CheckAccess,
        private errors: ErrorsService
    ) {
        this._baseURL = environment.baseURL;
    }

    getSuppliers(page, perPage, search_string = null) {
        if (this._checkAccess.listAccess('read', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            if (page === null && perPage === null) {
                return this.httpClient.post(this._baseURL + 'reference/supplier/list', { paginate: false }, { headers: this.headers }).pipe(
                    map((result: any) => {
                        return result.data.suppliers;
                    })
                );
            } else {
                const reqData = { paginate: true, page: page, per_page: perPage };
                if (search_string) {
                    reqData['search'] = search_string;
                }
                return this.httpClient.post(this._baseURL + 'reference/supplier/list', { ...reqData }, { headers: this.headers }).pipe(
                    map((result: any) => {
                        return result.data.suppliers;
                    })
                );
            }
        } else {
            this.errors.showSnackbar('msg_91', true);
        }
    }

    addSupplier(data) {
        if (this._checkAccess.listAccess('create', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .post(this._baseURL + 'reference/supplier/create', data, {
                    headers: this.headers
                })
                .pipe(
                    map((result: any) => {
                        return result.msg;
                    })
                );
        } else {
            this.errors.showSnackbar('msg_92', true);
        }
    }

    editSupplier(data) {
        if (this._checkAccess.listAccess('update', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .put(this._baseURL + 'reference/supplier/update', data, {
                    headers: this.headers
                })
                .pipe(
                    map((result: any) => {
                        return result.msg;
                    })
                );
        } else {
            this.errors.showSnackbar('msg_93', true);
        }
    }

    deleteSupplier(supplierId) {
        if (this._checkAccess.listAccess('delete', 6)) {
            this.headers = new HttpHeaders();
            this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
            this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
            return this.httpClient
                .delete(this._baseURL + `reference/supplier/${supplierId}`, {
                    headers: this.headers
                })
                .pipe(
                    map((result: any) => {
                        return result.msg;
                    })
                );
        } else {
            this.errors.showSnackbar('msg_94', true);
        }
    }
    getSubjectTypes() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient
            .get(this._baseURL + `reference/subject/types`, {
                headers: this.headers
            })
            .pipe(
                map((result: any) => {
                    return result.data;
                })
            );
    }

    getSupplier(id) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append('Authorization', 'Bearer ' + this.auth.token);
        this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
        return this.httpClient.get<any>(`${this._baseURL}reference/supplier/${id}`, { headers: this.headers });
    }
}
@Injectable({
    providedIn: 'root'
})
export class SuppliersStateService {
    constructor(
        private error: ErrorsService,
        private store: Store<suppliersState.SuppliersState>,
        private dialog: MatDialog
    ) {}

    public addSupplierSuccess(page, perPage, msg) {
        this.dialog.closeAll();
        this.error.showSnackbar(msg);
        return new suppliersActions.fetchSuppliers({ page, perPage });
    }

    public addSupplierFail(error: any) {
        this.error.showSnackbar(error.error?.error?.data?.msg ? error.error.error.data.msg : error.error?.error?.msg);
        return new suppliersActions.addSuppliersFail(error.error.error);
    }

    public editSupplierSuccess(page, perPage, msg) {
        this.dialog.closeAll();
        this.error.showSnackbar(msg);
        return new suppliersActions.fetchSuppliers({ page, perPage });
    }

    public editSupplierFail(error: any) {
        this.error.showSnackbar(error.error?.error?.data?.msg ? error.error.error.data.msg : error.error?.error?.msg);
        return new suppliersActions.addSuppliersFail(error.error.error);
    }
}
