import { Action, createReducer, on } from '@ngrx/store';
import {
    clearReportStore,
    fetchAccountingPointFieldsSuccess,
    fetchAvailableAccountingPointsSuccess,
    fetchAvailableAttributesSuccess,
    fetchFieldsTitlesSuccess,
    loadReport,
    saveCustomFields,
    setAccountingPointType,
    setDateEndReportDates,
    setDateStartReportDates,
    setMsgType,
    setReportDates,
    setReportTitle,
    setReportType,
    setReportTypeValue,
    setSelectedAccountingPoint,
    setSelectedFields
} from '../actions/report-generator.action';
import _ from 'lodash';

const initialState = {
    availableAccountingPoints: [],
    allAvailableAttributes: [],
    selectedAccountingPoints: [],
    reportTitle: '',
    reportType: 'group',
    reportTypeValue: 'horizontal',
    msgType: 0,
    selectedFields: [],
    customFields: [],
    accountingPointsFields: [],
    fieldTitles: [],
    accountingPointType: null,
    reportConfig: {
        dateStart: null,
        dateEnd: null
    },
    template: null
};
const reportGeneratorReducer = createReducer(
    initialState,
    on(setAccountingPointType, (state, { accountingPointType }) => {
        return {
            ...state,
            accountingPointType: accountingPointType.map((i) => parseInt(i))
        };
    }),
    on(fetchAvailableAccountingPointsSuccess, (state, { availableAccountingPoints }) => {
        return {
            ...state,
            availableAccountingPoints: availableAccountingPoints
        };
    }),
    on(setSelectedAccountingPoint, (state, { accountingPoints }) => {
        return {
            ...state,
            selectedAccountingPoints: accountingPoints
        };
    }),
    on(setSelectedFields, (state, { fields }) => {
        return {
            ...state,
            selectedFields: fields
        };
    }),
    on(fetchAccountingPointFieldsSuccess, (state, { fields }) => {
        return {
            ...state,
            accountingPointsFields: _.uniq(fields)
        };
    }),
    on(fetchFieldsTitlesSuccess, (state, { fields }) => {
        return {
            ...state,
            fieldTitles: fields.data.fields
        };
    }),
    on(saveCustomFields, (state, { customFields }) => {
        return {
            ...state,
            customFields: customFields
        };
    }),
    on(setReportDates, (state, { dateStart, dateEnd }) => {
        return {
            ...state,
            reportConfig: { dateStart, dateEnd }
        };
    }),
    on(setDateStartReportDates, (state, { dateStart }) => {
        return {
            ...state,
            reportConfig: { ...state.reportConfig, dateStart }
        };
    }),
    on(setDateEndReportDates, (state, { dateEnd }) => {
        return {
            ...state,
            reportConfig: { ...state.reportConfig, dateEnd }
        };
    }),
    on(fetchAvailableAttributesSuccess, (state, { attributes }) => {
        return {
            ...state,
            allAvailableAttributes: attributes
        };
    }),
    on(setReportTitle, (state, { title }) => {
        return {
            ...state,
            reportTitle: title
        };
    }),
    on(setReportType, (state, { selectedReportType }) => {
        return {
            ...state,
            reportType: selectedReportType
        };
    }),
    on(setReportTypeValue, (state, { reportTypeValue }) => {
        return {
            ...state,
            reportTypeValue
        };
    }),
    on(setMsgType, (state, { msgType }) => {
        return {
            ...state,
            msgType
        };
    }),
    on(loadReport, (state, { data }) => {
        return {
            ...state,
            ...data
        };
    }),
    on(clearReportStore, (state) => {
        return initialState;
    })
);
export function reducer(state: any | undefined, action: Action) {
    return reportGeneratorReducer(state, action);
}
