import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as moment from 'moment';
import 'moment-timezone';
import {CloudService, DateManipulations} from 'src/app/cloud/cloud.service';
import {select, Store} from '@ngrx/store';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DevicesService} from '../../../../services/devices.service';
import * as _ from 'lodash';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {selectMessageGroupsState} from '../../../../../../store/selectors/selectors';
import * as sharedState from '../../../../../../store/reducers/shared.reducer';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {TranslateLanguageService} from '../../../../../../core/services/translate-language.service';
import {MY_FORMATS} from '@core/constants/consts';

@Component({
    selector: 'app-select-device-data-date',
    templateUrl: './select-date.component.html',
    styleUrls: ['./select-date.component.less'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    ]
})
export class SelectDateComponent implements OnInit, OnDestroy {
    @Input() tab: string;
    @Input() msgTypes: any;
    @Input() dates: any;
    @Input() currentDevice: any;
    @Input() changeCurrentTabEvent: EventEmitter<any>;
    @Input() deviceType?: 'device' | 'gateway';
    mapMsgTypes = {
        100: 'Часовые архивы',
        101: 'Суточные архивы',
        102: 'Недельные архивы',
        103: 'Месячные архивы',
        104: 'Квартальные архивы',
        105: 'Полугодовой архивы',
        106: 'Годовой архивы',
        107: 'Часовой интегральные архивы',
        108: 'Суточные интегральные архивы',
        109: 'Недельные интегральные архивы',
        110: 'Месячные интегральные архивы',
        111: 'Квартальный интегральный архив',
        112: 'Полугодовой интегральный архив',
        113: 'Годовой интегральный архив',
        114: 'Часовой суммарный архив',
        115: 'Суточный суммарный архив',
        116: 'Недельный суммарный архив',
        117: 'Месячный суммарный архив',
        118: 'Квартальный суммарный архив',
        119: 'Полугодовой суммарный архив',
        120: 'Годовой суммарный архив',
        121: 'Произвольный архив',
        125: 'Архив на конец расчетного периода',
    };

    app;
    messageGroups;
    msgGroup;
    @Input() msgType = 1;
    @Input() profileType = '30';
    @Output() msgGroupChange = new EventEmitter<number>();
    @Output() msgTypeChange = new EventEmitter<number>();
    @Output() typeJournalChange = new EventEmitter<number>();
    @Output() powerProfileTypeChange = new EventEmitter<string>();
    @Output() dateChange = new EventEmitter<any>();
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    loadApp = this._cloudService.appConst.pipe(takeUntil(this.destroyed$)).subscribe((app: any) => {
        this.app = app;
    });
    dateForm: UntypedFormGroup = new UntypedFormGroup({
        startDate: new UntypedFormControl(moment().subtract(1, 'week').startOf('day').utc()),
        stopDate: new UntypedFormControl(moment().endOf('day').utc())
    });
    subscribers = [];
    sessionSettings: any;
    typeJournal: any;
    availableTypeJournals = [];
    currentLanguage = '';
    journalDeviceAllSelect = true;
    public getJournalMessagesTypes: any[];

    constructor(
        private _cloudService: CloudService,
        public dateManipulations: DateManipulations,
        private _deviceService: DevicesService,
        private store: Store<sharedState.SharedState>,
        private dateAdapter: DateAdapter<Date>,
        private translateLanguageService: TranslateLanguageService
    ) {
        this._cloudService.settings.pipe(takeUntil(this.destroyed$)).subscribe((sessionSettings) => {
            this.sessionSettings = sessionSettings;
            this.msgGroup = this.tab === 'archives' ? 0 : this.sessionSettings?.msgGroup ? this.sessionSettings?.msgGroup : 0;
            if (this.msgGroup === null || isNaN(this.msgGroup)) {
                this.msgGroup = 1;
            } else {
                return;
            }
        });
    }

    ngOnInit() {
        this.translateLanguageService.currentLanguage$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((lang) => {
                this.currentLanguage = lang;
                this.dateAdapter.setLocale(lang);
            });
        this._deviceService.getJournalMessagesTypes().subscribe(
            types => {
                this.mapMsgTypes = types.reduce((currentObject, item) => {
                    currentObject[item.id] = {title: item.title, value: item.max_period_request};
                    return currentObject;
                }, {});
            }
        );
        if (!_.isNull(this.dates)) {
            this.dateForm = new UntypedFormGroup({
                startDate: new UntypedFormControl(moment.unix(this.dateManipulations.dateLocal(this.dates.startDate)).startOf('day')),
                stopDate: new UntypedFormControl(moment.unix(this.dateManipulations.dateLocal(this.dates.stopDate)).endOf('day'))
            });
            this.dateChange.emit(this.dates);
        } else {
            this.dateChange.emit({
                startDate: moment.unix(this.dateManipulations.dateUTC(moment().subtract(1, 'week').startOf('day').unix())).unix(),
                stopDate: moment.unix(this.dateManipulations.dateUTC(moment().endOf('day').unix())).unix()
            });
        }
        this.msgGroupChange.emit(this.msgGroup);
        // setTimeout(() => {
        //     this.msgGroup = this.tab === 'archives' ? 0 : this.currentDevice !== undefined ?
        //         this.sessionSettings.msgGroup : 0;
        // }, 100);
        this.powerProfileTypeChange.emit(this.profileType);
        this.changeCurrentTabEvent.subscribe((tab) => {
            if (tab !== 'journal_device') {
                return;
            }
            this._deviceService.getJournalMessagesTypes().subscribe((types) => {
                this.getJournalMessagesTypes = types.filter((t) => {
                    if (this.msgTypes) {
                        return this.msgTypes.indexOf(t.id) !== -1;
                    } else {
                        return false;
                    }
                });
                this.typeJournal = this.getJournalMessagesTypes.map((j) => j.id);
                setTimeout(() => {
                    this.changeTypeJournal();
                }, 100);
            });
        });
        this.store
            .pipe(select(selectMessageGroupsState))
            .pipe(takeUntil(this.destroyed$))
            .subscribe((groups) => {
                this.messageGroups = groups;
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    getPeriodEnd(param) {
        const startDate = _.cloneDeep(this.dateForm.controls['startDate'].value);
        const stopDate = _.cloneDeep(this.dateForm.controls['stopDate'].value);
        for (let i = 0; i < this.messageGroups.length; i++) {
            if (this.msgGroup === this.messageGroups[i]['id']) {
                if (param === 'max') {
                    const maxTime = stopDate.startOf('day').unix();
                    return moment(maxTime * 1000);
                } else if (param === 'min') {
                    const minTime = startDate.endOf('day').unix();
                    return moment(minTime * 1000);
                }
            }
        }
    }

    getPeriod = (param) => {
        if (param === 'max') {
            return moment().add(1, 'year').month(12).date(31);
        } else if (param === 'min') {
            return this.dateForm.controls['startDate'].value;
        }
    };

    dateChanged(event, name) {
        this.dateForm.controls[name].setValue(event.value);
        let maxPeriodRequest = _.find(this.messageGroups, {id: this.msgGroup})['max_period_request'] - 86400;
        if (['power_profiles', 'journal_device'].includes(this.tab)) {
            maxPeriodRequest = 8640000 - 86400;
        } else if (this.tab === 'archives') {
            maxPeriodRequest = this.mapMsgTypes[this.msgType].value * 86400 - 86400;
        }
        if (
            name === 'startDate' &&
            (this.dateForm.controls['stopDate'].value.diff(this.dateForm.controls['startDate'].value, 'seconds') > maxPeriodRequest ||
                this.dateForm.controls['stopDate'].value.diff(this.dateForm.controls['startDate'].value, 'seconds') < 0)
        ) {
            const value = _.cloneDeep(event.value).add(maxPeriodRequest, 'seconds').startOf('day');
            this.dateForm.controls['stopDate'].setValue(value);
        } else if (
            name === 'stopDate' &&
            (this.dateForm.controls['stopDate'].value.diff(this.dateForm.controls['startDate'].value, 'seconds') > maxPeriodRequest ||
                this.dateForm.controls['stopDate'].value.diff(this.dateForm.controls['startDate'].value, 'seconds') < 0)
        ) {
            const value = _.cloneDeep(event.value).subtract(maxPeriodRequest, 'seconds').startOf('day');
            this.dateForm.controls['startDate'].setValue(value);
        }
        this.changeDateMessages();
    }

    changeDateMessages() {
        this.dateChange.emit({
            startDate: moment.unix(this.dateManipulations.dateUTC(this.dateForm.controls['startDate'].value.clone().unix())).utc().unix(),
            stopDate: moment
                .unix(this.dateManipulations.dateUTC(this.dateForm.controls['stopDate'].value.clone().endOf('day').unix()))
                .utc()
                .unix()
        });
        if (this.journalDeviceAllSelect) {
            this.typeJournalChange.emit(this.typeJournal);
        }
    }

    changePeriod(group: boolean) {
        setTimeout(() => {
            let maxPeriodRequest = _.find(this.messageGroups, {id: this.msgGroup})['max_period_request'] - 86400;
            if (['power_profiles', 'journal_device'].includes(this.tab)) {
                maxPeriodRequest = 8640000 - 86400;
            }
            if (this.tab === 'archives') {
                maxPeriodRequest = this.mapMsgTypes[this.msgType].value * 86400 - 86400;
            }
            const today = new Date();
            const stopDate = this.dateForm.controls['startDate'].value.clone().add(maxPeriodRequest, 'seconds');
            if (stopDate < today) {
                this.dateForm.controls['stopDate'].setValue(this.dateForm.controls['startDate'].value.clone().add(maxPeriodRequest, 'seconds'));
                if (this.deviceType !== 'gateway') {
                this._deviceService.dateForm.stopDate = moment
                    .unix(this.dateManipulations.dateUTC(this.dateForm.controls['stopDate'].value.endOf('day').unix())).utc().unix();
                }
            } else if (group) {
                if (this.deviceType !== 'gateway') {
                this._deviceService.dateForm.stopDate = moment
                    .unix(this.dateManipulations.dateUTC(this.dateForm.controls['stopDate'].value.endOf('day').unix())).utc().unix();
                }
            }
            if (group) {
                this.changeDateMessages();
            }
            if (this.dateForm.controls['startDate'].value.unix() < this.getPeriod('min').unix()) {
                this.dateForm.get('startDate').setValue(this.getPeriod('min'));
            }
            this.msgGroupChange.emit(this.tab === 'archives' ? 0 : this.msgGroup);
            this.msgTypeChange.emit(this.msgType);
            this.powerProfileTypeChange.emit(this.profileType);
            _.set(this.sessionSettings, 'msgGroup', this.msgGroup);
            this._cloudService.settings = this.sessionSettings;
        }, 0);

    }

    changeTypeJournal() {
        this.typeJournalChange.emit(this.typeJournal);
        setTimeout(() => {
            this.dateChange.emit({
                startDate: moment.unix(this.dateManipulations.dateUTC(this.dateForm.controls['startDate'].value.unix())).utc().unix(),
                stopDate: moment
                    .unix(this.dateManipulations.dateUTC(this.dateForm.controls['stopDate'].value.endOf('day').unix()))
                    .utc()
                    .unix()
            });
        }, 200);
    }

    getStartEarth() {
        return moment().year(1970).month(0).date(1);
    }

    toggleSelectAll(e) {
        this.journalDeviceAllSelect = e;
        if (!this.journalDeviceAllSelect) {
            this.typeJournal = [];
            this.typeJournalChange.emit(this.typeJournal);
        } else {
            this.typeJournal = this.getJournalMessagesTypes.map((j) => j.id);
        }
    }
}
