<mat-card *ngIf="isSpinnerVisible">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</mat-card>
<mat-card class="page-header" *ngIf="!isSpinnerVisible">
    <strong>{{ 'app_accounting_point.control.msg_2' | translate }} ({{ currentDevice.name }})</strong>
</mat-card>
<div>
    <div class="device">

        <!--        new controls for MG-08 model-->

        <!--        get all buttons at first-->
        <ng-container *ngFor="let btnParam of mg08Buttons">
            <div *ngIf="isControlActive(btnParam)" class="control-block">
                <mat-card style="padding: 25px">
                    <mat-card-header>
                        <mat-card-title>{{ mg08Params[btnParam]?.[lang === 'en' ? 'title_en' : 'title'] }}</mat-card-title>
                    </mat-card-header>
                    <div style="text-align: center">
                        <button
                            mat-raised-button
                            class="send_but"
                            color="primary"
                            (click)="mg08BtnClickHandler(btnParam)"
                            [disabled]="disableEdit"
                        >
                            {{ 'app_accounting_point.control.msg_51' | translate }}
                        </button>
                    </div>
                </mat-card>
            </div>
        </ng-container>

        <ng-container [formGroup]="mg08Form">
            <!--        list of toggles form mg-08-->
            <ng-container *ngFor="let toggledParam of mg08Toggles">
                <div class="control-block" *ngIf="isControlActive(toggledParam)">
                    <mat-card style="padding: 25px;">
                        <mat-card-header>
                            <mat-card-title>{{ mg08Params[toggledParam]?.[lang === 'en' ? 'title_en' : 'title'] }}</mat-card-title>
                        </mat-card-header>
                        <div style="padding-top: 15px; width: 200px; text-align: center; margin: 0 auto">
                            <div>
                                <label>{{ 'app_accounting_point.control.msg_4' | translate }}</label>
                                <br/>
                                <span>{{ 'app_accounting_point.control.msg_5' | translate }}</span>
                                <mat-slide-toggle
                                    class="side_p"
                                    [formControlName]="toggledParam"
                                    (change)="mg08ToggleHandler(toggledParam,$event)"
                                    [disabled]="disableEdit && !_checkAccess.listAccess('update', 4)"
                                ></mat-slide-toggle>
                                <span>{{ 'app_accounting_point.control.msg_6' | translate }}</span>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </ng-container>

            <!--        list of inputs form mg-08-->

            <ng-container *ngFor="let inputsParam of mg08Inputs">
                <div class="control-block" *ngIf="isControlActive(inputsParam)">
                    <mat-card style="padding: 25px;">
                        <mat-card-header>
                            <mat-card-title>{{ mg08Params[inputsParam]?.[lang === 'en' ? 'title_en' : 'title'] }}</mat-card-title>
                        </mat-card-header>
                        <div style="text-align: center">
                            <mat-form-field floatLabel="always" appearance="outline">
                                <mat-label>{{ 'app_accounting_point.control.msg_67' | translate }}</mat-label>
                                <input
                                    matInput
                                    type="number"
                                    [formControlName]="inputsParam"
                                    [disabled]="disableEdit"
                                />
                            </mat-form-field>
                            <button
                                mat-raised-button
                                color="primary"
                                class="send_but"
                                (click)="mg08TextInputHandler(inputsParam)"
                                [disabled]="disableEdit"
                            >
                                {{ 'app_accounting_point.control.msg_9' | translate }}
                            </button>
                        </div>
                    </mat-card>
                </div>
            </ng-container>
        </ng-container>
        <!--       / new controls for MG-08 model-->

        <!--Запрос текущих настроек-->
        <div class="control-block control-tariff"
             *ngIf="isControlActive('get_settings')"
        >
            <mat-card class="mat-card-get-settings">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_33' | translate }}</mat-card-title>
                </mat-card-header>
                <div style="text-align: center">
                    <button
                        class="send_but"
                        mat-raised-button
                        color="primary"
                        (click)="getSettings()"
                        [disabled]="disableEdit"
                    >
                        {{ 'app_accounting_point.control.msg_34' | translate }}
                    </button>
                </div>
            </mat-card>
        </div>
        <div class="control-block control-tariff"
             *ngIf="isControlActive('get_setpoint')"
        >
            <mat-card class="mat-card-get-settings">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_77' | translate }}</mat-card-title>
                </mat-card-header>
                <div style="text-align: center">
                    <button
                        class="send_but"
                        mat-raised-button
                        color="primary"
                        (click)="getSetPoints()"
                        [disabled]="disableEdit"
                    >
                        {{ 'app_accounting_point.control.msg_78' | translate }}
                    </button>
                </div>
            </mat-card>
        </div>
        <!--/Запрос текущих настроек-->

        <!--Перезагрузка устройства-->
        <div class="control-block"
             *ngIf="isControlActive('reset')"
        >
            <mat-card class="control-block__card">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_49' | translate }}</mat-card-title>
                </mat-card-header>
                <button mat-raised-button
                        color="primary"
                        class="send_but"
                        (click)="reloadModel()">
                    {{ 'app_accounting_point.control.msg_37' | translate }}
                </button>
            </mat-card>
        </div>
        <!--/Перезагрузка устройства-->

        <!--Прозрачный канал-->
        <div class="control-block virtual-bridge"
             *ngIf="options?.virtual_bridge">
            <mat-card class="control-block__card">
                <mat-card-header>
                    <mat-card-title>{{ 'gateway.msg_17'| translate }}</mat-card-title>
                </mat-card-header>
                <div>
                    <span>{{ 'gateway.msg_18'| translate }}:</span>
                    <span *ngIf="invisibleMode">{{ 'gateway.msg_12'| translate }}</span>
                    <span *ngIf="!invisibleMode">{{ 'gateway.msg_13'| translate }}</span>
                </div>
                <button *ngIf="!invisibleMode" mat-raised-button
                        color="primary"
                        (click)="postActiv(true)">
                    {{ 'gateway.msg_14'| translate }}
                </button>
                <button *ngIf="invisibleMode" mat-raised-button
                        color="primary"
                        (click)="postActiv(false)">
                    {{ 'gateway.msg_15'| translate }}
                </button>
                <div *ngIf="port">
                    <span>{{ 'gateway.msg_16'| translate }}: </span>
                    <span>{{ port }}</span>
                </div>
            </mat-card>
        </div>
        <!--/Прозрачный канал-->

        <!--Прочитать состояние реле-->
        <div class="control-block"
             *ngIf="isControlActive('relay_state')"
        >
            <mat-card class="control-block__card">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_50' | translate }}</mat-card-title>
                </mat-card-header>
                <button mat-raised-button
                        color="primary"
                        class="send_but"
                        (click)="readRelayStatus()">
                    {{ 'app_accounting_point.control.msg_51' | translate }}
                </button>
            </mat-card>
        </div>
        <!--/Прочитать состояние реле-->

        <!--Управление реле-->
        <div class="control-block"
             *ngIf="isControlActive('power')"
        >
            <mat-card style="padding: 25px; width: 90%; height: 70%; ">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_3' | translate }}</mat-card-title>
                </mat-card-header>
                <div style="padding-top: 15px; width: 200px; text-align: center; margin: 0 auto">
                    <div>
                        <label>{{ 'app_accounting_point.control.msg_4' | translate }}</label>
                        <br/>
                        <span>{{ 'app_accounting_point.control.msg_5' | translate }}</span>
                        <mat-slide-toggle
                            [(ngModel)]="powerSendObject['management']['power']"
                            class="side_p"
                            (change)="changePower($event)"
                            [disabled]="disableEdit && !_checkAccess.listAccess('update', 4)"
                        ></mat-slide-toggle>
                        <span>{{ 'app_accounting_point.control.msg_6' | translate }}</span>
                    </div>
                </div>
            </mat-card>
        </div>
        <!--/Управление реле-->

        <!--Прочитать лимит мгновенной мощности-->
        <div class="control-block"
             *ngIf="isControlActive('relay_state')"
        >
            <mat-card class="control-block__card">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_52' | translate }}</mat-card-title>
                </mat-card-header>
                <button mat-raised-button
                        color="primary"
                        class="send_but"
                        (click)="readPowerLimit()">
                    {{ 'app_accounting_point.control.msg_51' | translate }}
                </button>
            </mat-card>
        </div>
        <!--/Прочитать лимит мгновенной мощности-->

        <!--Установка лимита мощности-->
        <div
            class="control-block control-limit"
            *ngIf="isControlActive('limit')"
        >
            <mat-card style="padding: 25px; width: 90%; height: 70%">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_7' | translate }}</mat-card-title>
                </mat-card-header>
                <div style="text-align: center">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ 'app_accounting_point.control.msg_8' | translate }}</mat-label>
                        <input
                            [(ngModel)]="powerLimitObject['management']['limit']"
                            matInput
                            type="number"
                            [value]="powerLimitObject['management']['limit']"
                            [disabled]="disableEdit"
                        />
                    </mat-form-field>
                    <button
                        mat-raised-button
                        color="primary"
                        class="send_but"
                        (click)="changeLimit()"
                        [disabled]="disableEdit"
                    >
                        {{ 'app_accounting_point.control.msg_9' | translate }}
                    </button>
                </div>
                <mat-checkbox *ngIf="controls?.hasOwnProperty('limit') && controls?.limit?.additional_limit"
                              [(ngModel)]="extraOptions">{{ 'app_accounting_point.control.msg_38' | translate }}
                </mat-checkbox>
            </mat-card>
        </div>
        <!--/Установка лимита мощности-->

        <!--Установка электронной пломбы-->
        <div class="control-block"
             *ngIf="isControlActive('set_plomb')"
        >
            <mat-card class="control-block__card">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_59' | translate }}</mat-card-title>
                </mat-card-header>
                <button mat-raised-button
                        color="primary"
                        class="send_but"
                        (click)="onSetPlomb()">
                    {{ 'app_accounting_point.control.msg_65' | translate }}
                </button>
            </mat-card>
        </div>
        <!--/Установка электронной пломбы-->

        <div *ngIf="extraOptions"
             class="control-block control-limit"
        >
            <mat-card style="padding: 25px; width: 90%; height: 70%">
                <form style="display:flex; flex-direction: column"
                      [formGroup]="extraForm">
                    <div style="display:flex; justify-content: space-between">
                        <mat-form-field class="popup__form-field" appearance="outline"
                                        [style]="!modeInterval?null:'width:100%'">
                            <mat-label>{{ 'app_accounting_point.control.msg_39' | translate }}</mat-label>
                            <mat-select formControlName="measurementMode" required>
                                <mat-option *ngFor="let value of listOfMeasurementMode"
                                            (click)="changeMode()"
                                            [value]="value.id">
                                    {{ value.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="popup__form-field" *ngIf="!modeInterval" appearance="outline"
                                        style="margin-left: 15px">
                            <mat-label>{{ 'app_accounting_point.control.msg_40' | translate }}</mat-label>
                            <input formControlName="apv_pause"
                                   max="60"
                                   min="0"
                                   matInput
                                   placeholder="{{ 'app_accounting_point.control.msg_40' | translate }}" type="number"

                            />
                        </mat-form-field>
                    </div>
                    <div style="display:flex; justify-content: space-between">
                        <mat-form-field class="popup__form-field" appearance="outline">
                            <mat-label>{{ 'app_accounting_point.control.msg_41' | translate }}</mat-label>
                            <input formControlName="apv_timer"
                                   max="9"
                                   min="0"
                                   matInput
                                   placeholder="{{ 'app_accounting_point.control.msg_41' | translate }}" type="number"
                            />
                        </mat-form-field>
                        <mat-form-field class="popup__form-field" appearance="outline" style="margin-left: 15px">
                            <mat-label>{{ 'app_accounting_point.control.msg_42' | translate }}</mat-label>
                            <input formControlName="apv_count"
                                   max="9"
                                   min="0"
                                   matInput
                                   placeholder="{{ 'app_accounting_point.control.msg_42' | translate }}" type="number"
                            />
                        </mat-form-field>
                    </div>
                </form>
            </mat-card>
        </div>
        <!--Установка частоты-->
        <div class="control-block control-limit"
             *ngIf="isControlActive('setFrequency')"
        >
            <mat-card class="control-block__card">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_35' | translate }}</mat-card-title>
                </mat-card-header>
                <div style="text-align: center">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ 'app_accounting_point.control.msg_36' | translate }}</mat-label>
                        <input
                            [(ngModel)]="frequency"
                            matInput
                            type="number"
                            [value]="frequency"
                            [disabled]="disableEdit"
                        />
                    </mat-form-field>
                    <button
                        mat-raised-button
                        color="primary"
                        class="send_but"
                        (click)="changeFrequency()"
                        [disabled]="disableEdit"
                    >
                        {{ 'app_accounting_point.control.msg_9' | translate }}
                    </button>
                </div>
            </mat-card>
        </div>
        <!--/Установка частоты-->

        <!--Прочитать дату/время-->
        <div class="control-block"
             *ngIf="isControlActive('get_datetime')"
        >
            <mat-card class="control-block__card">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_53' | translate }}</mat-card-title>
                </mat-card-header>
                <button mat-raised-button
                        color="primary"
                        class="send_but"
                        (click)="readDateAndTime()">
                    {{ 'app_accounting_point.control.msg_51' | translate }}
                </button>
            </mat-card>
        </div>
        <!--/Прочитать дату/время-->

        <!--Установка даты/времени на устройстве-->
        <div
            class="control-block control-archive"
            *ngIf="isControlActive('time_set')"
        >
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_11' | translate }}</mat-card-title>
                </mat-card-header>
                <div>
                    <div>
                        <mat-form-field floatLabel="always" appearance="outline"
                                        style="margin-left: 15px; margin-right: 15px">
                            <mat-label>{{ 'app_accounting_point.control.msg_12' | translate }}</mat-label>
                            <input
                                matInput
                                class="data-date"
                                (click)="setDeviceDate.open()"
                                (focus)="setDeviceDate.open()"
                                [matDatepicker]="setDeviceDate"
                                [(ngModel)]="setDateTimeSendObject.management['date']"
                                [disabled]="disableEdit"
                            />
                            <mat-datepicker-toggle matSuffix [for]="setDeviceDate"></mat-datepicker-toggle>
                            <mat-datepicker #setDeviceDate></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field floatLabel="always" appearance="outline" class="time">
                            <mat-label>{{ 'app_accounting_point.control.msg_13' | translate }}</mat-label>
                            <input
                                matInput
                                aria-label="24hr format"
                                [ngxTimepicker]="fullTime"
                                [format]="24"
                                readonly
                                placeholder="{{ 'app_accounting_point.control.msg_14' | translate }}"
                                [(ngModel)]="setDateTimeSendObject.management['time']"
                                [disabled]="disableEdit"
                            />
                            <ngx-material-timepicker #fullTime [cancelBtnTmpl]="cancelBtn"
                                                     [confirmBtnTmpl]="confirmBtn">
                            </ngx-material-timepicker>
                            <ng-template #cancelBtn>
                                <button class="pickerBtn cancelBtn" mat-raised-button style="margin-right: 15px"
                                        type="button">
                                    {{ 'app_accounting_point.control.msg_15' | translate }}
                                </button>
                            </ng-template>
                            <ng-template #confirmBtn>
                                <button class="pickerBtn" mat-raised-button color="primary"
                                        type="button">{{ 'app_accounting_point.control.msg_16' | translate }}
                                </button>
                            </ng-template>
                        </mat-form-field>
                        <button
                            style="margin-left: 15px; height: 50px; margin-bottom: 15px; vertical-align: middle"
                            mat-raised-button
                            color="primary"
                            (click)="setDateTime()"
                            [disabled]="disableEdit"
                        >
                            {{ 'app_accounting_point.control.msg_48' | translate }}
                        </button>
                    </div>
                </div>
            </mat-card>
        </div>
        <!--/Установка даты/времени на устройстве-->

        <!--Корректировка даты/времени на устройстве-->
        <div
            class="control-block"
            *ngIf="isControlActive('time_correct')"
        >
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_18' | translate }}</mat-card-title>
                </mat-card-header>
                <div>
                    <div>
                        <mat-form-field floatLabel="always" appearance="outline"
                                        style="margin-left: 15px; margin-right: 15px">
                            <mat-label>{{ 'app_accounting_point.control.msg_19' | translate }}</mat-label>
                            <input
                                matInput
                                class="correction-seconds"
                                type="number"
                                max="30"
                                min="-30"
                                [step]="1"
                                [(ngModel)]="correctTimeSendObject.management['time_correct']"
                                [disabled]="disableEdit"
                            />
                        </mat-form-field>

                        <button
                            style="margin-left: 15px; height: 50px; margin-bottom: 15px; vertical-align: middle"
                            mat-raised-button
                            color="primary"
                            (click)="correctTime()"
                            [disabled]="disableEdit"
                        >
                            {{ 'app_accounting_point.control.msg_20' | translate }}
                        </button>
                    </div>
                </div>
            </mat-card>
        </div>
        <!--/Корректировка даты/времени на устройстве-->
        <!--Корректировка даты/времени на устройстве с повышенной точностью до десятых-->
        <div
            class="control-block"
            *ngIf="isControlActive('time_correct2')"
        >
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_68' | translate }}</mat-card-title>
                </mat-card-header>
                <div>
                    <div>
                        <mat-form-field floatLabel="always" appearance="outline"
                                        style="margin-left: 15px; margin-right: 15px">
                            <mat-label>{{ 'app_accounting_point.control.msg_19' | translate }}</mat-label>
                            <input
                                matInput
                                class="correction-seconds"
                                type="number"
                                max="30"
                                min="-30"
                                [step]="0.1"
                                [(ngModel)]="correctTimeSendObject2.management['time_correct2']"
                                [disabled]="disableEdit"
                            />
                        </mat-form-field>

                        <button
                            style="margin-left: 15px; height: 50px; margin-bottom: 15px; vertical-align: middle"
                            mat-raised-button
                            color="primary"
                            (click)="correctTime2()"
                            [disabled]="disableEdit"
                        >
                            {{ 'app_accounting_point.control.msg_20' | translate }}
                        </button>
                    </div>
                </div>
            </mat-card>
        </div>
        <!--/Корректировка даты/времени на устройстве с повышенной точностью до десятых-->

        <!--Прочитать тарифное расписание-->
        <div class="control-block"
             *ngIf="controls?.hasOwnProperty('get_shedule_tariff') && controls?.get_shedule_tariff?.active">
            <mat-card class="control-block__card">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_54' | translate }}</mat-card-title>
                </mat-card-header>
                <button mat-raised-button
                        color="primary"
                        class="send_but"
                        (click)="readTariffSchedule()">
                    {{ 'app_accounting_point.control.msg_51' | translate }}
                </button>
            </mat-card>
        </div>
        <!--/Прочитать тарифное расписание-->

        <!--Установка параметров-->
        <div class="control-block"
             *ngIf="isControlActive('complex_set')"
        >
            <mat-card style="padding: 25px; width: 90%; height: 70%">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_21' | translate }}</mat-card-title>
                </mat-card-header>
                <div>
                    <mat-checkbox style="margin-right: 10px" [(ngModel)]="complexSetSendObject.management['bytes'][0]">
                        {{ 'app_accounting_point.control.msg_22' | translate }}
                    </mat-checkbox>
                    <mat-checkbox style="margin-right: 10px" [(ngModel)]="complexSetSendObject.management['bytes'][1]">
                        {{ 'app_accounting_point.control.msg_23' | translate }}
                    </mat-checkbox>
                    <mat-checkbox style="margin-right: 10px" [(ngModel)]="complexSetSendObject.management['bytes'][2]">
                        {{ 'app_accounting_point.control.msg_24' | translate }}
                    </mat-checkbox>
                    <mat-checkbox style="margin-right: 10px" [(ngModel)]="complexSetSendObject.management['bytes'][3]">
                        {{ 'app_accounting_point.control.msg_25' | translate }}
                    </mat-checkbox>
                    <mat-checkbox style="margin-right: 10px" [(ngModel)]="complexSetSendObject.management['bytes'][4]">
                        {{ 'app_accounting_point.control.msg_26' | translate }}
                    </mat-checkbox>
                    <mat-checkbox style="margin-right: 10px" [(ngModel)]="complexSetSendObject.management['bytes'][5]">
                        {{ 'app_accounting_point.control.msg_27' | translate }}
                    </mat-checkbox>
                    <mat-checkbox style="margin-right: 10px" [(ngModel)]="complexSetSendObject.management['bytes'][6]">
                        {{ 'app_accounting_point.control.msg_28' | translate }}
                    </mat-checkbox>
                    <div>
                        <mat-form-field floatLabel="always" appearance="outline"
                                        style="margin-top: 15px; margin-right: 15px">
                            <mat-select [(ngModel)]="complexSetSendObject.management['m_msg1']"
                                        [disabled]="disableEdit">
                                <mat-option *ngFor="let msg of availableComplexSetMsgs" [value]="msg.value">
                                    {{ 'available_complex_set_msgs.msg_' + msg.value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field floatLabel="always" appearance="outline"
                                        style="margin-top: 15px; margin-right: 15px">
                            <mat-select [(ngModel)]="complexSetSendObject.management['m_msg2']"
                                        [disabled]="disableEdit">
                                <mat-option *ngFor="let msg of availableComplexSetMsgs" [value]="msg.value">
                                    {{ 'available_complex_set_msgs.msg_' + msg.value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field floatLabel="always" appearance="outline"
                                        style="margin-top: 15px; margin-right: 15px">
                            <mat-select [(ngModel)]="complexSetSendObject.management['m_msg3']"
                                        [disabled]="disableEdit">
                                <mat-option *ngFor="let msg of availableComplexSetMsgs" [value]="msg.value">
                                    {{ 'available_complex_set_msgs.msg_' + msg.value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field floatLabel="always" appearance="outline"
                                        style="margin-top: 15px; margin-right: 15px">
                            <mat-select [(ngModel)]="complexSetSendObject.management['m_msg4']"
                                        [disabled]="disableEdit">
                                <mat-option *ngFor="let msg of availableComplexSetMsgs" [value]="msg.value">
                                    {{ 'available_complex_set_msgs.msg_' + msg.value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field floatLabel="always" appearance="outline"
                                        style="margin-top: 15px; margin-right: 15px">
                            <mat-select [(ngModel)]="complexSetSendObject.management['m_msg5']"
                                        [disabled]="disableEdit">
                                <mat-option *ngFor="let msg of availableComplexSetMsgs" [value]="msg.value">
                                    {{ msg.title }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field floatLabel="always" appearance="outline"
                                        style="margin-top: 15px; margin-right: 15px">
                            <mat-select [(ngModel)]="complexSetSendObject.management['m_msg6']"
                                        [disabled]="disableEdit">
                                <mat-option *ngFor="let msg of availableComplexSetMsgs" [value]="msg.value">
                                    {{ 'available_complex_set_msgs.msg_' + msg.value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <button
                            style="margin-left: 15px; height: 50px; margin-bottom: 15px; vertical-align: middle"
                            mat-raised-button
                            color="primary"
                            (click)="complexSet()"
                            [disabled]="disableEdit"
                        >
                            {{ 'app_accounting_point.control.msg_29' | translate }}
                        </button>
                    </div>
                </div>
            </mat-card>
        </div>
        <!--/Установка параметров-->

        <!--Установить период выхода на связь-->
        <div
            class="control-block"
            *ngIf="isControlActive('get_report_period')"
        >
            <mat-card style="padding: 25px; width: 90%; height: 70%">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_30' | translate }}</mat-card-title>
                </mat-card-header>
                <div>
                    <div>
                        <mat-form-field floatLabel="always" appearance="outline"
                                        style="margin-left: 15px; margin-right: 15px">
                            <mat-label>{{ 'app_accounting_point.control.msg_31' | translate }}</mat-label>
                            <input
                                matInput
                                class="correction-seconds"
                                type="number"
                                max="86400"
                                min="0"
                                [(ngModel)]="reportPeriodObject.management['report_period']"
                                [disabled]="disableEdit"
                            />
                        </mat-form-field>
                        <mat-form-field floatLabel="always" appearance="outline"
                                        style="margin-left: 15px; margin-right: 15px">
                            <mat-label>{{ 'app_accounting_point.control.msg_32' | translate }}</mat-label>
                            <input
                                matInput
                                class="correction-seconds"
                                type="number"
                                max="86400"
                                min="0"
                                [(ngModel)]="reportPeriodObject.management['offset']"
                                [disabled]="disableEdit"
                            />
                        </mat-form-field>

                        <button
                            style="margin-left: 15px; height: 50px; margin-bottom: 15px; vertical-align: middle"
                            mat-raised-button
                            color="primary"
                            (click)="setReportPeriod()"
                            [disabled]="disableEdit"
                        >
                            {{ 'app_accounting_point.control.msg_17' | translate }}
                        </button>
                    </div>
                </div>
            </mat-card>
        </div>
        <!--/Установить период выхода на связь-->

        <!--Активация передачи данных-->
        <div
            class="control-block"
            *ngIf="isControlActive('mts_activate')"
        >
            <mat-card style="padding: 25px; width: 90%; height: 70%">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_45' | translate }}</mat-card-title>
                </mat-card-header>
                <div style="text-align: center">
                    <button
                        class="send_but"
                        mat-raised-button
                        color="primary"
                        (click)="activateDataTransfer()"
                        [disabled]="disableEdit"
                    >
                        {{ 'app_accounting_point.control.msg_46' | translate }}
                    </button>
                </div>
            </mat-card>
        </div>
        <!--/Активация передачи данных-->

        <!--Разблокировать-->
        <div
            class="control-block control-tariff"
            *ngIf="controls?.hasOwnProperty('unblock') && controls['unblock']['active'] === true"
        >
            <mat-card style="padding: 25px; width: 90%; height: 70%">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_56' | translate }}</mat-card-title>
                </mat-card-header>
                <div style="text-align: center">
                    <button
                        class="send_but"
                        mat-raised-button
                        color="primary"
                        (click)="unblock()"
                        [disabled]="disableEdit"
                    >
                        {{ 'app_accounting_point.control.msg_57' | translate }}
                    </button>
                </div>
            </mat-card>
        </div>
        <!--/Разблокировать-->


        <div class="vega-settings"
             *ngIf="controls?.vega_settings?.settings && controls?.vega_settings?.active">
            <div style="width: 100%; margin-bottom: 12px;"
                 *ngFor="let setting of controls.vega_settings.settings | keyvalue">
                <mat-card style="height: 150px;">
                    <mat-card-header>
                        <mat-card-title
                            [matTooltip]="getSettingName(setting)"
                            style="white-space: nowrap;
                                           overflow: hidden;
                                           text-overflow: ellipsis;
                                           max-width: 350px;">
                            {{ getSettingName(setting) }}
                        </mat-card-title>
                    </mat-card-header>
                    <ng-container *ngIf="setting.value.max">
                        <div style="display: flex; align-items: center">
                            <mat-form-field floatLabel="always" appearance="outline"
                                            style="margin-left: 15px; margin-right: 15px; margin-top: 18px">
                                <mat-label>{{ getSettingName(setting) }}</mat-label>
                                <input [appInputMinMaxValidate]="{ max: setting.value.max, min: setting.value.min }"
                                       matInput
                                       [(ngModel)]="vegaSettingsModel[setting.key]"
                                       placeholder="{{setting.value.min}} - {{setting.value.max}}"
                                       type="number"/>
                            </mat-form-field>
                            <button
                                style="height: 50px"
                                mat-raised-button
                                color="primary"
                                (click)="onChangeVegaSettings(setting.key, setting.value)"
                                [disabled]="disableEdit"
                            >
                                {{ 'tariffSetting.button' | translate }}
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="setting.value.properties">
                        <div style="display: flex; align-items: center">
                            <mat-form-field floatLabel="always" appearance="outline"
                                            style="margin-left: 15px; margin-right: 15px; margin-top: 18px">
                                <mat-label>{{ getSettingName(setting) }}</mat-label>
                                <mat-select [(ngModel)]="vegaSettingsModel[setting.key]">
                                    <mat-option *ngFor="let option of setting.value.properties | keyvalue"
                                                [value]="option.value">
                                        {{ 'other.' + option.key | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button
                                style="height: 50px"
                                mat-raised-button
                                color="primary"
                                (click)="onChangeVegaSettings(setting.key, setting.value)"
                                [disabled]="disableEdit"
                            >
                                {{ 'tariffSetting.button' | translate }}
                            </button>
                        </div>
                    </ng-container>
                </mat-card>
            </div>
        </div>
        <div
            *ngIf="controls?.set_settings?.settings && controls?.set_settings?.active">
            <mat-card class="mat-card-set-settings">
                <div class="set-settings">
                    <div *ngFor="let setting of controls.set_settings.settings | keyvalue">
                        <mat-card-header class="header">
                            <mat-card-title class="title"
                                [matTooltip]="'other.' + setting.key | translate">
                                {{ 'other.' + setting.key | translate }}
                            </mat-card-title>
                        </mat-card-header>
                        <ng-container *ngIf="setting.value.properties">
                            <div class="div-form-field">
                                <mat-form-field class="form-field" floatLabel="always" appearance="outline">
                                    <mat-label>{{ getSettingName(setting) }}</mat-label>
                                    <mat-select [(ngModel)]="setSettingsModel[setting.key]">
                                        <mat-option *ngFor="let option of setting.value.properties | keyvalue"
                                                    [value]="option.value">
                                            {{(option.key === 'Включен' || option.key === 'Выключен') ? ('other.' + option.key | translate) : option.key }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!setting.value.properties">
                            <div class="div-form-field">
                                <mat-form-field class="form-field" floatLabel="always" appearance="outline">
                                    <mat-label>{{ getSettingName(setting) }}</mat-label>
                                    <input [(ngModel)]="setSettingsModel[setting.key]" matInput/>
                                </mat-form-field>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <button class="btn"
                    mat-raised-button
                    color="primary"
                    (click)="onChangeSetSettings()"
                    [disabled]="disableEdit"
                >
                    {{ 'tariffSetting.button' | translate }}
                </button>
            </mat-card>
        </div>
        <div class="set-settings"
             *ngIf="controls?.set_setpoint?.active">
            <mat-card class="mat-card-set-settings">
                <div class="mat-card-div">
                    <div *ngFor="let setting of controls.set_setpoint.settings | keyvalue">
                        <mat-card-header>
                            <mat-card-title class="title"
                                [matTooltip]="getSettingName(setting)">
                                {{ getSettingName(setting) }}
                            </mat-card-title>
                        </mat-card-header>
                        <ng-container>
                            <div class="div-form-field">
                                <mat-form-field floatLabel="always" appearance="outline"
                                                class="form-field">
                                    <mat-label>{{ getSettingName(setting) }}</mat-label>
                                    <input [(ngModel)]="setSetpointsModel[setting.key]" matInput/>
                                </mat-form-field>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <button
                    class="btn"
                    mat-raised-button
                    color="primary"
                    (click)="onChangeSetpoints()"
                    [disabled]="disableEdit">
                    {{ 'tariffSetting.button' | translate }}
                </button>
            </mat-card>
        </div>

        <!--Изменить тарифное расписание-->
        <div *ngIf="currentDevice?.model?.options?.control?.set_tariff_shedule?.active"
             class="control-block control-archive">
            <app-tariff-setting [id]="currentDevice?.id"
                                [properties]="currentDevice?.properties?.schedule_electro"
                                [status]="currentStatus?.last_shedule_tariff"
            >
            </app-tariff-setting>
        </div>
        <!--/Изменить тарифное расписание-->

        <!--Отправка произвольных команд на устройства LoRaWan-->
        <div
            class="control-block custom-command"
            *ngIf="isControlActive('send_raw_payload')"
        >
            <mat-card class="mat-card-send-raw-payload">
                <mat-card-header class="header">
                    <mat-card-title>{{ 'app_accounting_point.control.msg_58' | translate }}</mat-card-title>
                </mat-card-header>
                <form [formGroup]="freeCommandForm">
                    <mat-form-field floatLabel="always"
                                    appearance="outline">
                        <mat-label>{{ 'app_accounting_point.control.msg_62' | translate }}</mat-label>
                        <input matInput type="text" formControlName="payload">
                    </mat-form-field>

                    <mat-form-field floatLabel="always"
                                    appearance="outline">
                        <mat-label>{{ 'app_accounting_point.control.msg_63' | translate }}</mat-label>
                        <input matInput type="number" formControlName="fport" min="0" max="255">
                    </mat-form-field>

                    <mat-form-field floatLabel="always"
                                    appearance="outline">
                        <mat-label>{{ 'app_accounting_point.control.msg_64' | translate }}</mat-label>
                        <mat-select formControlName="class">
                            <mat-option *ngFor="let val of classForCommandForLoRaWan"
                                        [value]="val.value">
                                {{ val.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
                <div style="text-align: center">
                    <button
                        class="send_but"
                        mat-raised-button
                        color="primary"
                        (click)="sendingCustomCommands()"
                        [disabled]="disableEdit"
                    >
                        {{ 'app_accounting_point.control.msg_10' | translate }}
                    </button>
                </div>
            </mat-card>
        </div>
        <!--/Отправка произвольных команд на устройства LoRaWan-->

        <mat-card style="padding: 25px; width: 90%; height: 70%" class="lartech-settings"
                  *ngIf="controls?.lartech_settings?.settings">
            <mat-card-header>
                <mat-card-title>{{ 'app_accounting_point.control.msg_47' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-form-field class="lartech-settings__field" floatLabel="always" appearance="outline">
                <mat-label>{{ getSettingName(lartechSettings?.task_id) }}
                </mat-label>
                <mat-select [(ngModel)]="selectedTaskId"
                            (selectionChange)="onSelectedTaskId()">
                    <mat-option *ngFor="let taskId of listTaskId"
                                [value]="taskId">
                        {{ taskId }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="isNumberSelectedTaskId">
                <div>
                    <form [formGroup]="settingsTaskPlanerForm">
                        <mat-form-field class="lartech-settings__field" floatLabel="always"
                                        appearance="outline">
                            <mat-label>
                                {{ getSettingName(lartechSettings?.delay) }}
                            </mat-label>
                            <mat-select formControlName="delay">
                                <mat-option *ngFor="let delay of lartechDelaySettings"
                                            [value]="delay.val">
                                    {{ delay.key }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="lartech-settings__field" floatLabel="always"
                                        appearance="outline">
                            <mat-label>{{ getSettingName(lartechSettings?.param1) || 'param1' }}</mat-label>
                            <input matInput type="number" formControlName="param1"
                                   max="{{lartechSettings?.param1 ? lartechSettings?.param1.max : 255}}"
                                   min="0">
                        </mat-form-field>
                        <mat-form-field class="lartech-settings__field" floatLabel="always"
                                        appearance="outline">
                            <mat-label>{{ getSettingName(lartechSettings?.param2) || 'param2' }}</mat-label>
                            <input matInput type="number" formControlName="param2"
                                   max="{{lartechSettings?.param2 ? lartechSettings?.param2.max : 255}}"
                                   min="0">
                        </mat-form-field>
                        <mat-form-field class="lartech-settings__field" floatLabel="always"
                                        appearance="outline">
                            <mat-label>{{ getSettingName(lartechSettings?.param3) || 'param3' }}</mat-label>
                            <input matInput type="number" formControlName="param3"
                                   max="{{lartechSettings?.param3 ? lartechSettings?.param3.max : 255}}"
                                   min="0">
                        </mat-form-field>
                        <mat-form-field class="lartech-settings__field" floatLabel="always"
                                        appearance="outline">
                            <mat-label>{{ getSettingName(lartechSettings?.param4) || 'param4' }}</mat-label>
                            <input matInput type="number" formControlName="param4"
                                   max="{{lartechSettings?.param4 ? lartechSettings?.param4.max : 255}}"
                                   min="0">
                        </mat-form-field>
                        <mat-form-field class="lartech-settings__field" floatLabel="always"
                                        appearance="outline">
                            <mat-label>{{ getSettingName(lartechSettings?.param5) || 'param5' }}</mat-label>
                            <input matInput type="number" formControlName="param5"
                                   max="{{lartechSettings?.param5 ? lartechSettings?.param5.max : 255}}"
                                   min="0">
                        </mat-form-field>
                        <mat-form-field class="lartech-settings__field" floatLabel="always"
                                        appearance="outline">
                            <mat-label>{{ getSettingName(lartechSettings?.param6) || 'param6' }}</mat-label>
                            <input matInput type="number" formControlName="param6"
                                   max="{{lartechSettings?.param6 ? lartechSettings?.param6.max : 255}}"
                                   min="0">
                        </mat-form-field>
                        <mat-form-field class="lartech-settings__field" floatLabel="always"
                                        appearance="outline">
                            <mat-label>{{ getSettingName(lartechSettings?.param7) || 'param7' }}</mat-label>
                            <input matInput type="number" formControlName="param7"
                                   max="{{lartechSettings?.param7 ? lartechSettings?.param7.max : 255}}"
                                   min="0">
                        </mat-form-field>
                        <mat-form-field class="lartech-settings__field" floatLabel="always"
                                        appearance="outline">
                            <mat-label>{{ getSettingName(lartechSettings?.param8) || 'param8' }}</mat-label>
                            <input matInput type="number" formControlName="param8"
                                   max="{{lartechSettings?.param8 ? lartechSettings?.param8.max : 255}}"
                                   min="0">
                        </mat-form-field>

                        <mat-form-field class="lartech-settings__field" floatLabel="always"
                                        appearance="outline">
                            <mat-label>{{ getSettingName(lartechSettings?.profile) || 'profile' }}</mat-label>
                            <mat-select formControlName="profile">
                                <mat-option *ngFor="let delay of lartechProfileSettings"
                                            [value]="delay.val">
                                    {{ delay.key }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="lartech-settings__field" floatLabel="always"
                                        appearance="outline">
                            <mat-label>{{ getSettingName(lartechSettings?.refresh) || 'refresh' }}</mat-label>
                            <mat-select formControlName="refresh">
                                <mat-option *ngFor="let delay of lartechRefreshSettings"
                                            [value]="delay.val">
                                    {{ delay.key }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </form>
                </div>
                <button mat-button mat-raised-button color="primary"
                        (click)="setSchedulerSettings()">
                    {{ 'app_accounting_point.control.msg_48' | translate }}
                </button>
            </ng-container>
        </mat-card>

        <!--Запрос специальные дни-->
        <div class="control-block control-tariff"
             *ngIf="isControlActive('get_special_day')"
        >
            <mat-card style="padding: 25px; width: 90%; height: 70%">
                <mat-card-header>
                    <mat-card-title>{{ 'app_accounting_point.control.msg_76' | translate }}</mat-card-title>
                </mat-card-header>
                <div style="text-align: center">
                    <button
                        class="send_but"
                        mat-raised-button
                        color="primary"
                        (click)="getSpecialDays()"
                        [disabled]="disableEdit"
                    >
                        {{ 'app_accounting_point.control.msg_51' | translate }}
                    </button>
                </div>
            </mat-card>
        </div>
        <!--/Запрос специальные дни-->

        <!-- специальные дни установка -->
        <div class="control-block" *ngIf="isControlActive('set_special_day')">
            <mat-card>
                <mat-card-header>
                    <mat-card-title [matTooltip]="'app_accounting_point.control.msg_69' | translate"
                                    style="white-space: nowrap;
                                       overflow: hidden;
                                       text-overflow: ellipsis;
                                       max-width: 350px;">
                        {{ 'app_accounting_point.control.msg_69' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <app-tariff-setting [id]="currentDevice?.id"
                                    [isSpecialDays]="true"
                                    [status]="correctSpecialDays.management.set_special_day.shedule"
                                    (updateForSpecialDays)="updateTariffsForSpecialDays($event)"
                >
                </app-tariff-setting>
                <div class="mt-2"></div>
                <app-special-days-selector
                    [(specialDays)]="correctSpecialDays.management.set_special_day.day_list"
                ></app-special-days-selector>
                <div class="mt-2"></div>
                <div style="text-align: center">
                    <button
                        class="send_but"
                        mat-raised-button
                        color="primary"
                        (click)="setSpecialDays()"
                        [disabled]="disableEdit"
                    >
                        {{ 'app_accounting_point.control.msg_75' | translate }}
                    </button>
                </div>
            </mat-card>
        </div>
        <!--/ специальные дни установка-->

    </div>


</div>
