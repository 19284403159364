<div>
    <h4 mat-dialog-title>
        {{'confirm_control.confirm_action' | translate}}
    </h4>
    <mat-dialog-actions>
        <button mat-button
                mat-flat-button
                color="default"
                (click)="no()">
            {{'confirm_control.no' | translate}}
        </button>
        <button mat-button
                mat-flat-button
                color="primary"
                (click)="yes()">
            {{'confirm_control.yes' | translate}}
        </button>
    </mat-dialog-actions>
</div>
