<button
    mat-icon-button
    [matMenuTriggerFor]="filterMenu">
    <mat-icon>filter_list</mat-icon>
</button>
<mat-menu #filterMenu="matMenu" class="table-filter-menu">
    <div class="p-2 table-filter-menu">
        <nekta-search *ngIf="withVirtualScroll"
                      size="full"
                      [searchString]="searchString"
                      (searchStringChange)="onSearch($event)"
                      (click)="$event.stopPropagation()"
        ></nekta-search>
        <mat-selection-list
            #matSelectionList
            color="primary"
            dense
            disableRipple
            [multiple]="false"
            (selectionChange)="onFilterChange($event.options[0].value)"
        >
            <ng-container *ngIf="withVirtualScroll; else baseList;">
                <cdk-virtual-scroll-viewport itemSize="10" class="virtual-scroll">
                    <mat-list-option
                        [class.mat-selection-list-selected-custom]="selectedOption === null"
                        [value]="null">
                        {{ 'common.all' | translate }}
                    </mat-list-option>
                    <mat-list-option
                        *cdkVirtualFor="let option of filteredOptions; trackBy: trackByFn;"
                        [class.mat-selection-list-selected-custom]="selectedOption === option.id"
                        [value]="option.id"
                    >
                        <p [class.option]="selectedOption !== option.id">
                            {{ option.title }}
                        </p>
                    </mat-list-option>
                </cdk-virtual-scroll-viewport>
            </ng-container>
            <ng-template #baseList>
                <mat-list-option
                    [class.mat-selection-list-selected-custom]="selectedOption === null"
                    [value]="null">
                    {{ 'common.all' | translate }}
                </mat-list-option>
                <mat-list-option
                    *ngFor="let option of filteredOptions; trackBy: trackByFn;"
                    [class.mat-selection-list-selected-custom]="selectedOption === option.id"
                    [value]="option.id"
                >
                    <p [class.option]="selectedOption !== option.id">
                        {{ option.title }}
                    </p>
                </mat-list-option>
            </ng-template>
        </mat-selection-list>
    </div>
</mat-menu>
