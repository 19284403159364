import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CloudComponent } from '@cloud/cloud.component';
import { CloudGuard } from '@core/guards/cloud.guard';
import { DevicesGuard } from '@core/guards/devices.guard';
import { DashboardGuard } from '@core/guards/dashboard.guard';
import { UsersGuard } from '@core/guards/users.guard';
import { CompaniesGuard } from '@core/guards/companies.guard';
import { ObjectsGuard } from '@core/guards/objects.guard';
import { ReportsGuard } from '@core/guards/reports.guard';
import { EventsGuard } from '@core/guards/events.guard';
import { WithoutAccessComponent } from '@auth/without-access/without-access.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanySettingsGuard } from '@core/guards/company-settings.guard';
import { AdministrationGuard } from '@core/guards/administration.guard';
import {AdminComponent} from './admin/admin.component';

const routes: Routes = [
    { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
    {
        path: 'cloud',
        component: CloudComponent,
        canActivate: [CloudGuard],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'devices',
                loadChildren: () => import('./cloud/devices/devices.module').then((m) => m.DevicesModule),
                canActivate: [DevicesGuard],
                runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./cloud/dashboard/dashboard.module').then((m) => m.DashboardModule),
                canActivate: [DashboardGuard],
                runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'
            },
            {
                path: 'users',
                loadChildren: () => import('./cloud/users/users.module').then((m) => m.UsersModule),
                canActivate: [UsersGuard],
                runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'
            },
            {
                path: 'companies',
                loadChildren: () => import('./cloud/companies/companies.module').then((m) => m.CompaniesModule),
                canActivate: [CompaniesGuard],
                runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'
            },
            {
                path: 'company-settings',
                canActivate: [CompanySettingsGuard],
                loadChildren: () => import('./cloud/company-settings/company-settings.module').then((m) => m.CompanySettingsModule),
                runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'
            },
            {
                path: 'objects',
                loadChildren: () => import('./cloud/objects/objects.module').then((m) => m.ObjectsModule),
                canActivate: [ObjectsGuard],
                runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'
            },
            {
                path: 'reports',
                loadChildren: () => import('./cloud/reports/reports.module').then((m) => m.ReportModule),
                canActivate: [ReportsGuard],
                runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'
            },
            {
                path: 'profile',
                loadChildren: () => import('./cloud/profile/profile.module').then((m) => m.ProfileModule)
            },
            {
                path: 'directories',
                loadChildren: () => import('./cloud/directories/directories.module').then((m) => m.DirectoriesModule)
            },
            {
                path: 'administration',
                loadChildren: () => import('./cloud/administration/administration.module').then((m) => m.AdministrationModule),
                canActivate: [AdministrationGuard]
            },
            {
                path: 'events',
                loadChildren: () => import('./cloud/events/events.module').then((m) => m.EventsModule),
                canActivate: [EventsGuard],
                runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'
            },
            {
                path: '**',
                redirectTo: 'dashboard'
            }
        ]
    },
    {
        path: 'admin',
        component: AdminComponent,
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
    },
    {
        path: 'withoutAccess',
        component: WithoutAccessComponent
    },
    {
        path: '**',
        redirectTo: 'auth/login'
    }
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            useHash: true,
            enableTracing: false,
            onSameUrlNavigation: 'reload'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
