import { ConsumersEffects } from 'src/app/cloud/directories/store/effects/consumers.effects';
import { SuppliersEffects } from 'src/app/cloud/directories/store/effects/suppliers.effects';
import { PollGroupsEffects } from 'src/app/cloud/devices/store/effects/polls-group.effects';
import { SharedEffects } from 'src/app/store/effects/shared.effects';
import { ReportGeneratorEffects } from 'src/app/store/effects/report-generator.effects';
import { TreeEffects } from '@store/tree/tree.effects';
import { ReportTemplatesEffects } from '@store/reportTemplates/reportTemplates.effects';
import { ReportBuilderEffects } from '@store/reportBuilder/report-builder.effects';

export const appEffects = [
    ConsumersEffects,
    SuppliersEffects,
    PollGroupsEffects,
    SharedEffects,
    ReportBuilderEffects,
    ReportGeneratorEffects,
    ReportTemplatesEffects,
    TreeEffects
];
