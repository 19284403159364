<div class="wrapper h-100 flex-center">
    <ng-container *ngIf="!isSpinnerVisible; else spinner;">
        <div *ngIf="!errorMsg" class="flex-between-center g-4 p-4 flex-wrap-mobile">
            <app-vector-diagram
                *ngIf="isAllPhasesReady()"
                [device]="device"
                [message]="pollResult"
            ></app-vector-diagram>
            <div class="device-details">
                <ng-container *ngFor="let key of resultKeys">
                    <p *ngIf="pollResult?.[key] || pollResult?.[key] === 0" class="mv-1">
                        <b class="pr-1">{{ getFieldTitle(key)?.title || 'unknown value' }}:</b>
                        <span *ngIf="isString(key)">{{pollResult?.[key]}}{{ getFieldTitle(key)?.unit }}</span>
                        <span *ngIf="isNumber(key)">{{pollResult?.[key] | number: '0.0-2'}}{{ getFieldTitle(key)?.unit }}</span>
                        <b *ngIf="!isNumber(key) && !isString(key)" class="ph-1">-</b>
                    </p>
                </ng-container>
            </div>
        </div>
        <mat-error *ngIf="errorMsg">
            <div class="p-4">
                {{ errorMsg }}
            </div>
        </mat-error>
        <button
            *ngIf="errorMsg && device"
            mat-raised-button
            color="primary"
            (click)="getPollData()"
        >
            <mat-icon>refresh</mat-icon>
            {{ 'common.refresh' | translate }}
        </button>
    </ng-container>
    <ng-template #spinner>
        <div class="p-4 flex-center">
            <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>
    </ng-template>

    <button
        class="close-btn"
        mat-mini-fab
        color="warn"
        (click)="closeModal()"
    >
        <mat-icon>cancel</mat-icon>
    </button>
</div>
