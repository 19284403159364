import {Action} from '@ngrx/store';

export enum PollGroupsActions {
    FetchPollGroups = '[PollGroups] Fetch polls group',
    FetchPollGroupsSuccess = '[PollGroups] Fetch polls group success',
    AddPollGroup = '[PollGroups] Add consumer',
    AddPollGroupSuccess = '[PollGroups] Add consumer success',
    AddPollGroupFail = '[PollGroups] Add consumer fail',
    EditPollGroup = '[PollGroups] Edit consumer',
    EditPollGroupSuccess = '[PollGroups] Edit consumer success',
    EditPollGroupFail = '[PollGroups] Edit consumer fail'
}

export class fetchPollGroups implements Action {
    readonly type = '[PollGroups] Fetch polls group';

    constructor(public payload: { page: number; perPage: number }) {}
}

export class fetchPollGroupsSuccess implements Action {
    readonly type = '[PollGroups] Fetch polls group success';

    constructor(public payload: any) {}
}

export class addPollGroups implements Action {
    readonly type = '[PollGroups] Add consumer';

    constructor(public payload: any) {}
}

export class addPollGroupsSuccess implements Action {
    readonly type = '[PollGroups] Add consumer success';

    constructor() {}
}

export class addPollGroupsFail implements Action {
    readonly type = '[PollGroups] Add consumer fail';

    constructor(public payload: any) {}
}

export class editPollGroups implements Action {
    readonly type = '[PollGroups] Edit consumer';

    constructor(public payload: any) {}
}

export class editPollGroupsSuccess implements Action {
    readonly type = '[PollGroups] Edit consumer success';

    constructor() {}
}

export class editPollGroupsFail implements Action {
    readonly type = '[PollGroups] Edit consumer fail';

    constructor(public payload: any) {}
}

export type PollGroupsUnion =
    | fetchPollGroups
    | fetchPollGroupsSuccess
    | addPollGroups
    | addPollGroupsSuccess
    | addPollGroupsFail
    | editPollGroups
    | editPollGroupsSuccess
    | editPollGroupsFail;
