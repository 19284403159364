import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'toFixedDecimalPipe'
})
export class ToFixedDecimalPipe implements PipeTransform {

    constructor() {
    }

    transform(value: number, decimal: number): string | number {
        if (_.isNumber(value) && value > 0) {
            return value.toFixed(decimal);
        }
        return value;
    }
}
