import { Pipe, PipeTransform } from '@angular/core';
import { TranslateLanguageService } from '../services/translate-language.service';

@Pipe({
    name: 'getTitlePipe'
})
export class GetTitlePipe implements PipeTransform {
    private readonly currentLanguage = this.translateLanguageService.currentLanguage$.getValue();
    constructor(private translateLanguageService: TranslateLanguageService) {
    }

    transform(value: any, propertyNameRu = '', propertyNameEn = ''): string {
        if (value === undefined || value === null) {
            return '-';
        }
        if (this.currentLanguage === 'en' && value[propertyNameEn]) {
            return value[propertyNameEn];
        } else if (value[propertyNameRu]) {
            return value[propertyNameRu];
        }
        return value;
    }
}
