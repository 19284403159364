import { createReducer, on } from '@ngrx/store';
import { TreeActions } from '@store/tree/tree.actions';
import { SortDirection } from '@angular/material/sort';
import { cloneDeep } from 'lodash';

const modifyTree: (state: TreeState, element: any) => any = (state, element) => {
    const index = element.node.parentId ? element.node.parentId : '0';
    const newState = {
        ...state,
        objectsTree: {
            ...state.objectsTree,
            [index]: [...(state.objectsTree[index] || []), element.node]
        }
    };
    return addChildsCount(newState, element);
};

const addChildsCount: (state: TreeState, element: any, changeParent?: any) => any = (state, element, changeParent = null) => {
    const index = element.parentId ? element.parentId : '0';
    const newState = {
        ...state,
        objectsTree: {
            ...state.objectsTree,
            [index]: state.objectsTree[index].map((el) =>
                el._id === element.node.parentId
                    ? {
                          ...el,
                          childs_count: el.childs_count === 0 || el.childs_count === undefined ? 1 : el.childs_count + 1
                      }
                    : el
            )
        }
    };
    return changeParent ? reduceChildsCount(newState, element, true) : newState;
};
const reduceChildsCount: (state: TreeState, element: any, changeParent?: any) => any = (state, element, changeParent = false) => {
    const index = element.parentId ? element.parentId : '0';
    return {
        ...state,
        objectsTree: {
            ...state.objectsTree,
            [index]: state.objectsTree[index].map((el) =>
                el._id === (changeParent ? element.key : element.node.parentId)
                    ? { ...el, childs_count: el.childs_count === 0 ? 0 : el.childs_count - 1 }
                    : el
            )
        }
    };
};

const sortObjectsTree: (state: TreeState) => any = (state) => {
    const newState = cloneDeep(state);
    const sortOrder = state.sortDirection || 'asc';
    for (const [key, value] of Object.entries(newState.objectsTree)) {
        if (Array.isArray(value)) {
            newState.objectsTree[key] = value.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (sortOrder === 'asc') {
                    return nameA.localeCompare(nameB);
                } else {
                    return nameB.localeCompare(nameA);
                }
            });
        }
    }
    newState.sortDirection = sortOrder === 'asc' ? 'desc' : 'asc';
    return newState;
};

export interface TreeState {
    objectsTree: any;
    sortDirection: SortDirection;
}

const initialState: TreeState = {
    objectsTree: null,
    sortDirection: ''
};

export const treeReducer = createReducer(
    initialState,

    on(TreeActions.addelement, (state, { payload }) => {
        return modifyTree(state, payload);
    }),
    on(TreeActions.cacheelement, (state, { payload }) => {
        return {
            ...state,
            objectsTree: {
                ...(state.objectsTree || {}),
                [payload.id || '0']: payload.items
            }
        };
    }),
    on(TreeActions.loadobjectstree, (state, { payload }) => {
        return {
            ...state,
            objectsTree: state.objectsTree === null ? { '0': payload } : { ...state.objectsTree }
        };
    }),
    on(TreeActions.sortobjectstree, (state) => {
        return sortObjectsTree(state);
    }),
    on(TreeActions.resetobjectstree, (state) => {
        return { ...state, objectsTree: null };
    }),
    on(TreeActions.resetall, (state) => {
        return { ...initialState };
    })
);
