<h2 mat-dialog-title class="safe-text text-center">
    {{ title | translate }}
</h2>
<div mat-dialog-content *ngIf="content" class="safe-text">
    <p
        class="text-center"
        [class.mat-error]="isError"
    >
        {{ content | translate }}
    </p>
</div>
<div mat-dialog-content class="safe-text">
    <mat-form-field class="w-100">
        <input
            matInput
            [formControl]="textInput"
            placeholder="{{ placeholder | translate }}"
        />
    </mat-form-field>
</div>
<div *ngIf="textInput?.errors && textInput.touched">
    <mat-error *ngIf="textInput?.errors?.required">
        {{ 'validators.required' | translate }}
    </mat-error>
    <mat-error *ngIf="textInput?.errors?.maxlength">
        {{ 'validators.maxlength' | translate : {value: textInput?.errors?.maxlength?.requiredLength || 255} }}
    </mat-error>
    <mat-error *ngIf="textInput?.errors?.minlength">
        {{ 'validators.minlength' | translate : {value: textInput?.errors?.minlength?.requiredLength || 3} }}
    </mat-error>
</div>
<div mat-dialog-actions class="flex-evenly">
    <button
        mat-button
        mat-flat-button
        class="alert-button safe-text"
        (click)="onClose()"
    >
        {{ cancelBtn | translate }}
    </button>
    <button
        mat-button
        mat-flat-button
        color="primary"
        class="alert-button safe-text"
        (click)="onConfirm()"
    >
        {{ confirmBtn | translate }}
    </button>
</div>
