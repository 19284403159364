import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CloudComponent} from 'src/app/cloud/cloud.component';
import {MatSort} from '@angular/material/sort';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ReplaySubject} from 'rxjs';
import {DevicesService} from '../../../../services/devices.service';
import * as moment from 'moment';
import 'moment-timezone';
import {CheckAccess, CloudService, DateManipulations} from 'src/app/cloud/cloud.service';
import 'zrender/lib/svg/svg';
import {MeteringDevicesDataComponent} from '../data.component';
import * as _ from 'lodash';
import {ErrorsService} from '@core/services/errors.service';
import {takeUntil} from 'rxjs/operators';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';

@Component({
    selector: 'metering-devices-data-journal',
    templateUrl: './journal.component.html',
    styleUrls: ['./journal.component.less']
})
export class MeteringDevicesJournalComponent implements OnInit, OnDestroy {
    @Input() msgType: number[];
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    withKt: boolean = false;
    withLoss: boolean = false;
    app;
    loadApp = this._cloudService.appConst.pipe(takeUntil(this.destroyed$)).subscribe((app: any) => {
        this.app = app;
    });

    @Input() device: any;
    @Input() dateForm: any;
    @Input() msgGroup: any;
    @Input() typeJournal: any;
    isSpinnerVisible = true;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('matPaginator', { read: MatPaginator }) paginator: MatPaginator;
    dataSource = new MatTableDataSource();

    displayedColumns: string[];
    availableColumns: string[];
    fieldsTitles: any = {
        datetime: this.translate.instant('devices.data.msg_1'),
        event_name: this.translate.instant('devices.data.msg_2'),
    };
    settings;
    showChart = true;
    dataType = 'device';
    pageSizeOptions: number[] = [10, 25, 100];
    previousPageIndex = 0;
    pageIndex = 0;
    pageSize = 10;
    itemsLength: number;
    isLoadingResults = false;

    constructor(
        private translate: TranslateService,
        public cloud: CloudComponent,
        private devicesService: DevicesService,
        private _cloudService: CloudService,
        private _dataComponent: MeteringDevicesDataComponent,
        public _checkAccess: CheckAccess,
        private errors: ErrorsService,
        public dateManipulations: DateManipulations
    ) {
        CloudService.setLanguage(translate);
    }

    ngOnInit() {
        this._cloudService.settings.subscribe((settings) => (this.settings = settings));
        this.devicesService.refreshData.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.isSpinnerVisible = true;
            if (!this.typeJournal || this.typeJournal.length === 0) {
                this.dataSource.data = [];
                this.isSpinnerVisible = false;
                return;
            }
            this.dataSource.sort = this.sort;
            this.devicesService
                .getMessagesByTypes(
                    this.device.id,
                    this.devicesService.dateForm.startDate,
                    this.devicesService.dateForm.stopDate,
                    this.typeJournal,
                    this.previousPageIndex,
                    this.pageIndex + 1,
                    this.pageSize,
                )
                .pipe(takeUntil(this.destroyed$))
                .subscribe((res: any) => {
                    if (res.data.messages.data.length == 0) {
                        this.dataSource.data = [];
                        setTimeout(() => {
                            this.isSpinnerVisible = false;
                        }, 500);
                        return;
                    }
                    this.pageIndex = res.data.messages.current_page - 1;
                    this.pageSize = res.data.messages.per_page;
                    this.itemsLength = res.data.messages.total;
                    this.dataSource.data = res.data.messages.data;
                    this.availableColumns = ['datetime', 'event_name'];
                    let filteredColumns = this.availableColumns;
                    this.displayedColumns = this.sortFields(
                        _.get(
                            this.settings,
                            'deviceFields.model_id_' + this.device?.model?.id + '.journal_device',
                            _.clone(filteredColumns)
                        ),
                        this.availableColumns
                    );
                    this.isSpinnerVisible = false;
                });
        });
    }

    changeFieldVisible(field) {
        if (this.displayedColumns.indexOf(field) === -1) {
            this.displayedColumns.push(field);
        } else {
            _.pull(this.displayedColumns, field);
        }
        this.displayedColumns = this.sortFields(this.displayedColumns, this.availableColumns);
        _.set(
            this.settings,
            'deviceFields.model_id_' + this.device?.model?.id + '.journal_device',
            this.displayedColumns
        );
        this._cloudService.settings = this.settings;
    }

    sortFields(columns, mainArr = null) {
        if (_.isNull(mainArr)) {
            mainArr = [
                'datetime',
                'in1',
                'start_in1',
                'end_in1',
                'delta_in1',
                'in2',
                'start_in2',
                'end_in2',
                'delta_in2',
                'in3',
                'start_in3',
                'end_in3',
                'delta_in3',
                'in4',
                'start_in4',
                'end_in4',
                'delta_in4',
                'tariff1',
                'start_tariff1',
                'end_tariff1',
                'delta_tariff1',
                'tariff2',
                'start_tariff2',
                'end_tariff2',
                'delta_tariff2',
                'tariff3',
                'start_tariff3',
                'end_tariff3',
                'delta_tariff3',
                'tariff4',
                'start_tariff4',
                'end_tariff4',
                'delta_tariff4',
                'tariff5',
                'start_tariff5',
                'end_tariff5',
                'delta_tariff5'
            ];
        }
        const result = mainArr.filter(function (field) {
            return columns.indexOf(field) !== -1;
        });
        return result.concat(columns.filter((el) => !result.includes(el)));
    }

    getFormatedDate(value) {
        return moment.unix(this.dateManipulations.dateLocal(value)).utc().format('DD.MM.YYYY HH:mm');
    }

    typeof(value) {
        return typeof value;
    }

    unique(arr) {
        const obj = {};
        for (let i = 0; i < arr.length; i++) {
            const str = arr[i];
            obj[str] = true;
        }
        return Object.keys(obj);
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    paginatorChanges(event: PageEvent) {
        this.isLoadingResults = true;
        this.previousPageIndex = event.previousPageIndex;
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.devicesService
            .getMessagesByTypes(
                this.device.id,
                this.devicesService.dateForm.startDate,
                this.devicesService.dateForm.stopDate,
                this.typeJournal,
                event.previousPageIndex,
                event.pageIndex + 1,
                event.pageSize,
            )
            .pipe(takeUntil(this.destroyed$))
            .subscribe((res: any) => {
                if (res.data.messages.data.length == 0) {
                    this.dataSource.data = [];
                    setTimeout(() => {
                        this.isLoadingResults = false;
                    }, 500);
                    return;
                }
                if (res.data.messages) {
                    this.pageIndex = res.data.messages.current_page - 1;
                    this.pageSize = res.data.messages.per_page;
                    this.itemsLength = res.data.messages.total;
                    this.dataSource.data = res.data.messages.data;

                    setTimeout(() => {
                        this.isLoadingResults = false;
                    }, 200);
                }
            });
    }
    downloadExcel() {
        const sortConfig = this.dataSource.sort;
        let messages = _.orderBy(
            _.cloneDeep(this.dataSource.data),
            [sortConfig.active],
            [sortConfig.direction || 'desc']
        );
        let displayedFields = _.cloneDeep(this.displayedColumns).filter(
            (c) => c != 'delete_message' && c !== 'reason' && c !== 'message_api' && c !== 'method'
        );
        displayedFields = this.sortFields(displayedFields, this.availableColumns);
        let workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet(this.device.name, {
            pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 }
        });
        let headers = [];
        _.each(displayedFields, (column) => {
            headers.push(this.fieldsTitles[column].title || column);
        });

        let headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFDDDDDD' },
                bgColor: { argb: 'FFFFFFFF' }
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            cell.alignment = { wrapText: true, indent: 1 };
        });
        _.each(messages, (message: any) => {
            let rowArr = [];
            for (let key of displayedFields) {
                if (key === 'datetime' || key === 'realdatetime' || key === 'created_at') {
                    rowArr.push(this.getFormatedDate(message[key]));
                } else {
                    rowArr.push(message[key] !== undefined && message[key] !== null ? message[key] : '-');
                }
            }
            let newRow = worksheet.addRow(rowArr);
            newRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' },
                    bgColor: { argb: 'FFFFFFFF' }
                };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
                cell.alignment = { wrapText: true, indent: 1 };
                //cell.alignment = { indent: 1 };
            });
        });
        _.each(displayedFields, function (column, index) {
            worksheet.getColumn(index + 1).width = 25;
        });
        const downloadMsg = this.translate.instant('devices.data.msg_3', {deviceName: this.device.name});
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            fs.saveAs(
                blob,
                `${downloadMsg} ${moment
                    .unix(this.dateManipulations.dateLocal(this.devicesService.dateForm.startDate))
                    .utc()
                    .format('DD.MM.YYYY')}
                     ${this.translate.instant('devices.data.msg_4')}
                     ${moment
                    .unix(this.dateManipulations.dateLocal(this.devicesService.dateForm.stopDate))
                    .utc()
                    .format('DD.MM.YYYY')}.xlsx`
            );
        });
    }
}
