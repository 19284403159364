<div *ngIf="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>

<div
    *ngIf="
        !spinner &&
        data.type !== 'archive_base_station' &&
        data.type !== 'archive_gateway' &&
        data.type !== 'default' &&
        data.type !== 'block_notification' &&
        data.type !== 'toggle_is_active_company' &&
        data['deleteType'] !== 'delete_by_confirm' &&
        deleteIn !== 'delete'
    "
>
    <h2 mat-dialog-title>{{ data.name }}</h2>
    <div class="error">
        {{ data.message }}
    </div>
    <!--    <div style="margin-top: 8px" *ngIf="data.type && data.type === 'subscribe newsletter'">-->
    <!--        <mat-checkbox [(ngModel)]="subscribeNewsletter">{{'app_popup.msg_18' | translate}}</mat-checkbox>-->
    <!--    </div>-->
    <mat-dialog-actions>
        <button mat-button mat-flat-button color="primary"
                (click)="close()">{{ 'app_popup.msg_20' | translate }}</button>
    </mat-dialog-actions>
</div>

<div *ngIf="data.type === 'archive_base_station'">
    <p>{{ 'app_popup.msg_1' | translate: {station: data['base_station_name']} }}
    </p>
    <p>{{ 'app_popup.msg_2' | translate }}</p>
    <mat-dialog-actions style="float: right">
        <button mat-button mat-flat-button (click)="close()">{{ 'app_popup.msg_3' | translate }}</button>
        <button mat-button mat-flat-button color="primary" (click)="archiveBaseStation(data['base_station_id'])">
            {{ 'app_popup.msg_4' | translate }}
        </button>
    </mat-dialog-actions>
</div>

<div
    *ngIf="
        !spinner && (deleteIn === 'delete' || data.type === 'archive_gateway') && data.type !== 'archive_base_station'
        && data['deleteType'] !== 'delete_by_confirm' && data['deleteType'] !== 'block_notification'
        && data.type !== 'delete_custom_template'
    "
>
    <p *ngIf="data.type !== 'delete_object_element' &&
              data.type !== 'delete_group_tasks_poll' &&
              data.type !== 'delete_all_survey_tasks_for_device' &&
              data.type !== 'delete_all_tasks_for_survey_group'"
       style="margin-top: 0">
         {{ data.type === 'archive_gateway' ?
        ('app_popup.msg_30' | translate: {name:data.name}) : ('app_popup.msg_7' | translate) }}
    </p>

    <p *ngIf="data.type === 'delete_object_element'" style="margin-top: 0">
        {{ 'app_popup.msg_5' | translate }} {{ data.type === 'archive_gateway' ?
        ('app_popup.msg_7' | translate) : ('app_popup.msg_7' | translate) }}
        <strong>"{{ data.name }}"</strong>?
    </p>

    <p *ngIf="data.type === 'delete_group_tasks_poll'"
       style="margin-top: 0">
        {{'app_popup.msg_211'| translate}} {{ this.data.selectedDevices.length }} {{'app_popup.msg_215'| translate}}</p>
    <p>

    <p *ngIf="data.type === 'delete_all_survey_tasks_for_device' && data.device_id"
       style="margin-top: 0">
        {{'app_popup.msg_211'| translate}} {{'app_popup.msg_212'| translate}} {{ this.data.device_id }}?</p>
    <p>

    <p *ngIf="data.type === 'delete_all_survey_tasks_for_device' && !data.device_id"
       style="margin-top: 0">
        {{'app_popup.msg_211'| translate}} {{'app_popup.msg_214'| translate}}</p>
    <p>

    <p *ngIf="data.type === 'delete_all_tasks_for_survey_group'"
       style="margin-top: 0">
        {{'app_popup.msg_211'| translate}} {{'app_popup.msg_213'| translate}} {{ this.data.poll_group_id }}?</p>
    <p>

    <p *ngIf="data.type === 'delete_base_station' && !data['deleteType']">
        {{ 'app_popup.msg_2' | translate }}
    </p>
    <p *ngIf="data.type !== 'archive_gateway'">
        {{ 'app_popup.msg_9' | translate }}
        <b>{{ typeof(data.params) === 'object' ? data.params['pin'] : data.params }}</b>
        {{ 'app_popup.msg_10' | translate }}
    </p>
    <p class="warning-message" *ngIf="this.data?.params?.type === 'folder'">
        {{ 'app_popup.msg_11' | translate }}
        <strong>{{ 'app_popup.msg_12' | translate }}</strong>
    </p>

    <p *ngIf="data.type === 'delete_object_element'">
        <mat-checkbox
            class="example-margin"
            [(ngModel)]="deleteMeteringPoints">
            {{ 'app_popup.msg_13' | translate }}
        </mat-checkbox>
    </p>
    <div class="error" *ngIf="data.type !== 'archive_gateway'">
        <mat-form-field floatLabel="always" appearance="outline" class="name" style="width: 100%">
            <mat-label>ID</mat-label>
            <input [(ngModel)]="deleteId" matInput required/>
        </mat-form-field>
    </div>
    <mat-dialog-actions style="float: right">
        <button mat-button mat-flat-button (click)="closeConfirm()">{{ 'app_popup.msg_3' | translate }}</button>
        <button mat-button mat-flat-button color="primary" (click)="confirmDelete()">
            {{ 'app_popup.msg_8' | translate }}, {{ data.type === 'archive_gateway' ?
            ('app_popup.msg_6' | translate) : ('app_popup.msg_7' | translate) }}
        </button>
    </mat-dialog-actions>
</div>

<div *ngIf="!spinner && data.type === 'default' || data.type === 'delete_custom_template' || data.type === 'toggle_is_active_company'">
    <h2 *ngIf="data.type !== 'toggle_is_active_company'" mat-dialog-title style="margin: 0 0 0">{{ data.title }}</h2>
    <ng-container *ngIf="data.type === 'toggle_is_active_company'">
        <p *ngIf="!data.isActive">
            {{ 'app_popup.msg_34' | translate: {name: data.title} }}</p>
        <p *ngIf="data.isActive">
            {{ 'app_popup.msg_35' | translate: {name: data.title} }}</p>
    </ng-container>
    <div class="error">
        <p class="info-message" *ngIf="data.body">{{ data.body }}</p>
    </div>
    <ng-container *ngIf="data.type === 'toggle_is_active_company'">
        <mat-checkbox *ngIf="!data.isActive" [(ngModel)]="data.isDevice">
            {{'app_popup.msg_33' | translate}}
        </mat-checkbox>
        <mat-checkbox *ngIf="data.isActive" [(ngModel)]="data.isDevice">
            {{'app_popup.msg_32' | translate}}
        </mat-checkbox>
    </ng-container>

    <mat-dialog-actions style="float: right">
        <button mat-button mat-flat-button (click)="closeConfirm()">{{ 'app_popup.msg_3' | translate }}</button>
        <button mat-button mat-flat-button color="primary"
                (click)="confirmDelete()">{{ 'app_popup.msg_8' | translate }}</button>
    </mat-dialog-actions>
</div>

<div *ngIf="!spinner && data['deleteType'] === 'delete_by_confirm'">
    <h2 mat-dialog-title style="margin: 0 0 0">{{ data.title }}</h2>
    <div class="error">
        <ng-container *ngIf="data.body">
            <p class="info-message" [innerHTML]="data.body"></p>
        </ng-container>
    </div>
    <form class="confirm-delete-form"
          [formGroup]="deleteByConfirmForm" (ngSubmit)="onConfirmDelete()">
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>Id</mat-label>
            <input matInput formControlName="deviceId" type="text" name="id" required/>
        </mat-form-field>
    </form>
    <mat-dialog-actions style="float: right">
        <button mat-button mat-flat-button type="button"
                (click)="closeConfirm()">{{ 'app_popup.msg_3' | translate }}</button>
        <button mat-button mat-flat-button type="submit"
                [disabled]="deleteByConfirmForm.invalid"
                color="primary" (click)="onConfirmDelete()">{{ 'app_popup.msg_8' | translate }}</button>
    </mat-dialog-actions>
</div>
<div *ngIf="data.type === 'block_notification'">
    <h2 mat-dialog-title>{{ data.name }}</h2>
    <p class="info-message">
        {{ 'app_popup.msg_14' | translate }}
    </p>
    <p class="info-message">
        <ng-container *ngIf="data.day === 1">
            {{ 'app_popup.msg_15' | translate: {day: data.day} }}
        </ng-container>
        <ng-container *ngIf="data.day >= 2 && data.day < 5">
            {{ 'app_popup.msg_16' | translate: {day: data.day} }}
        </ng-container>
        <ng-container *ngIf="data.day === 5">
            {{ 'app_popup.msg_17' | translate: {day: data.day} }}
        </ng-container>
    </p>
    <button mat-button mat-flat-button color="primary" (click)="close()">{{ 'app_popup.msg_20' | translate }}</button>
</div>
