import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DevicesService } from '../../../services/devices.service';
import { SnackbarService } from '@core/services/snackbar.service';

@Component({
    selector: 'app-tariff-setting',
    templateUrl: './tariff-setting.component.html',
    styleUrls: ['./tariff-setting.component.less']
})
export class TariffSettingComponent implements OnInit {
    @Input() properties?;

    @Input() status?;

    @Input() isSpecialDays?;

    @Input() id: number;

    @Output() updateForSpecialDays = new EventEmitter();

    tariff = 1;
    tariff1 = '10:00';
    tariff1S = '21:00';
    tariff2 = '07:00';
    tariff3 = '17:00';
    tariff3S = '23:00';
    tariff4 = '00:00';

    constructor(private _devicesService: DevicesService, private snackbar: SnackbarService) {}

    ngOnInit() {
        this.getCurrentTariffScheduleDevice(this.status || this.properties);
    }

    changeTime() {
        this.tariff === 2 ? (this.tariff1 = '23:00') : null;
        this.tariff === 3 ? (this.tariff1 = '10:00') : null;
        this.updateAtAnyChanges();
    }

    getCurrentTariffScheduleDevice(data) {
        if (data) {
            this.tariff = Object.keys(data).length;
            const keysTariff = Object.keys(data);
            switch (this.tariff) {
                case 1:
                    this.tariff1 = data[keysTariff[0]][0];
                    break;
                case 2:
                    this.tariff1 = data[keysTariff[0]][0];
                    this.tariff2 = data[keysTariff[1]][0];
                    break;
                case 3:
                    this.tariff1 = data[keysTariff[0]][0];
                    this.tariff1S = data[keysTariff[0]][1];
                    this.tariff2 = data[keysTariff[1]][0];
                    this.tariff3 = data[keysTariff[2]][0];
                    this.tariff3S = data[keysTariff[2]][1];
                    break;
                case 4:
                    this.tariff1 = data[keysTariff[0]][0];
                    this.tariff2 = data[keysTariff[1]][0];
                    this.tariff3 = data[keysTariff[2]][0];
                    this.tariff4 = data[keysTariff[3]][0];
                    break;
            }
        }
    }

    sendTariff() {
        if (!this.isSpecialDays) {
            this._devicesService.setTariff(this.id, this.getTariffData()).subscribe(() => {
                this.snackbar.open('devices.edit.success', true);
            });
        }
    }

    updateAtAnyChanges() {
        this.updateForSpecialDays.emit(this.getTariffData());
    }

    private getTariffData() {
        let data = {};
        switch (this.tariff) {
            case 1:
                data = { tariff0: ['00:00'] };
                break;
            case 2:
                data = {
                    tariff0: [this.tariff1],
                    tariff1: [this.tariff2]
                };
                break;
            case 3:
                data = {
                    tariff0: [this.tariff1, this.tariff1S],
                    tariff1: [this.tariff2],
                    tariff2: [this.tariff3, this.tariff3S]
                };
                break;
            case 4:
                data = {
                    tariff0: [this.tariff1],
                    tariff1: [this.tariff2],
                    tariff2: [this.tariff3],
                    tariff3: [this.tariff4]
                };
                break;
        }
        return data;
    }
}
